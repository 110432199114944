import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button/Button";
import UserContext from "../context/modal/Contextmodel";
import { isNull } from "lodash";
import { useSavedSearchMutation } from "../Services/Issuer/Search/IssuerSearchApi";
import { getKeyValuePair, showToastify } from "../constants/Common";

const ModalFooter = ({ ModuleName, postApiCall }) => {
  const [saveSearch, saveSearchData] = useSavedSearchMutation();
  const {
    setSaveModal,
    savedResultValue,
    SetSavedResultValue,
    count,
    setIsSaved,
    setsavedsearcheckbox,
    checkBoxFilter,
    savedsearcheckbox,
    pageNumber,
    searchTextTyped,
    savedSearchData,
    checkedValues,
    sortColumnName,
    sortOrder,
    tempSelectedFields,
    editViewMenuId
  } = useContext(UserContext);
  const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
  let checkedid = savedsearcheckbox;
  const initialValues =
    savedResultValue.length >= 1
      ? {
        Name: savedResultValue,
        moduleName: ModuleName,
        savedSearchViewId: editViewMenuId,
        searchQueryJson: {
          searchText: searchTextTyped === "*" ? "" : searchTextTyped,
          useDefaultFacets: true,
          sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
          fieldNamesToReturn: tempSelectedFields,
          fieldNamesToSearch: [],
          facetOptions: null,
          facetFilters: checkedValues,
          searchIndexName: "string",
          currentPage: count,
          pageSize: pageNumber,
        },

      }
      : {
        saveSearchId: checkedid,
        savedSearchViewId: editViewMenuId,
        searchQueryJson: {
          searchText: "*",
          useDefaultFacets: true,
          sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
          fieldNamesToReturn: tempSelectedFields,
          fieldNamesToSearch: [],
          facetOptions: null,
          facetFilters: checkedValues,
          searchIndexName: "string",
          currentPage: count,
          pageSize: pageNumber,
        },
        moduleName: ModuleName,
      };

  const closeModal = () => {
    setsavedsearcheckbox("");
    setSaveModal(false);
  };
  const saveModal = () => {
    setIsSaved(true);
    setsavedsearcheckbox("");
    saveSearch(initialValues);
    postApiCall({
      ...checkBoxFilter,
      searchText: searchTextTyped,
      fieldNamesToReturn: tempSelectedFields,
      currentPage: count,
      facetFilters: checkedValues,
      pageSize: pageNumber,
      sortOrderfilters: isNull(sortOrder) ? savedSearchData?.sortOrderfilters : sortFilter,
    })
  };
  useEffect(() => {
    if (saveSearchData?.isSuccess) {
      showToastify('success', 'SavedSearch added sucessfully');
      setSaveModal(false);
    }
    else if (saveSearchData && saveSearchData.error && saveSearchData.error.data && saveSearchData.error.data.errors && saveSearchData.error.data.errors.Name) {
      showToastify('warning', saveSearchData?.error?.data?.errors?.Name[0]);
      SetSavedResultValue("");
      setSaveModal(false);

    }
  }, [saveSearchData]);
  return (
    <>
      <div>
        <div className="grid grid-rows-1 grid-flow-col gap-4 mt-4">
          <Button value="Cancel" variant="secondary" onClick={closeModal} />
          {savedResultValue.length >= 1 || savedsearcheckbox >= 1 ? (
            <Button
              className="bg-blue-500 text-white font-bold  rounded  "
              value="Save"
              variant="primary"
              onClick={saveModal}
            />
          ) : (
            <Button
              className="bg-blue-500 text-white font-bold  rounded opacity-50 cursor-not-allowed "
              value="Save"
              variant="primary"
              disabled={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ModalFooter;
