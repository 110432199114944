import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { useUpdateUserMutation } from "../../../Services/Admin/ManageUsers/GetUserById";

const EditManageUsersModal = (props) => {
    const [enabled, setEnabled] = useState(props?.userDetail?.data?.isActive === true ? true : false);
    const [updateUser] = useUpdateUserMutation();

    useEffect(() => {
        props?.getAllManageUsersList?.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUser]);

    return (
        <div>
            <h3>Display Name</h3>
            <input
                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                type="text"
                style={{ backgroundColor: "rgb(243,244,246)" }}
                value={props?.userDetail?.data?.fullName}
                readOnly
            />
            <h3 className="mt-5">First Name</h3>
            <input
                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                type="text"
                style={{ backgroundColor: "rgb(243,244,246)" }}
                value={props?.userDetail?.data?.firstName}
                readOnly
            />
            <h3 className="mt-5">Last Name</h3>
            <input
                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                type="text"
                style={{ backgroundColor: "rgb(243,244,246)" }}
                value={props?.userDetail?.data?.lastName}
                readOnly
            />
            <h3 className="mt-5">Email</h3>
            <input
                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                type="text"
                style={{ backgroundColor: "rgb(243,244,246)" }}
                value={props?.userDetail?.data?.email}
                readOnly
            />
            <div className="flex">
                <h3 className="mt-8">Is Active?</h3>
                <Switch
                    checked={enabled}
                    disabled={props.userId.length === 0 || !props.canEdit}
                    onChange={() => {
                        const newEnabled = !enabled;
                        setEnabled(newEnabled);
                        const newIsActive = newEnabled ? true : false;
                        updateUser({ userId: props?.userDetail?.data?.userId, isActive: newIsActive, roleIds: props?.roleId });
                    }}
                    className={`${enabled ? "bg-primary" : "bg-gray-500"}
          relative inline-flex mx-4 mt-8 h-[20px] w-[45px] shrink-0 ${props.canEdit?"cursor-pointer":"cursor-not-allowed"} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                    <span
                        aria-hidden="true"
                        className={`${enabled ? "translate-x-5 bg-white" : "translate-x-0 bg-white"}
            pointer-events-none inline-block h-[16px] w-[20px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                </Switch>
                <h3 className=" mx-3 mt-8">{enabled ? "Yes" : "No"}</h3>
            </div>
        </div>
    );
};

export default EditManageUsersModal;
