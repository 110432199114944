import React, { useEffect, useState } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import Header from "../components/Header";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/dashboard";
import IssuerDashboard from "../pages/issuer/Dashboard";
import IssuerList from "../pages/IssuerList";
import MasterResult from "../pages/masterResult";
import ProtectedRoute from "./ProtectedRoute";
import { useIsAuthenticated } from "@azure/msal-react";
import IssuerDetails from "../pages/IssuerDetails/IssuerDetails";
import { Portfolio } from "../pages/Portfolio/Portfolio";
import Admin from "../pages/Admin/Admin";
import IssuerWatchList from "../pages/IssuerWatchList/IssuerWatchList";
import EliminateMain from "../pages/EliminateIssuer/EliminateMain";
import Productdashboard from "../pages/Product/Index";
import { useGetPermissionMutation } from "../Services/Permission/GetPerMissonApi";
import UserContext from "../context/modal/Contextmodel";
import ProductDetails from "../pages/Product/ProductComponents/ProductDetails";
import ProductWatchList from "../pages/Product/ProductWatchlist/ProductWatchlist";

// import Productdashboard from "../pages/Product/Index.js";
const Router = () => {
    const isAuthenticated = useIsAuthenticated();
    const [PermissionApi, Permission] = useGetPermissionMutation();
    useEffect(() => {
        if (Permission?.error?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace("/");
        }
    }, []);

    const [Permissionapi, setPermissionApi] = useState();
    useEffect(() => {
        PermissionApi();
    }, []);
    useEffect(() => {
        if (Permission.isSuccess === true) {
            setPermissionApi(Permission?.data?.permissions);
        }
    }, [Permission]);
    const Layout = () => {
        return (
            <div className="h-full flex w-full flex-col">
                <Outlet />
            </div>
        );
    };
    const HeaderSection = () => {
        return (
            <React.Fragment>
                <UserContext.Provider value={{ Permissionapi }}>
                    <Header />
                    <Outlet />
                </UserContext.Provider>
            </React.Fragment>
        );
    };
    useEffect(() => {
        if (window.location.pathname === '/') {
          window.location.replace("/dashboard");
        }
      }, []);
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/" element={<Login />} />
                <Route path="/about" element={<Login />} />
                <Route element={<HeaderSection />}>
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/issuer/:tab"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <IssuerDashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/:tab"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <Admin />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/productwatchlist"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <ProductWatchList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/issuerdetails"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <IssuerDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/eliminate/:tab"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <EliminateMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/issuerwatchlist"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <IssuerWatchList />
                            </ProtectedRoute>
                        }
                    />
                    {/* <Route
                        path="/Portfolio"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <Portfolio />
                            </ProtectedRoute>
                        }
                    /> */}
                    <Route
                        path="/Product/:tab"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                {/* <div className="flex justify-center h-full items-center p-4">
                                    <img src={comingSoon} className="lg:w-2/9 mt-5" />
                                </div> */}
                                <Productdashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/result"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <MasterResult />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/issuerlist"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <IssuerList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/productDetails"
                        element={
                            <ProtectedRoute isSignedIn={isAuthenticated}>
                                <ProductDetails />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Route>
        </Routes>
    );
};

export default Router;
