import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { LoginButton } from "../../components/LoginButton/LoginButton";
import logo from "../../assets/images/logo.svg";
import banner from "../../assets/images/banner.svg";
import elevateNew from "../../assets/images/elevateNew.png";
import { LOGIN } from "../../constants/appConstants";
import { loginRequest } from "../../globals/authConfig";
import { reftoken } from "../../constants/Common";
// export const ReDirectToDashboard = () => {
//   const location = useLocation();
//   console.log(location.pathname, "location");
//   return (
//     <Navigate
//       to={
//         sessionStorage.getItem("currentPath") === null
//           ? "/dashboard"
//           : location.pathname
//       }
//     />
//   );
// };
const Login = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();


  useEffect(() => {
    if (!isAuthenticated) {
      RequestProfileData();
    }
  }, []);

  async function RequestProfileData() {
    const maxRetries = 3; // Maximum number of authentication retries
    const retryDelay = 2000; // Delay in milliseconds between retries
    const [retryCount, setRetryCount] = useState(0);
    // Silently acquires an access token which is then attached to a request for MS Graph data
    while (retryCount < maxRetries) {
      try {
        // Attempt authentication
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        // Authentication succeeded
        localStorage.setItem("ACCESS_TOKEN", response.accessToken);
        reftoken();
        break; // Exit the retry loop
      } catch (error) {
        // Handle authentication error
        console.error(
          `Authentication attempt ${retryCount + 1} failed:`,
          error
        );
        setRetryCount(retryCount + 1);
        if (retryCount < maxRetries) {
          // Wait for a delay before the next retry
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
        } else {
          // Max retries reached, handle the failure here
          console.error("Max retries reached. Authentication failed.");
          // You can choose to redirect the user or display an error message.
          // For example, you can use navigate to redirect to an error page:
          // navigate("/error");
        }
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
      localStorage.removeItem("REFRESH_TOKEN");
    }
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      <header className="p-4 flex gap-3 justify-between">
        <div className="lg:w-1/6">
          <img src={logo} alt="One Ascent" className="w-full" />
        </div>
        <nav className="flex self-center gap-4"></nav>
      </header>
      <main className="flex h-full items-center px-4 gap-12 w-full justify-between">
        <div className="hidden md:flex flex-col gap-4 xl:w-1/3">
          <h1 className="text-3xl  whitespace-nowrap">{LOGIN.WELCOME}</h1>
          <img src={banner} alt="banner" className="xl:w-2/6 fixed bottom-0" />
        </div>
        <img
          src={elevateNew}
          className="hidden md:block 2xl:w-96"
          alt=""
          style={{ marginLeft: "16%", width: "20%" }}
        />
        <div className="flex justify-center">
          <div className="bg-primary_opacity-100 p-6 rounded flex flex-col gap-4 lg:w-1/2">
            <h3 className="text-xl">{LOGIN.SIGNIN}</h3>
            <p>{LOGIN.LOGINMSG}</p>
            {!isAuthenticated ? (
              <LoginButton />
            ) : (
              <Navigate
                to={
                  sessionStorage.getItem("currentPath") === null
                    ? "/dashboard"
                    : window.location.pathname
                }
              />
            )}
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Login;
