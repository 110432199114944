import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { has, isArray, isEmpty, isNull } from "lodash";
import { ToastContainer } from "react-toastify";

import { useDeleteProductWatchListApiMutation, useGetProductWatchListMutation } from "../../../Services/Product/ProductApi";
import Modal from "../../../components/Modals/Modals";
import DeleteFooter from "../../DeleteFooter";
import TailwindTable from "../../../components/Table/TailwindTable";
import UserContext from "../../../context/modal/Contextmodel";
import Badge from "../../../components/Badge/Badge";
import { IssuerWatchListColumnToRemove, StaticIssuerProductWatchlistHeader, capitalizeFirstLetter, getNormalDate, showToastify } from "../../../constants/Common";
import { ISSUER } from "../../../constants/appConstants";

function ProductWatchList() {
	const location = useLocation();
	const Navigate = useNavigate();
	const checkSort = localStorage.getItem(ISSUER.ISSUERSORTING);
	const checkSortHeader = localStorage.getItem(ISSUER.ISSUERSORTINGHEADER);
	const watchListIdsession= sessionStorage.getItem("Id")
	const masterpath=sessionStorage.getItem("masterSearchPath")
	const [saveModal, setSaveModal] = useState(false);
	const [isDropdownVisible, setIsDropdownVisible] = useState(true);
	const [data, setData] = useState([]);
	const [selectColumns, setSelectColumns] = useState([]);
	const [pageNumber, setPageNumber] = useState(5);
	const [count, setCount] = useState(1);
	const [issuerwatchlistname, setissuerWatchlistname] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const [shareInputValue, setShareInputValue] = useState("");
	const [shareDropDownValue, setShareDropDownValue] = useState("");
	const pageArray = [5, 10];
	const [sortColumnName, setSortColumnName] = useState(checkSortHeader);
	const [sortOrder, setSortOrder] = useState(checkSort);
	const watchlistId = location.state?.data;
	const [deleteIssuerWatchList, deleteIssuerWatchListData] = useDeleteProductWatchListApiMutation();
	const [GetWatchList, GetWatchListData] = useGetProductWatchListMutation();
	const valueToPass = isNull(watchlistId) || isEmpty(watchlistId)
		? watchListIdsession
		: watchlistId;
	useEffect(() => {
		if (
			GetWatchListData?.isSuccess &&
			has(GetWatchListData, ["data"]) &&
			isArray(GetWatchListData?.data?.productWatchList?.items)&&
			GetWatchListData?.data?.productWatchList?.items?.length > 0
		) {
			setData(GetWatchListData?.data?.productWatchList?.items);
			const fields = Object.keys(GetWatchListData?.data?.productWatchList?.items[0]);
			const resultArr = [];
			for (let i = 0; i < StaticIssuerProductWatchlistHeader.length; i++) {
				const index = fields.indexOf(StaticIssuerProductWatchlistHeader[i]);
				if (index > -1) {
					resultArr.push(fields[index]);
				}
			}

			for (let i = 0; i < IssuerWatchListColumnToRemove.length; i++) {
				const index = resultArr.indexOf(IssuerWatchListColumnToRemove[i]);
				if (index > -1) {
					resultArr.splice(index, 1);
				}
			}
			setSelectColumns(resultArr);
		}
	}, [GetWatchListData]);

	const sortAPI = () => {	
		GetWatchList({
			watchListId: valueToPass,
			PageNumber: count,
			PageSize: pageNumber,
			orderBy: capitalizeFirstLetter(sortColumnName==="Product Id"? "bloombergId":sortColumnName==="Name"?"name":sortColumnName),
			sortOrder: sortOrder ? sortOrder : 0,
		});
	};
	useEffect(() => {
		
		GetWatchList({
			watchListId: valueToPass,
			PageNumber: count,
			PageSize: pageNumber,
			orderBy: capitalizeFirstLetter(sortColumnName),
			sortOrder: isNull(sortOrder) ? 0 : sortOrder,
		});
	}, [pageNumber, count]);
	useEffect(() => {
		sortAPI();
	}, [sortOrder]);
	useEffect(() => {
		
		if (deleteIssuerWatchListData?.isSuccess) {
			showToastify('success', 'Deleted Successfully');
			GetWatchList({
				watchListId: valueToPass,
				PageNumber: count,
				PageSize: pageNumber,
				orderBy: capitalizeFirstLetter(sortColumnName),
				sortOrder: isNull(sortOrder) ? 0 : sortOrder,
			});
		}
	}, [deleteIssuerWatchListData]);

	const iconClick = (id, name) => {
		setSaveModal(true);
		setDeleteId(id);
		setissuerWatchlistname(name);
	};

	const handleApiCall = () => {
		deleteIssuerWatchList({ id: deleteId });
		setSaveModal(false);
		
		GetWatchList({
			watchListId: valueToPass,
			PageNumber: count,
			PageSize: pageNumber,
			orderBy: capitalizeFirstLetter(sortColumnName),
			sortOrder: isNull(sortOrder) ? 0 : sortOrder,
		});
	};

	const columns = selectColumns?.map((key, id) => {
		if (key === "name") {
			return {
				Header: "Name",
				accessor: key,
				 
			};
		}
		if (key === "bloombergId") {
			return {
				Header: "Product Id",
				accessor: key,
			};
		}
		if (key === "status") {
			return {
				Header: key,
				accessor: key,
				Cell: (e) => {
					return e?.value === "Pass" ? <Badge variant={"CircleSuccess"} /> : <Badge variant={"CircleFail"} />;
				},
			};
		} else {
			return {
				Header: key,
				accessor: key,
			};
		}
	});
	columns.unshift({
		Header: "Action",
		Cell: ({ row }) => <Badge variant='TrashRed' onClick={() => iconClick(row?.original?.id, row?.original?.name) } />,
	});


	return (
		<>
			<div className='h-full w-full overflow-auto flex flex-col'>
				<div className='flex flex-grow justify-between overflow-hidden border-b items-center py-4 md:p-4'>
					<div className='w-full h-full flex flex-col'>
						<div className='flex justify-between border-b'>
							<div className='pb-4 flex gap-3'>
								<h1 className='text-lg mb-1'>
									<i
										className='far fa-arrow-left cursor-pointer'
										onClick={() => {
											Navigate(masterpath?masterpath:-1);
										}}
									></i>
								</h1>
								<div>
									{GetWatchListData?.isSuccess && has(GetWatchListData, ["data"]) && !isEmpty(GetWatchListData?.data) && (
										<>
											<div className='flex items-center gap-2 text-sm'>
												<h1 className='text-2xl mb-1'>Product Watch List</h1>
												<div className='w-1.5 h-1.5 bg-gray-400 rounded-lg'></div>
												<h1 className='text-2xl mb-1'>{GetWatchListData?.data?.watchList?.name}</h1>
											</div>

											<div className='flex items-center gap-2 text-sm'>
												<p className='text-gray-400'>{GetWatchListData?.data?.watchList?.userName}</p>
												<div className='w-1.5 h-1.5 bg-gray-400 rounded-lg'></div>
												<p className='text-gray-400'>{getNormalDate(GetWatchListData?.data?.watchList?.lastModified)}</p>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<div className='h-full flex-col flex overflow-auto static-scroll'>
							<ToastContainer
								position='top-right'
								autoClose={5000}
								hideProgressBar={true}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								theme='light'
							/>

							{!isEmpty(GetWatchListData?.data?.productWatchList?.items) ? (

								<TailwindTable
									columns={columns}
									data={data}
									keyValue='productWatchlist'
									setCount={setCount}
									count={count}
									totalLength={GetWatchListData?.data?.productWatchList?.totalCount}
									pageNumber={pageNumber}
									setPageNumber={setPageNumber}
									pageArray={pageArray}
									sortColumnName={sortColumnName}
									setSortColumnName={setSortColumnName}
									sortOrder={sortOrder}
									setSortOrder={setSortOrder}
									path="/productDetails"
									returnPath={"/productwatchlist"}
								/>
							) : (
								<div className='flex flex-col'>
									<div className='-my-2 overflow-x-auto'>
										<div className='py-2 align-middle inline-block min-w-full'>
											<div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
												<p className='text-center'>No Data Found</p>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<UserContext.Provider
				value={{
					shareInputValue,
					setShareInputValue,
					shareDropDownValue,
					setShareDropDownValue,
					isDropdownVisible,
					setIsDropdownVisible,
				}}
			>
				<Modal
					showmodal={saveModal}
					setshowmodal={setSaveModal}
					title='Delete Issuer Watch List'
					body={
						<>
							Are you sure you want to delete "<b className='break-all'>{issuerwatchlistname}</b>"?
						</>
					}
					footer={<DeleteFooter onClick={handleApiCall} setSaveModal={setSaveModal} />}
					isFooterEnable={true}
					size={"w-96"}
				/>
				
			</UserContext.Provider>
		</>
	);
}

export default ProductWatchList;
