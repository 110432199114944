import React, { useContext, useEffect } from "react";
import Button from "../components/Button/Button";
import UserContext from "../context/modal/Contextmodel";
import { EDITVIEWFOOTER, ISSUER } from "../constants/appConstants";
import { COMMONCLASSNAME, getKeyValuePair, showToastify } from "../constants/Common";
import { useEditViewSaveMutation } from "../Services/Issuer/Search/IssuerSearchApi";
import { isNull } from "lodash";

const EditViewFooter = ({moduleName,postApiCall,AddID}) => {
    const {
        setEditView,
        editViewInput,
        setEditViewInput,
        searchTextTyped,
        tempSelectedValues,
        count,
        checkedValues,
        pageNumber,
        sortOrder,
        editViewReturnValue,
        setEditViewMenu,
        checkedFields,
        postIssuerList,
        checkBoxFilter,
    } = useContext(UserContext);
    const [editViewSave, editViewSaveData] = useEditViewSaveMutation();
    const sortFilter = getKeyValuePair(sortOrder?.sortColumnName, sortOrder?.sortOrder);
    const closeModal = () => {
        setEditView(false);
    };
    const saveEditView = () => {
        
        if(!isNull(AddID) && AddID !==undefined && editViewSaveData?.isSuccess){
            checkedFields.push(AddID);
            editViewReturnValue.push(AddID);
        }
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(ISSUER.ISSUERFILTER, myArrayString);
        editViewSave({
            name: editViewInput,
            fieldNameJson: {
                useDefaultFacets: true,
                fieldNamesToSearch: [],
                facetOptions: null,
                searchIndexName: "string",
                searchText: searchTextTyped,
                fieldNamesToReturn: editViewReturnValue?.length === 0 ? checkedFields : editViewReturnValue,
                currentPage: count,
                facetFilters: [],
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            },
            moduleName: moduleName
        });
    };
    useEffect(() => {
        if (editViewSaveData?.isSuccess) {
            showToastify("success", "EditView Saved successfully");
            postApiCall({
                ...checkBoxFilter,
                searchText: searchTextTyped,
                fieldNamesToReturn: editViewReturnValue?.length === 0 ? checkedFields : editViewReturnValue,
                currentPage: count,
                facetFilters: checkedValues,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            });
            setEditView(false);
            setEditViewMenu(editViewInput);
            setEditViewInput("");
        } else if (editViewSaveData?.isError) {
            showToastify("error", editViewSaveData?.error?.data?.errors?.Name[0]);
            setEditView(false);
        }
    }, [editViewSaveData]);
    return (
        <div className=" w-full">
            <div className="flex flex-col">
                <p className="block  mb-2 ">{EDITVIEWFOOTER?.ENTERNAME}</p>
                <input
                    type="text"
                    id="small-input"
                    value={editViewInput}
                    onChange={(e) => setEditViewInput(e.target.value)}
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
            </div>

            <div className="grid grid-rows-1 grid-flow-col mt-4 gap-4 ">
                <Button value="Cancel" variant="secondary" onClick={closeModal} />

                <Button
                    className={editViewInput.length === 0 && COMMONCLASSNAME?.BUTTONDISABLE}
                    disabled={editViewInput.length === 0}
                    value="Save"
                    variant="primary"
                    onClick={saveEditView}
                />
            </div>
        </div>
    );
};

export default EditViewFooter;
