import { interceptorsApiCall } from "../../globals/interceptors";

export const GetIssuerStackholderGraphDetailsApi =
  interceptorsApiCall.injectEndpoints({
    endpoints: (builder) => ({
      GetIssuerStackholderGraphDetails: builder.query({
        query: (params) =>
          `/api/Issuer/GetIssuerStackholderGraphData?${params}`,
      }),
    }),
  });

export const { useGetIssuerStackholderGraphDetailsQuery } =
  GetIssuerStackholderGraphDetailsApi;
