import React, { useContext } from "react";
import UserContext from "../../../context/modal/Contextmodel";
import Button from "../../../components/Button/Button";

const UserReviewDocumentModalFooter = () => {
    const { setDocumentModalView } = useContext(UserContext);
    const closeModal = () => {
        setDocumentModalView(false);
    };
    return (
        <div>
            <div className="grid grid-rows-1 grid-flow-col mt-4 gap-4 ">
                <Button value="Cancel" variant="secondary" onClick={closeModal} />
                {/* <Button className="cursor-pointer" disabled={true} value="Add" variant="primary" onClick={closeModal} /> */}
            </div>
        </div>
    );
};

export default UserReviewDocumentModalFooter;
