import React, { useEffect, useContext, useState } from "react";
import Button from "../../components/Button/Button";
import UserContext from "../../context/modal/Contextmodel";
import { usePostCreateUserReviewMutation, usePostUserReviewDocumentMutation } from "../../Services/Issuer/UserReview/UserReviewApi";

const AddUserReviewModalFooter = (props) => {
    const isuseruserid = sessionStorage.getItem("userid");
    const [buttonDisabled,setButtonDisabled] = useState(false)
    const [PostUserReviewDocument, PostUserReview] = usePostUserReviewDocumentMutation();
    const [PostCreateUserReview, PostCreated] = usePostCreateUserReviewMutation();
    const { setUserid,setToast,tabchange,issueruseridstate,setiissueruseridstate, checkvalue ,fileuploaded,setfileuploaded, setuserreviewtoaster,addUserReviewModal,apibody,setapibody} = useContext(UserContext);
    const closeModal = () => {
        sessionStorage.removeItem("userid");
        props.setAddUserReviewModal(false);
    };
    useEffect(()=>{
      if(PostUserReview.isSuccess)
      {
        props.setAddUserReviewModal(false);
        sessionStorage.removeItem("userid");
        setToast(true);
      }
    },[PostUserReview])
    useEffect(()=>{
      if(addUserReviewModal===false)
      {
        sessionStorage.removeItem("userid");
      }
    },[addUserReviewModal])
    
    useEffect(()=>{
      if(PostCreated.isSuccess)
      {
        setiissueruseridstate(PostCreated?.data.id);
      }
      if(PostCreated.isSuccess && tabchange==="details")
      {
        props.setAddUserReviewModal(false)
        setuserreviewtoaster(true);
      }
    },[PostCreated])
    
    const handleadd = async () => {
      setButtonDisabled(true);
      await PostCreateUserReview(apibody);
        // } else {
        //   const response = await PostCreateUserReview(props?.apibody);
        //   if(response.data.id)
        //   {
        //     setuserreviewtoaster(true);
        //   }
        //   if (isuseruserid !== null && isuseruserid !== undefined) {
        //     await sessionStorage.setItem("userid", response.data.id);
        //   }
        //   props.setAddUserReviewModal(false);
        // }
      };
      
      useEffect(()=>{
        if(fileuploaded)
        PostUserReviewDocument(props?.attachfile);
        setfileuploaded(false)
        setiissueruseridstate("");

      },[fileuploaded])
    return (
        <div>
            <div className="grid grid-rows-1 grid-flow-col mt-4 gap-4 ">
                <Button value="Cancel" variant="secondary" onClick={closeModal} />

                <Button
                    className={checkvalue && !buttonDisabled ? `cursor-pointer` : "opacity-50 cursor-not-allowed"}
                    value="Add"
                    variant="primary"
                    disabled={buttonDisabled}
                    onClick={checkvalue ? handleadd : ""}
                />
            </div>
        </div>
    );
};

export default AddUserReviewModalFooter;
