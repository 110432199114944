import { interceptorsApiCall } from "../../globals/interceptors";


export const GetIssuerSearch = interceptorsApiCall.injectEndpoints({
	endpoints: (builder) => ({
        getSavedSearchById: builder.query({
			query: (params) => `api/Issuer/GetSavedSearchById?${params}`,
		}),
    })
});

export const {useGetSavedSearchByIdQuery}=GetIssuerSearch;