import React from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useTable, useFilters, useGlobalFilter, usePagination, useBlockLayout, useExpanded } from "react-table";
import { useDispatch } from "react-redux";
import { classNames } from "../TailwindShared/Utils";
import Badge from "../Badge/Badge";
import { SortIcon, SortUpIcon, SortDownIcon } from "../TailwindShared/Icons";
import { checkBoxoption, stockValueoption } from "../../redux/actions/tableAction";
import { PAGECONSTANTS, formatNumber } from "../../constants/Common";
import { TAILWINDTABLE } from "../../constants/appConstants";

export function CheckBoxColumnFilter({ column: { preFilteredRows, id } }) {
    const dispatch = useDispatch();
    const options = React.useMemo(() => {
        let counts = {};
        preFilteredRows.forEach((x) => {
            x = x.values[id];
            counts[x] = (counts[x] || 0) + 1;
        });
        return counts;
    }, [id, preFilteredRows]);
    dispatch(checkBoxoption(options));
    return <></>;
}
export function CheckBoxColumnFilter1({ column: { preFilteredRows, id } }) {
    const dispatch = useDispatch();
    const options = React.useMemo(() => {
        let counts = {};
        preFilteredRows.forEach((x) => {
            x = x.values[id];
            counts[x] = (counts[x] || 0) + 1;
        });
        return counts;
    }, [id, preFilteredRows]);
    dispatch(stockValueoption(options));
    return <></>;
}

export const MultipleFilter = (rows, filler, filterValue) => {
    const arr = [];
    rows.forEach((val) => {
        if (filterValue.includes(val.original.col1)) arr.push(val);
    });
    return arr;
};

export function StatusPill({ value }) {
    const status = value ? value.toLowerCase() : "unknown";

    return (
        <span
            className={classNames(
                "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
                status.startsWith("active") ? "bg-green-100 text-green-800" : null,
                status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
                status.startsWith("offline") ? "bg-red-100 text-red-800" : null
            )}
        >
            {status}
        </span>
    );
}

export function AvatarCell({ value, column, row }) {
    return (
        <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
                <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
            </div>
            <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">{value}</div>
                <div className="text-sm text-gray-500">{row.original[column.emailAccessor]}</div>
            </div>
        </div>
    );
}

export function getNormalDate(dateString) {
    if (!dateString) {
        return dateString;
    }

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

function TailwindTable({
    columns,
    data,
    setCount,
    count,
    totalLength,
    loading,
    pageNumber,
    setPageNumber,
    pageArray,
    setDocumentModalView,
    setUserEditView,
    setSortColumnName,
    sortOrder,
    setSortOrder,
    keyValue,
    sortingName,
    sortingHeadername,
    path,
    returnPath,
    setCanEditState,
    setCanApproveState,
}) {
    const nameValue = "Name";
    const productNameValue = keyValue === "productHoldings" ? "name" : "";
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, nextPage, previousPage, setPageSize } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useGlobalFilter,
        useBlockLayout,
        useExpanded,
        usePagination
    );

    React.useEffect(() => {
        setPageSize(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    const override = {
        display: "block",
        marginLeft: "43%",
        margin: "2% 50%",
        borderColor: "black",
    };

    const handlePageChange = (pageNumber) => {
        setCount(pageNumber);
    };
    const Pagination = () => {
        const pageNumbers = [];

        const maxVisiblePages = 5; // Maximum number of visible pages

        // Add page numbers to array

        for (let i = 1; i <= Math.ceil(totalLength / pageNumber); i++) {
            pageNumbers.push(i);
        }

        // If there are less pages than the maximum visible pages, show all pages

        if (pageNumbers.length <= maxVisiblePages) {
            return (
                <div className="flex justify-end items-center gap-4">
                    <button onClick={() => handlePageChange(count - 1)} disabled={count === 1} className="">
                        Prev
                    </button>

                    {pageNumbers.map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={`w-8 h-8 rounded ${count === number ? "border border-gray-900" : ""}`}
                        >
                            {number}
                        </button>
                    ))}

                    <button onClick={() => handlePageChange(count + 1)} disabled={count === Math.ceil(totalLength / pageNumber)} className="">
                        Next
                    </button>
                </div>
            );
        }

        // Show only the first and last maxVisiblePages/2 pages with dots in between

        const totalPages = pageNumbers.length;

        let firstPage = 1;

        let lastPage = maxVisiblePages;

        // If the current page is within the first maxVisiblePages/2 pages, show only the first maxVisiblePages

        if (count <= maxVisiblePages / 2) {
            lastPage = maxVisiblePages;
        }

        // If the current page is within the last maxVisiblePages/2 pages, show only the last maxVisiblePages
        else if (count >= totalPages - maxVisiblePages / 2) {
            firstPage = totalPages - maxVisiblePages + 1;

            lastPage = totalPages;
        }

        // Otherwise, show the current page and its neighbors, with dots on either side
        else {
            firstPage = count - Math.floor(maxVisiblePages / 2);

            lastPage = count + Math.floor(maxVisiblePages / 2);

            if (maxVisiblePages % 2 === 0) {
                firstPage += 1;
            }
        }

        const visiblePages = pageNumbers.slice(firstPage - 1, lastPage);

        return (
            <div className="flex justify-end items-center gap-4">
                <button onClick={() => handlePageChange(count - 1)} disabled={count === 1} className="">
                    {PAGECONSTANTS?.PREVIOUS}
                </button>

                {firstPage > 1 && <span>...</span>}

                {visiblePages.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`w-8 h-8 rounded ${count === number ? "border border-gray-900" : ""}`}
                    >
                        {number}
                    </button>
                ))}

                {lastPage < totalPages && <span>...</span>}

                <button onClick={() => handlePageChange(count + 1)} disabled={count === Math.ceil(totalLength / pageNumber)} className="">
                    {PAGECONSTANTS?.NEXT}
                </button>
            </div>
        );
    };

    // Render the UI for your table
    return (
        <>
            <div className="sm:flex sm:gap-x-2">
                {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                        column.Filter ? (
                            <div className="mt-2 sm:mt-0" key={column.id}>
                                {column.render("Filter")}
                            </div>
                        ) : null
                    )
                )}
            </div>
            {/* table */}
            <div className="overflow-auto w-full h-full">
                <table {...getTableProps()} className="table min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 " style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        {headerGroups.map((headerGroup) => (
                            <tr className="tr">
                                {headerGroup.headers.map((column) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props

                                    <th
                                        scope="col"
                                        className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                    >
                                        {column.render("Header") === "  " ? (
                                            <div className="ml-2.5">
                                                <Badge variant={"PaperClipIcon"} />{" "}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="flex w-full h-full gap-3 items-center">
                                            <div
                                                className="flex flex-grow items-center gap-2 font-bold"
                                                style={{ fontSize: "13px" }}
                                                onClick={() => {
                                                    if (
                                                        column.render("Header") !== "EliminateIssues" &&
                                                        column.render("Header") !== "Action" &&
                                                        column.render("Header") !== "AttachmentNames" &&
                                                        column.render("Header") !== "AttachmentTypes" &&
                                                        column.render("Header") !== "AttachmentNotes" &&
                                                        column.render("Header") !== "Id" &&
                                                        column.render("Header") !== "  " 
                                                    ) {
                                                        setSortColumnName(
                                                            keyValue !== "productHoldings" && column.render("Header") === "Status"
                                                                ? "EliminateStatus"
                                                                : column.render("Header") === "Weighted % Eliminated"
                                                                ? "WeightedEliminatedPercent"
                                                                : column.render("Header") === "Total # Eliminated Stocks"
                                                                ? "EliminatedIssuersCount"
                                                                : column.render("Header") === "% of Product Identified"
                                                                ? "IdentifiedProductsPercent"
                                                                : column.render("Header") === "% of Product Screened"
                                                                ? "ScreenedProductsPercent"
                                                                : column.render("Header") === "# Holdings"
                                                                ? "HoldingsCount"
                                                                : column.render("Header") === "# Issuers"
                                                                ? "IssuersCount"
                                                                : column.render("Header") === "# Issuers Identified"
                                                                ? "IdentifiedIssuersCount"
                                                                : column.render("Header") === "# Issuers Screened"
                                                                ? "ScreenedIssuersCount"
                                                                : column.render("Header") === "# Underlying Products"
                                                                ? "ProductsCount"
                                                                : column.render("Header") === "# Underlying Products Identified"
                                                                ? "IdentifiedProductsCount"
                                                                : column.render("Header") === "# Underlying Products Screened"
                                                                ? "ScreenedProductsCount"
                                                                : column.render("Header") === "# Bonds Identified"
                                                                ? "IdenifiedBondsCount"
                                                                : column.render("Header") === "# Other Identified"
                                                                ? "IdenifiedOthersCount"
                                                                : column.render("Header") === "# Unclassified"
                                                                ? "UnclassifedCount"
                                                                : column.render("Header") === "Cash Allocation"
                                                                ? "CashAllocationPercent"
                                                                : column.render("Header") === "Is MSC Product"
                                                                ? "OAISMSCApprovalStatus"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Ticker"
                                                                ? "holdingTicker"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Name"
                                                                ? "holdingName"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Type"
                                                                ? "holdingType"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Weight"
                                                                ? "holdingWeight"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Category"
                                                                ? "holdingCategory"
                                                                : // : keyValue === "productHoldings" && column.render("Header") === "Status"
                                                                // ? "Status"
                                                                column.render("Header")==="Eliminate Issue"?"IssueName":
                                                                column.render("Header")==="Eliminate Sub -Issue"?"SubIssueName":
                                                                keyValue === "productHoldings" && column.render("Header") === "ISIN"
                                                                ? "isin"
                                                                : // : keyValue === "productHoldingss"
                                                                  // ? column.render("Header").charAt(0).toUpperCase() + column.render("Header").slice(1)
                                                                  column.render("Header")
                                                        );
                                                        setSortOrder(sortOrder === 1 ? 0 : 1);
                                                        localStorage.setItem(sortingName, sortOrder === 1 ? 0 : 1);

                                                        localStorage.setItem(
                                                            sortingHeadername,
                                                            keyValue !== "productHoldings" && column.render("Header") === "Status"
                                                                ? "EliminateStatus"
                                                                : column.render("Header") === "Weighted % Eliminated"
                                                                ? "WeightedEliminatedPercent"
                                                                : column.render("Header") === "Total # Eliminated Stocks"
                                                                ? "EliminatedIssuersCount"
                                                                : column.render("Header") === "% of Product Identified"
                                                                ? "IdentifiedProductsPercent"
                                                                : column.render("Header") === "% of Product Screened"
                                                                ? "ScreenedProductsPercent"
                                                                : column.render("Header") === "# Holdings"
                                                                ? "HoldingsCount"
                                                                : column.render("Header") === "# Issuers"
                                                                ? "IssuersCount"
                                                                : column.render("Header") === "# Issuers Identified"
                                                                ? "IdentifiedIssuersCount"
                                                                : column.render("Header") === "# Issuers Screened"
                                                                ? "ScreenedIssuersCount"
                                                                : column.render("Header") === "# Underlying Products"
                                                                ? "ProductsCount"
                                                                : column.render("Header") === "# Underlying Products Identified"
                                                                ? "IdentifiedProductsCount"
                                                                : column.render("Header") === "# Underlying Products Screened"
                                                                ? "ScreenedProductsCount"
                                                                : column.render("Header") === "# Bonds Identified"
                                                                ? "IdenifiedBondsCount"
                                                                : column.render("Header") === "# Other Identified"
                                                                ? "IdenifiedOthersCount"
                                                                : column.render("Header") === "# Unclassified"
                                                                ? "UnclassifedCount"
                                                                : column.render("Header") === "Cash Allocation"
                                                                ? "CashAllocationPercent"
                                                                : column.render("Header") === "Is MSC Product"
                                                                ? "OAISMSCApprovalStatus"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Ticker"
                                                                ? "holdingTicker"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Name"
                                                                ? "holdingName"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Type"
                                                                ? "holdingType"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Weight"
                                                                ? "holdingWeight"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Category"
                                                                ? "holdingCategory"
                                                                : keyValue === "productHoldings" && column.render("Header") === "Status"
                                                                ? "status"
                                                                : // : keyValue === "productHoldings"
                                                                  // ? column.render("Header").charAt(0).toUpperCase() + column.render("Header").slice(1)
                                                                  column.render("Header")
                                                        );
                                                        sessionStorage.setItem("ISIN");
                                                    }
                                                }}
                                            >
                                                {column.render("Header") === "MarketCap"
                                                    ? "Market Cap"
                                                    : column.render("Header") === "isin"
                                                    ? "ISIN" 
                                                    : column.render("Header").charAt(0).toUpperCase() +
                                                      column
                                                          .render("Header")
                                                          .slice(1)
                                                          .replace(/(?=[A-Z][a-z])/g, " ")}
                                                {/* Add a sort direction indicator */}

                                                {column.render("Header") !== "EliminateIssues" &&
                                                column.render("Header") !== "Id" &&
                                                column.render("Header") !== "Action" &&
                                                column.render("Header") !== " " &&
                                                column.render("Header") !== "AttachmentNames" &&
                                                column.render("Header") !== "AttachmentTypes" &&
                                                column.render("Header") !== "AttachmentNotes" &&
                                                column.render("Header") !== "  " 
                                                 ? (
                                                    <span>
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <SortDownIcon
                                                                    OnClick={() => {
                                                                        setSortColumnName(column.render("Header"));
                                                                        setSortOrder(0);
                                                                    }}
                                                                    className="w-4 h-4 text-gray-400"
                                                                />
                                                            ) : (
                                                                <SortUpIcon
                                                                    OnClick={() => {
                                                                        setSortColumnName(column.render("Header"));
                                                                        setSortOrder(1);
                                                                    }}
                                                                    className="w-4 h-4 text-gray-400"
                                                                />
                                                            )
                                                        ) : (
                                                            <SortIcon className="w-4 h-4 text-gray-400 sm:opacity-0 group-hover:opacity-100" />
                                                        )}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page?.length ? (
                            page?.map((row, i) => {
                                // new
                                prepareRow(row);
                                return (
                                    <>
                                        <tr className="tr even:bg-gray-100 whitespace-nowrap">
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td
                                                        className="td px-4 py-2 hover:max-w-none"
                                                        role="cell"
                                                        title={cell.column.id === "document.Description" ? cell.value : undefined}
                                                    >
                                                        <div className="flex items-center h-full text-sm relative">
                                                            {cell?.column?.Header === "Status" && cell?.value === "Pass" ? (
                                                                <Badge variant={"CircleSuccess"} />
                                                            ) : cell?.column?.Header === "Status" && cell?.value === "Fail" ? (
                                                                <Badge variant={"CircleFail"} />
                                                            ) : cell?.column?.Header === "Status" && cell?.value === "Warning" ? (
                                                                <Badge variant={"CircleSuccess1"} />
                                                            ) : cell?.column?.Header === "status" && cell?.row?.original?.status === true ? (
                                                                <Badge variant={"CircleSuccess"} />
                                                            ) : cell?.column?.Header === "status" && cell?.row?.original?.status === false ? (
                                                                <Badge variant={"CircleFail"} />
                                                            ) : cell?.column?.Header === "StatusRequest" && cell?.value === "Pass" ? (
                                                                <Badge variant={"CircleSuccess"} />
                                                            ) : cell?.column?.Header === "StatusRequest" && cell?.value === "Fail" ? (
                                                                <Badge variant={"CircleFail"} />
                                                            ) : cell?.column?.Header === "RequestStatus" && cell?.value === "Pass" ? (
                                                                <Badge variant={"CircleSuccess"} />
                                                            ) : cell?.column?.Header === "RequestStatus" && cell?.value === "Fail" ? (
                                                                <Badge variant={"CircleFail"} />
                                                            ) : cell?.column?.Header === "OriginalStatus" && cell?.value === "Pass" ? (
                                                                <Badge variant={"CircleSuccess"} />
                                                            ) : cell?.column?.Header === "OriginalStatus" && cell?.value === "Fail" ? (
                                                                <Badge variant={"CircleFail"} />
                                                            ) : cell?.column?.Header === "ApprovalStatus" ? (
                                                                ""
                                                            ) : cell?.column?.Header === "Weighted % Eliminated" ? (
                                                                cell.row.original.document.WeightedEliminatedPercent.toFixed(4)
                                                                ) : cell?.column?.Header === "% of Product Identified" ? (
                                                                    cell.row.original.document.IdentifiedProductsPercent.toFixed(4)
                                                                    ) : cell?.column?.Header === "% of Product Screened" ? (
                                                                        cell.row.original.document.ScreenedProductsPercent.toFixed(4)
                                                                        ) : cell?.column?.Header === "Cash Allocation" ? (
                                                                            cell.row.original.document.CashAllocationPercent.toFixed(4)
                                                            ) : cell?.column?.Header === "ReviewDate" ? (
                                                                getNormalDate(cell?.value)
                                                            ) : cell?.column?.Header === nameValue && keyValue !== "productHoldings" ? (
                                                                <Link
                                                                    className="text-primary"
                                                                    to={keyValue === "product" ? "/productDetails" : path}
                                                                    state={{
                                                                        data:
                                                                            keyValue === "IssuerWatchList"
                                                                                ? cell?.row?.original?.isin
                                                                                : keyValue === "product"
                                                                                ? cell?.row?.original?.document?.ProductId
                                                                                : keyValue === "productWatchlist"
                                                                                ? cell.row?.original?.bloombergId
                                                                                : cell?.row?.original?.document?.ISIN,
                                                                        path: returnPath,
                                                                    }}
                                                                    onClick={() => {
                                                                        keyValue === "product"
                                                                            ? sessionStorage.setItem("productId", cell?.row?.original?.document?.ProductId)
                                                                            : keyValue === "productWatchlist"
                                                                            ? sessionStorage.setItem("productId", cell.row?.original?.bloombergId)
                                                                            : keyValue === "IssuerWatchList"
                                                                            ? sessionStorage.setItem("ISIN", cell?.row?.original?.isin)
                                                                            : sessionStorage.setItem("ISIN", cell?.row?.original?.document?.ISIN);
                                                                        sessionStorage.setItem(
                                                                            "currentPath",
                                                                            keyValue === "product" ? "/productDetails" : path
                                                                        );
                                                                        sessionStorage.setItem("issuerPath", returnPath);
                                                                        sessionStorage.setItem("BasePath", returnPath);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {cell?.value}{" "}
                                                                </Link>
                                                            ) : cell?.column?.Header === productNameValue ? (
                                                                <span>{cell?.row?.original?.name}</span>
                                                            ):cell?.column?.Header === "Name" && keyValue === "productHoldings" ? (
                                                                cell?.row?.original?.isin ? (
                                                                <Link className="text-primary"    to={keyValue === "productHoldings" ? "/issuerdetails" : path}
                                                                onClick={() => {
                                                                    sessionStorage.setItem("ISIN",cell?.row?.original?.isin)
                                                                     sessionStorage.setItem("issuerPath",returnPath)
                                                                }}>{cell?.row?.original?.holdingName}</Link>):(
                                                                    <span>{cell?.row?.original?.holdingName}</span>
                                                                )
                                                            ) : cell?.column?.Header === "weight" && keyValue === "productHoldings" ? (
                                                                <span>{`${cell?.row?.original?.weight} %`}</span>
                                                            ) : cell?.column?.Header === "status" && keyValue === "productHoldings" ? (
                                                                <span>{`${cell?.row?.original?.status}`}</span>
                                                            ) : cell?.column?.Header === "isin" && keyValue === "productHoldings" ? (
                                                                <Link to={keyValue === "productHoldings" ? "/issuerdetails" : path} className="text-primary " onClick={() => {
                                                                    sessionStorage.setItem("ISIN",cell?.row?.original?.isin)
                                                                    sessionStorage.setItem("issuerPath",returnPath)
                                                                }}>{`${cell?.row?.original?.isin}`}</Link>
                                                            ) : cell?.column?.Header === "Ticker" && keyValue === "productHoldings" ? (
                                                                cell?.row?.original?.isin ? (
                                                                <Link to={keyValue === "productHoldings" ? "/issuerdetails" : path} className="text-primary" onClick={() => {
                                                                    sessionStorage.setItem("ISIN",cell?.row?.original?.isin)
                                                                    sessionStorage.setItem("issuerPath",returnPath)
                                                                }}>{`${cell?.row?.original?.holdingTicker}` } </Link>):(
                                                                    <span>{cell?.row?.original?.holdingTicker}</span>
                                                                )
                                                            ) : cell?.column?.Header === "type" && keyValue === "productHoldings" ? (
                                                                <span>{`${cell?.row?.original?.type}`}</span>
                                                            ) : cell?.column?.Header === "category" && keyValue === "productHoldings" ? (
                                                                <span>{`${
                                                                    cell?.row?.original?.category !== null && cell?.row?.original?.category !== undefined
                                                                        ? cell?.row?.original?.category
                                                                        : ""
                                                                }`}</span>
                                                            ) : cell?.column?.Header === "issue" && keyValue === "productHoldings" ? (
                                                                <span>{`${cell?.row?.original?.issue}`}</span>
                                                            ) : cell?.column?.Header === "subIssues" && keyValue === "productHoldings" ? (
                                                                <span>{`${cell?.row?.original?.subIssues}`}</span>
                                                            ) : cell?.column?.Header === "  " ? (
                                                                <Link className="items-center mx-6">
                                                                    {cell?.row?.original?.document?.AttachmentCount <= 0 ||
                                                                    cell?.row?.original?.document?.AttachmentCount === null ||
                                                                    !cell?.row?.original?.document?.AttachmentCount ? (
                                                                        ""
                                                                    ) : (
                                                                        <div className="flex">
                                                                            <Badge
                                                                                variant="DocumentText"
                                                                                onClick={() => {
                                                                                    sessionStorage.setItem("userReviewId", cell?.row?.original?.document?.Id);
                                                                                    setDocumentModalView(true);
                                                                                }}
                                                                            />
                                                                            <span className="mt-0.5">-</span>
                                                                            <span className="mt-0.5">{cell?.row?.original?.document?.AttachmentCount}</span>
                                                                        </div>
                                                                    )}
                                                                </Link>
                                                            ) : cell?.column?.Header === " " ? (
                                                                <Link className="items-center text-sm">
                                                                    <Badge
                                                                        variant="CheckCircle"
                                                                        onClick={() => {
                                                                            sessionStorage.setItem("editUserReviewId", cell?.row?.original?.document?.Id);
                                                                            setCanEditState(cell?.row?.original?.document?.CanEdit);
                                                                            setCanApproveState(cell?.row?.original?.document?.CanApprove);
                                                                            setUserEditView(true);
                                                                        }}
                                                                    />
                                                                </Link>
                                                            ) : cell?.column?.Header === "ISIN" ? (
                                                                <Link
                                                                    className="text-primary"
                                                                    to={keyValue === "product" ? "/productDetails" : path}
                                                                    state={{
                                                                        data: cell?.row?.original?.document?.ISIN,
                                                                        path: returnPath,
                                                                    }}
                                                                    onClick={() => {
                                                                        sessionStorage.setItem(
                                                                            "ISIN",
                                                                            keyValue === "product"
                                                                                ? cell?.row?.original?.document?.ProductId
                                                                                : cell?.row?.original?.document?.ISIN
                                                                        );
                                                                        sessionStorage.setItem("issuerPath", returnPath);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {cell?.value}{" "}
                                                                </Link>
                                                            ) : cell?.column?.Header === "Ticker" && keyValue !== "productHoldings" ? (
                                                                <Link
                                                                    className="text-primary"
                                                                    to={keyValue === "product" ? "/productDetails" : "/issuerdetails"}
                                                                    state={{
                                                                        data:
                                                                            keyValue === "product"
                                                                                ? cell?.row?.original?.document?.ProductId
                                                                                : cell?.row?.original?.document?.ISIN,
                                                                        path: returnPath,
                                                                    }}
                                                                    onClick={() => {
                                                                        keyValue === "product"
                                                                            ? sessionStorage.setItem("productId", cell?.row?.original?.document?.ProductId)
                                                                            : sessionStorage.setItem("ISIN", cell?.row?.original?.document?.ISIN);
                                                                        sessionStorage.setItem(
                                                                            "currentPath",
                                                                            keyValue === "product" ? "/productDetails" : "/issuerdetails"
                                                                        );
                                                                        sessionStorage.setItem("issuerPath", returnPath);
                                                                    }}
                                                                >
                                                                    {cell?.value}
                                                                </Link>
                                                            ) : cell?.column?.Header === "ProductId" ? (
                                                                <Link
                                                                    className="text-primary"
                                                                    to="/productDetails"
                                                                    state={{
                                                                        data: cell?.row?.original?.document?.ProductId,
                                                                        path: returnPath,
                                                                    }}
                                                                    onClick={() => {
                                                                        sessionStorage.setItem("productId", cell?.row?.original?.document?.ProductId);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    {cell?.value}{" "}
                                                                </Link>
                                                            ) : cell?.column?.Header === "MarketCap" || cell?.column?.Header === "marketCap" ? (
                                                                cell?.value !== undefined ? (
                                                                    "$ " + formatNumber(cell?.value)
                                                                ) : (
                                                                    cell?.value
                                                                )
                                                            ) : cell?.column?.Header === "LastUpdated" ||
                                                              cell?.column?.Header === "RequestDate" ||
                                                              cell?.column?.Header === "ApprovedDate" ? (
                                                                getNormalDate(cell?.value)
                                                            ) : cell?.column?.Header === "EliminateIssues" ? (
                                                                cell?.value?.toString().replace(/,/g, ", ")
                                                            ) : (
                                                                cell.render("Cell")
                                                            )}
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </>
                                );
                            })
                        ) : loading ? (
                            <ClipLoader cssOverride={override} />
                        ) : (
                            <tr class="border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700">
                                <td colspan={headerGroups && headerGroups.length > 0 ? 19 : 0} class="whitespace-nowrap px-6 py-4 text-center">
                                    {PAGECONSTANTS?.NODATAFOUND}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Pagination */}
            <div className="sm:p-4 flex items-center justify-between mt-auto">
                <div className="flex-1 flex justify-between sm:hidden border-t">
                    <button
                        className="w-full py-3"
                        onClick={() => {
                            previousPage();
                        }}
                    >
                        {TAILWINDTABLE?.PREVIOUS}
                    </button>
                    <button
                        className="w-full py-3 bg-primary text-white"
                        onClick={() => {
                            nextPage();
                        }}
                    >
                        {TAILWINDTABLE?.NEXT}
                    </button>
                </div>
                {totalLength > 4 && (
                    <div className="sm:flex justify-between w-full gap-4 hidden">
                        <div className="flex items-center gap-4">
                            {Number(totalLength) > 0 && (
                                <span className="text-sm text-gray-700">
                                    {PAGECONSTANTS?.PAGE} <span className="font-medium">{count}</span> of
                                    <span className="font-medium">{" " + Math.ceil(totalLength / pageNumber)}</span>
                                </span>
                            )}
                            <label>
                                <select
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={pageNumber}
                                    onChange={(e) => {
                                        setPageNumber(Number(e.target.value));
                                        setCount(1);
                                    }}
                                >
                                    {pageArray.map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        {page.length > 0 ? Pagination() : ""}
                    </div>
                )}
            </div>
        </>
    );
}

export default TailwindTable;
