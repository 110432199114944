import React, { useEffect, useState } from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { useGetViewUserRoleQuery } from "../../../Services/Admin/ViewUserRoleReport/ViewUserRoleApi";
import { has, isArray, isEmpty } from "lodash";
import { disPascalize } from "../../../constants/Common";

const ViewUserRoleReport = () => {
    const getViewUserRoleReport = useGetViewUserRoleQuery();
    useEffect(() => {
        getViewUserRoleReport.refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="flex-grow overflow-hidden md:pr-4 static-scroll">
            <div className="h-full flex-col flex">
                <div className="overflow-auto w-full h-full">
                    <table className="table min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr className="tr">
                                <th className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer">
                                    <div className="flex w-full h-full gap-3 items-center">
                                        <div className="flex flex-grow items-center gap-2 font-bold" style={{ fontSize: "13px" }}>
                                            User
                                        </div>
                                    </div>
                                </th>
                                {has(getViewUserRoleReport, ["data", "role"]) &&
                                    isArray(getViewUserRoleReport?.data?.role) &&
                                    !isEmpty(getViewUserRoleReport?.data?.role) &&
                                    [...getViewUserRoleReport?.data?.role]
                                        .sort((a, b) => {
                                            if (a.name === "System Administrator") {
                                                return -1; // "System Administrator" comes first
                                            } else if (b.name === "System Administrator") {
                                                return 1; // "System Administrator" comes second
                                            } else {
                                                return 0; // Preserve the original order for other objects
                                            }
                                        })
                                        ?.map((item) => {
                                            return (
                                                <th
                                                    className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer justify"
                                                    key={item?.name}
                                                >
                                                    <div className="flex w-full h-full gap-3 items-center">
                                                        <div
                                                            className="flex flex-grow items-center gap-2 font-bold"
                                                            style={{ fontSize: "13px", justifyContent: "center" }}
                                                        >
                                                            {disPascalize(item.name)}
                                                        </div>
                                                    </div>
                                                </th>
                                            );
                                        })}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {has(getViewUserRoleReport, ["data", "user"]) &&
                                isArray(getViewUserRoleReport?.data?.user) &&
                                !isEmpty(getViewUserRoleReport?.data?.user) &&
                                getViewUserRoleReport?.data?.user.map((data, index) => (
                                    <tr className="tr even:bg-gray-100 whitespace-nowrap">
                                        <td className="td px-4 py-2 hover:max-w-none overflow-hidden">{data.firstName + " " + data.lastName}</td>
                                        {has(data, ["roles"]) &&
                                            isArray(data?.roles) &&
                                            !isEmpty(data?.roles) &&
                                            [...getViewUserRoleReport?.data?.role]
                                                .sort((a, b) => {
                                                    if (a.name === "System Administrator") {
                                                        return -1; // "System Administrator" comes first
                                                    } else if (b.name === "System Administrator") {
                                                        return 1; // "System Administrator" comes second
                                                    } else {
                                                        return 0; // Preserve the original order for other objects
                                                    }
                                                })
                                                .map((role) => {
                                                    return (
                                                        <td className="td px-4 py-2 hover:max-w-none overflow-hidden text-center">
                                                            <div className="flex justify-center">
                                                                {data.roles.includes(role.roleId) && (
                                                                    <div>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ViewUserRoleReport;
