import React from "react";
import imgCommunities from "../../assets/images/icons/communities.svg";
import imgCustomers from "../../assets/images/icons/customers.svg";
import imgEmployees from "../../assets/images/icons/employees.svg";
import imgEnvironment from "../../assets/images/icons/environment.svg";
import imgSociety from "../../assets/images/icons/society.svg";
import imgSuppliers from "../../assets/images/icons/suppliers.svg";

const PrintRating = ({ RatingData, EliminateIssuerData }) => {

  const eliminateLocation = EliminateIssuerData?.data?.issues?.map((tab) =>
  {return window.location.pathname.startsWith(`/eliminate/${tab.issueName}`)}
 );

  const ElementIconHandler = () => {
    switch (RatingData.icon) {
      case "communities":
        return imgCommunities;
      case "customers":
        return imgCustomers;
      case "employees":
        return imgEmployees;
      case "environment":
        return imgEnvironment;
      case "society":
        return imgSociety;
      case "suppliers":
        return imgSuppliers;
      default:
        return RatingData;
    }
  };
  const NegativeElements = [];
  const PassitiveElements = [];
  const NagativeHandler = () => {
    let i = 1;
    if (RatingData.negative.visible) {
      for (i; i <= RatingData.cell; i++) {
        if (i <= RatingData.negative.rating) {
          NegativeElements.push(
            <div
              key={i}
              style={{
                border: "1.2px solid grey",
                height: "1.25rem",
                width: "1.25rem",
                // borderRadius: i === RatingData.cell ?"0.25rem 0 0 0.25rem":"",
                borderRight: i === RatingData.cell ? "" : "0",
                backgroundColor: RatingData.iconBg,
              }}
            ></div>
          );
        } else {
          NegativeElements.push(
            <div
              key={i}
              style={{
                border: "1.2px solid grey",
                height: "1.25rem",
                width: "1.25rem",
                // borderRadius: "0.25rem 0 0 0.25rem",
                borderRight: i === RatingData.cell ? "" : "0",
              }}
            ></div>
          );
        }
      }
    }
  };
  const PassitiveHandler = () => {
    let i = 1;
    if (RatingData.passitive.visible) {
      for (i; i <= RatingData.cell; i++) {
        if (i <= RatingData.passitive.rating) {
          PassitiveElements.push(
            <div
              key={i}
              style={{
                border: "1.2px solid grey",
                height: "1.25rem",
                width: "1.25rem",
                // borderRadius:i === RatingData.passitive.rating ?"0 0.25rem 0.25rem 0":"",
                borderLeft: i === 1 ? "" : "0",
                backgroundColor: "var(--green-500)",
              }}
            ></div>

          );
        } else {
          PassitiveElements.push(
            <div
              key={i}
              style={{
                border: "1.2px solid grey",
                height: "1.25rem",
                width: "1.25rem",
                // borderRadius: i === RatingData.passitive.rating ?"0 0.25rem 0.25rem 0":"",
                borderLeft: "0",
              }}
            ></div>
          );
        }
      }
    }
  };
  
  NagativeHandler();
  PassitiveHandler();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>{NegativeElements}</div>
      <div
        style={{
            border: '0.5px solid #111827',
          height: '3rem',
          width: '3rem',
          marginBottom: '-0.25rem',
          justifyContent: 'center',
          alignItems: 'middle',
          display: 'flex',
          backgroundColor: RatingData.iconBg,
        }}
      >
        <img style={{ width: '50%' }} src={ElementIconHandler()} alt="img" />
      </div>
      {!eliminateLocation && <div style={{ display: 'flex' }}>{PassitiveElements}</div>}
    </div>
  </div>
  
  );
};

export default PrintRating;
