import { ChevronRightIcon, MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { has, isArray, isEmpty, isEqual, isNull, range, sortBy } from "lodash";
import axios from "axios";
import fileDownload from "js-file-download";
import AddToWatchListView from "./AddToWatchList";
import AddToWatchListFooter from "./AddToWatchListFooter";
import EditView from "./EditView";
import EditViewFooter from "./EditViewFooter";
import Saveresultbody from "./Saveresultbody";
import ModalFooter from "./ModalFooter";
import Modal from "../components/Modals/Modals";
import UserContext from "../context/modal/Contextmodel";
import ShareModal from "../pages/ShareModal/ShareModal";
import TailwindTable from "../components/Table/TailwindTable";
import Checkbox from "../components/Checkbox/Checkbox";
import Button from "../components/Button/Button";
import { useGetEditViewByIdQuery, useGetEditViewSaveQuery, usePostIssuerListMutation } from "../Services/Issuer/Search/IssuerSearchApi";
import { ISSUER, ISSUERDETAILS, ISSUERLIST } from "../constants/appConstants";
import { useGetSavedSearchByIdQuery } from "../Services/IssuerList/IssuerListApi";
import { queryString } from "../helpers";
import { useGetAllUsersQuery, usePostSavedSearchShareMutation } from "../Services/ShareModal/ShareModalApi";
import { useGetIssuerReturnFieldQuery } from "../Services/Issuer/Home/IssuerHomeApi";
import Badge from "../components/Badge/Badge";
import { PAGECONSTANTS, get_numeric_value_sorting, loadingTableheader, showToastify } from "../constants/Common";

const IssuerList = () => {
    const [filterOpen, setFilterOpen] = useState(false);
    const location = useLocation();
    const pageArray = [50, 75, 100, 125, 150];
    const { Permissionapi } = useContext(UserContext);
    const getSavedIdLocal = localStorage.getItem(ISSUER.SAVEDID);
    const getEditViewIdLocal = localStorage.getItem(ISSUER.EDITVIEWID);
    const getExisitingViewLocal = localStorage.getItem(ISSUER.EXISTINGVIEW);
    const getReturnColumnLocal = localStorage.getItem(ISSUER.SAVEDRETURNCOLUMN);
    const getCheckedBadges = localStorage.getItem(ISSUER.ISSUERBADGEVALUES);
    const getSearchValue = localStorage.getItem(ISSUER.ISSUERSEARCHTEXT);
    const checkValues = localStorage.getItem(ISSUER.ISSUERFILTER);
    const checkSort = localStorage.getItem(ISSUER.ISSUERSORTING);
    const checkSortHeader = localStorage.getItem(ISSUER.ISSUERSORTINGHEADER);
    const applyButtonValue = localStorage.getItem("ApplyButton");
    const retString = localStorage.getItem(ISSUER.ISSUERCOLUMNFILTER);
    const retArray = JSON.parse(retString);
    const SavedsearchId = getSavedIdLocal === "undefined" || isNull(getSavedIdLocal) ? location.state?.data : getSavedIdLocal;
    const [tempSelectedFields, setTempSelectedFields] = useState(isNull(getReturnColumnLocal) ? [] : JSON.parse(getReturnColumnLocal));
    const [finalCheckedBadgeValues, setFinalCheckedBadgeValues] = useState(JSON.parse(getCheckedBadges));
    const [editViewMenu, setEditViewMenu] = useState(isNull(getExisitingViewLocal) ? "" : getExisitingViewLocal);
    const [editViewMenuId, setEditViewMenuId] = useState(getEditViewIdLocal === "undefined" ? "" : getEditViewIdLocal);
    const [filterApplyBtn, setFilterApplyBtn] = useState(isNull(applyButtonValue) ? false : applyButtonValue);
    const parsedValues = checkValues ? JSON.parse(checkValues) : [];
    const [checkedValues, setCheckedValues] = useState(parsedValues);
    const [sortColumnName, setSortColumnName] = useState(checkSortHeader);
    const [sortOrder, setSortOrder] = useState(checkSort);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [ViewFields, setViewFields] = useState([]);
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedFields, setSelectedFields] = useState([]);
    const [savedSearchdata, setSavedSearchdata] = useState([]);
    const [checkedFields, setCheckedFields] = useState([]);
    const [savedResultValue, SetSavedResultValue] = useState("");
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [saveModal, setSaveModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [editView, setEditView] = useState(false);
    const [editViewInput, setEditViewInput] = useState("");
    const [addToWatchList, setAddToWatchList] = useState(false);
    const [WLName, setWLName] = useState("");
    const [WLId, setWLId] = useState("");
    const [Isin, setIsin] = useState();
    const [showState, setShowState] = useState({});
    const [savedsearcheckbox, setsavedsearcheckbox] = useState("");
    const [pageNumber, setPageNumber] = useState(50);
    const [savedSearchData, setSavedSearchData] = useState({});
    const [shareInputValue, setShareInputValue] = useState("");
    const [shareDropDownValue, setShareDropDownValue] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [userId, setUserId] = useState("");
    const [checked, setChecked] = useState([]);
    const [updatedFacets, setUpdatedFacets] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [checkedValueBadges, setCheckedValueBadges] = useState(isNull(JSON.parse(getCheckedBadges)) ? [] : JSON.parse(getCheckedBadges));
    const [checkedState, setCheckedState] = useState(0);
    const [searchTextTyped, setSearchTextTyped] = useState("");
    const [staticViewFields, setStaticViewFields] = useState([]);
    const [isSaved, setIsSaved] = useState(false);
    const [editViewReturnValue, setEditViewReturnValue] = useState([]);
    const [searchInput, setSearchInput] = useState(false);
    const [exisitngViewColumn, setExisitingViewColumn] = useState([]);
    const initialValues = {
        searchText: isNull(getSearchValue) ? "*" : getSearchValue,
        useDefaultFacets: true,
        sortOrderfilters: null,
        fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
        fieldNamesToSearch: [],
        facetOptions: null,
        facetFilters: checkedValues,
        searchIndexName: "string",
        currentPage: 1,
        pageSize: pageNumber,
        // facetFilters: [],
    };
    const [checkBoxFilter, setCheckBoxFilter] = useState({ ...initialValues });
    const [postIssuerList, postIssuerListData] = usePostIssuerListMutation();
    const [PostSavedSearchShare, sucess] = usePostSavedSearchShareMutation();
    const getIssuerSearchData = useGetSavedSearchByIdQuery(queryString({ params: { Id: SavedsearchId } }), {
        skip: !SavedsearchId || (staticViewFields && staticViewFields.length === 0),
    });
    const GetUserDetailsData = useGetAllUsersQuery(queryString({ params: { searchText: shareInputValue } }), {
        skip: shareInputValue.length < 1,
    });
    const IssuerReturnName = useGetIssuerReturnFieldQuery(queryString({ params: { searchIndexName: "issuers" } }));
    const getEditViewSave = useGetEditViewSaveQuery(queryString({ params: { ModuleName: "Issuers" } }));
    const EditViewData = useGetEditViewByIdQuery(queryString({ params: { Id: editViewMenuId } }), {
        skip: !editViewMenuId || !isNull(retArray),
    });
    const EditViewDefaultCheckAll = () => {
        if (has(IssuerReturnName, ["data"]) && isArray(IssuerReturnName?.data) && !isEmpty(IssuerReturnName?.data)) {
            const tempIssuerReturnName = IssuerReturnName?.data.reduce((obj, field) => {
                obj[field] = true;
                return obj;
            }, {});
            setCheckedItems(tempIssuerReturnName);
            setCheckedFields(IssuerReturnName?.data);
        }
    };
    const exportapicall = async () => {
        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
        const body = {
            searchIndexName: "",
            searchText: searchTextTyped,
            currentPage: count,
            pageSize: pageNumber,
            useDefaultFacets: true,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            fieldNamesToReturn: tempSelectedFields,
            fieldNamesToSearch: [],
            facetOptions: null,
            facetFilters: checkedValues,
            format: 0,
        };
        axios
            .post(`${process.env.REACT_APP_DEV_APIBASEURI}api/Issuer/ExportIssuerSearchResult`, body, {
                responseType: "arraybuffer",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
                },
            })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                fileDownload(blob, `IssuerSearchResult.xlsx`);
            });
    };
    const handleShareClick = (email, id) => {
        setIsDropdownVisible(false);
        setShareDropDownValue(email);
        setUserId(id);
    };
    const handlePostApi = () => {
        if ((userId && SavedsearchId) || selectedCheckbox) {
            const body = {
                savedSearchId: SavedsearchId || selectedCheckbox[0],
                sharedUserId: userId,
            };
            PostSavedSearchShare(body);
            if (sucess.isSuccess === true) {
                showToastify(ISSUERLIST?.SUCCESS, ISSUERLIST?.SEARCHSUCESSFULLY);
            }
            setSelectedCheckbox([]);
        } else {
            showToastify(ISSUERLIST?.WARNING, ISSUERLIST?.SHAREVALIDATION);
        }
        setShareModal(false);
        setUserId("");
    };
    const commonElements = ViewFields.filter((element) => tempSelectedFields.includes(element));
    const columns = commonElements.map((key, id) => {
        // Modify the column header for the key "EliminateStatus"
        if (key === "EliminateStatus") {
            return {
                Header: "Status", // Change the column header
                accessor: "document." + key,
            };
        }
        if (key !== "undefined") {
            if (key === "ISIN") {
                return {
                    Header: key,
                    accessor: "document." + key,
                    Cell: (e) => (
                        <Link className=" items-center text-primary text-sm" to="/issuerdetails" state={{ data: e.value }}>
                            {e.value}
                        </Link>
                    ),
                };
            } else {
                return {
                    Header: key,
                    accessor: "document." + key,
                };
            }
        }
    });
    columns.unshift({
        Header: "Action",
        Cell: ({ row }) => (
            <Link className=" items-center text-primary text-sm" onClick={() => handleAddToWatchList(row)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </Link>
        ),
    });
    const handleSaveResultModal = (event) => {
        setSaveModal(true);
        SetSavedResultValue("");
        event.preventDefault();
    };
    const handleEditView = (e) => {
        e.preventDefault();
        setEditView(true);
    };
    const handleAddToWatchList = (row) => {
        setIsin(row.values["document.ISIN"]);
        setAddToWatchList(true);
    };
    const handleCheckChange = (e, facetDisplayName) => {
        const value = e.target.id;
        const name = e.target.value;
        setFilterApplyBtn(false);
        localStorage.setItem("ApplyButton", false);
        // Remove name from checkedValueBadges if unchecked
        if (!e.target.checked) {
            setCheckedValueBadges((prevBadges) => prevBadges.filter((badge) => badge !== name));
        } else if (!checkedValueBadges.includes(name)) {
            setCheckedValueBadges((prevBadges) => [...prevBadges, name]);
        }

        setCheckedValues((prevValues) => {
            const facetIndex = prevValues.findIndex((facet) => facet.facetName === facetDisplayName);
            const prevFacetValues = facetIndex !== -1 ? prevValues[facetIndex].values : [];
            if (prevFacetValues.includes(value)) {
                // Uncheck
                const updatedValues = prevFacetValues.filter((v) => v !== value);
                if (updatedValues.length === 0) {
                    return prevValues.filter((facet) => facet.facetName !== facetDisplayName);
                } else {
                    return prevValues.map((facet, i) => (i === facetIndex ? { ...facet, values: updatedValues } : facet));
                }
            } else {
                // Check
                const updatedValues = [...prevFacetValues, value];
                const updatedFacet = {
                    facetName: facetDisplayName,
                    values: updatedValues,
                };
                if (facetIndex !== -1) {
                    return prevValues.map((facet, i) => (i === facetIndex ? updatedFacet : facet));
                } else {
                    return [...prevValues, updatedFacet];
                }
            }
        });
    };
    const handleApplyClick = async () => {
        setFilterApplyBtn(true);
        localStorage.setItem("ApplyButton", true);
        setFinalCheckedBadgeValues(checkedValueBadges);
        localStorage.setItem(ISSUER.ISSUERBADGEVALUES, JSON.stringify(checkedValueBadges));
        localStorage.setItem(ISSUER.ISSUERSEARCHTEXT, searchText);
        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setCheckBoxFilter((prevFilter) => ({
            ...prevFilter,
            facetFilters: checkedValues,
        }));
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(ISSUER.ISSUERFILTER, myArrayString);
        postIssuerList({
            ...checkBoxFilter,
            searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
            fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            currentPage: 1,
            pageSize: pageNumber,
            facetFilters: checkedValues,
            sortOrderfilters: isNull(sortOrder) ? savedSearchData?.sortOrderfilters : sortFilter,
        });
    };
    const defaultReset = () => {
        localStorage.removeItem(ISSUER.EDITVIEWCHECKED);
        localStorage.removeItem(ISSUER.ISSUERCOLUMNFILTER);
        localStorage.removeItem(ISSUER.EDITVIEWID);
        localStorage.removeItem(ISSUER.EXISTINGVIEW);
        setExisitingViewColumn([]);
        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
        postIssuerList({
            ...checkBoxFilter,
            searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
            fieldNamesToReturn: [],
            currentPage: count,
            facetFilters: checkedValues,
            pageSize: pageNumber,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });

        EditViewDefaultCheckAll();
    };
    const handleResetClick = async () => {
        localStorage.removeItem(ISSUER.ISSUERFILTER);
        localStorage.removeItem(ISSUER.ISSUERSEARCHTEXT);
        localStorage.removeItem(ISSUER.ISSUERBADGEVALUES);
        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);

        setCheckedValues([]);
        setSearchText("");
        setFinalCheckedBadgeValues([]);
        setCheckedValueBadges([]);
        setSearchTextTyped("");
        setCheckBoxFilter({ ...initialValues });
        postIssuerList({
            ...checkBoxFilter,
            searchText: "*",
            fieldNamesToReturn: tempSelectedFields,
            currentPage: 1,
            facetFilters: [],
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
        for (var i = 0; i < postIssuerListData?.data?.facets.length; i++) {
            unToggle(postIssuerListData?.data?.facets[i].displayName);
        }
    };
    const unToggle = (displayName) => {
        if (showState[displayName] === true) {
            setShowState((prevState) => ({
                ...prevState,
                [displayName]: !prevState[displayName],
            }));
        }
    };
    const toggleShowState = (displayName) => {
        setShowState((prevState) => ({
            ...prevState,
            [displayName]: !prevState[displayName],
        }));
    };
    const getKeyValuePair = (_key, _value) => {
        return { [_key]: _value };
    };
    const OnChangeHandler = (e) => {
        let InputValue = e.target.value;
        setSearchTextTyped(e.target.value);
        setSearchText(InputValue);
    };
    const handleSearchIconClick = () => {
        if (searchText.length > 0 && searchTextTyped.length > 0) {
            setSearchTextTyped(searchText);
            localStorage.setItem(ISSUER?.ISSUERSEARCHTEXT, searchText);
            const sortFilter =
                Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
            postIssuerList({
                ...checkBoxFilter,
                searchText: searchTextTyped,
                fieldNamesToReturn: tempSelectedFields,
                currentPage: count,
                facetFilters: checkedValues,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            });
        } else if (searchText?.length === 0) {
            showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
            setSearchText("");
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (e.target.value.length > 0) {
                setSearchTextTyped(searchText);
                localStorage.setItem(ISSUER?.ISSUERSEARCHTEXT, searchText);
                const sortFilter =
                    Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
                postIssuerList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped,
                    fieldNamesToReturn: tempSelectedFields,
                    currentPage: count,
                    facetFilters: checkedValues,
                    pageSize: pageNumber,
                    sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                });
            } else if (e.target.value.length === 0) {
                showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
                setSearchText("");
            }
        }
    };
    const sortAPI = () => {
        setFinalCheckedBadgeValues(checkedValueBadges);
        localStorage.setItem(ISSUER.ISSUERBADGEVALUES, JSON.stringify(checkedValueBadges));
        localStorage.setItem(ISSUER.ISSUERSEARCHTEXT, searchText);
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(ISSUER.ISSUERFILTER, myArrayString);
        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
        postIssuerList({
            ...checkBoxFilter,
            searchText: searchText,
            fieldNamesToReturn: tempSelectedFields,
            currentPage: count,
            facetFilters: checkedValues,
            pageSize: pageNumber,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
    };
    const handleCheckedBadgeRemove = (index, value) => {
        setCheckedState(checkedState + 1);
        setFinalCheckedBadgeValues(checkedValueBadges);
        setShowState(false);
        setFinalCheckedBadgeValues((prevBadges) => {
            return prevBadges.filter((badge) => badge !== value);
        });
        setCheckedValueBadges((prevBadges) => {
            return prevBadges.filter((badge) => badge !== value);
        });

        setCheckedValues((prevValues) =>
            prevValues
                .map((facet) => {
                    const values = facet.values.filter((v) => v !== value);
                    return { ...facet, values };
                })
                .filter((facet) => facet.values.length > 0)
        );
        const updatedProductBadgeValues = finalCheckedBadgeValues.filter((badge) => badge !== value);
        const updatedProductFilter = checkedValues
            .map((facet) => {
                const values = facet.values.filter((v) => v !== value);
                return { ...facet, values };
            })
            .filter((facet) => facet.values.length > 0);

        // Update local storage with the updated values
        localStorage.setItem(ISSUER.ISSUERBADGEVALUES, JSON.stringify(updatedProductBadgeValues));
        localStorage.setItem(ISSUER.ISSUERFILTER, JSON.stringify(updatedProductFilter));
    };
    const handleBadgeRemove = (values) => {
        setSearchTextTyped("");
        setSearchText("");

        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
        postIssuerList({
            ...checkBoxFilter,
            searchText: searchText,
            fieldNamesToReturn: tempSelectedFields,
            currentPage: count,
            facetFilters: checkedValues,
            pageSize: pageNumber,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
        setCheckedValues((prevValues) =>
            prevValues.map((facet) =>
                facet.values.includes(values)
                    ? {
                          ...facet,
                          values: facet.values.filter((v) => v !== values),
                      }
                    : facet
            )
        );
    };
    useEffect(() => {
        setFinalCheckedBadgeValues(JSON.parse(getCheckedBadges));
        setSearchTextTyped(getSearchValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // const checkboxQueryJson = JSON.parse(EditViewData?.data?.fieldNameJson);

        if (tempSelectedFields.length === staticViewFields.length) {
            if (editViewMenu === "") {
                setEditViewMenu("Default");
            }
        } else if (editViewMenu === "Default" && tempSelectedFields.length != staticViewFields.length) {
            setEditViewMenu("");
        }
    }, [tempSelectedFields]);
    useEffect(() => {
        if (getIssuerSearchData && getIssuerSearchData?.isSuccess && has(getIssuerSearchData, ["data", "searchQueryJson"])) {
            const checkboxQueryJson = JSON.parse(getIssuerSearchData?.data?.searchQueryJson);
            setEditViewMenuId(getIssuerSearchData?.data?.savedSearchViewId);
            setSavedSearchData(checkboxQueryJson);
            const savedSearchText = checkboxQueryJson.searchText;
            setSearchTextTyped(savedSearchText);
            const checkedItems = {};
            for (const field of staticViewFields) {
                checkedItems[field] = checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
            }
            // const name =[EliminateStatus,Ticker,Name,ISIN,MarketCap]
            localStorage.setItem(ISSUER.SAVEDRETURNCOLUMN, JSON.stringify(checkboxQueryJson?.fieldNamesToReturn));
            setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
            setCheckedItems(checkedItems);

            const checkboxText = Array.isArray(checkboxQueryJson?.facetFilters)
                ? checkboxQueryJson?.facetFilters
                      .map((item) => {
                          return item.values;
                      })
                      .flat()
                : [];
            setFinalCheckedBadgeValues(checkboxText);
            setCheckedValueBadges(checkboxText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIssuerSearchData]);
    useEffect(() => {
        if (
            EditViewData &&
            EditViewData?.isSuccess &&
            has(EditViewData, ["data", "fieldNameJson"]) &&
            Object.keys(EditViewData?.data?.fieldNameJson).length > 0
        ) {
            const checkboxQueryJson = JSON.parse(EditViewData?.data?.fieldNameJson);
            setExisitingViewColumn(checkboxQueryJson?.fieldNamesToReturn);
            // setSavedSearchData(checkboxQueryJson);

            const checkedItems = {};
            for (const field of staticViewFields) {
                checkedItems[field] = checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
            }
            // setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
            setCheckedItems(checkedItems);
            setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);

            const getKeyValuePair = (_key, _value) => {
                return { [_key]: _value };
            };
            const sortFilter = getKeyValuePair(sortOrder?.sortColumnName, sortOrder?.sortOrder);
            if (isNull(retArray) || Object.keys(savedSearchData).length === 0) {
                postIssuerList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped,
                    fieldNamesToReturn: isNull(retArray) ? checkboxQueryJson?.fieldNamesToReturn : retArray,
                    currentPage: count,
                    facetFilters: isNull(localStorage.getItem(ISSUER.ISSUERFILTER)) ? [] : checkedValues,
                    pageSize: pageNumber,
                    sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                });
            }
            setEditViewMenu(EditViewData?.data?.name);
            localStorage.setItem(ISSUER.EXISTINGVIEW, EditViewData?.data?.name);
            if (isNull(localStorage.getItem(ISSUER.ISSUERFILTER))) {
                setCheckedValues([]);
            }
        }
    }, [EditViewData]);
    useEffect(() => {
        if (getEditViewSave && getEditViewSave?.isSuccess && has(getEditViewSave, ["data"])) {
            const ID = getEditViewSave?.data.find((obj) => obj.name === editViewMenu);
            localStorage.setItem(ISSUER.EDITVIEWID, ID?.id);
            const editView = getEditViewSave.data.find((item) => item.name === editViewMenu);
            if (editView) {
                const checkboxQueryJson = JSON.parse(editView.fieldNameJson);
                setSavedSearchData(checkboxQueryJson);

                const checkedItems = {};
                for (const field of staticViewFields) {
                    checkedItems[field] = checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
                }
                setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
                setCheckedItems(checkedItems);
            }
        }
    }, [getEditViewSave]);
    useEffect(() => {
        setEditViewInput("");
    }, [editView]);
    useEffect(() => {
        if (searchText.length === 0 && getIssuerSearchData && searchInput) {
            // setFinalCheckedBadgeValues(checkedValueBadges);
            const sortFilter =
                Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
            postIssuerList({
                ...checkBoxFilter,
                searchText: "*",
                fieldNamesToReturn: tempSelectedFields,
                currentPage: count,
                facetFilters: checkedValues,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);
    useEffect(() => {
        if (has(IssuerReturnName, ["data"]) && isArray(IssuerReturnName?.data) && !isEmpty(IssuerReturnName?.data)) {
            const data = [...IssuerReturnName.data];
            const isinIndex = data.indexOf("ISIN");
            if (isinIndex > -1 && isinIndex !== 2) {
                const name = data.splice(1, 1)[0];
                data.splice(2, 0, name);
                data.splice(isinIndex, 1);
                data.splice(2, 0, "ISIN");
                setStaticViewFields(data);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedSearchData, postIssuerListData]);
    useEffect(() => {
        //on page load ISIN
        const sortFilter = Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);

        if (postIssuerListData?.isSuccess) {
            if (checkedValues.length === 0) {
                setFinalCheckedBadgeValues(checkedValueBadges);
                localStorage.setItem(ISSUER.ISSUERBADGEVALUES, JSON.stringify(checkedValueBadges));
                localStorage.setItem(ISSUER.ISSUERSEARCHTEXT, searchText);
                const myArrayString = JSON.stringify(checkedValues);
                localStorage.setItem(ISSUER.ISSUERFILTER, myArrayString);
                postIssuerList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped,
                    fieldNamesToReturn: tempSelectedFields,
                    currentPage: count,
                    pageSize: pageNumber,
                    facetFilters: checkedValues,
                    sortOrderfilters: isNull(sortOrder) ? savedSearchData?.sortOrderfilters : sortFilter,
                });
            } else {
                setFinalCheckedBadgeValues(checkedValueBadges);
                localStorage.setItem(ISSUER.ISSUERBADGEVALUES, JSON.stringify(checkedValueBadges));
                localStorage.setItem(ISSUER.ISSUERSEARCHTEXT, searchText);
                const myArrayString = JSON.stringify(checkedValues);
                localStorage.setItem(ISSUER.ISSUERFILTER, myArrayString);
                postIssuerList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped,
                    fieldNamesToReturn: tempSelectedFields,
                    currentPage: count,
                    facetFilters: checkedValues,
                    pageSize: pageNumber,
                    sortOrderfilters: isNull(sortOrder) ? savedSearchData?.sortOrderfilters : sortFilter,
                });
            }
        }
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, pageNumber]);
    useEffect((e) => {
        if (addToWatchList) {
            e.preventDefault();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setSelectedFields(selectedFields);
        let retString = localStorage.getItem(ISSUER.ISSUERCOLUMNFILTER);
        let retArray = JSON.parse(retString);
        const fields =
            has(postIssuerListData, ["data", "results"]) && isArray(postIssuerListData?.data?.results) && !isEmpty(postIssuerListData?.data?.results)
                ? Object.keys(postIssuerListData?.data?.results[0]?.document)
                : [];
        if (
            savedSearchData &&
            Object.keys(savedSearchData).length > 0 &&
            postIssuerListData &&
            postIssuerListData.isSuccess &&
            postIssuerListData.data &&
            postIssuerListData.data.count === 0
        ) {
            setTempSelectedFields(
                retArray === null
                    ? has(postIssuerListData, ["data", "results"]) && isArray(postIssuerListData?.data?.results) && !isEmpty(postIssuerListData?.data?.results)
                        ? Object.keys(postIssuerListData?.data?.results[0]?.document)
                        : IssuerReturnName?.data
                    : retArray
            );
        } else if (postIssuerListData?.isSuccess && postIssuerListData?.data?.count === 0) {
            setTempSelectedFields(postIssuerListData?.originalArgs?.fieldNamesToReturn);
        } else {
            setTempSelectedFields(retArray === null ? ViewFields : retArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFields, ViewFields]);
    useEffect(() => {
        if (!isNull(localStorage.getItem(ISSUER.ISSUERSORTINGHEADER))) {
            sortAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder]);
    useEffect(() => {
        if (
            postIssuerListData?.isSuccess &&
            has(postIssuerListData, ["data", "facets"]) &&
            isArray(postIssuerListData?.data?.facets) &&
            !isEmpty(postIssuerListData?.data?.facets)
        ) {
            const tempArr = sortBy(postIssuerListData?.data?.facets, ["displayName"]);
            const indexIndustry = tempArr.findIndex((obj) => obj.displayName === "GICSIndustry");

            const indexGICSSector = tempArr.findIndex((obj) => obj.displayName === "GICSSector");

            [tempArr[indexIndustry], tempArr[indexGICSSector]] = [tempArr[indexGICSSector], tempArr[indexIndustry]];

            setUpdatedFacets(tempArr);
        }

        if (
            postIssuerListData?.isSuccess &&
            has(postIssuerListData, ["data", "facets"]) &&
            isArray(postIssuerListData?.data?.facets) &&
            !isEmpty(postIssuerListData?.data?.facets) &&
            searchTextTyped?.length === 0 &&
            checkedValueBadges?.length > 0
        ) {
            setFinalCheckedBadgeValues(checkedValueBadges);
        }
    }, [postIssuerListData]);
    useEffect(() => {
        localStorage.setItem(ISSUER.SAVEDID, SavedsearchId);
    }, [SavedsearchId]);
    useEffect(() => {
        if (checkedState) {
            const sortFilter =
                Object.keys(savedSearchData).length > 0 ? getKeyValuePair(sortColumnName, sortOrder) : getKeyValuePair(sortColumnName, sortOrder);
            postIssuerList({
                ...checkBoxFilter,
                searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
                fieldNamesToReturn: tempSelectedFields,
                currentPage: count,
                facetFilters: checkedValues,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedState]);
    useEffect(() => {
        if (getIssuerSearchData?.isSuccess) {
            getIssuerSearchData?.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SavedsearchId]);

    useEffect(() => {
        if (saveModal === false) {
            setsavedsearcheckbox([]);
        }
    }, [saveModal]);

    useEffect(() => {
        if (Object.keys(savedSearchData).length > 0) {
            if (Array.isArray(savedSearchData?.facetFilters)) {
                setCheckBoxFilter({ ...savedSearchData });
                setCheckedValues([...savedSearchData?.facetFilters]);
                setPageNumber(savedSearchData?.pageSize);
            }
            let sortOrderfilterskeys = savedSearchData?.sortOrderfilters;
            if (sortOrderfilterskeys !== null && typeof sortOrderfilterskeys === "object") {
                setSortOrder(checkSort);
            }
            postIssuerList({
                ...savedSearchData,
                facetFilters: checkedValues?.length === 0 ? savedSearchData?.facetFilters : filterApplyBtn ? checkedValues : [],
                fieldNamesToReturn: isNull(retArray) ? savedSearchData?.fieldNamesToReturn : retArray,
            });
            const checkValues = localStorage.getItem(ISSUER.ISSUERFILTER);
            const getReturnColumnLocal = localStorage.getItem(ISSUER.SAVEDRETURNCOLUMN);
            setCheckedValues(isNull(checkValues) ? savedSearchData?.facetFilters : JSON.parse(checkValues));
            setTempSelectedFields(isNull(getReturnColumnLocal) ? savedSearchData?.fieldNamesToReturn : JSON.parse(getReturnColumnLocal));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedSearchData]);
    useEffect(() => {
        const sortFilter =
            Object.keys(savedSearchData).length > 0 ? savedSearchData.sortOrderfilters : getKeyValuePair(sortOrder?.sortColumnName, sortOrder?.sortOrder);
        postIssuerList({
            ...checkBoxFilter,
            searchText: searchTextTyped,
            fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            currentPage: count,
            facetFilters: checkedValues,
            pageSize: pageNumber,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
    }, [isSaved]);

    useEffect(() => {
        EditViewDefaultCheckAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IssuerReturnName]);

    React.useMemo(() => {
        setCount(postIssuerListData?.originalArgs?.currentPage);
        if (postIssuerListData?.isSuccess) {
            setData(postIssuerListData?.data?.results);
            const fields =
                has(postIssuerListData, ["data", "results"]) && isArray(postIssuerListData?.data?.results) && !isEmpty(postIssuerListData?.data?.results)
                    ? Object.keys(postIssuerListData?.data?.results[0]?.document)
                    : [];
            const data = [...fields];
            if (data && data.includes("ISIN")) {
                const isinIndex = data.indexOf("ISIN");
                if (isinIndex > -1 && isinIndex !== 2) {
                    const name = data.splice(1, 1)[0];
                    data.splice(2, 0, name);
                    data.splice(isinIndex, 1);
                    data.splice(2, 0, "ISIN");
                    setStaticViewFields(data);
                    setViewFields([...data]);
                }
            }
            if (data && data.includes("EliminateStatus")) {
                const EliminateStatusIndex = data.indexOf("EliminateStatus");
                const item = data[EliminateStatusIndex];
                const newData = [item, ...data.slice(0, EliminateStatusIndex), ...data.slice(EliminateStatusIndex + 1)];
                setViewFields([...newData]);
            }
            if (data && !data.includes("ISIN") && !data.includes("EliminateStatus")) {
                setViewFields([...data]);
            }
            if (count >= 1) {
                if (isEmpty(fields)) {
                    setViewFields(postIssuerListData?.originalArgs?.fieldNamesToReturn);
                } else {
                    const isinIndex = fields.indexOf("ISIN");
                    fields.splice(isinIndex, 1);
                    fields.splice(2, 0, "ISIN");
                    setSelectedFields(fields);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postIssuerListData, savedSearchData]);
    const sortedExistingViewColumn = sortBy(exisitngViewColumn);
    const sortedCheckedFields = sortBy(checkedFields);
    const arraysMatch = isEqual(sortedExistingViewColumn, sortedCheckedFields);
    return (
        <React.Fragment>
            <div className="flex-grow w-full overflow-hidden flex h-full">
                <div
                    className={`transition flex flex-col bg-gray-50 w-10/12 md:min-w-[322px] md:max-w-[322px] fixed sm:relative z-10 top-0 h-full ${
                        filterOpen ? "left-0" : "-left-96 sm:left-0"
                    }`}
                >
                    <div className="flex justify-between p-4">
                        <p className="font-medium">{PAGECONSTANTS?.FILTER}</p>
                        <button
                            className="block sm:hidden"
                            onClick={() => {
                                setFilterOpen(false);
                            }}
                        >
                            Close
                        </button>
                    </div>
                    <div className="flex px-4 pb-4 border-b">
                        <div className="w-full relative rounded-md flex shadow-sm" style={{ backgroundColor: "rgb(243,244,246)" }}>
                            <input
                                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                                type="text"
                                aria-label="Filter projects"
                                placeholder={searchTextTyped?.length > 0 ? "" : "Keyword Search..."}
                                maxLength={20}
                                onKeyDown={handleKeyPress}
                                onChange={OnChangeHandler}
                                onClick={() => setSearchInput(true)}
                                value={searchTextTyped === "*" ? "" : searchTextTyped}
                                style={{ backgroundColor: "rgb(243,244,246)" }}
                            />
                            {searchTextTyped?.length > 0 && searchTextTyped !== "*" && (
                                <XCircleIcon
                                    className="w-[18px]  ml-1 text-red-500 cursor-pointer absolute right-7 top-2"
                                    onClick={() => {
                                        handleBadgeRemove();
                                    }}
                                />
                            )}

                            <MagnifyingGlassIcon
                                className="w-4 text-dark absolute right-2 top-2 cursor-pointer"
                                value={"Click"}
                                onClick={handleSearchIconClick}
                            />
                        </div>
                    </div>
                    <div className="flex-1 overflow-auto px-4">
                        <div className="flex flex-wrap">
                            {finalCheckedBadgeValues?.map((item, index) => {
                                return (
                                    <div className="flex rounded w-fit p-2">
                                        <Badge value={item} variant={"GrayBlue"} />
                                        <XCircleIcon
                                            className="w-[18px] ml-1 text-red-500 cursor-pointer"
                                            onClick={() => handleCheckedBadgeRemove(index, item)}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        {postIssuerListData?.isSuccess &&
                        has(postIssuerListData, ["data", "facets"]) &&
                        isArray(postIssuerListData?.data?.facets) &&
                        !isEmpty(postIssuerListData?.data?.facets) ? (
                            updatedFacets.map((facet) => {
                                const sortedFacets = (facet?.facetItems || [])
                                    .map((item) => ({ value: item.value, count: item.count }))
                                    .sort((a, b) => {
                                        if (facet.displayName === "MarketCapRange") {
                                            return get_numeric_value_sorting(b) - get_numeric_value_sorting(a);
                                        }
                                        return a.value.localeCompare(b.value);
                                    });
                                const facetDisplayName = facet.displayName;
                                const isShown = showState[facetDisplayName] ?? false;
                                return (
                                    <>
                                        <div>
                                            <div
                                                className="flex items-center gap-1 py-2 whitespace-nowrap font-bold cursor-pointer"
                                                onClick={() => toggleShowState(facetDisplayName)}
                                            >
                                                <ChevronRightIcon className={"w-4"} /> {facetDisplayName.replace(/(?=[A-Z][a-z])/g, " ")}
                                            </div>
                                            {isShown && (
                                                <div className="pl-5 flex flex-col gap-2">
                                                    {sortedFacets.map((item) => {
                                                        const value = item.value;
                                                        const checked = checkedValues?.some((facet) => facet?.values?.includes(value));
                                                        return (
                                                            <>
                                                                <div className="flex gap-2 items-center" key={data.id}>
                                                                    <Checkbox
                                                                        label={value + " (" + item.count + ")"}
                                                                        className={"rounded"}
                                                                        id={value}
                                                                        name={value}
                                                                        Checked={checked}
                                                                        handleChange={(e) => handleCheckChange(e, facetDisplayName)}
                                                                    />
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            })
                        ) : (
                            <></>
                        )}
                        {postIssuerListData?.isLoading && (
                            <div class="flex flex-col space-y-4">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="flex-1 space-y-4 py-1">
                                        {range(15).map((range, i) => (
                                            <div class="h-4 bg-gray-300 rounded w-5/6"></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex gap-4 p-4 h-15 pb-0">
                        <Button className="w-full" variant="primary-outline" value="Reset" onClick={() => handleResetClick()} />
                        <Button className="w-full" variant="primary" value="Apply" onClick={() => handleApplyClick()} />
                    </div>
                    <div className="flex p-4 pt-3">
                        <Button className="w-full" variant="primary" value="Save Search" onClick={(e) => handleSaveResultModal(e)} />
                    </div>
                </div>
                <div className="h-full w-full overflow-auto flex flex-col">
                    <div className="flex justify-between border-b items-center py-4 md:p-4">
                        <h1 className="hidden lg:block font-medium whitespace-nowrap">
                            Issuer Search ({postIssuerListData?.data?.count ? (postIssuerListData?.data?.count).toLocaleString() : ""})
                        </h1>
                        {postIssuerListData?.isLoading || postIssuerListData?.data?.results?.length === 0 || postIssuerListData?.isError ? (
                            ""
                        ) : (
                            <div className="text-white gap-4 flex flex-col md:flex-row w-full px-4 justify-end">
                                <div className="flex justify-between">
                                    <Menu as="div" className="relative text-left">
                                        <div className="flex">
                                            <p className="text-gray-900 whitespace-nowrap block sm:hidden lg:block">{ISSUERLIST?.EXISTINGVIEW}</p>
                                            <Menu.Button className="flex justify-center rounded-md bg-white px-3 font-semibold text-gray-900 hover:bg-gray-50 items-center">
                                                {/* {tempSelectedFields.length === staticViewFields.length && "Default"} */}
                                                {exisitngViewColumn.length === 0 ? editViewMenu : arraysMatch ? editViewMenu : ""}
                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute border shadow  z-10 mt-2 w-56 origin-top-right rounded-md bg-white 	 focus:outline-none  static-scroll">
                                                <div className="py-1 max-h-[10rem] overflow-auto">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <span
                                                                className={(active ? "bg-gray-100 text-gray-700" : "  text-black", "block px-4 py-2 text-sm")}
                                                                onClick={() => {
                                                                    defaultReset();
                                                                    setEditViewMenu(ISSUERLIST?.DEFAULT);
                                                                    setEditViewMenuId("");
                                                                }}
                                                                style={{ color: "black", cursor: "pointer" }}
                                                            >
                                                                {ISSUERLIST?.DEFAULT}
                                                            </span>
                                                        )}
                                                    </Menu.Item>
                                                    {getEditViewSave?.isSuccess &&
                                                        has(getEditViewSave, ["data"]) &&
                                                        isArray(getEditViewSave?.data) &&
                                                        !isEmpty(getEditViewSave?.data) &&
                                                        getEditViewSave?.data?.map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <span
                                                                            className={
                                                                                (active ? "bg-gray-100 text-gray-700" : "  text-black",
                                                                                "block px-4 py-2 text-sm hover:bg-gray-100 rounded")
                                                                            }
                                                                            onClick={() => {
                                                                                setEditViewMenu(item.name);
                                                                                setEditViewMenuId(item.id);
                                                                                localStorage.setItem(ISSUER.EDITVIEWID, item?.id);
                                                                                localStorage.removeItem(ISSUER.EDITVIEWCHECKED);
                                                                                localStorage.removeItem(ISSUER.ISSUERCOLUMNFILTER);
                                                                                // handleResetClick();
                                                                                // defaultReset();
                                                                            }}
                                                                            style={{ color: "black", cursor: "pointer" }}
                                                                        >
                                                                            {item.name}
                                                                        </span>
                                                                    )}
                                                                </Menu.Item>
                                                            );
                                                        })}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <button
                                        className="text-dark flex gap-2 items-center sm:hidden"
                                        onClick={() => {
                                            setFilterOpen(true);
                                        }}
                                    >
                                        <i className="far fa-filter"></i>
                                        {PAGECONSTANTS?.FILTER}
                                    </button>
                                </div>
                                <div className="flex gap-3">
                                    <Link className="items-center text-primary whitespace-nowrap" onClick={handleEditView}>
                                        {ISSUERLIST?.EDITVIEW}
                                    </Link>
                                    {Permissionapi?.includes("Issuer_ExportSearchResult") || Permissionapi?.includes("All") ? (
                                        <>
                                            <div className="text-gray-200 h-1">|</div>
                                            <Link Link className="items-center text-primary whitespace-nowrap flex justify-center">
                                                <span
                                                    className="text-primary"
                                                    onClick={() => {
                                                        exportapicall();
                                                        showToastify("success", "Export initiated");
                                                    }}
                                                >
                                                    {"Export"}
                                                </span>
                                            </Link>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-grow overflow-hidden md:pr-4 static-scroll">
                        {postIssuerListData?.isSuccess && !isEmpty(postIssuerListData?.data?.results) && (
                            <div className="h-full flex-col flex">
                                <TailwindTable
                                    columns={columns}
                                    data={data}
                                    setCount={setCount}
                                    count={count || 1}
                                    totalLength={postIssuerListData?.data?.count}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    pageArray={pageArray}
                                    sortColumnName={sortColumnName}
                                    setSortColumnName={setSortColumnName}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    path="/issuerdetails"
                                    returnPath="/issuer/search"
                                    keyValue="issuerList"
                                    sortingName={ISSUER.ISSUERSORTING}
                                    sortingHeadername={ISSUER.ISSUERSORTINGHEADER}
                                />
                            </div>
                        )}
                        {postIssuerListData?.isLoading && (
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                    <tr>
                                                        {loadingTableheader?.IssuerList?.map((item) => {
                                                            return (
                                                                <th
                                                                    scope="col"
                                                                    className="th group px-4 py-2 text-left text-xs font-bold text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                >
                                                                    {item}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {range(10).map((range, i) => (
                                                        <tr className="animate-pulse" key={i}>
                                                            {loadingTableheader?.IssuerList?.map((item) => {
                                                                return (
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {postIssuerListData?.isError ||
                            (isEmpty(postIssuerListData?.data?.results) && !postIssuerListData?.isLoading && (
                                <div className="flex flex-col">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <p className="text-center">{PAGECONSTANTS?.NODATAFOUND}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <UserContext.Provider
                value={{
                    saveModal,
                    setSaveModal,
                    shareModal,
                    setWLName,
                    pageNumber,
                    WLName,
                    checked,
                    setChecked,
                    setShareModal,
                    savedSearchdata,
                    setSavedSearchdata,
                    setFinalCheckedBadgeValues,
                    editView,
                    sortOrder,
                    setEditView,
                    addToWatchList,
                    Isin,
                    ViewFields,
                    checkedValues,
                    setViewFields,
                    selectedCheckbox,
                    setSelectedCheckbox,
                    sortColumnName,
                    selectedFields,
                    count,
                    setSelectedFields,
                    searchTextTyped,
                    setAddToWatchList,
                    savedResultValue,
                    SetSavedResultValue,
                    editViewInput,
                    setEditViewInput,
                    checkedItems,
                    setCheckedItems,
                    checkedFields,
                    setCheckedFields,
                    checkedValueBadges,
                    WLId,
                    setIsSaved,
                    setWLId,
                    shareInputValue,
                    setShareInputValue,
                    shareDropDownValue,
                    setShareDropDownValue,
                    isDropdownVisible,
                    setIsDropdownVisible,
                    staticViewFields,
                    checkBoxFilter,
                    postIssuerList,
                    savedsearcheckbox,
                    setsavedsearcheckbox,
                    postIssuerListData,
                    tempSelectedFields,
                    editViewReturnValue,
                    setEditViewReturnValue,
                    setEditViewMenu,
                    editViewMenuId,
                }}
            >
                <Modal
                    showmodal={saveModal}
                    setshowmodal={setSaveModal}
                    title="Save Search"
                    size={"w-[400px] "}
                    body={<Saveresultbody ModuleName="Issuers" />}
                    footer={<ModalFooter ModuleName="Issuers" postApiCall={postIssuerList} />}
                    isFooterEnable={true}
                />

                <ShareModal
                    title={ISSUERDETAILS.SHARE_SAVEDSEARCH}
                    size={"w-[60%] "}
                    showmodal={shareModal}
                    setshowmodal={setShareModal}
                    ApiData={GetUserDetailsData?.isSuccess && GetUserDetailsData}
                    handleShareClick={handleShareClick}
                    setShareModal={setShareModal}
                    onClick={handlePostApi}
                    setUserId={setUserId}
                />
                <Modal
                    setshowmodal={setEditView}
                    showmodal={editView}
                    title="Edit View"
                    size={"w-[485px]"}
                    body={<EditView postApiCall={postIssuerList} issuerColumnFilter={ISSUER.ISSUERCOLUMNFILTER} issuerEditViewCheck={ISSUER.EDITVIEWCHECKED} />}
                    footer={<EditViewFooter postApiCall={postIssuerList} moduleName="Issuers" />}
                    isFooterEnable={true}
                />

                <Modal
                    setshowmodal={setAddToWatchList}
                    showmodal={addToWatchList}
                    title="Add to Watch List"
                    body={<AddToWatchListView ModuleName="Issuers" />}
                    size={"w-[400px]"}
                    footer={<AddToWatchListFooter ModuleName="Issuers" />}
                    isFooterEnable={true}
                />
            </UserContext.Provider>
        </React.Fragment>
    );
};
export default IssuerList;
