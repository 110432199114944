import { interceptorsApiCall } from "../../globals/interceptors";

export const EliminateIssuerApi = interceptorsApiCall.injectEndpoints({
  endpoints: (builder) => ({
    EliminateIssuerDetailsApi: builder.query({
      query: (params) => `/api/Issuer/GetIssuerEliminateDataGrouping?${params}`,
    }),
  }),
});

export const { useEliminateIssuerDetailsApiQuery } = EliminateIssuerApi;
