import { interceptorsApiCall } from "../../globals/interceptors";
const apiWithTag = interceptorsApiCall.enhanceEndpoints({ addTagTypes: ['GetIssuerWatchList'] });
export const GetIssuerWatchListApi = apiWithTag.injectEndpoints({
	endpoints: (builder) => ({
		// getIssuerWatchList: builder.query({
		// 	query: (params) => `api/Issuer/GetPaginatedIssuerWatchList?${params}`,
		// 	providesTags: ['GetIssuerWatchList'],
		// }),
		getIssuerWatchList: builder.mutation({
			query: (payload) => ({
				url: "api/Issuer/GetPaginatedIssuerWatchList",
				method: "POST",
				body: payload,
				//   credentials: 'include',
			}),
		}),
	deleteIssuerWatchListApi: builder.mutation({
		query: (payload) => ({
			url: "api/Issuer/DeleteIssuerWatchList",
			method: "DELETE",
			body: payload,
			//   credentials: 'include',
		}),
		invalidatesTags: ['GetIssuerWatchList'],
	}),
})
})

export const {useDeleteIssuerWatchListApiMutation,useGetIssuerWatchListMutation} = GetIssuerWatchListApi;