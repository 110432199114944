import { React, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Modal({
  body,
  footer,
  title,
  showmodal,
  setshowmodal,
  size
}) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={showmodal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setshowmodal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={`relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${size}`}>
                <div className='bg-white sm:pt-5 pt-2 pb-0 p-3 sm:pb-0 rounded-xl'>
                  <div className='w-full'>
                    <div className='text-left'>
                      <div className='flex border-gray-200 border-b pb-2 justify-between'>
                        <Dialog.Title as='h1' className='text-xl font-semibold leading-6 text-gray-900 mt-1'>
                          {title}
                        </Dialog.Title>
                        <button>
                          <i
                            className="far fa-times"
                            onClick={() => {
                              setshowmodal(false);
                            }}
                          ></i>
                        </button>
                      </div>
                      <div className="py-4 pb-0 w-full static-scroll">{body}</div>
                    </div>
                  </div>
                </div>
                <div className="py-3 pt-0 md:flex sm:flex-row-reverse px-3">
                  {footer}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
