import React from "react";
import Button from "../../../components/Button/Button";

const EditManageUsersModalFooter = (props) => {
    return (
        <div>
            <Button
                className="w-full text-white"
                variant="primary"
                value="Cancel"
                onClick={() => (props.editUsersModal ? props.setEditUsersModal(false) : props.setMatrixReportModal(false))}
            />
        </div>
    );
};

export default EditManageUsersModalFooter;
