import React, { useContext } from "react";
import Button from "../../../components/Button/Button";
import UserContext from "../../../context/modal/Contextmodel";
import { isEmpty, isNull } from "lodash";

const UpdateMetaDataFooter = ({ buttonValue, onClick, setValue }) => {
    const { shareDropDownValue } = useContext(UserContext);

    return (
        <div className="grid grid-rows-1 grid-flow-col gap-4 mt-4 ">
            <Button value="Cancel" variant="secondary" onClick={() => { setValue(false) }} />
            <Button
                value={buttonValue}
                variant="primary"
                onClick={() => { onClick(); setValue(false); }}
                className={"cursor-pointer"}
            />
        </div>
    );
};

export default UpdateMetaDataFooter;
