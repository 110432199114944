import { interceptorsApiCall } from "../../../globals/interceptors";
const apiWithTag = interceptorsApiCall.enhanceEndpoints({ addTagTypes: ['editViewSave'] });
export const IssuerSearchApi = apiWithTag.injectEndpoints({
	endpoints: (builder) => ({
        postWatchList: builder.mutation({
			query: (payload) => ({
					url: "api/Issuer/CreateWatchlist",
					method: "POST",
					body: payload,
			}),
		}),
        postIssuerList: builder.mutation({
			query: (payload) => {
				return {
					url: "api/issuer/AdvancedIssuerSearch",
					method: "POST",
					body: payload,
				};
			},
		}),
		savedSearch:builder.mutation({
			query:(payload)=>{
				return{
					url:"api/Issuer/CreateSaveSearch",
					method:"POST",
					body:payload
				}
			}
		}),
		editViewSave:builder.mutation({
			query:(payload)=>{
				return{
					url:"api/Issuer/CreateSavedSearchView",
					method:"POST",
					body:payload
				}
			},
			invalidatesTags: ['editViewSave'],
		}),
		getEditViewSave: builder.query({
			query: (params) => `api/Issuer/GetSavedSearchViewList?${params}`,
			providesTags: ['editViewSave'],
		}),
		getEditViewById: builder.query({
			query: (params) => `api/Issuer/GetSavedSearchViewById?${params}`,
		}),
    })
});

export const {usePostWatchListMutation, usePostIssuerListMutation, useSavedSearchMutation,useEditViewSaveMutation,useGetEditViewSaveQuery,useGetEditViewByIdQuery} = IssuerSearchApi