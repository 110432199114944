import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState, Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { has, isArray, isEmpty, isEqual, isNull, range, sortBy } from "lodash";
import axios from "axios";
import fileDownload from "js-file-download";
import UpdateMetaDataModal from "./ProductComponents/UpdateMetaDataModal";
import UpdateMetaDataFooter from "./ProductComponents/UpdateMetaDataFooter";
import Saveresultbody from "../Saveresultbody";
import ModalFooter from "../ModalFooter";
import AddToWatchListView from "../AddToWatchList";
import AddToWatchListFooter from "../AddToWatchListFooter";
import EditView from "../EditView";
import EditViewFooter from "../EditViewFooter";
import TailwindTable from "../../components/Table/TailwindTable";
import Checkbox from "../../components/Checkbox/Checkbox";
import Button from "../../components/Button/Button";
import { ISSUER, ISSUERLIST, PRODUCTLIST } from "../../constants/appConstants";
import Badge from "../../components/Badge/Badge";
import { queryString } from "../../helpers";
import UserContext from "../../context/modal/Contextmodel";
import Modal from "../../components/Modals/Modals";
import { useGetSavedSearchByIdQuery } from "../../Services/IssuerList/IssuerListApi";
import {
  PAGECONSTANTS,
  getKeyValuePair,
  get_numeric_value_sorting,
  loadingTableheader,
  showToastify,
} from "../../constants/Common";
import {
  usePostProductListMutation,
  useUpdateProductMetaDataMutation,
} from "../../Services/Product/ProductApi";
import { useGetIssuerReturnFieldQuery } from "../../Services/Issuer/Home/IssuerHomeApi";
import {
  useGetEditViewByIdQuery,
  useGetEditViewSaveQuery,
} from "../../Services/Issuer/Search/IssuerSearchApi";

const ProductList = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const location = useLocation();
  const pageArray = [50, 75, 100, 125, 150];
  const getEditViewIdLocal = localStorage.getItem(
    PRODUCTLIST.PRODUCTEDITVIEWID
  );
  const [OAISMSCApprovalStatus, setOAISMSCApprovalStatus] = useState();
  const getExisitingViewLocal = localStorage.getItem(
    PRODUCTLIST.PRODUCTEXISTINGVIEW
  );
  const getCheckedBadges = localStorage.getItem(PRODUCTLIST.PRODUCTBADGEVALUES);
  const checkSort = localStorage.getItem(PRODUCTLIST.PRODUCTSORTING);
  const checkSortHeader = localStorage.getItem(PRODUCTLIST.PRODUCTSORTINGNAME);
  const checkValues = localStorage.getItem(PRODUCTLIST.PRODUCTFILTER);
  const getSavedIdLocal = localStorage.getItem(ISSUER.PRODUCSAVEDID);
  const SavedsearchId =
    getSavedIdLocal === "undefined" || isNull(getSavedIdLocal)
      ? location.state?.data
      : getSavedIdLocal;
  const getReturnColumnLocal = localStorage.getItem(
    PRODUCTLIST.PRODUCTSAVEDRETURNCOLUMN
  );
  const getSearchValue = localStorage.getItem(PRODUCTLIST.PRODUCTSEARCHTEXT);
  const parsedValues = checkValues ? JSON.parse(checkValues) : [];
  const retString = localStorage.getItem(PRODUCTLIST.PRODUCTCOLUMNFILTER);
  const retArray = JSON.parse(retString);
  const applyButtonValue = localStorage.getItem("ProductApplyButton");
  const [sortOrder, setSortOrder] = useState(checkSort);
  const [sortColumnName, setSortColumnName] = useState(checkSortHeader);
  const [editViewMenuId, setEditViewMenuId] = useState(
    getEditViewIdLocal === "undefined" ? "" : getEditViewIdLocal
  );
  const [editViewMenu, setEditViewMenu] = useState(
    isNull(getExisitingViewLocal) ? "Default" : getExisitingViewLocal
  );
  const [finalCheckedBadgeValues, setFinalCheckedBadgeValues] = useState(
    JSON.parse(getCheckedBadges)
  );
  const [filterApplyBtn, setFilterApplyBtn] = useState(
    isNull(applyButtonValue) ? false : applyButtonValue
  );
  const [tempSelectedFields, setTempSelectedFields] = useState(
    isNull(getReturnColumnLocal) ? [] : JSON.parse(getReturnColumnLocal)
  );
  const [Isin, setIsin] = useState();
  const [WLId, setWLId] = useState("");
  const [WLName, setWLName] = useState("");
  const [addToWatchList, setAddToWatchList] = useState(false);
  const [count, setCount] = useState(1);
  const [showState, setShowState] = useState({});
  const [pageNumber, setPageNumber] = useState(50);
  const [searchTextTyped, setSearchTextTyped] = useState("");
  const [searchInput, setSearchInput] = useState(false);
  const [editView, setEditView] = useState(false);
  const [editViewInput, setEditViewInput] = useState("");
  const [checked, setChecked] = useState([]);
  const [editViewReturnValue, setEditViewReturnValue] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [updatedFacets, setUpdatedFacets] = useState([]);
  const [staticViewFields, setStaticViewFields] = useState([]);
  const [checkedValueBadges, setCheckedValueBadges] = useState(
    isNull(JSON.parse(getCheckedBadges)) ? [] : JSON.parse(getCheckedBadges)
  );
  const [checkedState, setCheckedState] = useState(0);
  const [checkedValues, setCheckedValues] = useState(parsedValues);
  const [selectedFields, setSelectedFields] = useState([]);
  const [savedResultValue, SetSavedResultValue] = useState("");
  const [Productid, setProductid] = useState("");
  const [savedsearcheckbox, setsavedsearcheckbox] = useState("");
  const [savedSearchData, setSavedSearchData] = useState({});
  const [savedSearchdata, setSavedSearchdata] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [shareDropDownValue, setShareDropDownValue] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedFields, setCheckedFields] = useState([]);
  const [ViewFields, setViewFields] = useState([]);
  const [showUpdateMeta, setShowUpdateMeta] = useState(false);
  const [isOAIS, setIsOAIS] = useState(true);
  const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
  const [exisitngViewColumn, setExisitingViewColumn] = useState([]);
  const [productList, productListData] = usePostProductListMutation();
  const getIssuerSearchData = useGetSavedSearchByIdQuery(
    queryString({ params: { Id: SavedsearchId } }),
    {
      skip: !SavedsearchId || !isNull(checkValues),
    }
  );
  const IssuerReturnName = useGetIssuerReturnFieldQuery(
    queryString({ params: { searchIndexName: "products" } })
  );
  const getEditViewSave = useGetEditViewSaveQuery(
    queryString({ params: { ModuleName: "Products" } })
  );
  const EditViewData = useGetEditViewByIdQuery(
    queryString({ params: { Id: editViewMenuId } }),
    {
      skip: !editViewMenuId,
    }
  );
  const [updateProductMetaDataApi, updateProductMetaDataValue] =
    useUpdateProductMetaDataMutation();
  const initialValues = {
    searchText: isNull(getSearchValue) ? "*" : getSearchValue,
    useDefaultFacets: true,
    sortOrderfilters: { Ticker: 0 },
    fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
    fieldNamesToSearch: [],
    facetOptions: null,
    facetFilters: checkedValues,
    searchIndexName: "string",
    currentPage: 1,
    pageSize: pageNumber,
  };
  useEffect(() => {
    if (updateProductMetaDataValue.isSuccess) {
      productList({
        searchText: isNull(getSearchValue) ? "*" : getSearchValue,
        useDefaultFacets: true,
        sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
        fieldNamesToSearch: [],
        facetOptions: null,
        facetFilters: checkedValues,
        searchIndexName: "string",
        currentPage: 1,
        pageSize: pageNumber,
      });
    }
  }, [updateProductMetaDataValue]);
  const [checkBoxFilter, setCheckBoxFilter] = useState({ ...initialValues });
  const EditViewDefaultCheckAll = () => {
    if (
      has(IssuerReturnName, ["data"]) &&
      isArray(IssuerReturnName?.data) &&
      !isEmpty(IssuerReturnName?.data)
    ) {
      const tempIssuerReturnName = IssuerReturnName?.data.reduce(
        (obj, field) => {
          obj[field] = true;
          return obj;
        },
        {}
      );
      setCheckedItems(tempIssuerReturnName);
      setCheckedFields(IssuerReturnName?.data);
    }
  };
  const exportapicall = async () => {
    const sortFilter =
      Object.keys(savedSearchData).length > 0
        ? getKeyValuePair(sortColumnName, sortOrder)
        : getKeyValuePair(sortColumnName, sortOrder);

    const body = {
      searchIndexName: "",
      searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
      currentPage: count,
      pageSize: pageNumber,
      useDefaultFacets: true,
      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
      fieldNamesToReturn: tempSelectedFields,
      fieldNamesToSearch: [],
      facetOptions: null,
      facetFilters: checkedValues,
      format: 0,
    };
    axios
      .post(
        `${process.env.REACT_APP_DEV_APIBASEURI}api/Product/ExportProductSearchResult`,
        body,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, `ProductSearchResult.xlsx`);
      });
  };
  const OnChangeHandler = (e) => {
    let InputValue = e.target.value;
    setSearchTextTyped(e.target.value);
    setSearchText(InputValue);
  };
  const handleSearchIconClick = () => {
    if (searchText.length > 0 && searchTextTyped.length > 0) {
      setSearchTextTyped(searchText);
      localStorage.setItem(PRODUCTLIST.PRODUCTSEARCHTEXT, searchText);
      const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
      productList({
        searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
        currentPage: count,
        pageSize: pageNumber,
        useDefaultFacets: true,
        sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        fieldNamesToReturn: tempSelectedFields,
        fieldNamesToSearch: [],
        facetOptions: null,
        facetFilters: checkedValues,
      });
    } else if (searchText?.length === 0) {
      showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
      setSearchText("");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.length > 0) {
        setSearchTextTyped(searchText);
        localStorage.setItem(PRODUCTLIST.PRODUCTSEARCHTEXT, searchText);
        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
        productList({
          searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
          currentPage: count,
          pageSize: pageNumber,
          useDefaultFacets: true,
          sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
          fieldNamesToReturn: tempSelectedFields,
          fieldNamesToSearch: [],
          facetOptions: null,
          facetFilters: checkedValues,
        });
      } else if (e.target.value.length === 0) {
        showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
        setSearchText("");
      }
    }
  };
  const commonElements = ViewFields.filter((element) =>
    tempSelectedFields.includes(element)
  );
  const columns = commonElements.map((key, id) => {
    if (key === "EliminateStatus") {
      return {
        Header: "Status", // Change the column header
        accessor: "document." + key,
      };
    }
    if (key === "ProductId") {
      return {
        Header: "Id", // Change the column header
        accessor: "document." + key,
      };
    }
    if (key === "WeightedEliminatedPercent") {
      return {
        Header: "Weighted % Eliminated",
        accessor: "document." + key,
      };
    }
    if (key === "EliminatedIssuersCount") {
      return {
        Header: "Total # Eliminated Stocks",
        accessor: "document." + key,
      };
    }
    if (key === "IdentifiedProductsPercent") {
      return {
        Header: "% of Product Identified",
        accessor: "document." + key,
      };
    }
    if (key === "ScreenedProductsPercent") {
      return {
        Header: "% of Product Screened",
        accessor: "document." + key,
      };
    }
    if (key === "HoldingsCount") {
      return {
        Header: "# Holdings",
        accessor: "document." + key,
      };
    }
    if (key === "IssuersCount") {
      return {
        Header: "# Issuers",
        accessor: "document." + key,
      };
    }
    if (key === "IdentifiedIssuersCount") {
      return {
        Header: "# Issuers Identified",
        accessor: "document." + key,
      };
    }
    if (key === "ScreenedIssuersCount") {
      return {
        Header: "# Issuers Screened",
        accessor: "document." + key,
      };
    }
    if (key === "ProductsCount") {
      return {
        Header: "# Underlying Products",
        accessor: "document." + key,
      };
    }
    if (key === "IdentifiedProductsCount") {
      return {
        Header: "# Underlying Products Identified",
        accessor: "document." + key,
      };
    }
    if (key === "ScreenedProductsCount") {
      return {
        Header: "# Underlying Products Screened",
        accessor: "document." + key,
      };
    }
    if (key === "IdenifiedBondsCount") {
      return {
        Header: "# Bonds Identified",
        accessor: "document." + key,
      };
    }
    if (key === "IdenifiedOthersCount") {
      return {
        Header: "# Other Identified",
        accessor: "document." + key,
      };
    }
    if (key === "UnclassifedCount") {
      return {
        Header: "# Unclassified",
        accessor: "document." + key,
      };
    }
    if (key === "CashAllocationPercent") {
      return {
        Header: "Cash Allocation",
        accessor: "document." + key,
      };
    }
    if (key === "OAISMSCApprovalStatus") {
      return {
        Header: "Is MSC Product",
        accessor: "document." + key,
      };
    }
    if (key !== "undefined") {
      if (key === "ISIN") {
        return {
          Header: key,
          accessor: "document." + key,
          Cell: (e) => (
            <Link
              className=" items-center text-primary text-sm"
              to="/issuerdetails"
              state={{ data: e.value }}
            >
              {e.value}
            </Link>
          ),
        };
      } else {
        return {
          Header: key,
          accessor: "document." + key,
        };
      }
    }
  });
  columns.unshift({
    Header: "Action",
    Cell: ({ row }) => (
      <>
        <Link
          className=" items-center text-primary text-sm"
          onClick={() => handleAddToWatchList(row)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </Link>
        <Link
          className=" items-center text-primary text-sm"
          onClick={() => handleUpdateMetaData(row)}
        >
          <svg
            class="w-6 h-6 "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
            ></path>
          </svg>
        </Link>
      </>
    ),
  });
  const toggleShowState = (displayName) => {
    setShowState((prevState) => ({
      ...prevState,
      [displayName]: !prevState[displayName],
    }));
  };
  const handleCheckChange = (e, facetDisplayName) => {
    const value = e.target.id;
    const name = e.target.value;
    setFilterApplyBtn(false);
    localStorage.setItem("ProductApplyButton", false);
    // Remove name from checkedValueBadges if unchecked
    if (!e.target.checked) {
      setCheckedValueBadges((prevBadges) =>
        prevBadges.filter((badge) => badge !== name)
      );
    } else if (!checkedValueBadges.includes(name)) {
      setCheckedValueBadges((prevBadges) => [...prevBadges, name]);
    }

    setCheckedValues((prevValues) => {
      const facetIndex = prevValues?.findIndex(
        (facet) => facet?.facetName === facetDisplayName
      );
      const prevFacetValues =
        facetIndex !== -1 ? prevValues[facetIndex].values : [];
      if (prevFacetValues.includes(value)) {
        // Uncheck
        const updatedValues = prevFacetValues.filter((v) => v !== value);
        if (updatedValues?.length === 0) {
          return prevValues.filter(
            (facet) => facet?.facetName !== facetDisplayName
          );
        } else {
          return prevValues.map((facet, i) =>
            i === facetIndex ? { ...facet, values: updatedValues } : facet
          );
        }
      } else {
        // Check
        const updatedValues = [...prevFacetValues, value];
        const updatedFacet = {
          facetName: facetDisplayName,
          values: updatedValues,
        };
        if (facetIndex !== -1) {
          return prevValues.map((facet, i) =>
            i === facetIndex ? updatedFacet : facet
          );
        } else {
          return [...prevValues, updatedFacet];
        }
      }
    });
  };
  const handleEditView = (e) => {
    e.preventDefault();
    setEditView(true);
  };
  const sortAPI = () => {
    setFinalCheckedBadgeValues(checkedValueBadges);
    const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
    localStorage.setItem(
      PRODUCTLIST.PRODUCTBADGEVALUES,
      JSON.stringify(checkedValueBadges)
    );
    const myArrayString = JSON.stringify(checkedValues);
    localStorage.setItem(PRODUCTLIST.PRODUCTFILTER, myArrayString);
    localStorage.setItem(PRODUCTLIST.PRODUCTSEARCHTEXT, searchText);
    productList({
      ...checkBoxFilter,
      searchText: searchTextTyped,
      fieldNamesToReturn: tempSelectedFields,
      currentPage: count,
      facetFilters: checkedValues,
      pageSize: pageNumber,
      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
    });
  };
  const handleApplyClick = async () => {
    // setFilterApplyBtn(true)
    localStorage.setItem("ProductApplyButton", true);
    setFinalCheckedBadgeValues(checkedValueBadges);
    localStorage.setItem(
      PRODUCTLIST.PRODUCTBADGEVALUES,
      JSON.stringify(checkedValueBadges)
    );
    // localStorage.setItem(ISSUER.ISSUERSEARCHTEXT, searchText);
    const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
    checkedValues?.length === 0 && setShowState(false);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const myArrayString = JSON.stringify(checkedValues);
    localStorage.setItem(PRODUCTLIST.PRODUCTFILTER, myArrayString);
    productList({
      ...checkBoxFilter,
      searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
      fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
      currentPage: 1,
      pageSize: pageNumber,
      facetFilters: checkedValues,
      sortOrderfilters: isNull(sortOrder)
        ? savedSearchData?.sortOrderfilters
        : sortFilter,
    });
  };
  const handleResetClick = async () => {
    localStorage.removeItem(PRODUCTLIST.PRODUCTFILTER);
    localStorage.removeItem(PRODUCTLIST.PRODUCTSEARCHTEXT);
    localStorage.removeItem(PRODUCTLIST.PRODUCTBADGEVALUES);
    const sortFilter = getKeyValuePair(sortColumnName, sortOrder);

    setCheckedValues([]);
    setFinalCheckedBadgeValues([]);
    setCheckedValueBadges([]);
    setSearchTextTyped("");
    setSearchText("");
    setShowState(false);
    productList({
      searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
      currentPage: count,
      pageSize: pageNumber,
      useDefaultFacets: true,
      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
      fieldNamesToReturn: tempSelectedFields,
      fieldNamesToSearch: [],
      facetOptions: null,
    });
  };
  const defaultReset = () => {
    localStorage.removeItem(PRODUCTLIST.PRODUCTEDITVIEWCHECKED);
    localStorage.removeItem(PRODUCTLIST.PRODUCTCOLUMNFILTER);
    localStorage.removeItem(PRODUCTLIST.PRODUCTEXISTINGVIEW);
    localStorage.removeItem(PRODUCTLIST.PRODUCTEDITVIEWID);
    setExisitingViewColumn([]);
    const sortFilter =
      Object.keys(savedSearchData).length > 0
        ? getKeyValuePair(sortColumnName, sortOrder)
        : getKeyValuePair(sortColumnName, sortOrder);

    productList({
      ...checkBoxFilter,
      searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
      fieldNamesToReturn: [],
      currentPage: count,
      facetFilters: checkedValues,
      pageSize: pageNumber,
      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
    });
    EditViewDefaultCheckAll();
  };
  const handleBadgeRemove = (values) => {
    setSearchTextTyped("");
    setSearchText("");
    localStorage.removeItem(PRODUCTLIST.PRODUCTSEARCHTEXT);
    // checkedValueBadges?.length === 0 && setFinalCheckedBadgeValues([]);

    const sortFilter = getKeyValuePair(sortColumnName, sortOrder);

    productList({
      ...checkBoxFilter,
      searchText: searchText,
      fieldNamesToReturn: tempSelectedFields,
      currentPage: count,
      facetFilters: checkedValues,
      pageSize: pageNumber,
      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
    });
    setCheckedValues((prevValues) =>
      prevValues.map((facet) =>
        facet.values.includes(values)
          ? {
              ...facet,
              values: facet.values.filter((v) => v !== values),
            }
          : facet
      )
    );
  };
  const handleCheckedBadgeRemove = (index, value) => {
    setCheckedState(checkedState + 1);
    setFinalCheckedBadgeValues(checkedValueBadges);
    setFinalCheckedBadgeValues((prevBadges) => {
      return prevBadges.filter((badge) => badge !== value);
    });
    setCheckedValueBadges((prevBadges) => {
      return prevBadges.filter((badge) => badge !== value);
    });

    setCheckedValues((prevValues) =>
      prevValues
        .map((facet) => {
          const values = facet.values.filter((v) => v !== value);
          return { ...facet, values };
        })
        .filter((facet) => facet.values.length > 0)
    );
    setShowState(false);
    const updatedProductBadgeValues = finalCheckedBadgeValues.filter(
      (badge) => badge !== value
    );
    const updatedProductFilter = checkedValues
      .map((facet) => {
        const values = facet.values.filter((v) => v !== value);
        return { ...facet, values };
      })
      .filter((facet) => facet.values.length > 0);

    // Update local storage with the updated values
    localStorage.setItem(
      PRODUCTLIST.PRODUCTBADGEVALUES,
      JSON.stringify(updatedProductBadgeValues)
    );
    localStorage.setItem(
      PRODUCTLIST.PRODUCTFILTER,
      JSON.stringify(updatedProductFilter)
    );
  };
  const handleAddToWatchList = (row) => {
    setIsin(row.values["document.ISIN"]);
    setProductid(row.values["document.ProductId"]);
    setAddToWatchList(true);
  };
  const handleUpdateMetaData = (row) => {
    setProductid(row.values["document.ProductId"]);
    setOAISMSCApprovalStatus(row.values["document.OAISMSCApprovalStatus"]);
    setShowUpdateMeta(true);
  };
  const handleUpdateMetaDataAPICall = () => {
    updateProductMetaDataApi({
      bloombergId: Productid,
      isOAISProduct: isOAIS,
      refreshInterval: shareDropDownValue.length ? shareDropDownValue : null,
    });
  };
  const handleSaveResultModal = (event) => {
    setSaveModal(true);
    SetSavedResultValue("");
    event.preventDefault();
  };
  useEffect(() => {
    setIsOAIS(true);
  }, [showUpdateMeta]);
  useEffect(() => {
    localStorage.setItem(ISSUER.PRODUCSAVEDID, SavedsearchId);
  }, [SavedsearchId]);
  useEffect(() => {
    setFinalCheckedBadgeValues(JSON.parse(getCheckedBadges));
    setSearchTextTyped(getSearchValue);
    EditViewData.refetch();
    productList(checkBoxFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tempSelectedFields.length === staticViewFields.length) {
      if (editViewMenu === "") {
        setEditViewMenu("Default");
      }
    } else if (
      editViewMenu === "Default" &&
      tempSelectedFields.length != staticViewFields.length
    ) {
      setEditViewMenu("");
    }
  }, [tempSelectedFields]);
  useEffect(() => {
    if (
      getIssuerSearchData &&
      getIssuerSearchData?.isSuccess &&
      has(getIssuerSearchData, ["data", "searchQueryJson"])
    ) {
      const checkboxQueryJson = JSON.parse(
        getIssuerSearchData?.data?.searchQueryJson
      );
      setEditViewMenuId(getIssuerSearchData?.data?.savedSearchViewId);
      setSavedSearchData(checkboxQueryJson);
      const savedSearchText = checkboxQueryJson.searchText;
      setSearchTextTyped(savedSearchText);
      const checkedItems = {};
      for (const field of staticViewFields) {
        checkedItems[field] =
          checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
      }
      localStorage.setItem(
        PRODUCTLIST.PRODUCTSAVEDRETURNCOLUMN,
        JSON.stringify(checkboxQueryJson?.fieldNamesToReturn)
      );
      setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
      setCheckedItems(checkedItems);

      const checkboxText = Array.isArray(checkboxQueryJson?.facetFilters)
        ? checkboxQueryJson?.facetFilters
            .map((item) => {
              return item.values;
            })
            .flat()
        : [];
      setFinalCheckedBadgeValues(checkboxText);
      setCheckedValueBadges(checkboxText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIssuerSearchData]);
  useEffect(() => {
    if (
      EditViewData &&
      EditViewData?.isSuccess &&
      has(EditViewData, ["data", "fieldNameJson"]) &&
      Object.keys(EditViewData?.data?.fieldNameJson).length > 0
    ) {
      const checkboxQueryJson = JSON.parse(EditViewData?.data?.fieldNameJson);
      setExisitingViewColumn(checkboxQueryJson?.fieldNamesToReturn);
      const checkedItems = {};
      for (const field of staticViewFields) {
        checkedItems[field] =
          checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
      }
      setCheckedItems(checkedItems);
      setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);

      const getKeyValuePair = (_key, _value) => {
        return { [_key]: _value };
      };
      const sortFilter = getKeyValuePair(
        sortOrder?.sortColumnName,
        sortOrder?.sortOrder
      );
      if (isNull(retArray)) {
        productList({
          ...checkBoxFilter,
          searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
          fieldNamesToReturn: isNull(retArray)
            ? checkboxQueryJson?.fieldNamesToReturn
            : retArray,
          currentPage: count,
          facetFilters: isNull(localStorage.getItem(PRODUCTLIST.PRODUCTFILTER))
            ? []
            : checkedValues,
          pageSize: pageNumber,
          sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
        if (isNull(localStorage.getItem(PRODUCTLIST.PRODUCTFILTER))) {
          setCheckedValues([]);
        }
      }
      setEditViewMenu(EditViewData?.data?.name);
      localStorage.setItem(
        PRODUCTLIST.PRODUCTEXISTINGVIEW,
        EditViewData?.data?.name
      );
    }
  }, [EditViewData]);
  useEffect(() => {
    if (
      getEditViewSave &&
      getEditViewSave?.isSuccess &&
      has(getEditViewSave, ["data"])
    ) {
      const ID = getEditViewSave?.data.find((obj) => obj.name === editViewMenu);
      localStorage.setItem(PRODUCTLIST.PRODUCTEDITVIEWID, ID?.id);
      const editView = getEditViewSave.data.find(
        (item) => item.name === editViewMenu
      );
      if (editView) {
        const checkboxQueryJson = JSON.parse(editView.fieldNameJson);
        setSavedSearchData(checkboxQueryJson);
        const checkedItems = {};
        for (const field of staticViewFields) {
          checkedItems[field] =
            checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
        }
        setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
        setCheckedItems(checkedItems);
      }
    }
  }, [getEditViewSave]);
  useEffect(() => {
    setEditViewInput("");
  }, [editView]);
  useEffect(() => {
    if (searchText.length === 0 && getIssuerSearchData && searchInput) {
      setFinalCheckedBadgeValues(checkedValueBadges);
      const sortFilter =
        Object.keys(savedSearchData).length > 0
          ? getKeyValuePair(sortColumnName, sortOrder)
          : getKeyValuePair(sortColumnName, sortOrder);
      productList({
        ...checkBoxFilter,
        searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
        fieldNamesToReturn: tempSelectedFields,
        currentPage: count,
        facetFilters: checkedValues,
        pageSize: pageNumber,
        sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
      });
    }
    if (searchText.length === 0 && searchInput) {
      // setFinalCheckedBadgeValues(checkedValueBadges);
      const sortFilter = getKeyValuePair(sortColumnName, sortOrder);

      productList({
        searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
        currentPage: count,
        pageSize: pageNumber,
        useDefaultFacets: true,
        sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        fieldNamesToReturn: tempSelectedFields,
        fieldNamesToSearch: [],
        facetOptions: null,
        facetFilters: checkedValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  useEffect(() => {
    if (
      has(IssuerReturnName, ["data"]) &&
      isArray(IssuerReturnName?.data) &&
      !isEmpty(IssuerReturnName?.data)
    ) {
      const data = [...IssuerReturnName.data];
      if (data && data.includes("ProductId")) {
        const isinIndex = data.indexOf("ProductId");
        if (isinIndex > -1 && isinIndex !== 2) {
          const name = data.splice(1, 1)[0];
          data.splice(2, 0, name);
          data.splice(isinIndex, 1);
          data.splice(2, 0, "ProductId");
          const oaiIndex = data.indexOf("OAISMSCApprovalStatus");
          data.splice(oaiIndex, 1);
          data.splice(3, 0, "OAISMSCApprovalStatus");
          setStaticViewFields(data);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchData, productListData]);
  useEffect(() => {
    if (getIssuerSearchData?.isSuccess) {
      getIssuerSearchData?.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SavedsearchId]);
  React.useMemo(() => {
    setCount(productListData?.originalArgs?.currentPage);
    if (productListData?.isSuccess) {
      setData(productListData?.data?.results);
      const fields =
        has(productListData, ["data", "results"]) &&
        isArray(productListData?.data?.results) &&
        !isEmpty(productListData?.data?.results)
          ? Object.keys(productListData?.data?.results[0]?.document)
          : [];
      const data = [...fields];
      if (data && data.includes("ProductId")) {
        const isinIndex = data.indexOf("ProductId");
        if (isinIndex > -1 && isinIndex !== 2) {
          const name = data.splice(1, 1)[0];
          data.splice(1, 0, name);
          data.splice(isinIndex, 1);
          data.splice(0, 0, "ProductId");
          setStaticViewFields(data);
          setViewFields([...data]);
        }
        if (data.includes("Name")) {
          const nameIndex = data.indexOf("Name");
          const name = data.splice(nameIndex, 1)[0];
          data.splice(1, 0, name);
        }
      }
      if (data && data.includes("EliminateStatus")) {
        const EliminateStatusIndex = data.indexOf("EliminateStatus");
        const item = data[EliminateStatusIndex];
        const newData = [
          item,
          ...data.slice(0, EliminateStatusIndex),
          ...data.slice(EliminateStatusIndex + 1),
        ];
        setViewFields([...newData]);
      }

      if (count >= 1) {
        if (isEmpty(fields)) {
          setViewFields(productListData?.originalArgs?.fieldNamesToReturn);
        } else {
          const isinIndex = fields.indexOf("ProductId");
          fields.splice(isinIndex, 1);
          fields.splice(2, 0, "ProductId");
          setSelectedFields(fields);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productListData, savedSearchData]);
  useEffect(() => {
    if (Object.keys(savedSearchData).length > 0) {
      if (Array.isArray(savedSearchData?.facetFilters)) {
        setCheckBoxFilter({ ...savedSearchData });
        setCheckedValues([...savedSearchData?.facetFilters]);
        setPageNumber(savedSearchData?.pageSize);
      }
      let sortOrderfilterskeys = savedSearchData?.sortOrderfilters;
      if (
        sortOrderfilterskeys !== null &&
        typeof sortOrderfilterskeys === "object"
      ) {
        setSortOrder(checkSort);
      }
      productList({
        ...savedSearchData,
        facetFilters:
          checkedValues?.length === 0
            ? savedSearchData?.facetFilters
            : filterApplyBtn
            ? checkedValues
            : [],
        fieldNamesToReturn: isNull(retArray)
          ? savedSearchData?.fieldNamesToReturn
          : retArray,
      });
      const checkValues = localStorage.getItem(ISSUER.ISSUERFILTER);
      const getReturnColumnLocal = localStorage.getItem(
        ISSUER.SAVEDRETURNCOLUMN
      );
      setCheckedValues(
        isNull(checkValues)
          ? savedSearchData?.facetFilters
          : JSON.parse(checkValues)
      );
      setTempSelectedFields(
        isNull(getReturnColumnLocal)
          ? savedSearchData?.fieldNamesToReturn
          : JSON.parse(getReturnColumnLocal)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchData]);
  useEffect(() => {
    //on page load ISIN
    const sortFilter = getKeyValuePair(sortColumnName, sortOrder);

    if (productListData?.isSuccess) {
      if (checkedValues.length === 0) {
        setFinalCheckedBadgeValues(checkedValueBadges);
        localStorage.setItem(
          PRODUCTLIST.PRODUCTBADGEVALUES,
          JSON.stringify(checkedValueBadges)
        );
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(PRODUCTLIST.PRODUCTFILTER, myArrayString);
        localStorage.setItem(PRODUCTLIST.PRODUCTSEARCHTEXT, searchText);
        productList({
          ...checkBoxFilter,
          searchText: searchTextTyped,
          fieldNamesToReturn: tempSelectedFields,
          currentPage: count,
          pageSize: pageNumber,
          facetFilters: checkedValues,
          sortOrderfilters: isNull(sortOrder)
            ? savedSearchData?.sortOrderfilters
            : sortFilter,
        });
      } else {
        setFinalCheckedBadgeValues(checkedValueBadges);
        localStorage.setItem(
          PRODUCTLIST.PRODUCTBADGEVALUES,
          JSON.stringify(checkedValueBadges)
        );
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(PRODUCTLIST.PRODUCTFILTER, myArrayString);
        localStorage.setItem(PRODUCTLIST.PRODUCTSEARCHTEXT, searchText);
        productList({
          ...checkBoxFilter,
          searchText: searchTextTyped,
          fieldNamesToReturn: tempSelectedFields,
          currentPage: count,
          facetFilters: checkedValues,
          pageSize: pageNumber,
          sortOrderfilters: isNull(sortOrder)
            ? savedSearchData?.sortOrderfilters
            : sortFilter,
        });
      }
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, pageNumber]);
  useEffect(() => {
    setSelectedFields(selectedFields);
    let retString = localStorage.getItem(PRODUCTLIST.PRODUCTCOLUMNFILTER);
    let retArray = JSON.parse(retString);
    const fields =
      has(productList, ["data", "results"]) &&
      isArray(productList?.data?.results) &&
      !isEmpty(productList?.data?.results)
        ? Object.keys(productList?.data?.results[0]?.document)
        : [];
    if (
      savedSearchData &&
      Object.keys(savedSearchData).length > 0 &&
      productList &&
      productList.isSuccess &&
      productList.data &&
      productList.data.count === 0
    ) {
      setTempSelectedFields(
        retArray === null
          ? has(productList, ["data", "results"]) &&
            isArray(productList?.data?.results) &&
            !isEmpty(productList?.data?.results)
            ? Object.keys(productList?.data?.results[0]?.document)
            : IssuerReturnName?.data
          : retArray
      );
    } else if (productList?.isSuccess && productList?.data?.count === 0) {
      setTempSelectedFields(productList?.originalArgs?.fieldNamesToReturn);
    } else {
      setTempSelectedFields(retArray === null ? ViewFields : retArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields, ViewFields]);
  useEffect(() => {
    EditViewDefaultCheckAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IssuerReturnName]);
  useEffect(() => {
    if (checkedState) {
      const sortFilter = getKeyValuePair(sortColumnName, sortOrder);

      productList({
        searchText: searchTextTyped?.length > 0 ? searchTextTyped : "*",
        currentPage: count,
        pageSize: pageNumber,
        useDefaultFacets: true,
        sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        fieldNamesToReturn: tempSelectedFields,
        fieldNamesToSearch: [],
        facetOptions: null,
        facetFilters: checkedValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedState]);
  useEffect(() => {
    if (!isNull(localStorage.getItem(PRODUCTLIST.PRODUCTSORTINGNAME))) {
      sortAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder]);
  useEffect(() => {
    if (
      productListData?.isSuccess &&
      has(productListData, ["data", "facets"]) &&
      isArray(productListData?.data?.facets) &&
      !isEmpty(productListData?.data?.facets)
    ) {
      const tempArr = sortBy(productListData?.data?.facets, ["displayName"]);
      setUpdatedFacets(tempArr);
    }

    if (
      productList?.isSuccess &&
      has(productList, ["data", "facets"]) &&
      isArray(productList?.data?.facets) &&
      !isEmpty(productList?.data?.facets) &&
      searchTextTyped?.length === 0 &&
      checkedValueBadges?.length > 0
    ) {
      setFinalCheckedBadgeValues(checkedValueBadges);
    }
  }, [productListData]);
  const sortedExistingViewColumn = sortBy(exisitngViewColumn);
  const sortedCheckedFields = sortBy(checkedFields);
  const arraysMatch = isEqual(sortedExistingViewColumn, sortedCheckedFields);
  return (
    <React.Fragment>
      <div className="flex-grow w-full overflow-hidden flex h-full">
        <div
          className={`transition flex flex-col bg-gray-50 w-10/12 md:min-w-[322px] md:max-w-[322px] fixed sm:relative z-10 top-0 h-full ${
            filterOpen ? "left-0" : "-left-96 sm:left-0"
          }`}
        >
          <div className="flex justify-between p-4">
            <p className="font-medium">{PAGECONSTANTS?.FILTER}</p>
            <button
              className="block sm:hidden"
              onClick={() => {
                setFilterOpen(false);
              }}
            >
              Close
            </button>
          </div>
          <div className="flex px-4 pb-4 border-b">
            <div
              className="w-full relative rounded-md flex shadow-sm"
              style={{ backgroundColor: "rgb(243,244,246)" }}
            >
              <input
                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                type="text"
                aria-label="Filter projects"
                placeholder={
                  searchTextTyped?.length > 0 ? "" : "Keyword Search..."
                }
                maxLength={20}
                onKeyDown={handleKeyPress}
                onChange={OnChangeHandler}
                onClick={() => setSearchInput(true)}
                value={searchTextTyped === "*" ? "" : searchTextTyped}
                style={{ backgroundColor: "rgb(243,244,246)" }}
              />
              {searchTextTyped?.length > 0 && searchTextTyped !== "*" && (
                <XCircleIcon
                  className="w-[18px]  ml-1 text-red-500 cursor-pointer absolute right-7 top-2"
                  onClick={() => {
                    handleBadgeRemove();
                  }}
                />
              )}

              <MagnifyingGlassIcon
                className="w-4 text-dark absolute right-2 top-2 cursor-pointer"
                value={"Click"}
                onClick={handleSearchIconClick}
              />
            </div>
          </div>
          <div className="flex-1 overflow-auto px-4">
            <div className="flex flex-wrap">
              {finalCheckedBadgeValues?.map((item, index) => {
                return (
                  <div className="flex rounded w-fit p-2">
                    <Badge value={item} variant={"GrayBlue"} />
                    <XCircleIcon
                      className="w-[18px] ml-1 text-red-500 cursor-pointer"
                      onClick={() => handleCheckedBadgeRemove(index, item)}
                    />
                  </div>
                );
              })}
            </div>

            {productListData?.isSuccess &&
            has(productListData, ["data", "facets"]) &&
            isArray(productListData?.data?.facets) &&
            !isEmpty(productListData?.data?.facets) ? (
              updatedFacets.map((facet) => {
                const sortedFacets = (facet?.facetItems || [])
                  .map((item) => ({ value: item.value, count: item.count }))
                  .sort((a, b) => {
                    if (facet.displayName === "MarketCapRange") {
                      return (
                        get_numeric_value_sorting(b) -
                        get_numeric_value_sorting(a)
                      );
                    }
                    return a.value.localeCompare(b.value);
                  });
                const facetDisplayName = facet.displayName;
                const isShown = showState[facetDisplayName] ?? false;
                return (
                  <>
                    <div>
                      <div
                        className="flex items-center gap-1 py-2 whitespace-nowrap font-bold cursor-pointer"
                        onClick={() => toggleShowState(facetDisplayName)}
                      >
                        <ChevronRightIcon className={"w-4"} />{" "}
                        {facetDisplayName === "OAISMSCApprovalStatus"
                          ? "Is MSC Product"
                          : facetDisplayName.replace(/(?=[A-Z][a-z])/g, " ")}
                      </div>
                      {isShown && (
                        <div className="pl-5 flex flex-col gap-2">
                          {sortedFacets.map((item) => {
                            const value = item.value;
                            const checked = checkedValues?.some((facet) =>
                              facet?.values?.includes(value)
                            );
                            return (
                              <>
                                <div
                                  className="flex gap-2 items-center"
                                  key={item.id}
                                >
                                  <Checkbox
                                    label={value + " (" + item.count + ")"}
                                    className={"rounded"}
                                    id={value}
                                    name={value}
                                    Checked={checked}
                                    handleChange={(e) =>
                                      handleCheckChange(e, facetDisplayName)
                                    }
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </>
                );
              })
            ) : (
              <></>
            )}
            {productListData?.isLoading && (
              <div class="flex flex-col space-y-4">
                <div class="animate-pulse flex space-x-4">
                  <div class="flex-1 space-y-4 py-1">
                    {range(7).map((range, i) => (
                      <div class="h-4 bg-gray-300 rounded w-5/6"></div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex gap-4 p-4 h-15 pb-0">
            <Button
              className="w-full"
              variant="primary-outline"
              value="Reset"
              onClick={() => handleResetClick()}
            />
            <Button
              className="w-full"
              variant="primary"
              value="Apply"
              onClick={() => handleApplyClick()}
            />
          </div>
          <div className="flex p-4 pt-3">
            <Button
              className="w-full"
              variant="primary"
              value="Save Search"
              onClick={(e) => handleSaveResultModal(e)}
            />
          </div>
        </div>
        <div className="h-full w-full overflow-auto flex flex-col">
          <div className="flex justify-between border-b items-center py-4 md:p-4">
            <h1 className="hidden lg:block font-medium whitespace-nowrap">
              Product Search ({productListData?.data?.count})
            </h1>
            {productListData?.isLoading ||
            productListData?.data?.results?.length === 0 ||
            productListData?.isError ? (
              ""
            ) : (
              <div className="text-white gap-4 flex flex-col md:flex-row w-full px-4 justify-end">
                <div className="flex justify-between">
                  <Menu as="div" className="relative text-left">
                    <div className="flex">
                      <p className="text-gray-900 whitespace-nowrap block sm:hidden lg:block">
                        {ISSUERLIST?.EXISTINGVIEW}
                      </p>
                      <Menu.Button className="flex justify-center rounded-md bg-white px-3 font-semibold text-gray-900 hover:bg-gray-50 items-center">
                        {exisitngViewColumn.length === 0
                          ? editViewMenu
                          : arraysMatch
                          ? editViewMenu
                          : ""}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute border shadow  z-10 mt-2 w-56 origin-top-right rounded-md bg-white 	 focus:outline-none  static-scroll">
                        <div className="py-1 max-h-[10rem] overflow-auto">
                          <Menu.Item>
                            {({ active }) => (
                              <span
                                className={
                                  (active
                                    ? "bg-gray-100 text-gray-700"
                                    : "  text-black",
                                  "block px-4 py-2 text-sm")
                                }
                                onClick={() => {
                                  defaultReset();
                                  setEditViewMenu(ISSUERLIST?.DEFAULT);
                                  setEditViewMenuId("");
                                }}
                                style={{ color: "black", cursor: "pointer" }}
                              >
                                {ISSUERLIST?.DEFAULT}
                              </span>
                            )}
                          </Menu.Item>
                          {getEditViewSave?.isSuccess &&
                            has(getEditViewSave, ["data"]) &&
                            isArray(getEditViewSave?.data) &&
                            !isEmpty(getEditViewSave?.data) &&
                            getEditViewSave?.data?.map((item) => {
                              return (
                                <Menu.Item>
                                  {({ active }) => (
                                    <span
                                      className={
                                        (active
                                          ? "bg-gray-100 text-gray-700"
                                          : "  text-black",
                                        "block px-4 py-2 text-sm hover:bg-gray-100 rounded")
                                      }
                                      onClick={() => {
                                        setEditViewMenu(item.name);
                                        setEditViewMenuId(item.id);
                                        localStorage.setItem(
                                          PRODUCTLIST.PRODUCTEDITVIEWID,
                                          item?.id
                                        );
                                        localStorage.removeItem(
                                          PRODUCTLIST.PRODUCTEDITVIEWCHECKED
                                        );
                                        localStorage.removeItem(
                                          PRODUCTLIST.PRODUCTCOLUMNFILTER
                                        );
                                      }}
                                      style={{
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {item.name}
                                    </span>
                                  )}
                                </Menu.Item>
                              );
                            })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <button
                    className="text-dark flex gap-2 items-center sm:hidden"
                    onClick={() => {
                      setFilterOpen(true);
                    }}
                  >
                    <i className="far fa-filter"></i>
                    {PAGECONSTANTS?.FILTER}
                  </button>
                </div>
                <div className="flex gap-3">
                  <Link
                    className="items-center text-primary whitespace-nowrap"
                    onClick={handleEditView}
                  >
                    {ISSUERLIST?.EDITVIEW}
                  </Link>
                  <div className="text-gray-200 h-1">|</div>
                  <Link
                    Link
                    className="items-center text-primary whitespace-nowrap flex justify-center"
                  >
                    <span
                      className="text-primary"
                      onClick={() => {
                        exportapicall();
                        showToastify("success", "Export initiated");
                      }}
                    >
                      {"Export"}
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="flex-grow overflow-hidden md:pr-4 static-scroll">
            {productListData?.isSuccess &&
              !isEmpty(productListData?.data?.results) && (
                <div className="h-full flex-col flex">
                  <TailwindTable
                    columns={columns}
                    data={data}
                    setCount={setCount}
                    count={count || 1}
                    totalLength={productListData?.data?.count}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pageArray={pageArray}
                    sortColumnName={sortColumnName}
                    setSortColumnName={setSortColumnName}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    path="/productDetails"
                    returnPath="/Product/search"
                    keyValue="product"
                    sortingName={PRODUCTLIST.PRODUCTSORTING}
                    sortingHeadername={PRODUCTLIST.PRODUCTSORTINGNAME}
                  />
                </div>
              )}
            {productListData?.isLoading && (
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead
                          className="bg-gray-50"
                          style={{ position: "sticky", top: 0, zIndex: 1 }}
                        >
                          <tr>
                            {loadingTableheader?.ProductList?.map((item) => {
                              return (
                                <th
                                  scope="col"
                                  className="th group px-4 py-2 text-left text-xs font-bold text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                >
                                  {item}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {range(10).map((range, i) => (
                            <tr className="animate-pulse" key={i}>
                              {loadingTableheader?.ProductList?.map((item) => {
                                return (
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {productListData?.isError ||
              (isEmpty(productListData?.data?.results) &&
                !productListData?.isLoading && (
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <p className="text-center">
                            {PAGECONSTANTS?.NODATAFOUND}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <UserContext.Provider
        value={{
          saveModal,
          setSaveModal,
          savedResultValue,
          SetSavedResultValue,
          savedsearcheckbox,
          setsavedsearcheckbox,
          setSavedSearchdata,
          savedSearchdata,
          isDropdownVisible,
          setIsDropdownVisible,
          shareDropDownValue,
          setShareDropDownValue,
          setIsSaved,
          count,
          checkBoxFilter,
          pageNumber,
          searchTextTyped,
          addToWatchList,
          Productid,
          setAddToWatchList,
          savedSearchData,
          checkedValues,
          sortColumnName,
          sortOrder,
          tempSelectedFields,
          editViewMenuId,
          editViewReturnValue,
          setEditViewReturnValue,
          setEditViewMenu,
          productList,
          staticViewFields,
          editViewInput,
          setEditViewInput,
          checkedItems,
          setCheckedItems,
          OAISMSCApprovalStatus,
          checkedFields,
          setCheckedFields,
          checkedValueBadges,
          selectedFields,
          setSelectedFields,
          ViewFields,
          WLName,
          checked,
          setChecked,
          setWLName,
          WLId,
          setWLId,
          setViewFields,
          editView,
          setEditView,
          setFinalCheckedBadgeValues,
          showUpdateMeta,
          setProductid,
        }}
      >
        <Modal
          showmodal={saveModal}
          setshowmodal={setSaveModal}
          title="Save Search"
          size={"w-[400px] "}
          body={<Saveresultbody ModuleName={"Products"} />}
          footer={
            <ModalFooter ModuleName="Products" postApiCall={productList} />
          }
          isFooterEnable={true}
        />
        <Modal
          setshowmodal={setAddToWatchList}
          showmodal={addToWatchList}
          title="Add to Watch List"
          body={<AddToWatchListView ModuleName="Products" />}
          size={"w-[400px]"}
          footer={<AddToWatchListFooter ModuleName="Products" />}
        />
        <Modal
          setshowmodal={setEditView}
          showmodal={editView}
          title="Edit View"
          size={"w-[485px]"}
          body={
            <EditView
              postApiCall={productList}
              issuerColumnFilter={PRODUCTLIST.PRODUCTCOLUMNFILTER}
              issuerEditViewCheck={PRODUCTLIST.PRODUCTEDITVIEWCHECKED}
            />
          }
          footer={
            <EditViewFooter postApiCall={productList} moduleName="products" />
          }
          isFooterEnable={true}
        />
        <Modal
          body={<UpdateMetaDataModal setValue={setIsOAIS} />}
          showmodal={showUpdateMeta}
          setshowmodal={setShowUpdateMeta}
          footer={
            <UpdateMetaDataFooter
              setValue={setShowUpdateMeta}
              buttonValue={"Save"}
              onClick={() => handleUpdateMetaDataAPICall()}
            />
          }
          title={"Update Product Meta Data"}
          size={"w-[400px]"}
        />
      </UserContext.Provider>
    </React.Fragment>
  );
};
export default ProductList;
