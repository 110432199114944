import React, { useContext, useEffect } from "react";
import Button from "../../components/Button/Button";
import UserContext from "../../context/modal/Contextmodel";
import { usePostIssuerNotesMutation } from "../../Services/IssuerDetails/CreateIssuerNotesApi";
const AddFooter = ({ value1, value2, isin }) => {
	const { setAddmodal, setPrint, setNote, note, setSucess, setApicall } = useContext(UserContext);
	const [postIssuerNotes] = usePostIssuerNotesMutation();
	const body = {
		description: note,
		isin: isin,
	};
	const closeModal = () => {
		setAddmodal(false);
		setPrint(false);
		setNote("");
	};

	const SaveModal = async () => {
		setAddmodal(false);
		setPrint(false);
		if (note?.length >= 1) {
			postIssuerNotes(body);
			setSucess(true);
			setApicall(true)
		}
		setNote("");
		setSucess(false);

	};

	return (
		<>
			<div className='grid grid-rows-1 grid-flow-col gap-4 '>
				<Button value={value1} variant='secondary' onClick={closeModal} />
				<Button value={value2} variant='primary' onClick={SaveModal} className={note?.length >= 1 ? "" : "opacity-50 cursor-not-allowed"} disabled={note?.length < 1} />
			</div>
		</>
	);
};

export default AddFooter;
