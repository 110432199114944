import { APP_VARIABLES } from "../constants/appConstants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { msalInstance } from "../index";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_DEV_APIBASEURI}`,
    credentials: "same-origin",
    prepareHeaders: (headers, { getState, endpoint }) => {
        headers.set("Access-Control-Allow-Headers", "*");
        headers.set("Access-Control-Allow-Origin", "*");
        headers.set("user-tz", new Date().getTimezoneOffset().toString());
        headers.set("Access-Control-Allow-Methods", "POST, GET, PUT, OPTIONS, DELETE");
        headers.set("Authorization", `Bearer ${localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)}`);
        headers.set("Cache-Control", "no-cache, no-store, must-revalidate");
        if (endpoint === "GetUserReviewDocumentDownloadLink") {
            headers.set("Content-Type", "text/plain");
        }
        return headers;
    },
});
const baseQueryWithTokenRefresh = async (args, api, extraOptions) => {
    try {
        const account = msalInstance.getAllAccounts()[0];
        const accessTokenRequest = {
            scopes: [process.env.REACT_APP_LOGIN_SCOPES],
            account: account,
            authority: process.env.REACT_APP_LOGIN_AUTHORITY,
            redirectUri: process.env.REACT_APP_AZURE_REDIRECTURI,
        };
        await msalInstance.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
            let accessToken = accessTokenResponse;
            localStorage.setItem("ACCESS_TOKEN", accessToken?.accessToken);
        });
        const result = await baseQuery(args, api, extraOptions);
        if (result.meta.response.status === 200) {
            const contentType = result.meta.response.headers.get("content-type");
            if (contentType && !contentType.includes("application/json")) {
                result.data = { data: result.error };
                const tempData = result.error;
                delete result.error;
                return { ...result, data: tempData };
            }
        }
        return result;
    } catch (error) {
        if (error.status === 400) {
            localStorage.clear();
            window.location.href = "/";
        }
        throw error;
    }
};
export default baseQueryWithTokenRefresh;
export const interceptorsApiCall = createApi({ baseQuery: baseQueryWithTokenRefresh, tagTypes: "", endpoints: () => ({}) });
