import { method } from "lodash";
import { interceptorsApiCall } from "../../globals/interceptors";
export const ProductModuleApi = interceptorsApiCall.injectEndpoints({
    endpoints: (builder) => ({
        postProductList: builder.mutation({
            query: (payload) => ({
                url: "/api/Product/AdvancedProductSearch",
                method: "POST",
                body: payload,
            }),
        }),
        getPaginatedPproductHoldingList: builder.mutation({
            query: (payload) => {
                return {
                    url: `/api/Product/GetPaginatedProductHoldingList`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getProductById: builder.query({
            query: (params) => `/api/Product/GetProduct?${params}`,
        }),
        postProductNotesListApi: builder.mutation({
            query: (payload) => {
                return {
                    url: `/api/Product/GetPaginatedProductNotes`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        createProductNotesApi: builder.mutation({
            query: (payload) => ({
                url: "/api/Product/CreateProductNotes",
                method: "POST",
                body: payload,
            }),
        }),
        shareProductNotesApi: builder.mutation({
            query: (payload) => ({
                url: "/api/Product/CreateProductNotesShare",
                method: "POST",
                body: payload,
            }),
        }),
        getProductWatchList: builder.mutation({
            query: (payload) => ({
                url: "api/Product/GetPaginatedProductWatchList",
                method: "POST",
                body: payload,
                // credentials: 'include',
            }),
        }),
        postProductWatchListShare: builder.mutation({
            query: (payload) => {
                return {
                    url: "/api/Product/CreateProductWatchListShare",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        deleteProductWatchListApi: builder.mutation({
            query: (payload) => ({
                url: "api/Product/DeleteProductWatchList",
                method: "DELETE",
                body: payload,
                //   credentials: 'include',
            }),
        }),
        updateProductMetaData: builder.mutation({
            query: (payload) => {
                return {
                    url: "/api/Product/UpdateProductMetaData",
                    method: "PUT",
                    body: payload,
                };
            },
        }),
        getOaisProductData: builder.mutation({
            query: (payload) => {
                return { url: "/api/Product/GetOAISProduct", method: "POST", body: payload };
            },
        }),
        updateProductNotes: builder.mutation({
			query: (payload) => {
				return {
					url: "/api/Product/UpdateProductNotes",
					method: "PUT",
					body: payload,
				};
			},
			
		}),
    }),
});

export const {
    usePostProductListMutation,
    useGetProductByIdQuery,
    useGetPaginatedPproductHoldingListMutation,
    usePostProductNotesListApiMutation,
    useCreateProductNotesApiMutation,
    useShareProductNotesApiMutation,
    useUpdateProductMetaDataMutation,
    useDeleteProductWatchListApiMutation,
    useGetOaisProductDataMutation,
    usePostProductWatchListShareMutation,
    useGetProductWatchListMutation,
    useUpdateProductNotesMutation,
} = ProductModuleApi;
