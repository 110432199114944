import React from "react";
import { useMsal } from "@azure/msal-react";
import {LOGIN } from "../../constants/appConstants";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const LogoutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        localStorage.clear();
        sessionStorage.clear();
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/",
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };
    return (
        <button className="text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm" onClick={() => handleLogout("popup")}>
            {LOGIN.LOGOUT}
        </button>
    );
};
