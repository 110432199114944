import React, { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Tab } from "@headlessui/react";
import { useGetManageAcessControlerApiQuery } from "../../../Services/Admin/ManageAccessControl";
import { ADMIN } from "../../../constants/appConstants";
import { disPascalize } from "../../../constants/Common";

const ManageAccessControl = () => {
    // Get api call
    const storedActiveTab = localStorage.getItem("activeTab1");
    const [activeTab, setActiveTab] = useState(storedActiveTab || "Admin");
    const [permissions, setPermissions] = useState([]);

    const handleTabClick = (key) => {
        setActiveTab(key);
        setPermissions(manageAccessControlerApi?.data?.availablePermissions[key]);
        localStorage.setItem("activeTab1", key);
    };

    const manageAccessControlerApi = useGetManageAcessControlerApiQuery();

    useEffect(() => {
        if (manageAccessControlerApi.isSuccess) {
            manageAccessControlerApi.refetch();
        }
    }, []);
    const sortedRoles = useMemo(() => {
        const roles = manageAccessControlerApi?.data?.roles || [];
        const sorted = [...roles].sort((a, b) => {
          if (a?.name === "System Administrator") return -1;
          if (b?.name === "System Administrator") return 1;
          return 0;
        });
        return sorted;
      }, [manageAccessControlerApi?.data?.roles]);
    useEffect(() => {
        setPermissions(manageAccessControlerApi?.data?.availablePermissions[Object.keys(manageAccessControlerApi?.data?.availablePermissions)[0]]);
    }, [manageAccessControlerApi]);
    useEffect(() => {
        localStorage.setItem("activeTab1", "Admin");
    }, []);
    useEffect(() => {
        if (manageAccessControlerApi.isSuccess && !storedActiveTab) {
            const availableTabs = Object.keys(manageAccessControlerApi?.data?.availablePermissions);
            if (availableTabs.length > 0) {
                setActiveTab(availableTabs[0]);
            }
        }
    }, [manageAccessControlerApi.isSuccess, manageAccessControlerApi?.data?.availablePermissions, storedActiveTab]);

    return (
        <>
            {manageAccessControlerApi.isSuccess && !isEmpty(manageAccessControlerApi?.data?.availablePermissions) && (
                <Tab.Group>
                    <div className="border-b">
                        <Tab.List className="flex">
                            {Object.keys(manageAccessControlerApi?.data?.availablePermissions).map((key) => {
                                if (key === "All") {
                                    return null;
                                }
                                return (
                                    <Tab
                                        key={key}
                                        className={`${
                                            activeTab === key ? "border-b-2  border-primary" : "border-b border-gray-200 hover:border-gray-300 text-gray-500"
                                        } bg-white inline-block py-2 px-4 font-semibold transition-colors duration-300`}
                                        onClick={() => handleTabClick(key)}
                                    >
                                        {key}
                                    </Tab>
                                );
                            })}
                        </Tab.List>
                    </div>
                    <Tab.Panels>
                        {Object.keys(manageAccessControlerApi?.data?.availablePermissions)?.map((key) => {
                            if (key === "All") {
                                return null;
                            }
                            return (
                                <Tab.Panel key={key}>
                                    <div className="flex-grow overflow-hidden  static-scroll">
                                        <div className="h-full flex-col flex">
                                            <div className="overflow-auto w-full h-full">
                                                <table className="table min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                        <tr className="tr">
                                                            <th className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer">
                                                                <div className="flex w-full h-full gap-3 items-center">
                                                                    <div
                                                                        className="flex flex-grow items-center gap-2 font-bold"
                                                                        style={{ fontSize: "13px" }}
                                                                    >
                                                                        {ADMIN.PERMISSIONS}
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            {sortedRoles?.map((value) => (
                                                                <th
                                                                    className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                    key={value?.name}
                                                                >
                                                                    <div className="flex w-full h-full gap-3 items-center">
                                                                        <div className="flex flex-grow items-center gap-2 font-bold" style={{ fontSize: "13px",justifyContent:"center" }}>{disPascalize(value?.name)}</div>
                                                                    </div>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {permissions?.map((permission, index) => (
                                                            <tr
                                                                key={permission?.displayname}
                                                                className="tr even:bg-gray-100 whitespace-nowrap"
                                                            >
                                                                <td className="td px-4 py-2 hover:max-w-none overflow-hidden">{disPascalize(permission)}</td>
                                                                {sortedRoles?.map((role, i) => (
                                                                    <td
                                                                        key={`${permission.name}-${role?.name}`}
                                                                        className="td px-4 py-2 hover:max-w-none overflow-hidden text-center"
                                                                    >
                                                                        <div className="flex justify-center">
                                                                            {" "}
                                                                            {/* Add flex and justify-center classes */}
                                                                            {role?.permissions === "All" ||role?.permissions?.includes(storedActiveTab+"_"+permission)? (
                                                                                <div>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="1.5"
                                                                                        stroke="currentColor"
                                                                                        className="w-6 h-6"
                                                                                    >
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            d="M4.5 12.75l6 6 9-13.5"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                            );
                        })}
                    </Tab.Panels>
                </Tab.Group>
            )}
        </>
    );
};
export default ManageAccessControl;
