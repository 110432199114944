import { toast } from "react-toastify";

export const PAGECONSTANTS = {
    HOME: "Home",
    ISSUER: "Issuer",
    REVIEW: "User Review",
    DEFAULT: "Default",
    NEXT: "Next",
    ISIN: "ISIN",
    NAME: "Name",
    ADMIN: "Admin",
    SYSTEM: "System Review",
    WARNING: "warning",
    SUCCESS: "success",
    ADD: "Add",
    ADD_REVIEW: "Add User Review",
    FILTER: "Filter",
    PLEASESELECTEMAIL: "please select email to share",
    DELETEDSUCCESSFULLY: "Deleted Successfully",
    FAVORITE: "Favorite",
    STATUS: "Status",
    ISSUE: "Issue",
    ABOUT: "About",
    PREVIOUS: "Prev",
    COMPLETED: "Completed",
    SEEMORE: "See More",
    NODATAFOUND: "No Data Found",
    PAGE: "Page",
};
export const loadingTableheader = {
    IssuerList: [
        "Action",
        "Status",
        "Ticker",
        "Name",
        "ISIN",
        "Stock Exchange",
        "GICS Sector",
        "GICS Industry Group",
        "GICS Industry",
        "Market Cap",
        "Eliminate Issues",
        "Last Updated",
        "Country Of Incorporation",
    ],
    IssuerWatchList: ["Action", "Status", "Name", "Symbol", "Country Of Incorporation", "Indicies", "Industry", "Sector", "Market Cap", "Oais Products"],
    ProductList: [
        "Action",
        "Status",
        "Id",
        "Name",
        "Ticker",
        "Product Type",
        "Weighted % Eliminated",
        "Total # Eliminated Stocks",
        "IdentifiedProductsPercent",
        "% of Products Screened",
        "# Holdings",
        "# Issuers",
        "# Identified Issuers",
        "# Screened Issuers",
        "# Products",
        "# Identified Products",
        "# Screened Products",
        "# Idenified Bonds",
        "# Idenified Others",
        "# Unclassifed",
        "Cash Allocation",
        "Is MSC Product",
        "Refresh Interval",
        "Last Updated",
    ],
    ProductDetailsList: ["status", "isin", "ticker", "name", "type", "category", "weight", "issue", "subIssues", "statusText"],
    UserReviewList: [
        " ",
        "AttachmentCount",
        "RequestDate",
        "RequestedBy",
        "Ticker",
        "Name",
        "ISIN",
        "OriginalStatus",
        "RequestStatus",
        "IssueName",
        "SubIssueName",
        "Note",
        "AttachmentNames",
        "AttachmentTypes",
        "ReviewedBy",
        "ReviewStatus",
        "ReviewDate",
        "AttachmentNotes",
        "LastUpdated",
    ],
    ProductDetailsnotes:[
        "Description",
        "User Name",
        "Bloomberg Id",
        "Last Modified",
        "Action"
    ]
};
export const getNormalDate = (dateString) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
};

export const getKeyValuesByLocalStorage = (pattern, getLocalStorageKeys) => {
    let localStorageKey = "";
    getLocalStorageKeys &&
        getLocalStorageKeys.length &&
        getLocalStorageKeys.forEach((values) => {
            if (values.match(pattern)) {
                if (values.match(pattern)) {
                    localStorageKey = values;
                }
            }
        });
    return localStorageKey;
};

export const userNameFromLocal = () => {
    const getLocalStorageKeys = Object.keys(localStorage);
    const getBaseAuthIdToken = getKeyValuesByLocalStorage(/idtoken/gi, getLocalStorageKeys);
    const getlocalStorageIdToken = JSON.parse(localStorage.getItem(getBaseAuthIdToken));
    const realm = getlocalStorageIdToken?.realm;
    const splitToken = getBaseAuthIdToken.split("idtoken");
    const getUserData = JSON.parse(localStorage.getItem(`${splitToken.at(0)}${realm}`));
    return getUserData;
};
export const reftoken = () => {
    const getLocalStorageKeys = Object.keys(localStorage);
    const getBaseAuthIdToken = getKeyValuesByLocalStorage(/refreshtoken/gi, getLocalStorageKeys);
    const getlocalStorageIdToken = JSON.parse(localStorage.getItem(getBaseAuthIdToken));
    localStorage.setItem("REFRESH_TOKEN", getlocalStorageIdToken?.secret);
};
export const localStoragedetails = () => {
    const getLocalStorageKeys = Object.keys(localStorage);
    const getBaseAuthIdToken = getKeyValuesByLocalStorage(/idtoken/gi, getLocalStorageKeys);
    const getlocalStorageIdToken = JSON.parse(localStorage.getItem(getBaseAuthIdToken));
    return getlocalStorageIdToken;
};

export const formatCamelCase = (str) => {
    return str?.replace(/([a-z])([A-Z])/g, "$1 $2")?.replace(/\b([a-z])/g, (match) => match.toUpperCase());
};

export const formatNumber = (numberToFormat, decimalPlaces = 2) => {
    const suffixes = ["", "K", "M", "B", "T", "Q"]; // Suffixes for thousands, millions, billions, etc.

    let index = 0;
    while (numberToFormat >= 1000 && index < suffixes.length - 1) {
        numberToFormat /= 1000;
        index++;
    }

    const roundedNumber = Math.round(numberToFormat * 100) / 100; // Round to 2 decimal places

    return Number.isNaN(roundedNumber) ? "" : `${roundedNumber}${suffixes[index]}`;
};

export const getGreeting = () => {
    const hours = new Date().getHours();
    const time = hours % 24 < 6 ? "Zzz" : hours < 12 ? "Good Morning" : hours < 18 ? "Good Afternoon" : "Good Evening";
    return time;
};

export const getKeyValuePair = (_key, _value) => {
    return { [_key]: _value };
};
export const StaticIssuerWatchlistHeader = [
    "status",
    "name",
    "symbol",
    "countryOfIncorporation",
    "indicies",
    "industry",
    "sector",
    "marketCap",
    "oaisProducts",
    "issuerWatchListId",
    "issuerId",
    "isin",
];

export const StaticIssuerProductWatchlistHeader = ["name", "ticker", "bloombergId", "type"];

export const IssuerWatchListColumnToRemove = ["issuerWatchListId", "issuerId", "isin"];

export const get_numeric_value_sorting = (d) => {
    const range_str = d.value;
    if (range_str === "> 500B") {
        return 501000000000;
    }
    const [lower, upper] = range_str.split(" - ");
    const lower_val = parseFloat(lower) * (lower.endsWith("M") ? 1000000 : 1000000000);
    const upper_val = parseFloat(upper) * (upper.endsWith("M") ? 1000000 : 1000000000);
    return (lower_val + upper_val) / 2;
};

export const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const showToastify = (type, message) => {
    switch (type) {
        case "success":
            toast.success(message);
            break;
        case "error":
            toast.error(message);
            break;
        case "warning":
            toast.warning(message);
            break;
        case "info":
            toast.info(message);
            break;
        default:
            toast(message);
    }
};

export const COMMONCLASSNAME = {
    BUTTONDISABLE: "cursor-not-allowed opacity-50",
};
export const disPascalize = (value) => {
    const words = value
        .replace(/([A-Z])/g, " $1")
        .trim()
        .split(" ");
    const lowercasedWords = words.map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1));
    return lowercasedWords.join(" ");
};
export const removeSpecialCharacters = (fileName) => {
    const pattern = /[\\\/:*?"<>|]/g;
    const sanitizedFileName = fileName.replace(pattern, "");

    if (sanitizedFileName === "") {
        return "PeoductDetailsHolding"; // Return a static file name when the resulting name is empty
    }

    return sanitizedFileName;
};
