import React, { useContext, useEffect } from "react";
import Button from "../components/Button/Button";
import UserContext from "../context/modal/Contextmodel";
import { usePostWatchListMutation } from "../Services/Issuer/Search/IssuerSearchApi";
import { showToastify } from "../constants/Common";

const AddToWatchListFooter = ({ ModuleName }) => {
    const { setAddToWatchList, WLName, Isin, setWLName, WLId, setWLId, Productid } = useContext(UserContext);
    const [postWatchList, postWatchListData] = usePostWatchListMutation();
    const closeModal = () => {
        setAddToWatchList(false);
        setWLName("");
        setWLId("");
    };
    const saveWatchList = async () => {
        const body = {
            name: WLName,
            watchListId: WLId ? WLId : null,
            isin: Isin,
            moduleName: ModuleName,
            bloombergId: Productid ? Productid : null,
        };
        postWatchList(body);
        setWLName("");
        setWLId("");
    };
    useEffect(() => {
        if (postWatchListData?.isSuccess) {
            showToastify("success", "WatchList added successfully");
            setAddToWatchList(false);
        } else if (postWatchListData?.isError) {
            showToastify("warning", postWatchListData?.error?.data?.errors?.Name[0]);
            setWLName("");
            setAddToWatchList(false);
        }
    }, [postWatchListData]);
    return (
        <>
            <div className="grid grid-rows-1 grid-flow-col gap-4 mt-4">
                <Button value="Cancel" variant="secondary" onClick={closeModal} />

                {(!WLName && !WLId) || (WLName && WLId) ? (
                    <Button value="Save" variant="primary" className="opacity-50 cursor-not-allowed" onClick={saveWatchList} disabled={true} />
                ) : (
                    <Button value="Save" variant="primary" onClick={saveWatchList} />
                )}
            </div>
        </>
    );
};

export default AddToWatchListFooter;
