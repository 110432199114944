import React, { useContext } from "react";
import Button from "../../components/Button/Button";
import UserContext from "../../context/modal/Contextmodel";

const ShareFooter = ({ value1, value2 ,UpdateApiCall}) => {
  const { setshare, setshowmodal, edit, tdata, setApicall } = useContext(UserContext);
  

  const closeModal = () => {
    setshare(false);
    setshowmodal(false);

  };

  const saveModal = async () => {
    if (edit?.length >= 1) {
      await UpdateApiCall({ id: tdata, description: edit });
    }
    setshare(false);
    setshowmodal(false);
    setApicall(true);
  };

  const isDisabled = edit?.length < 1;

  return (
    <div className="grid grid-rows-1 grid-flow-col gap-4 ">
      <div>
        <Button value={value1} variant="secondary" onClick={closeModal} />
      </div>
      <div>
        <Button
          value={value2}
          variant="primary"
          onClick={saveModal}
          className={isDisabled ? "opacity-50 cursor-not-allowed" : ""}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default ShareFooter;
