import { interceptorsApiCall } from "../../globals/interceptors";

export const GetAllUserApi = interceptorsApiCall.injectEndpoints({
	endpoints: (builder) => ({
		getAllUsers: builder.query({
			query: (params) => `api/Admin/Users/GetAllUsers?${params}`,
		}),
        postIssuerNotesShare: builder.mutation({
			query: (payload) => {
				return {
					url: "api/Issuer/CreateIssuerNotesShare",
					method: "POST",
					body: payload,
				};
			},
		}),
		postWatchListShare: builder.mutation({
			query: (payload) => {
				return {
					url: "api/Issuer/CreateIssuerWatchListShare",
					method: "POST",
					body: payload,
				};
			},
		}),
		postSavedSearchShare: builder.mutation({
			query: (payload) => {
				return {
					url: "api/Issuer/CreateSavedSearchShare",
					method: "POST",
					body: payload,
				};
			},
		}),
	}),
    
		
});

export const {useGetAllUsersQuery,usePostIssuerNotesShareMutation,usePostWatchListShareMutation,usePostSavedSearchShareMutation} = GetAllUserApi;
