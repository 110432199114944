import React, { useContext, useRef, useState } from "react";
import UserContext from "../../../context/modal/Contextmodel";

const UpdateMetaDataModal = ({ setValue }) => {
    const { shareDropDownValue, setShareDropDownValue,OAISMSCApprovalStatus, isDropdownVisible, setIsDropdownVisible, setProductid, showUpdateMeta } = useContext(UserContext);
    const [selectedOption, setSelectedOption] = useState(OAISMSCApprovalStatus);
    const divRef = useRef();

    const handleCheckbox = (value) => {
        setSelectedOption(value);
        setValue(value === "Yes");
    };

    const options = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];
    const intervalOptions = [
        { id: 1, value: "Never", name: "Never" },
        { id: 2, value: "Daily", name: "Daily" },
        { id: 3, value: "Weekly", name: "Weekly" },
        { id: 4, value: "Monthly", name: "Monthly" },
        { id: 5, value: "Quarterly", name: "Quarterly"},
        { id: 6, value: "Yearly", name: "Yearly" },
    ];
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };
    const handleShareClick = (id, name) => {
        setShareDropDownValue(name);
        setIsDropdownVisible(false);
        // setWLId(id);
    };
    const handleDropChange = () => {
        if (shareDropDownValue?.length === 0) {
            setIsDropdownVisible(false);
        }
    };
    React.useEffect(() => {
        if (showUpdateMeta === false) {
            // setChecked([]);
            setProductid("");
            setShareDropDownValue("");
        }
    }, [showUpdateMeta]);
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <>
        <div className=" grid grid-cols-2 "> <div className="">Is MSC Product? </div>  <div className="flex  mb-2">
                {options.map((option) => (
                    <div key={option.value}>
                        <input
                            type="checkbox"
                            className="form-checkbox focus:ring-transparent text-primary-600 rounded"
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={() => handleCheckbox(option.value)}
                        />
                        <label className="mx-2 text-sm text-gray-700">{option.label}</label>
                    </div>
                ))}
            </div></div>
            <div className="py-2">
                <div className="grid grid-cols-2"><h1 className="">Refresh interval</h1>
                <div className="relative"> <input
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    id="username"
                    type="text"
                    value={shareDropDownValue}
                    onClick={() => {
                        handleDropChange();
                        setIsDropdownVisible(!isDropdownVisible);
                    }}
                    // disabled={WLName?.length > 0}
                    style={{
                        backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                        backgroundPosition: "150px 50%",
                        backgroundRepeat: "no-repeat",
                    }}
                />
                 
                 {isDropdownVisible && (
                    <div className="absolute left-0 w-full flex flex-col rounded mt-1  max-h-80 md:max-h-none h-40">
                        <div className="py-4 pl-1.5 bg-white border shadow rounded z-10 justify-between border-b last:border-b-0 flex overflow-auto ">
                            <div className="flex text-dark flex-col gap-1 w-full " ref={divRef}>
                                {intervalOptions?.length > 0 ? (
                                    intervalOptions?.map((data, index) => {
                                        return (
                                            <h1
                                                key={index}
                                                onClick={() => handleShareClick(data?.id, data?.name)}
                                                className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                            >
                                                {data?.name}
                                            </h1>
                                        );
                                    })
                                ) : (
                                    <h1
                                        ref={divRef}
                                        onClick={() => setIsDropdownVisible(false)}
                                        className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                    >
                                        <b>No Data Found</b>
                                    </h1>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                </div></div>
              
            </div>
        </>
    );
};

export default UpdateMetaDataModal;
