import { interceptorsApiCall } from "../../../globals/interceptors";

export const GetUserByIdApi = interceptorsApiCall.injectEndpoints({
    endpoints: (builder) => ({
        GetUserById: builder.query({
            query: (params) => `/api/Admin/Users/GetUser?${params}`,
            providesTags: ["AddUser", "DeleteUser", "UpdateUser"],
        }),
        GetAllManageUsers: builder.query({
            query: (params) => `/api/Admin/Users/GetAllUsers?${params}`,
            providesTags: ["UpdateUser"],
        }),
        addUserRole: builder.mutation({
            query: (payload) => ({
                url: "/api/Admin/Users/AddUserToARole",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["AddUser"],
        }),
        deleteUserRole: builder.mutation({
            query: (payload) => ({
                url: "/api/Admin/Users/RemoveUserFromARole",
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["DeleteUser"],
        }),
        updateUser: builder.mutation({
            query: (payload) => ({
                url: "/api/Admin/Users/UpdateUser",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["UpdateUser"],
        }),
    }),
});

export const { useGetUserByIdQuery,useGetAllManageUsersQuery, useAddUserRoleMutation, useDeleteUserRoleMutation, useUpdateUserMutation } = GetUserByIdApi;
