import React, { useContext, useEffect, useRef, useState } from "react";
import RatingList from "../../components/Rating/RatingList";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import "react-loading-skeleton/dist/skeleton.css";
import { ISSUERDETAILS } from "../../constants/appConstants";
import Modal from "../../components/Modals/Modals";
import Sharebody from "./ShareAction";
import UserContext from "../../context/modal/Contextmodel";
import AddnoteBody from "./AddActionBody";
import ShareFooter from "./ShareFooter";
import AddFooter from "./AddFooter";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useGetIssuerDetailsQuery, usePostPaginatedIssuerNotesMutation, usePutIssuerNotesMutation } from "../../Services/IssuerDetails/CreateIssuerNotesApi";
import { queryString } from "../../helpers";
import { has, isEmpty, range } from "lodash";
import { useGetIssuerStackholderGraphDetailsQuery } from "../../Services/IssuerDetails/GetIssuerStackholderGraphDataApi";
import { useGetAllUsersQuery, usePostIssuerNotesShareMutation } from "../../Services/ShareModal/ShareModalApi";
import ShareModal from "../ShareModal/ShareModal";
import { formatNumber, showToastify } from "../../constants/Common";
import PrintModalBody from "./PrintModalBody";
import PrintModalFooter from "./PrintModalFooter";
import IssuerDetailsTable from "../../components/Table/IssuerDetailsTable";
import PrintRatingList from "../../components/Rating/PrintRatingList";
const IssuerDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { Permissionapi } = useContext(UserContext);
    const path = location?.state?.path || "";
    const pathName = path ? path : sessionStorage.getItem("issuerPath");
    const setPath = sessionStorage.getItem("issuerPath");
    const printRef = useRef();
    const print1Ref = useRef();
    const print2Ref = useRef();
    const [issuerItem, setIssuerItem] = useState({});
    const [modal, setshowmodal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [share, setshare] = useState(false);
    const [addmodal, setAddmodal] = useState(false);
    const [sucess, setSucess] = useState(false);
    const [print, setPrint] = useState(false);
    const [sortOrder, setSortorder] = useState("0");
    const [printNote, setPrintNote] = useState(true);
    const [notelist, setNotelist] = useState([]);
    const [apicall, setApicall] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [headername, setHeadername] = useState("");
    const [tdata, settdata] = useState({});
    const [note, setNote] = useState("");
    const [userId, setUserId] = useState("");
    const [edit, setEdit] = useState({});
    const [stackHolderData, setStackHolderData] = useState({});
    const [stackBarCount, setStackBarCount] = useState([]);
    const [stackName, setStackName] = useState([]);
    const [shareInputValue, setShareInputValue] = useState("");
    const [shareDropDownValue, setShareDropDownValue] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(true);
    const body = {
        isin: sessionStorage.getItem("ISIN"),
        PageNumber: currentPage,
        sortOrder: sortOrder,
        orderBy: headername.charAt(0).toUpperCase() + headername.slice(1),
        PageSize: "5",
    };
    const [putIssuerNotes] = usePutIssuerNotesMutation();
    const [IssuerNotePagination, IssuerNotePaginationdata] = usePostPaginatedIssuerNotesMutation();
    const IssuerDetailsData = useGetIssuerDetailsQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));
    const IssuerStackholderData = useGetIssuerStackholderGraphDetailsQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));
    const [PostIssuerNotesShare] = usePostIssuerNotesShareMutation();
    const GetUserDetailsData = useGetAllUsersQuery(queryString({ params: { searchText: shareInputValue } }), {
        skip: shareInputValue.length < 1,
    });
    const getNormalDate = (dateString) => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const addnote = () => showToastify("success", "Added sucessfully");

    const handleIssuerStackholderData = () =>
        IssuerStackholderData?.isSuccess && has(IssuerStackholderData, ["data"]) && !isEmpty(IssuerStackholderData?.data)
            ? setStackHolderData(IssuerStackholderData?.data)
            : [];

    const handleBarcount = () =>
        IssuerStackholderData?.isSuccess && has(IssuerStackholderData, ["data"]) && !isEmpty(IssuerStackholderData?.data)
            ? stackHolderData?.issueStackHolders?.map((data) => {
                setStackBarCount(data?.barCount);
                setStackName(data?.name);
                return "";
            })
            : [];
    const handlePostApi = () => {
        if (userId && tdata) {
            const body = {
                issuerNotesId: tdata,
                sharedUserId: userId,
            };
            PostIssuerNotesShare(body);
            showToastify("success", "Notes Shared Successfully");
        }
        setShareModal(false);
        setUserId("");
        settdata("");
    };
    if (sucess === true) {
        addnote();
        setSucess(false);
    }
    const handleShareClick = (email, id) => {
        setIsDropdownVisible(false);
        setShareDropDownValue(email);
        setUserId(id);
    };
    const RatingData = [
        {
            id: "3",
            icon: "employees",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Employees")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Employees")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "2",
            icon: "customers",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Customers")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Customers")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "6",
            icon: "suppliers",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Suppliers")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Suppliers")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "4",
            icon: "environment",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Environment")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Environment")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "1",
            icon: "communities",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Communities")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Communities")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },

        {
            id: "5",
            icon: "society",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Society")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Society")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
    ];
    const triggerPrint = () => {
        var content = document.getElementById("print3");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(
            "<html><head><style>@media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; }}</style></head><body>" +
            content.outerHTML +
            "</body></html>"
        );
        pri.document.close();
        pri.focus();
        pri.print();
    };
    useEffect(() => {
        IssuerNotePagination(body);
        if (apicall === true) {
            IssuerNotePagination(body);
            setApicall(false);
            setCurrentPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, apicall, sessionStorage.getItem("ISIN"), sortOrder]);
    useEffect(() => {
        IssuerNotePagination({
            isin: sessionStorage.getItem("ISIN"),
            PageNumber: currentPage,
            PageSize: print ? IssuerNotePaginationdata?.data?.totalCount : "5",
        });
    }, [print]);
    useEffect(() => {
        handleIssuerStackholderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IssuerStackholderData]);

    useEffect(() => {
        handleBarcount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stackHolderData]);
    useEffect(() => {
        if (IssuerNotePaginationdata?.isSuccess && isEmpty(IssuerNotePaginationdata?.data.items) && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }, [IssuerNotePaginationdata]);

    useEffect(() => {
        if (IssuerNotePaginationdata?.isSuccess && has(IssuerNotePaginationdata, ["data"]) && !isEmpty(IssuerNotePaginationdata?.data)) {
            const tempArr = [...IssuerNotePaginationdata?.data?.items];
            const newArr = tempArr.map((values) => {
                const myNewObject = {
                    ...values,
                    Action: true,
                    lastModified: getNormalDate(values?.lastModified),
                };
                return myNewObject;
            });
            setNotelist(newArr);
        }
        if (has(IssuerDetailsData, ["data"]) && IssuerDetailsData?.isSuccess) {
            setIssuerItem(IssuerDetailsData.data);
        }
    }, [IssuerNotePaginationdata, IssuerDetailsData]);
    useEffect(() => {
        setShareInputValue("");
        setShareDropDownValue("");
    }, [shareModal]);
    useEffect(() => {
        setPrintNote(true);
    }, [print]);
    return (
        <>
            <div className="w-full h-full" id="printArea">
                <>
                    <div id="print3">
                        {!print ? (
                            <div className="flex justify-between px-4 pt-4 border-b">
                                <div className="pb-4 flex gap-3">
                                    <h1 className="text-lg mb-1">
                                        <i
                                            className="far fa-arrow-left cursor-pointer"
                                            onClick={() => {
                                                navigate(pathName);
                                                    sessionStorage.setItem("currentPath", setPath);
                                            }}
                                        ></i>
                                    </h1>
                                    <div ref={printRef}>
                                        <h1 className="text-2xl mb-1">{issuerItem?.Name}</h1>
                                        <div className="flex items-center gap-2 text-sm">
                                            <p className="text-gray-400">Date Last Modified</p>
                                            <div className="w-1.5 h-1.5 bg-gray-400 rounded-lg"></div>

                                            <p className="text-gray-400">{getNormalDate(issuerItem?.LastUpdated)}</p>
                                        </div>
                                    </div>
                                </div>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={true}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                                {Permissionapi?.includes("Issuer_PrintIssuerReport") || Permissionapi?.includes("All") ? (
                                    <p
                                        className="cursor-pointer"
                                        style={{ color: "rgb(14 165 233)" }}
                                        onClick={() => {
                                            setPrint(true);
                                        }}
                                    >
                                        {ISSUERDETAILS.PRINT}
                                    </p>
                                ) : null}
                            </div>
                        ) : (
                            <div ref={printRef} style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <h1 style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>{issuerItem?.Name}</h1>
                                <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontSize: "0.875rem", color: "#A0AEC0" }}>
                                    <p>Date Last Modified</p>
                                    <div style={{ width: "1.5px", height: "1.5px", backgroundColor: "#A0AEC0", borderRadius: "50%" }}></div>
                                    <p>{getNormalDate(issuerItem?.LastUpdated)}</p>
                                </div>
                            </div>
                        )}

                        <div className="sm:flex">
                            {" "}
                            <div className="p-4 ">
                                {!print ? (
                                    <RatingList RatingData={RatingData} isin={sessionStorage.getItem("ISIN")} />
                                ) : (
                                    <PrintRatingList RatingData={RatingData} isin={sessionStorage.getItem("ISIN")} print={print} />
                                )}
                            </div>
                            <div className="p-4" style={{ flexGrow: 1 }} ref={print1Ref}>
                                <div id="print2" ref={print2Ref}>
                                    <div style={{ borderRadius: "4px", marginBottom: "24px" }}>
                                        <table style={{ width: "100%", border: "1px solid #e5e7eb", backgroundColor: "#e5e7eb" }}>
                                            <thead style={{ backgroundColor: "#e5e7eb" }}>
                                                <th
                                                    style={{
                                                        backgroundColor: "#e5e7eb",
                                                        textAlign: "left",
                                                        padding: "10px",
                                                        color: "black",
                                                        border: "1px solid #e5e7eb",
                                                    }}
                                                    colSpan={2}
                                                >
                                                    {ISSUERDETAILS?.COMPANY}
                                                </th>
                                            </thead>
                                            <tbody style={{ backgroundColor: "white" }}>
                                                <tr>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.ISIN}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{issuerItem?.ISIN}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.TICKER}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{issuerItem?.Ticker}</td>
                                                </tr>
                                                <tr
                                                // style={{
                                                //     borderBottom: "1px solid #e5e7eb",
                                                // }}
                                                >
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.COUNTRY}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{issuerItem?.CountryOfIncorporation}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.GICSSECTOR}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{issuerItem?.GICSSector}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.GICSINDUSTRY}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{issuerItem?.GICSIndustry}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.GICSINDUSTRYGROUP}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{issuerItem?.GICSIndustryGroup}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>{ISSUERDETAILS?.MARKETCAP}</td>
                                                    <td style={{ padding: "10px", borderBottom: "1px solid #e5e7eb" }}>
                                                        {issuerItem?.MarketCap ? "$ " + formatNumber(issuerItem?.MarketCap) : ""}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ border: "1px solid #e5e7eb", marginBottom: "24px" }}>
                                        <table style={{ width: "100%", backgroundColor: "#e5e7eb" }}>
                                            <thead style={{ backgroundColor: "#e5e7eb" }}>
                                                <th style={{ backgroundColor: "#e5e7eb", textAlign: "left", padding: "10px", color: "black" }}>
                                                    {ISSUERDETAILS?.DESCRIPTION}
                                                </th>
                                            </thead>
                                            <tbody style={{ backgroundColor: "white" }}>
                                                <tr>
                                                    <td style={{ padding: "10px" }}>
                                                        <p>{issuerItem?.Description}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {printNote && (
                            <div className="sm:flex">
                                {" "}
                                <div className="p-4" style={{ height: "100px", overflow: "hidden", visibility: "hidden" }}>
                                    {!print ? (
                                        <RatingList RatingData={RatingData} isin={sessionStorage.getItem("ISIN")} />
                                    ) : (
                                        <PrintRatingList RatingData={RatingData} isin={sessionStorage.getItem("ISIN")} print={print} />
                                    )}
                                </div>
                                <div className="p-4" style={{ flexGrow: 1, marginLeft: print ? "3rem" : "" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
                                        <h3 style={{ fontWeight: "bold", marginTop: "2px" }}>{ISSUERDETAILS.NOTES}</h3>
                                        {!print && <Button variant="primary" value="Add notes" onClick={() => setAddmodal(true)} />}
                                    </div>
                                    {IssuerNotePaginationdata?.isSuccess && !isEmpty(IssuerNotePaginationdata?.data?.items) && (
                                        <div style={{ border: "1px solid #e5e7eb", borderRadius: "4px" }} id="table">
                                            <IssuerDetailsTable
                                                data={IssuerNotePaginationdata?.isSuccess ? notelist : []}
                                                onclick={() => setshowmodal(true)}
                                                setEdit={setEdit}
                                                settdata={settdata}
                                                sortOrder={sortOrder}
                                                itemsPerPage="5"
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                count={IssuerNotePaginationdata?.data?.totalCount}
                                                setshare={setShareModal}
                                                print={print}
                                                setSortorder={setSortorder}
                                                setHeadername={setHeadername}
                                            />
                                        </div>
                                    )}
                                    {IssuerNotePaginationdata?.isLoading && (
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Description
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        User Name
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Last Modified
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                    >
                                                                        Show Edit
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {/* Skeleton loading UI for table rows */}
                                                                {range(6).map((range, i) => (
                                                                    <tr className="animate-pulse" key={i}>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="bg-gray-200 h-4 w-40 rounded"></div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="bg-gray-200 h-4 w-24 rounded"></div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                            <div className="bg-gray-200 h-4 w-10 rounded"></div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <iframe id="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute" }}></iframe>
                </>

                <UserContext.Provider
                    value={{
                        setshowmodal,
                        setAddmodal,
                        note,
                        setNote,
                        setShareModal,
                        setPrint,
                        setNotelist,
                        setshare,
                        setSucess,
                        share,
                        setApicall,
                        tdata,
                        setEdit,
                        edit,
                        userId,
                        setUserId,
                        shareInputValue,
                        setShareInputValue,
                        shareDropDownValue,
                        setShareDropDownValue,
                        isDropdownVisible,
                        setIsDropdownVisible,
                    }}
                >
                    {" "}
                    <Modal
                        body={<Sharebody setEdit={setEdit} edit={edit} Bodytitle={ISSUERDETAILS?.SHORTNOTES} />}
                        showmodal={modal}
                        setshowmodal={setshowmodal}
                        footer={<ShareFooter value1={"Cancel"} value2={"Save"} UpdateApiCall={putIssuerNotes}/>}
                        title={ISSUERDETAILS.EDIT}
                        size={"w-[400px]"}
                    />
                    <ShareModal
                        title={ISSUERDETAILS.SHARE_ISSUERNOTES}
                        size={"w-[38%] "}
                        showmodal={shareModal}
                        setshowmodal={setShareModal}
                        ApiData={GetUserDetailsData?.isSuccess && GetUserDetailsData}
                        handleShareClick={handleShareClick}
                        setShareModal={setShareModal}
                        onClick={handlePostApi}
                        id={settdata}
                        setUserId={setUserId}
                    />
                    <Modal
                        body={<AddnoteBody title={ISSUERDETAILS.SHORTNOTES} setnote={setNote} Addnotes={true} />}
                        showmodal={addmodal}
                        setshowmodal={setAddmodal}
                        footer={<AddFooter value1={"Cancel"} value2={"Add"} isin={sessionStorage.getItem("ISIN")} />}
                        title={"Add Notes"}
                        size={"w-[400px]"}
                    />
                    <Modal
                        body={<PrintModalBody setPrintNote={setPrintNote} />}
                        showmodal={print}
                        setshowmodal={setPrint}
                        footer={<PrintModalFooter setPrint={setPrint} buttonValue={"Print"} onClick={() => triggerPrint()} />}
                        title={"Print"}
                        size={"sm:w-[20%]"}
                    />
                </UserContext.Provider>
            </div>
        </>
    );
};

export default IssuerDetails;
