import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../globals/authConfig";
import {LOGIN } from "../../constants/appConstants";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const LoginButton = () => {
	const { instance } = useMsal();

	const handleLogin = (loginType) => {
		if (loginType === "popup") {
			instance.loginPopup(loginRequest).catch((e) => {
			});
		} else if (loginType === "redirect") {
			instance.loginRedirect(loginRequest).catch((e) => {
			});
		}
	};
	return (
		<button
			className='rounded bg-primary px-6 py-2.5 text-xs font-medium uppercase text-white shadow-md hover:bg-primary_opacity-700 hover:shadow-lg transition-all'
			onClick={() => handleLogin("popup")}
		>
			{LOGIN.LOGIN}
		</button>
	);
};
