import { CheckIcon, XMarkIcon, PencilIcon, ShareIcon, ExclamationIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, DocumentArrowDownIcon, DocumentTextIcon, PaperClipIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

const Badge = ({ value, variant, onClick, title }) => {
    let textVisibility = false;
    const batchTheme = () => {
        switch (variant) {
            case "GrayBlue":
                return "bg-gray-100 text-primary px-2 py-0 ";
            case "GrayGreen":
                return "bg-gray-100 text-green-500 px-2 py-0 ";
            case "GrayYellow":
                return "bg-gray-100 text-yellow-500 px-2 py-0 ";
            case "CircleSuccess":
                textVisibility = true;
                return "bg-green-100 text-green-500 rounded-full w-6 h-6 flex items-center justify-center ";
            case "CircleFail":
                textVisibility = true;
                return "bg-red-100 text-red-500 rounded-full w-6 h-6 flex items-center justify-center ";
            case "PencilIcon":
                textVisibility = true;
                return "cursor-pointer ";
            case "DocumentText":
                textVisibility = true;
                return "cursor-pointer ";
            case "DocumentDownload":
                textVisibility = true;
                return "cursor-pointer";
            case "TrashRed":
                textVisibility = true;
                return "text-red-500 w-6 h-6 ";
            case "ShareIcon":
                textVisibility = true;
                return "w-4  mr-3 cursor-pointer";
            case "OutlineSuccess":
                textVisibility = true;
                return "w-5 h-6 cursor-pointer";
            case "OutlineCross":
                textVisibility = true;
                return "w-5 h-6 cursor-pointer text-red-500";
            case "PaperClipIcon":
                textVisibility = true;
                return "w-6 h-6 mx-3 flex items-center justify-center";
            case "CheckCircle":
                textVisibility = true;
                return "w-6 h-6 mx-3 flex items-center justify-center";
            case "CircleSuccess1":
                textVisibility = true;
                return "bg-yellow-100 text-yellow-500 rounded-full w-6 h-6 flex items-center justify-center ";

            default:
                return variant;
        }
    };
    const IconHandler = () => {
        if (variant === "CircleSuccess") {
            return <CheckIcon className="w-4  " />;
        } else if (variant === "CircleFail") {
            return <XMarkIcon className="w-4" />;
        } else if (variant === "PencilIcon") {
            return <PencilIcon className="w-4  " onClick={onClick} />;
        } else if (variant === "ShareIcon") {
            return <ShareIcon className="w-4  " onClick={onClick} />;
        } else if (variant === "TrashRed") {
            return <i className="far fa-trash cursor-pointer" onClick={onClick}></i>;
        } else if (variant === "OutlineSuccess") {
            return <CheckIcon onClick={onClick}></CheckIcon>;
        } else if (variant === "OutlineCross") {
            return <XMarkIcon onClick={onClick}></XMarkIcon>;
        } else if (variant === "DocumentText") {
            return <DocumentTextIcon className="w-5 " onClick={onClick}></DocumentTextIcon>;
        } else if (variant === "DocumentDownload") {
            return <DocumentArrowDownIcon className="w-5" onClick={onClick}></DocumentArrowDownIcon>;
        } else if (variant === "PaperClipIcon") {
            return <PaperClipIcon className="w-5 mt-0.5"></PaperClipIcon>;
        } else if (variant === "CheckCircle") {
            return <CheckCircleIcon className="w-5 mt-0.5" onClick={onClick}></CheckCircleIcon>;
        } else if (variant === "CircleSuccess1") {
            return  <span className="font-bold">!</span>
         
        }
    };
    return (
        <div className={`rounded text-sm ${batchTheme()}`} title={title}>
            {textVisibility ? IconHandler() : value}
        </div>
    );
};

export default Badge;
