import React, { useEffect, useRef, useState } from "react";
import { useCreateRolesMutation, useDeleteRolesApiMutation, useGetRolesApiQuery, useUpdateRolesMutation } from "../../../Services/Admin/RolesAPI/RolesGetApi";
import { has, isArray, isEmpty } from "lodash";
import UserContext from "../../../context/modal/Contextmodel";
import { SAVEDSEARCH } from "../../../constants/appConstants";
import DeleteFooter from "../../DeleteFooter";
import Modal from "../../../components/Modals/Modals";
import { queryString } from "../../../helpers";
import { disPascalize, showToastify } from "../../../constants/Common";
import Badge from "../../../components/Badge/Badge";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const Roles = ({ canEdit }) => {
    const editViewRef = useRef(null);
    const [saveModal, setSaveModal] = useState(false);
    const [editRoleModal, setEditRoleModal] = useState(false);
    const [stateReset, setStateReset] = useState(false);
    const [deleteRoleName, setDeleteRoleName] = useState("");
    const [roleName, setRoleName] = useState("");
    const [updateRoleName, setUpdateRoleName] = useState("");
    const [roleId, setRoleId] = useState("");
    const [isDeleted, setIsDeleted] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const GetRolesAPI = useGetRolesApiQuery();
    const [createRoles, createRolesData] = useCreateRolesMutation();
    const [deleteRolesDetail, deleteRolesDetailData] = useDeleteRolesApiMutation();
    const [updateRoles, updateRolesData] = useUpdateRolesMutation();
    const handleApiCall = () => {
        deleteRolesDetail(queryString({ params: { RoleId: roleId } }));
        setStateReset(true);
    };
    const createApiCall = () => {
        setRoleName("");
        const body = {
            roleName: roleName,
            description: "string",
            type: "standard",
        };
        createRoles(body);
        setStateReset(false);
    };
    const updateApiCall = () => {
        setUpdateRoleName("");
        const body = {
            roleId: roleId,
            roleName: updateRoleName,
            description: "string",
            type: "standard",
        };
        updateRoles(body);
    };

    useEffect(() => {
        GetRolesAPI.refetch();
    }, []);

    useEffect(() => {
        if (updateRolesData.isSuccess) {
            showToastify("success", "Role Updated successfully");
            setEditRoleModal(false);
        }
    }, [updateRolesData]);

    useEffect(() => {
        if (createRolesData?.isSuccess && !stateReset) {
            showToastify("success", "New Role added successfully");
        } else if (deleteRolesDetailData?.isSuccess && stateReset) {
            showToastify("success", "Role deleted successfully");
            setSaveModal(false);
        }
    }, [deleteRolesDetailData, createRolesData]);

    return (
        <div className="grid grid-cols-12 gap-4 md:gap-8">
            <div class="col-span-12 md:col-span-8 lg:col-span-6">
                <div class="flex justify-between">
                    <span className="font-bold self-center">Roles</span>
                </div>
                <div className="overflow-auto min-h-[400px] max-h-[400px]" ref={editViewRef}>
                    <table className="table min-w-full divide-y divide-gray-200" style={{ position: "relative" }}>
                        <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                            <tr className="tr">
                                <th className="bg-gray-50 py-2 text-left text-xs font-bold text-gray-500 tracking-wider overflow-hidden whitespace-nowrap">
                                    <div className="flex items-center gap-3">
                                        <div className="flex ml-3 flex-grow items-center gap-2 font-bold" style={{ fontSize: "13px" }}>
                                            Name
                                        </div>
                                    </div>
                                </th>
                                {canEdit && (
                                    <th className="th group py-2 text-xs font-bold text-gray-500 tracking-wider overflow-hidden whitespace-nowrap">
                                        <div className="flex items-center gap-3">
                                            <div
                                                className="flex ml-6 flex-grow items-center gap-2 font-bold"
                                                style={{ fontSize: "13px", justifyContent: "start" }}
                                            >
                                                Action
                                            </div>
                                        </div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {has(GetRolesAPI, ["data"]) &&
                                isArray(GetRolesAPI?.data) &&
                                !isEmpty(GetRolesAPI?.data) &&
                                [...GetRolesAPI?.data]
                                    .sort((a, b) => {
                                        if (a.name === "System Administrator") {
                                            return -1; // "System Administrator" comes first
                                        } else if (b.name === "System Administrator") {
                                            return 1; // "System Administrator" comes second
                                        } else {
                                            return 0; // Preserve the original order for other objects
                                        }
                                    })
                                    .map((item, index) => {
                                        return (
                                            <tr className={`border-b odd:bg-gray-100 ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                                                <td className="sticky left-0 px-4 py-2 overflow-hidden">
                                                    {editRoleModal && selectedRowIndex === index ? (
                                                        <input
                                                            class="bg-primary_opacity-50 border-2 px-2 focus:ring-transparent focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-1 pl-3 text-dark"
                                                            type="text"
                                                            aria-label="Filter projects"
                                                            placeholder="Enter a Role"
                                                            maxLength={150}
                                                            style={{ backgroundColor: "rgb(243,244,246)" }}
                                                            value={isDeleted ? "" : updateRoleName || deleteRoleName}
                                                            onChange={(e) => {
                                                                setUpdateRoleName(e.target.value);
                                                                setIsDeleted(e.target.value === "");
                                                            }}
                                                        />
                                                    ) : (
                                                        disPascalize(item.name)
                                                    )}
                                                </td>
                                                {canEdit && (
                                                    <td className="td px-4 py-2 hover:max-w-none overflow-hidden text-center">
                                                        {item.name !== "System Administrator" ? (
                                                            <div class="text-center flex justify-start items-center">
                                                                <div className="p-1 rounded cursor-pointer">
                                                                    {editRoleModal && selectedRowIndex === index ? (
                                                                        <Badge
                                                                            variant="OutlineSuccess"
                                                                            onClick={() => {
                                                                                setEditRoleModal(false);
                                                                                setSelectedRowIndex(index);
                                                                                if (updateRoleName.length) updateApiCall();
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Badge
                                                                            variant="PencilIcon"
                                                                            onClick={() => {
                                                                                setEditRoleModal(true);
                                                                                setRoleId(item.roleId);
                                                                                setUpdateRoleName();
                                                                                setDeleteRoleName(item.name);
                                                                                setSelectedRowIndex(index);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="rounded cursor-pointer">
                                                                    {editRoleModal && selectedRowIndex === index ? (
                                                                        <Badge
                                                                            variant="OutlineCross"
                                                                            onClick={() => {
                                                                                setEditRoleModal(false);
                                                                                setUpdateRoleName();
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Badge
                                                                            variant="TrashRed"
                                                                            onClick={() => {
                                                                                setDeleteRoleName(item.name);
                                                                                setSaveModal(true);
                                                                                setRoleId(item.roleId);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div class="text-center flex justify-start ml-5 items-center">-</div>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            {canEdit && (
                                <tr className={`border-b odd:bg-gray-100 even:bg-gray-100`} style={{ position: "sticky", bottom: 0, zIndex: 1 }}>
                                    <td className="px-4">
                                        <div className=" md:relative drop-shadow-sm">
                                            <input
                                                className="shadow appearance-none w-full rounded py-2 px-4 text-gray-700 leading-tight "
                                                id="username"
                                                type="text"
                                                placeholder="Enter a Role"
                                                maxLength={150}
                                                value={roleName}
                                                onChange={(e) => setRoleName(e.target.value)}
                                            />
                                        </div>
                                    </td>
                                    <td className="py-2 ml-2 text-center flex justify-start">
                                        <div>
                                            <div className="p-1 rounded">
                                                <PlusCircleIcon
                                                    onClick={() => createApiCall()}
                                                    disabled={roleName?.length < 1}
                                                    className={`w-8 h-8 ml-2 text-primary ${
                                                        roleName?.length > 0 ? "cursor-pointer" : "opacity-50 cursor-not-allowed"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <UserContext.Provider value={{ updateRoleName, setUpdateRoleName, deleteRoleName }}>
                    <Modal
                        showmodal={saveModal}
                        setshowmodal={setSaveModal}
                        title={SAVEDSEARCH?.DELETETITLE}
                        body={
                            <div>
                                {SAVEDSEARCH?.DELETEMESSAGE} "<b className="break-all">{deleteRoleName}</b>"?
                            </div>
                        }
                        footer={<DeleteFooter onClick={handleApiCall} setSaveModal={setSaveModal} />}
                        isFooterEnable={true}
                        size={"w-96"}
                    />
                </UserContext.Provider>
            </div>
        </div>
    );
};

export default Roles;
