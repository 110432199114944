import React from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { store } from "./redux/store";
import { msalConfig } from "./globals/authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import App from "./App";
import "./style.scss";
import Login from "./pages/auth/Login";
 export const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <BrowserRouter>
                <AuthenticatedTemplate>
                    <App />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Login />
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </Provider>
    </MsalProvider>
);
