import React from "react";
import { useState } from "react";
import Badge from "../../components/Badge/Badge";

const EliminateTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabTitle, setActiveTabTitle] = useState('oaisValue');
  const tabData = [
    {
      title: "OAIS",
      key:"oaisValue",
      contenttitle: `${props?.tabdetails?.map((value) => {
        return value
      })}`,
      content: `${props?.tabdetails
      })}`,
    },
    {
      title: "MSCI",
      key:"msciValue",
      content: `${props?.tabdetails?.map((value) => {
        return value?.msciValue;
      })}`,
    },
    {
      title: "ISS",
      key:"issValue",
      content: `${props?.tabdetails?.map((value) => {
        return value?.issValue;
      })}`,
    },
  ];

  const handleTabClick = (tabIndex,title) => {
    setActiveTab(tabIndex);
    setActiveTabTitle(title)
  };

  return (
    <div>
      <div className="tabs">
        {tabData.map((tab, index) => (
          <button
            key={index}
            value={tab.key}
            className={
              activeTab === index
                ? "active bg-primary px-2 py-0.5 rounded-md text-white"
                : "p-2 m-2"
            }
            onClick={(e) => handleTabClick(index,e.target.value)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
      {props.tabdetails?.map((item) => {
  const displayName = item.displayName.replace(/ Note$/, '');
  const oaisValue = item.oaisValue || '';
  const [oaisWithPrefix, iss, msci] = oaisValue.split(/ISS:|MSCI:/).map((s) => s.trim());
  const oais = oaisWithPrefix.split(/OAIS:/).pop().trim();
  return (
    <>
      <b className="mb-3 ">{displayName}</b>
      {activeTab===0||1||2?<>
        {activeTab===0?<>
        <div className="flex ml-2 "><div className="font-bold">OAIS</div><div className="flex ml-2">:</div><div className="mb-1 ml-3">{`${oais}`}</div></div>
        <div className="flex ml-2 "><div className="font-bold">ISS</div><div className="flex ml-6">:</div><div className="mb-1 ml-3">{`${iss}`}</div></div>
        <div className="flex ml-2 "><div className="font-bold">MSCI</div><div className="flex ml-2">:</div><div className="mb-1 ml-3">{`${msci}`}</div></div>
    </>:""}
        {activeTab===2||0?<div className=" ml-2">{` ${iss}`}</div>:""}
        {activeTab===1||0?<div className="ml-2">{` ${msci}`}</div>:""}
      </>:""}
      
    </>
  );
})}
        <table className="w-full mt-5">
          <thead>
            <tr className="border-b-2">
              <th className="p-3 text-left">#</th>
              <th className="p-3 text-left">{tabData[activeTab].title}</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableData?.map((values, index) => {
              return (
                <tr key={index} className="border-b">
                  <td className="p-3">{values?.displayName}</td>
                  {activeTab === 0 && (
                    <td className="p-3">
                      {values?.oaisValue === null ||
                      values?.oaisValue === "-" ? (
                        "-"
                      ) : values?.oaisValue === "True" ? (
                        <Badge variant={"CircleFail"} />
                      ) : values?.oaisValue === "False" ? (
                        <Badge variant={"CircleSuccess"} />
                      ) : (
                        ""
                      )}
                    </td>
                  )}
                  {activeTab === 1 && (
                    <td className="p-3">
                      {values?.msciValue === null ||
                      values?.msciValue === "-" ? (
                        "-"
                      ) : values?.msciValue === "True" ? (
                        <Badge variant={"CircleFail"} />
                      ) : values?.msciValue === "False" ? (
                        <Badge variant={"CircleSuccess"} />
                      ) : (
                        ""
                      )}
                    </td>
                  )}
                  {activeTab === 2 && (
                    <td className="p-3">
                      {values?.issValue === null || values?.issValue === "-" ? (
                        "-"
                      ) : values?.issValue === "True" ? (
                        <Badge variant={"CircleFail"} />
                      ) : values?.issValue === "False" ? (
                        <Badge variant={"CircleSuccess"} />
                      ) : (
                        ""
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EliminateTabs;
