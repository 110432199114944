import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { has, isArray, isEmpty, isNull, range } from "lodash";
import { ToastContainer } from "react-toastify";
import ShareModal from "../ShareModal/ShareModal";
import DeleteFooter from "../DeleteFooter";
import { queryString } from "../../helpers";
import TailwindTable from "../../components/Table/TailwindTable";
import { ISSUER, ISSUERDETAILS } from "../../constants/appConstants";
import {
    IssuerWatchListColumnToRemove,
    StaticIssuerWatchlistHeader,
    capitalizeFirstLetter,
    getNormalDate,
    loadingTableheader,
    showToastify,
} from "../../constants/Common";
import Modal from "../../components/Modals/Modals";
import UserContext from "../../context/modal/Contextmodel";
import Badge from "../../components/Badge/Badge";
import { useDeleteIssuerWatchListApiMutation, useGetIssuerWatchListMutation } from "../../Services/IssuerWatchListTable/GetIssuerWatchlistApi";
import { useGetAllUsersQuery, usePostWatchListShareMutation } from "../../Services/ShareModal/ShareModalApi";

function IssuerWatchList() {
    const location = useLocation();
    const Id = location.state?.data;
    const Navigate = useNavigate();
    const checkSort = localStorage.getItem(ISSUER.ISSUERSORTING);
    const checkSortHeader = localStorage.getItem(ISSUER.ISSUERSORTINGHEADER);
    const [shareModal, setShareModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(true);
    const [data, setData] = useState([]);
    const [selectColumns, setSelectColumns] = useState([]);
    const [pageNumber, setPageNumber] = useState(5);
    const [count, setCount] = useState(1);
    const [issuerwatchlistname, setissuerWatchlistname] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [shareInputValue, setShareInputValue] = useState("");
    const [shareDropDownValue, setShareDropDownValue] = useState("");
    const [userId, setUserId] = useState("");
    const pageArray = [5, 10];
    const [sortColumnName, setSortColumnName] = useState(checkSortHeader);
    const [sortOrder, setSortOrder] = useState(checkSort);
    const watchlistId = isNull(Id)|| Id ===undefined?sessionStorage.getItem("Id"):Id
    const [deleteIssuerWatchList, deleteIssuerWatchListData] = useDeleteIssuerWatchListApiMutation();
    const [PostWatchListShare] = usePostWatchListShareMutation();
    const [GetWatchList, GetWatchListData] = useGetIssuerWatchListMutation();
    const GetUserDetailsData = useGetAllUsersQuery(queryString({ params: { searchText: shareInputValue } }), {
        skip: shareInputValue.length < 1,
    });
    useEffect(() => {
        if (
            GetWatchListData?.isSuccess &&
            has(GetWatchListData, ["data"]) &&
            isArray(GetWatchListData?.data?.issuerWatchList?.items) &&
            GetWatchListData?.data?.issuerWatchList?.items.length > 0
        ) {
            setData(GetWatchListData?.data?.issuerWatchList?.items);
            const fields = Object.keys(GetWatchListData?.data?.issuerWatchList?.items[0]);

            const resultArr = [];
            for (let i = 0; i < StaticIssuerWatchlistHeader.length; i++) {
                const index = fields.indexOf(StaticIssuerWatchlistHeader[i]);
                if (index > -1) {
                    resultArr.push(fields[index]);
                }
            }

            for (let i = 0; i < IssuerWatchListColumnToRemove.length; i++) {
                const index = resultArr.indexOf(IssuerWatchListColumnToRemove[i]);
                if (index > -1) {
                    resultArr.splice(index, 1);
                }
            }
            setSelectColumns(resultArr);
        }
    }, [GetWatchListData]);

    const sortAPI = () => {
        GetWatchList({
            Id: watchlistId,
            PageNumber: count,
            PageSize: pageNumber,
            orderBy: capitalizeFirstLetter(sortColumnName),
            sortOrder: sortOrder ? sortOrder : 0,
        });
    };
    useEffect(() => {
        GetWatchList({
            Id: watchlistId,
            PageNumber: count,
            PageSize: pageNumber,
            orderBy: capitalizeFirstLetter(sortColumnName),
            sortOrder: isNull(sortOrder) ? 0 : sortOrder,
        });
    }, [pageNumber, count]);
    useEffect(() => {
        sortAPI();
    }, [sortOrder]);
    useEffect(() => {
        if (deleteIssuerWatchListData?.isSuccess) {
            showToastify("success", "Deleted Successfully");
            GetWatchList({
                Id: watchlistId,
                PageNumber: count,
                PageSize: pageNumber,
                orderBy: capitalizeFirstLetter(sortColumnName),
                sortOrder: isNull(sortOrder) ? 0 : sortOrder,
            });
        }
    }, [deleteIssuerWatchListData]);

    const iconClick = (id, name) => {
        setSaveModal(true);
        setDeleteId(id);
        setissuerWatchlistname(name);
    };

    const handleApiCall = () => {
        deleteIssuerWatchList({ id: deleteId });
        setSaveModal(false);
    };

    const handlePostApi = () => {
        if (userId && watchlistId) {
            const body = {
                watchListId: watchlistId,
                sharedUserId: userId,
            };
            PostWatchListShare(body);
            showToastify("success", "WatchList Shared Successfully");
        }
        setShareModal(false);
        setUserId("");
    };
    const columns = selectColumns?.map((key, id) => {
        if (key === "name") {
            return {
                Header: "Name",
                accessor: key,
                Cell: (e) => (
                    <Link className=" items-center text-primary text-sm" to="/issuerdetails" state={{ data: e.value }}>
                        {e.value}
                    </Link>
                ),
            };
        }
        if (key === "status") {
            return {
                Header: key,
                accessor: key,
                Cell: (e) => {
                    return e?.value === "Pass" ? <Badge variant={"CircleSuccess"} /> : <Badge variant={"CircleFail"} />;
                },
            };
        } else {
            return {
                Header: key,
                accessor: key,
            };
        }
    });
    columns.unshift({
        Header: "Action",
        Cell: ({ row }) => <Badge variant="TrashRed" onClick={() => iconClick(row.original.issuerWatchListId, row.original.name)} />,
    });
    const handleShareClick = (email, id) => {
        setIsDropdownVisible(false);
        setShareDropDownValue(email);
        setUserId(id);
    };

    return (
        <>
            <div className="h-full w-full overflow-auto flex flex-col">
                <div className="flex flex-grow justify-between overflow-hidden border-b items-center py-4 md:p-4">
                    <div className="w-full h-full flex flex-col">
                        <div className="flex justify-between border-b">
                            <div className="pb-4 flex gap-3">
                                <h1 className="text-lg mb-1">
                                    <i
                                        className="far fa-arrow-left cursor-pointer"
                                        onClick={() => {
                                            Navigate("/issuer/home");
                                        }}
                                    ></i>
                                </h1>
                                <div>
                                    {GetWatchListData?.isSuccess && has(GetWatchListData, ["data"]) && !isEmpty(GetWatchListData?.data) && (
                                        <>
                                            <div className="flex items-center gap-2 text-sm">
                                                <h1 className="text-2xl mb-1">Issuer Watch List</h1>
                                                <div className="w-1.5 h-1.5 bg-gray-400 rounded-lg"></div>
                                                <h1 className="text-2xl mb-1">{GetWatchListData?.data?.watchList.name}</h1>
                                            </div>

                                            <div className="flex items-center gap-2 text-sm">
                                                <p className="text-gray-400">{GetWatchListData?.data?.watchList.userName}</p>
                                                <div className="w-1.5 h-1.5 bg-gray-400 rounded-lg"></div>
                                                <p className="text-gray-400">{getNormalDate(GetWatchListData?.data?.watchList.lastModified)}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="h-full flex-col flex overflow-auto static-scroll">
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                            {!isEmpty(GetWatchListData?.data?.issuerWatchList?.items) && (
                                <TailwindTable
                                    columns={columns}
                                    data={data}
                                    keyValue="IssuerWatchList"
                                    setCount={setCount}
                                    count={count}
                                    totalLength={GetWatchListData?.data?.issuerWatchList?.totalCount}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    pageArray={pageArray}
                                    sortColumnName={sortColumnName}
                                    setSortColumnName={setSortColumnName}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    path="/issuerdetails"
                                    returnPath="/issuerwatchlist"
                                />
                            )}
                            {GetWatchListData?.isLoading && (
                                <div className="flex flex-col">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                        <tr>
                                                            {loadingTableheader?.IssuerWatchList?.map((item) => {
                                                                return (
                                                                    <th
                                                                        scope="col"
                                                                        className="th group px-4 py-2 text-left text-xs font-bold text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                    >
                                                                        {item}
                                                                    </th>
                                                                );
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {range(10).map((range, i) => (
                                                            <tr className="animate-pulse" key={i}>
                                                                {loadingTableheader?.IssuerWatchList?.map((item) => {
                                                                    return (
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isEmpty(GetWatchListData?.data?.issuerWatchList?.items) && GetWatchListData?.isError && (
                                <div className="flex flex-col">
                                    <div className="-my-2 overflow-x-auto">
                                        <div className="py-2 align-middle inline-block min-w-full">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <p className="text-center">No Data Found</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <UserContext.Provider
                value={{
                    shareInputValue,
                    setShareInputValue,
                    shareDropDownValue,
                    setShareDropDownValue,
                    isDropdownVisible,
                    setIsDropdownVisible,
                }}
            >
                <Modal
                    showmodal={saveModal}
                    setshowmodal={setSaveModal}
                    title="Delete Issuer Watch List"
                    body={
                        <>
                            Are you sure you want to delete "<b className="break-all">{issuerwatchlistname}</b>"?
                        </>
                    }
                    footer={<DeleteFooter onClick={handleApiCall} setSaveModal={setSaveModal} />}
                    isFooterEnable={true}
                    size={"w-96"}
                />
                <ShareModal
                    title={ISSUERDETAILS.SHARE_ISSUERWATCHLIST}
                    size={"w-[38%] "}
                    showmodal={shareModal}
                    setshowmodal={setShareModal}
                    ApiData={GetUserDetailsData?.isSuccess && GetUserDetailsData}
                    handleShareClick={handleShareClick}
                    setShareModal={setShareModal}
                    onClick={handlePostApi}
                    setUserId={setUserId}
                />
            </UserContext.Provider>
        </>
    );
}

export default IssuerWatchList;
