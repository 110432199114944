import React, { useContext, useEffect } from "react";
import { Tab } from "@headlessui/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { Productportfolio } from "./ProductComponents/Productportfolio";
import { Productsavedsearch } from "./ProductComponents/ProductSavedSearch";
import { Productwatchlist } from "./ProductComponents/ProductWatchList";
import { PRODUCT } from "../../constants/appConstants";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import IssuerList from "../IssuerList";
import ProductList from "./ProductList";
import UserContext from "../../context/modal/Contextmodel";

const Productdashboard = () => {
    const { Permissionapi } = useContext(UserContext);
    const { tab } = useParams();
    const nav = useNavigate();

    const tabcss =
        "whitespace-nowrap px-0 py-4 mx-6 sm:py-0 ui-selected:border-b-primary ui-selected:border-b-2 ui-selected:text-primary ui-not-selected:border-b-2 ui-not-selected:border-b-transparent ui-not-selected:text-black";
    const tabLabel = ["home", "search"];

    useEffect(() => {
        if (!isEmpty(tab) && tabLabel.includes(tab)) {
            // Reset all tabs to unselected state
            tabLabel.forEach((label) => {
                document.getElementById(label).setAttribute("data-headlessui-state", "");
                document.getElementById(label).setAttribute("aria-selected", "false");
            });

            // Set the selected tab to selected state
            document.getElementById(tab).setAttribute("data-headlessui-state", "selected");
            document.getElementById(tab).setAttribute("aria-selected", "true");
        } else {
            // Reset all tabs to unselected state if no tab is selected
            tabLabel.forEach((label) => {
                document.getElementById(label).setAttribute("data-headlessui-state", "");
                document.getElementById(label).setAttribute("aria-selected", "false");
            });
        }
    }, [tab]);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Tab.Group>
                <div className="w-100 flex justify-between sm:px-4  border-b w-full gap-4">
                    <div className=" hidden sm:block">
                        <h1 className="text-2xl font-bold p-2 hidden sm:block">{PRODUCT?.PRODUCT}</h1>
                    </div>
                    <div className="flex overflow-auto">
                        <Tab.List className="flex h-full align-middle overflow-auto">
                            <Tab
                                id="home"
                                className={tabcss}
                                onClick={() => {
                                    nav("/Product/home");
                                    sessionStorage.setItem("currentPath", "/Product/home");
                                    sessionStorage.setItem("masterSearchPath", "/Product/home");
                                }}
                            >
                                Home
                            </Tab>
                            <Tab
                                id="search"
                                className={tabcss}
                                onClick={() => {
                                    nav("/Product/search");
                                    sessionStorage.setItem("currentPath", "/Product/search");
                                    sessionStorage.setItem("masterSearchPath", "/Product/search");
                                }}
                            >
                                Search
                            </Tab>
                        </Tab.List>
                    </div>
                </div>
                <Tab.Panels className="flex-grow overflow-auto">
                    {!isEmpty(tab) && tabLabel.includes(tab) && tab === tabLabel.at(0) && (
                        <div className="flex gap-8 p-4 h-full overflow-hidden">
                            <Productportfolio />
                            <div className="flex w-1/2 flex-col gap-8 h-full overflow-auto">
                                <Productsavedsearch />
                                <div className="flex flex-col grow">
                                    <Productwatchlist />
                                </div>
                            </div>
                        </div>
                    )}
                    {!isEmpty(tab) && tabLabel.includes(tab) && tab === tabLabel.at(1) && <ProductList />}
                </Tab.Panels>
            </Tab.Group>
        </>
    );
};
export default Productdashboard;
