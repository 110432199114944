import { Tab } from "@headlessui/react";
import "react-toastify/dist/ReactToastify.css";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import comingSoon from "../../assets/images/coming-soon-a.svg";
import IssuerList from "../IssuerList";
import { useGetSavedSearchQuery, useGetWatchListQuery } from "../../Services/Issuer/Home/IssuerHomeApi";
import { isEmpty } from "lodash";
import { Eliminate } from "./IssuerComponents/Eliminate";
import { Savedsearch } from "./IssuerComponents/Savedsearch";
import { Watchlist } from "./IssuerComponents/Watchlist";
import UserReview from "../UserReview/UserReview";
import SystemReview from "../SystemReview/SystemReview";
import UserContext from "../../context/modal/Contextmodel";

const Dashboard = () => {
    const { Permissionapi } = useContext(UserContext);
    const permissionArray = Permissionapi?.split(', ');
    const { tab } = useParams();
    const tabLabel = ["home", "search", "user-review", "system-review"];
    const tabcss =
        "whitespace-nowrap px-0 py-4 mx-6 sm:py-0 ui-selected:border-b-primary ui-selected:border-b-2 ui-selected:text-primary ui-not-selected:border-b-2 ui-not-selected:border-b-transparent ui-not-selected:text-black";

    const nav = useNavigate();

    const handleClick = () => {
        const path = window.location.pathname; // Get the current path dynamically
    };


    // useEffect(() => {
    //     const activeTab = getFirstActiveTab();
    //     if (activeTab) {
    //         nav(`/issuer/${activeTab}`);
    //         sessionStorage.setItem("PATH", activeTab);
    //     }
    // }, [Permissionapi]);
    // const getFirstActiveTab = () => {
    //     if (permissionArray?.includes("Issuer_Search") || permissionArray?.includes("All")) {
    //         return "home";
    //     }
    //     if (permissionArray?.includes("Issuer_UserReview") || permissionArray?.includes("All")) {
    //         return "user-review";
    //     }
    //     if (permissionArray?.includes("Issuer_SystemReview") || permissionArray?.includes("All")) {
    //         return "manage-access-control";
    //     }

    //     return null;
    // };
    //document.getElementById("inputElement3").setAttribute("data-headlessui-state","selected");
    //document.getElementById("inputElement3").setAttribute("aria-selected","true");
    useEffect(() => {
        if (!isEmpty(tab) && tabLabel?.includes(tab)) {
            tabLabel.forEach((label) => {
                const element = document.getElementById(label);
                if (element) {
                    element.setAttribute("data-headlessui-state", "");
                    element.setAttribute("aria-selected", "false");
                }
            });

            const selectedTab = document.getElementById(tab);
            if (selectedTab) {
                selectedTab.setAttribute("data-headlessui-state", "selected");
                selectedTab.setAttribute("aria-selected", "true");
            }
        } else {
            tabLabel.forEach((label) => {
                const element = document.getElementById(label);
                if (element) {
                    element.setAttribute("data-headlessui-state", "");
                    element.setAttribute("aria-selected", "false");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);
    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Tab.Group>
                <div className="w-100 flex justify-between sm:px-4  border-b w-full gap-4">
                    <div className=" hidden sm:block">
                        <h1 className="text-2xl font-bold p-2 hidden sm:block">Issuer</h1>
                    </div>
                    <div className="flex overflow-auto">
                        <Tab.List className="flex h-full align-middle overflow-auto">
                            {permissionArray && permissionArray !== undefined && (permissionArray?.includes("Issuer_Search") || permissionArray?.includes("All")) ? (
                                <>
                                    <Tab
                                        id="home"
                                        className={tabcss}
                                        onClick={() => {
                                            nav("/issuer/home");
                                            handleClick();
                                            sessionStorage.setItem("currentPath","/issuer/home")
                                            sessionStorage.setItem("masterSearchPath", "/issuer/home");
                                        }}
                                    >
                                        Home
                                    </Tab>
                                    <Tab
                                        id="search"
                                        className={tabcss}
                                        onClick={() => {
                                            nav("/issuer/search");
                                            handleClick();
                                            sessionStorage.setItem("currentPath","/issuer/search")
                                            sessionStorage.setItem("masterSearchPath", "/issuer/search");
                                        }}
                                    >
                                        Search
                                    </Tab>
                                </>
                            ) : null}
                            {permissionArray?.includes("Issuer_UserReview") || permissionArray?.includes("All") ? (
                                <Tab
                                    id="user-review"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/issuer/user-review");
                                        handleClick();
                                        sessionStorage.setItem("currentPath","/issuer/user-review")
                                        sessionStorage.setItem("masterSearchPath", "/issuer/user-review");
                                    }}
                                >
                                    User Review
                                </Tab>
                            ) : null}
                            {permissionArray?.includes("Issuer_SystemReview") || permissionArray?.includes("All") ? (
                                <Tab
                                    id="system-review"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/issuer/system-review");
                                        handleClick();
                                        sessionStorage.setItem("currentPath","/issuer/system-review")
                                        sessionStorage.setItem("masterSearchPath", "/issuer/system-review");
                                    }}
                                >
                                    System Review
                                </Tab>
                            ) : null}
                        </Tab.List>
                    </div>
                </div>
                <Tab.Panels className="flex-grow overflow-auto">
                    {Permissionapi?.includes("Issuer_Search") || Permissionapi?.includes("All")
                        ? !isEmpty(tab) &&
                          tabLabel?.includes(tab) &&
                          tab === tabLabel.at(0) && (
                              <div className="grid lg:grid-cols-2 gap-8 p-4 h-full">
                                  <Eliminate />
                                  <div className="flex flex-col gap-8">
                                      <Savedsearch />
                                      <div className="flex flex-col grow">
                                          <Watchlist />
                                      </div>
                                  </div>
                              </div>
                          )
                        : null}
                    {permissionArray?.includes("Issuer_Search") || permissionArray?.includes("All")
                        ? !isEmpty(tab) && tabLabel?.includes(tab) && tab === tabLabel.at(1) && <IssuerList />
                        : null}
                    {permissionArray?.includes("Issuer_UserReview") || permissionArray?.includes("All")
                        ? !isEmpty(tab) &&
                          tabLabel?.includes(tab) &&
                          tab === tabLabel.at(2) && (
                              <div className="flex justify-center h-full items-center">
                                  <UserReview />
                              </div>
                          )
                        : null}
                    {permissionArray?.includes("Issuer_SystemReview") || permissionArray?.includes("All")
                        ? !isEmpty(tab) &&
                          tabLabel?.includes(tab) &&
                          tab === tabLabel.at(3) && (
                              // <SystemReview/>
                              <div className="flex justify-center h-full items-center p-4">
                                  <img src={comingSoon} className="lg:w-2/9 mt-5" />
                              </div>
                          )
                        : null}
                </Tab.Panels>
            </Tab.Group>
        </React.Fragment>
    );
};
export default Dashboard;
