import React, { useContext, useRef, useState } from "react";
import UserContext from "../../context/modal/Contextmodel";

const EditUserReviewModal = () => {
    const divRef = useRef();

    const { canEditState, canApproveState, notes, setNotes, currentStatus, setCurrentStatus, getUserReviewById } = useContext(UserContext);
    const inputClassName = `appearance-none border border-gray-300 rounded-md  py-2 px-2 text-gray-700 leading-tight focus:outline-none h-[110px]`;
    const [editStatusDropdownVisible, setEditStatusDropdownVisible] = useState(false);

    const editStatusRequestDropdown = ["Approved", "Denied", "Pending", "Additional Details Needed", "In Review", "Cancel"];
    // const getISsueSubIssueDropdownData = useGetIssueSubIssueDataGroupingQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setEditStatusDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [divRef]);

    return (
        <>
            <div className="sm:grid sm:grid-cols-2 gap-4">
                <div  >
                    <div className="flex gap-2 sm:grid sm:grid-cols-2">
                        <strong>Issuer Name: </strong>

                        {getUserReviewById?.data?.issuerName?.length > 12 ? (
                            <span className="" title={getUserReviewById?.data?.issuerName}>
                                {getUserReviewById?.data?.issuerName?.slice(0, 17) + "..."}
                            </span>
                        ) : (
                            <span className="">{getUserReviewById?.data?.issuerName}</span>
                        )}
                    </div>
                    <div className="sm:grid sm:grid-cols-2 flex gap-2 mt-4">
                        <p>
                            <strong>Issue:</strong>
                        </p>
                        <span className="" title={getUserReviewById?.data?.issueName}>
                            {getUserReviewById?.data?.issueName
                                ? getUserReviewById?.data?.issueName?.length > 12
                                    ? getUserReviewById?.data?.issueName?.slice(0, 15) + "..."
                                    : getUserReviewById?.data?.issueName
                                : ""}
                        </span>
                    </div>
                </div>
                <div>
                    <p>
                        <strong>Issuer Ticker: </strong>
                        <span className="pl-2">{getUserReviewById?.data?.issuerTicker}</span>
                    </p>
                    <div className="mt-4 flex gap-2">
                        <p>
                            <strong>Sub-Issue:</strong>
                        </p>
                        <span className="sm:ml-[12%]" title={getUserReviewById?.data?.subIssueName}>
                            {getUserReviewById?.data?.subIssueName
                                ? getUserReviewById?.data?.subIssueName?.length > 12
                                    ? getUserReviewById?.data?.subIssueName?.slice(0, 15) + "..."
                                    : getUserReviewById?.data?.subIssueName
                                : ""}
                        </span>
                    </div>
                </div>


                <div className="sm:grid sm:grid-cols-2 flex gap-2">
                    <p>
                        <strong>Original Status:</strong>
                    </p>
                    <span className="">{getUserReviewById?.data?.requesStatus ? getUserReviewById?.data?.requesStatus : "-"}</span>
                </div>
            </div>
            <div className="mt-4">
                {
                    <>
                        <strong>Review Status:</strong>
                        <div className="relative mt-1">
                            {/* {!canEditState && (
                    <input
                        className="block cursor-not-allowed w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={currentStatus}
                        readOnly
                    />
                )} */}

                            <input
                                className="block cursor-pointer w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                id="username"
                                type="text"
                                value={currentStatus}
                                ref={divRef}
                                // onChange={(e) => setCurrentStatus(e.target.value)}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent event propagation
                                    setEditStatusDropdownVisible(!editStatusDropdownVisible);
                                }}
                                style={{
                                    backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                                    backgroundPosition: "100% 50%",
                                    backgroundRepeat: "no-repeat",
                                }}
                            />

                            {editStatusDropdownVisible && (
                                <div ref={divRef} className="absolute left-0 w-full flex flex-col rounded mt-1 max-h-80 md:max-h-none h-40">
                                    <div className="py-4 pl-1.5 bg-white border shadow rounded justify-between border-b last:border-b-0 flex overflow-auto">
                                        <div className="flex text-dark flex-col gap-1 w-full">
                                            {editStatusRequestDropdown ? (
                                                editStatusRequestDropdown?.map((item) => (
                                                    <h3
                                                        key={item}
                                                        onClick={() => {
                                                            setCurrentStatus(item); // Update the currentStatus state
                                                            setEditStatusDropdownVisible(false); // Close the dropdown
                                                        }}
                                                        className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                    >
                                                        {item}
                                                    </h3>
                                                ))
                                            ) : (
                                                <h3
                                                    ref={divRef}
                                                    onClick={() => setEditStatusDropdownVisible(false)}
                                                    className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                >
                                                    <b>No Data Found</b>
                                                </h3>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                }
            </div>
            <div className="mt-4">
                <strong>Note</strong>
                <textarea
                    className={`${inputClassName} h-[97px] w-full mt-2`}
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    style={{ resize: "none" }}
                // id={share ? undefined : "username"}
                />
                {/* </div> */}
                {/* {tab === "editAttachments" && (
                        <div class="grid grid-cols-1 gap-4">
                            <div className="border-2 border-dashed mt-3 mb-3 overflow-auto static-scroll p-0" style={{ height: "295px", overflowY: "scroll" }}>
                                <UserReviewFileUpload />
                            </div>
                        </div>
                    )} */}
            </div>
        </>
    );
};

export default EditUserReviewModal;
