import { interceptorsApiCall } from "../../../globals/interceptors";

export const GetUserReviewListApi = interceptorsApiCall.injectEndpoints({
    endpoints: (builder) => ({
        GetAllUserReviewList: builder.query({
            query: () => "api/UserReview/GetUserReviewList",
        }),
        GetUserReviewDocuments: builder.query({
            query: (params) => `/api/UserReview/GetUserReviewDocuments?${params}`,
            providesTags: ["GetUserReviewById", "UpdateDocumentcomment"],
        }),

        deleteUserReviewDocument: builder.mutation({
            query: (params) => ({
                url: `/api/UserReview/DeleteUserReviewDocument?${params}`,
                method: "DELETE",
            }),
            invalidatesTags: ["GetUserReviewById"],
        }),
        GetUserReviewDocumentDownloadLink: builder.query({
            query: (params) => `/api/UserReview/GetUserReviewDocumentDownloadLink?${params}`,
        }),
        updateUserReview: builder.mutation({
            query: (payload) => ({
                url: "/api/UserReview/updateUserReview",
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["GetUserReview"],
        }),
        GetUserReviewById: builder.query({
            query: (params) => `/api/UserReview/GetUserReviewById?${params}`,
            providesTags: ["GetUserReviewById"],
        }),
        postCreateUserReview: builder.mutation({
            query: (payload) => ({
                url: "/api/UserReview/CreateUserReview",
                method: "POST",
                body: payload,
            }),
        }),
        getIssueSubIssueDataGrouping: builder.query({
            query: (params) => `/api/Issuer/GetIssueSubIssueDataGrouping?${params}`,
        }),
        PostUserReviewDocument: builder.mutation({
            query: (payload) => ({
                url: "/api/UserReview/AddUserReviewDocument",
                method: "POST",
                body: payload,
            }),
        }),
        postUserReviewList: builder.mutation({
            query: (payload) => {
                return {
                    url: "api/UserReview/AdvancedUserReviewSearch",
                    method: "POST",
                    body: payload,
                };
            },
            providesTags: ["GetUserReview"],
        }),
        UpdateUserReviewDocumentComment: builder.mutation({
            query: (payload) => {
                return {
                    url: "/api/UserReview/UpdateUserReviewDocumentComment",
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: ["UpdateDocumentcomment"],
        }),
    }),
});

export const {
    useGetAllUserReviewListQuery,
    useGetUserReviewDocumentsQuery,
    useGetUserReviewByIdQuery,
    useDeleteUserReviewDocumentMutation,
    useGetUserReviewDocumentDownloadLinkQuery,
    useUpdateUserReviewMutation,
    usePostCreateUserReviewMutation,
    usePostUserReviewDocumentMutation,
    useUpdateUserReviewDocumentCommentMutation,
    useGetIssueSubIssueDataGroupingQuery,
    usePostUserReviewListMutation,
} = GetUserReviewListApi;
