import React, { useContext, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import { PAGECONSTANTS } from "../../constants/Common";
import { ADMIN } from "../../constants/appConstants";
import ViewUserRoleReport from "./ViewUserRoleReport/ViewUserRoleReport";
import ManageUserRoles from "./ManageUsers/ManageUserRoles";
import ManageAccessControl from "./ManageAcessControl/ManageAccessControl";
import "react-toastify/dist/ReactToastify.css";
import Roles from "./Roles/Roles";
import ViewAccessControl from "./ViewAccessControlReport/ViewAccessControlReport";
import UserContext from "../../context/modal/Contextmodel";

const Admin = () => {
    const { Permissionapi } = useContext(UserContext);
    const { tab } = useParams();
    const tabLabel = ["users", "roles", "manage-access-control", "view-access-control-report", "view-user-role-report"];
    const tabcss =
        "whitespace-nowrap px-0 py-4 mx-6 sm:py-0 ui-selected:border-b-primary ui-selected:border-b-2 ui-selected:text-primary ui-not-selected:border-b-2 ui-not-selected:border-b-transparent ui-not-selected:text-black";

    const nav = useNavigate();
    useEffect(() => {
        if (!isEmpty(tab) && tabLabel.includes(tab)) {
            tabLabel.forEach((label) => {
                const element = document.getElementById(label);
                if (element) {
                    element.setAttribute("data-headlessui-state", "");
                    element.setAttribute("aria-selected", "false");
                }
            });

            const selectedTab = document.getElementById(tab);
            if (selectedTab) {
                selectedTab.setAttribute("data-headlessui-state", "selected");
                selectedTab.setAttribute("aria-selected", "true");
            }
        } else {
            tabLabel.forEach((label) => {
                const element = document.getElementById(label);
                if (element) {
                    element.setAttribute("data-headlessui-state", "");
                    element.setAttribute("aria-selected", "false");
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    

    useEffect(() => {
        const activeTab = getFirstActiveTab();
        if (activeTab) {
            nav(`/admin/${activeTab}`);
            sessionStorage.setItem("PATH", activeTab);
        }
    }, [Permissionapi]);

    const getFirstActiveTab = () => {
        if (Permissionapi?.includes("Admin_ManageUsers") || Permissionapi?.includes("All") || Permissionapi?.includes("Admin_ViewUsers")) {
            return "users";
        }
        if (Permissionapi?.includes("Admin_ManageRoles") || Permissionapi?.includes("All") || Permissionapi?.includes("Admin_ViewRoles")) {
            return "roles";
        }
        if (
            Permissionapi?.includes("Admin_ManageRoleAccessControl") ||
            Permissionapi?.includes("All") ||
            Permissionapi?.includes("Admin_ViewManageRoleAccessControl")
        ) {
            return "manage-access-control";
        }
        if (Permissionapi?.includes("Admin_ViewRoleAccessControl") || Permissionapi?.includes("All")) {
            return "view-access-control-report";
        }
        if (Permissionapi?.includes("Admin_ViewUserRoleMatrix") || Permissionapi?.includes("All")) {
            return "view-user-role-report";
        }
        return null;
    };

    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Tab.Group>
                <div className="w-100 flex justify-between sm:px-4  border-b w-full gap-4 mb-4 static-scroll">
                    <div className=" hidden sm:block">
                        <h1 className="text-2xl font-bold p-2 hidden sm:block">{PAGECONSTANTS?.ADMIN}</h1>
                    </div>
                    <div className="flex overflow-auto">
                        <Tab.List className="flex h-full align-middle overflow-auto">
                            {Permissionapi?.includes("Admin_ManageUsers") || Permissionapi?.includes("All") || Permissionapi?.includes("Admin_ViewUsers") ? (
                                <Tab
                                    id="users"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/admin/users");
                                        sessionStorage.setItem("currentPath", "/admin/users");
                                        sessionStorage.setItem("masterSearchPath", "/admin/users");
                                    }}
                                >
                                    <span>
                                        {Permissionapi?.includes("Admin_ManageUsers") || Permissionapi?.includes("All") ? ADMIN?.USERS : ADMIN?.VIEWUSERS}
                                    </span>
                                </Tab>
                            ) : null}
                            {Permissionapi?.includes("Admin_ManageRoles") || Permissionapi?.includes("All") || Permissionapi?.includes("Admin_ViewRoles") ? (
                                <Tab
                                    id="roles"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/admin/roles");
                                        sessionStorage.setItem("currentPath", "/admin/roles");
                                        sessionStorage.setItem("masterSearchPath", "/admin/roles");
                                    }}
                                >
                                    <span>
                                        {Permissionapi?.includes("Admin_ManageRoles") || Permissionapi?.includes("All") ? ADMIN?.ROLES : ADMIN?.VIEWROLES}
                                    </span>
                                </Tab>
                            ) : null}
                            {Permissionapi?.includes("Admin_ManageRoleAccessControl") ||
                            Permissionapi?.includes("All") ||
                            Permissionapi?.includes("Admin_ViewManageRoleAccessControl") ? (
                                <Tab
                                    id="manage-access-control"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/admin/manage-access-control");
                                        sessionStorage.setItem("currentPath", "/admin/manage-access-control");
                                        sessionStorage.setItem("masterSearchPath", "/admin/manage-access-control");
                                    }}
                                >
                                    <span>
                                        {Permissionapi?.includes("Admin_ManageRoleAccessControl") || Permissionapi?.includes("All")
                                            ? ADMIN?.MANAGEACCESS
                                            : ADMIN?.VIEWMANAGEACCESS}
                                    </span>
                                </Tab>
                            ) : null}
                            {Permissionapi?.includes("Admin_ViewRoleAccessControl") || Permissionapi?.includes("All") ? (
                                <Tab
                                    id="view-access-control-report"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/admin/view-access-control-report");
                                        sessionStorage.setItem("currentPath", "/admin/view-access-control-report");
                                        sessionStorage.setItem("masterSearchPath", "/admin/view-access-control-report");
                                    }}
                                >
                                    <span>{ADMIN?.VIEWACCESS}</span>
                                </Tab>
                            ) : null}
                            {Permissionapi?.includes("Admin_ViewUserRoleMatrix") || Permissionapi?.includes("All") ? (
                                <Tab
                                    id="view-user-role-report"
                                    className={tabcss}
                                    onClick={() => {
                                        nav("/admin/view-user-role-report");
                                        sessionStorage.setItem("currentPath", "/admin/view-user-role-report");
                                        sessionStorage.setItem("masterSearchPath", "/admin/view-user-role-report");
                                    }}
                                >
                                    <span>{ADMIN?.VIEWUSER}</span>
                                </Tab>
                            ) : null}
                        </Tab.List>
                    </div>
                </div>
                <Tab.Panels className="flex-grow overflow-auto m-4">
                    {Permissionapi?.includes("Admin_ManageUsers") || Permissionapi?.includes("All") || Permissionapi?.includes("Admin_ViewUsers")
                        ? !isEmpty(tab) &&
                          tabLabel.includes(tab) &&
                          tab === tabLabel[0] && <ManageUserRoles canEdit={Permissionapi?.includes("Admin_ManageUsers") || Permissionapi?.includes("All")} />
                        : null}
                    {Permissionapi?.includes("Admin_ManageRoles") || Permissionapi?.includes("All") || Permissionapi?.includes("Admin_ViewRoles")
                        ? !isEmpty(tab) &&
                          tabLabel.includes(tab) &&
                          tab === tabLabel[1] && <Roles canEdit={Permissionapi?.includes("Admin_ManageRoles") || Permissionapi?.includes("All")} />
                        : null}
                    {Permissionapi?.includes("Admin_ManageRoleAccessControl") ||
                    Permissionapi?.includes("All") ||
                    Permissionapi?.includes("Admin_ViewManageRoleAccessControl")
                        ? !isEmpty(tab) &&
                          tabLabel.includes(tab) &&
                          tab === tabLabel[2] && (
                              <ManageAccessControl
                                  canEdit={Permissionapi?.includes("Admin_ManageRoleAccessControl") || Permissionapi?.includes("All")}
                                  tab={tab}
                              />
                          )
                        : null}
                    {Permissionapi?.includes("Admin_ViewRoleAccessControl") || Permissionapi?.includes("All")
                        ? !isEmpty(tab) && tabLabel.includes(tab) && tab === tabLabel[3] && <ViewAccessControl />
                        : null}
                    {Permissionapi?.includes("Admin_ViewUserRoleMatrix") || Permissionapi?.includes("All")
                        ? !isEmpty(tab) && tabLabel.includes(tab) && tab === tabLabel[4] && <ViewUserRoleReport />
                        : null}
                </Tab.Panels>
            </Tab.Group>
        </React.Fragment>
    );
};

export default Admin;
