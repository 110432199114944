import React from "react";
import { ISSUER } from "../../../constants/appConstants";
import comingSoon from "../../../assets/images/coming-soon-a.svg";

export const Eliminate = () => {
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="flex justify-between align-middle mb-4">
          <h3 className="text-xl">{ISSUER.ELIMINATE}</h3>
        </div>
        <div className="border p-4 text-center flex justify-center rounded flex-grow">
          <img src={comingSoon} alt="Commingsoon" className="lg:w-2/6" />
          {/* <Table data={Eliminatedata} onclick={iconClick} />  for future use*/}
        </div>
      </div>
    </div>
  );
};
