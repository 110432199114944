import React from "react";
import { ISSUERDETAILS } from "../../constants/appConstants";

const Sharebody = ({ edit, setEdit, Bodytitle, share, notelist }) => {
  const inputClassName = `appearance-none border border-gray-300 rounded-md w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none h-[110px]`;
  return (
    <>
    <div className="h-[130px] mb-5">
      <p className="font-md">{Bodytitle}</p>
      <textarea
        className={inputClassName}
        type="text"
        value={share ? notelist : edit}
        onChange={(e) => setEdit(e.target.value)}
        disabled={share}
        defaultValue={ISSUERDETAILS?.INPUTVALUE}
        id={share ? undefined : "username"}
        style={{ resize: "none" }}
      />
      </div>
    </>
  );
};

export default Sharebody;
