import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState, useContext } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import UserContext from "../../context/modal/Contextmodel";

const UserReviewFileUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [fileName, setFileName] = useState("");
    const [dragOver, setDragOver] = useState(false);
    const [fileSelectionType, setFileSelectionType] = useState(false);
    const [documentDescription, setDocumentDescription] = useState("");
    const { setAttachfile,issueruseridstate,setfileuploaded} = useContext(UserContext);
    const handleFileChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files);
        const fileNames = fileArray.map((file) => file.name);
        setFileName(fileNames.join(", "));
        setSelectedFiles(event.target.files);

        if (!files) {
            setErrorMessage("No file selected. Please choose a file to upload.");
            setSelectedFiles([]);
        }

        setFileSelectionType(true);
    };

    useEffect(() => {
        if (selectedFiles?.length > 1) {
            setErrorMessage("You cannot upload multiple files.");
            setFileSelectionType(false);
            setSelectedFiles([]);
        }
    }, [selectedFiles]);
    useEffect(() => {
        if(issueruseridstate)
        {
            const formData = new FormData();
            formData.append("UserReviewId", issueruseridstate);
            formData.append("FileModelDTO.FileName", fileName);
            formData.append("FileModelDTO.File", selectedFiles[0]);
            formData.append("FileModelDTO.Comment", documentDescription);
            formData.append("FileModelDTO.IsPrivate", "false");
            setAttachfile(formData);
            setfileuploaded(true)
        }
        
    }, [fileName, selectedFiles, documentDescription,issueruseridstate]);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);
        const files = event.dataTransfer.files;
        const fileArray = Array.from(files);

        const fileNames = fileArray.map((file) => file.name);
        setSelectedFiles([...selectedFiles, ...fileNames]);

        setFileSelectionType(true);
    };

    const handleGoBack = () => {
        setFileSelectionType(false);
        setFileName("");
        setSelectedFiles([]);
        setDocumentDescription("");
        errorMessage && setErrorMessage("");
    };

    return (
        <>
            {!fileSelectionType ? (
                <div
                    className={`justify-center items-center flex flex-col p-5 ${dragOver ? "bg-gray-200" : ""}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <label>
                        <ArrowUpTrayIcon className="w-10" />
                    </label>
                    <h3>Select or Drag and Drop here to upload a new file!</h3>
                    <h5 className="text-gray-500">[.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, .xml, .zip, .txt]</h5>
                    <h5 className="mt-3">OR</h5>
                    <label className="rounded bg-primary text-white mt-3 px-5 py-2 cursor-pointer">
                        <input className="w-0 opacity-0" type="file" accept="*" onChange={handleFileChange} />
                        Browse
                    </label>

                    {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
                </div>
            ) : (
                <>
                    <div className="flex justify-between items-center p-2" style={{ marginTop: "4px" }}>
                        <h3>Document Name(s)</h3>
                        <button className="text-primary">
                            <ChevronLeftIcon className="w-6 h-6" onClick={handleGoBack} />
                        </button>
                    </div>
                    <input
                        className="border-0 ml-2 focus:ring-2 rounded-md focus:ring-transparent focus:outline-none appearance-none w-[94%] text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 text-dark"
                        type="text"
                        style={{ backgroundColor: "rgb(243,244,246)" }}
                        value={fileName}
                        readOnly
                    />
                    <div className="flex flex-col p-2">
                        <h3 className="mt-1">Add Description (optional)</h3>
                        <textarea
                             maxLength="499"
                            className="border-1 mt-1 focus:ring-2 rounded-md focus:ring-transparent focus:outline-none appearance-none w-[96%] text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 text-dark h-[70px]"
                            type="text"
                            value={documentDescription}
                            onChange={(e) => setDocumentDescription(e.target.value)}
                            style={{ resize: "none" }}
                        />
                        <label className="relative block mt-2">
                            <span
                                className="absolute cursor-pointer inset-y-0 left-0 flex items-center p-2 bg-primary"
                                style={{ borderEndStartRadius: "5px", borderStartStartRadius: "5px" }}
                            >
                                <svg
                                    className="h-5 w-5 fill-slate-300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="white"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                            <label className="block bg-white w-[95%] border border-slate-300 cursor-pointer rounded-md py-2 ml-2 pl-9 pr-3 shadow-sm sm:text-sm">
                                <strong>Public</strong> - Any one can see this document.
                            </label>
                        </label>
                        <label className="relative block mt-2 opacity-50">
                            <span
                                className="absolute cursor-not-allowed inset-y-0 left-0 flex items-center p-2 bg-primary"
                                style={{ borderEndStartRadius: "5px", borderStartStartRadius: "5px" }}
                            >
                                <svg
                                    className="h-5 w-5 fill-slate-300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="white"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                    />
                                </svg>
                            </span>
                            <label className="block bg-white cursor-pointer w-[95%] border border-slate-300 rounded-md py-2 ml-2 pl-9 pr-3 shadow-sm sm:text-sm">
                                <strong>Private</strong> - Only you can see this document.
                            </label>
                        </label>
                    </div>
                </>
            )}
        </>
    );
};

export default UserReviewFileUpload;
