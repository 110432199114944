import { ChevronRightIcon, XCircleIcon, MagnifyingGlassIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { has, isArray, isEmpty, isEqual, isNull, range, sortBy } from "lodash";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import EditUserReviewModal from "./EditUserReviewModal";
import EditUserReviewModalFooter from "./EditUserReviewModalFooter";
import UserReviewDocumentModalFooter from "./UserReviewDocuments/UserReviewDocumentModalFooter";
import UserReviewDocumentModal from "./UserReviewDocuments/UserReviewDocumentModal";
import EditView from "../EditView";
import EditViewFooter from "../EditViewFooter";
import Modal from "../../components/Modals/Modals";
import UserContext from "../../context/modal/Contextmodel";
import { PAGECONSTANTS, getKeyValuePair, loadingTableheader, showToastify } from "../../constants/Common";
import Badge from "../../components/Badge/Badge";
import { ISSUERLIST, PRODUCTLIST, USER_REVIEW } from "../../constants/appConstants";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import TailwindTable from "../../components/Table/TailwindTable";
import { useGetUserReviewByIdQuery, usePostUserReviewListMutation, useUpdateUserReviewMutation } from "../../Services/Issuer/UserReview/UserReviewApi";
import { queryString } from "../../helpers";
import { useGetEditViewByIdQuery, useGetEditViewSaveQuery } from "../../Services/Issuer/Search/IssuerSearchApi";
import { useGetIssuerReturnFieldQuery } from "../../Services/Issuer/Home/IssuerHomeApi";

const UserReview = () => {
    const [filterOpen, setFilterOpen] = useState(false);
    const getCheckedBadges = localStorage.getItem(USER_REVIEW.USERBADGEVALUES);
    const checkValues = localStorage.getItem(USER_REVIEW.USERFILTER);
    const checkSort = localStorage.getItem(USER_REVIEW.USERSORTING);
    const checkSortHeader = localStorage.getItem(USER_REVIEW.USERSORTINGHEADER);
    const getSearchValue = localStorage.getItem(USER_REVIEW.USERSEARCHTEXT);
    const userId = sessionStorage.getItem("editUserReviewId");
        const getEditViewIdLocal = localStorage.getItem(USER_REVIEW.USERREVIEWEDITVIEWID);
    const getExisitingViewLocal = localStorage.getItem(USER_REVIEW.USERREVIEWEXISTINGVIEW);
    const retString = localStorage.getItem(USER_REVIEW.USERREVIEWCOLUMNFILTER);
    const getReturnColumnLocal = localStorage.getItem(USER_REVIEW.USERREVIEWSAVEDRETURNCOLUMN);
    const retArray = JSON.parse(retString);
    const applyButtonValue = localStorage.getItem("ApplyButton");
    const [filterApplyBtn, setFilterApplyBtn] = useState(isNull(applyButtonValue) ? false : applyButtonValue);
    const [editViewMenu, setEditViewMenu] = useState(isNull(getExisitingViewLocal) ? "" : getExisitingViewLocal);
    const [editViewMenuId, setEditViewMenuId] = useState(getEditViewIdLocal === "undefined" ? "" : getEditViewIdLocal);
    const pageArray = [50, 75, 100, 125, 150];
    const [finalCheckedBadgeValues, setFinalCheckedBadgeValues] = useState(JSON.parse(getCheckedBadges));
    const parsedValues = checkValues ? JSON.parse(checkValues) : [];
    const [commentapicall, setCommentapicall] = useState(false);
    const [checkedValues, setCheckedValues] = useState(parsedValues);
    const [sortColumnName, setSortColumnName] = useState(checkSortHeader);
    const [sortOrder, setSortOrder] = useState(checkSort);
    const [tempSelectedFields, setTempSelectedFields] = useState(isNull(getReturnColumnLocal) ? [] : JSON.parse(getReturnColumnLocal));
        const [count, setCount] = useState(1);
    const [userEditView, setUserEditView] = useState(false);
    const [showState, setShowState] = useState({}); //facet display name open close state
    const [pageNumber, setPageNumber] = useState(50);
    const [checked, setChecked] = useState([]);
    const [checkedValueBadges, setCheckedValueBadges] = useState(isNull(JSON.parse(getCheckedBadges)) ? [] : JSON.parse(getCheckedBadges));
    const [searchTextTyped, setSearchTextTyped] = useState("");
    const [documentModalView, setDocumentModalView] = useState(false);
    const [data, setData] = useState([]);
    const [selectedSubIssue, setSelectedSubIssue] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");
    const [currentStatus, setCurrentStatus] = useState("");
    const [notes, setNotes] = useState("");
    const [triggerUserReviewUpdate, setTriggerUserReviewUpdate] = useState(false);
    const [updatedFacets, setUpdatedFacets] = useState([]);
    const [searchInput, setSearchInput] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [checkedState, setCheckedState] = useState(0);
    const [canEditState, setCanEditState] = useState(false);
    const [canApproveState, setCanApproveState] = useState(false);
    const [editView, setEditView] = useState(false);
    const [checkedFields, setCheckedFields] = useState([]);
    const [editViewInput, setEditViewInput] = useState("");
    const [checkedItems, setCheckedItems] = useState({});
    const [ViewFields, setViewFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [editViewReturnValue, setEditViewReturnValue] = useState([]);
    const [staticViewFields, setStaticViewFields] = useState([]);
    const [exisitngViewColumn, setExisitingViewColumn] = useState([]);
    const initialValues = {
        searchText: isNull(getSearchValue) ? "*" : getSearchValue,
        useDefaultFacets: true,
        sortOrderfilters: null,
        fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
        fieldNamesToSearch: [],
        facetOptions: null,
        facetFilters: checkedValues,
        searchIndexName: "string",
        currentPage: 1,
        pageSize: pageNumber,
    };
    const [checkBoxFilter, setCheckBoxFilter] = useState({ ...initialValues });
    const [userReviewList, userReviewListData] = usePostUserReviewListMutation();
    const [updateUserReview, updatedUserReviewData] = useUpdateUserReviewMutation();
    const getUserReviewById = useGetUserReviewByIdQuery(queryString({ params: { Id: userId } }), { skip: !userId || userId === undefined || userId === null });
    const IssuerReturnName = useGetIssuerReturnFieldQuery(queryString({ params: { searchIndexName: "userreviews" } }));
    const getEditViewSave = useGetEditViewSaveQuery(queryString({ params: { ModuleName: "UserReview" } }));
    const EditViewData = useGetEditViewByIdQuery(queryString({ params: { Id: editViewMenuId } }), {
        skip: !editViewMenuId || !isNull(retArray),
    });
    const handleEditView = (e) => {
        e.preventDefault();
        setEditView(true);
    };
    const sortAPI = () => {
        setFinalCheckedBadgeValues(checkedValueBadges);
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(USER_REVIEW.USERFILTER, myArrayString);
        localStorage.setItem(USER_REVIEW.USERBADGEVALUES, JSON.stringify(checkedValueBadges));
        localStorage.setItem(USER_REVIEW.USERSEARCHTEXT, searchText);
        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
        if (tempSelectedFields?.length > 0) {
            tempSelectedFields.push("Id");
        }
        userReviewList({
            ...checkBoxFilter,
            searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
            currentPage: count,
            pageSize: pageNumber,
            fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            facetFilters: checkedValues,
        });
    };
    useEffect(()=>{
        if (tempSelectedFields?.length > 0) {
            tempSelectedFields.push("Id");
        }
    },[tempSelectedFields])
    useEffect(() => {
        if (commentapicall) {
            if (tempSelectedFields?.length > 0) {
                tempSelectedFields.push("Id");
            }
            const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
            userReviewList({
                ...checkBoxFilter,
                searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                fieldNamesToSearch: [],
                facetOptions: null,
                facetFilters: checkedValues,
                searchIndexName: "string",
                currentPage: 1,
                pageSize: pageNumber,
            });
            setCommentapicall(false);
        }
    }, [commentapicall]);

    const commonElements = ViewFields.filter((element) => tempSelectedFields.includes(element));
    const columns = commonElements?.map((key, id) => {
        // Modify the column header for the key "EliminateStatus"
        if (key === "EliminateStatus") {
            return {
                Header: "Status", // Change the column header
                accessor: "document." + key,
            };
        }
        if (key === "AttachmentCount") {
            return {
                Header: "  ", // Change the column header
                accessor: "document." + key,
            };
        }
        if (key === "IssueName") {
            return {
                Header: "Eliminate Issue",
                accessor: "document." + key,
            };
        }
        if (key === "SubIssueName") {
            return {
                Header: "Eliminate Sub -Issue",
                accessor: "document." + key,
            };
        }
        if (key !== "undefined") {
            if (key === "ISIN") {
                return {
                    Header: key,
                    accessor: "document." + key,
                    Cell: (e) => (
                        <Link className=" items-center text-primary text-sm" to="/issuerdetails" state={{ data: e.value }}>
                            {e.value}
                        </Link>
                    ),
                };
            } else {
                return {
                    Header: key,
                    accessor: "document." + key,
                };
            }
        }
    });
    if (staticViewFields?.length > 0) {
        columns.unshift({
            Header: " ",
        });
    }
    const toggleShowState = (displayName) => {
        setShowState((prevState) => ({
            ...prevState,
            [displayName]: !prevState[displayName],
        }));
    };
    const handleCheckChange = (e, facetDisplayName) => {
        const value = e.target.id;
        const name = e.target.value;
        setFilterApplyBtn(false);
        localStorage.setItem("ApplyButton", false);
        if (!e.target.checked) {
            setCheckedValueBadges((prevBadges) => prevBadges.filter((badge) => badge !== name));
        } else if (!checkedValueBadges.includes(name)) {
            setCheckedValueBadges((prevBadges) => [...prevBadges, name]);
        }

        setCheckedValues((prevValues) => {
            const facetIndex = prevValues.findIndex((facet) => facet.facetName === facetDisplayName);
            const prevFacetValues = facetIndex !== -1 ? prevValues[facetIndex].values : [];
            if (prevFacetValues.includes(value)) {
                // Uncheck
                const updatedValues = prevFacetValues.filter((v) => v !== value);
                if (updatedValues.length === 0) {
                    return prevValues.filter((facet) => facet.facetName !== facetDisplayName);
                } else {
                    return prevValues.map((facet, i) => (i === facetIndex ? { ...facet, values: updatedValues } : facet));
                }
            } else {
                // Check
                const updatedValues = [...prevFacetValues, value];
                const updatedFacet = {
                    facetName: facetDisplayName,
                    values: updatedValues,
                };
                if (facetIndex !== -1) {
                    return prevValues.map((facet, i) => (i === facetIndex ? updatedFacet : facet));
                } else {
                    return [...prevValues, updatedFacet];
                }
            }
        });
    };
    const handleApplyClick = async () => {
        setFilterApplyBtn(true);
        localStorage.setItem("ApplyButton", true);
        setFinalCheckedBadgeValues(checkedValueBadges);
        localStorage.setItem(USER_REVIEW.USERBADGEVALUES, JSON.stringify(checkedValueBadges));
        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
        checkedValues?.length === 0 && setShowState(false);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setCheckBoxFilter((prevFilter) => ({
            ...prevFilter,
            facetFilters: checkedValues,
        }));
        const myArrayString = JSON.stringify(checkedValues);
        localStorage.setItem(USER_REVIEW.USERFILTER, myArrayString);
        if (tempSelectedFields.length > 0) {
            tempSelectedFields.push("Id");
        }
        userReviewList({
            ...checkBoxFilter,
            searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
            fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            currentPage: 1,
            pageSize: pageNumber,
            facetFilters: checkedValues,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
    };
    const handleResetClick = async () => {
        localStorage.removeItem(USER_REVIEW.USERFILTER);
        localStorage.removeItem(USER_REVIEW.USERSEARCHTEXT);
        localStorage.removeItem(USER_REVIEW.USERBADGEVALUES);
        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
        if (tempSelectedFields?.length > 0) {
            tempSelectedFields.push("Id");
        }
        setCheckedValues([]);
        setFinalCheckedBadgeValues([]);
        setCheckedValueBadges([]);
        setSearchTextTyped("");
        setShowState(false);
        setCheckBoxFilter({ ...initialValues });
        userReviewList({
            ...checkBoxFilter,
            searchText: "*",
            fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            currentPage: 1,
            facetFilters: [],
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
    };
    const OnChangeHandler = (e) => {
        let InputValue = e.target.value;
        setSearchTextTyped(e.target.value);
        setSearchText(InputValue);
    };
    const handleSearchIconClick = () => {
        if (searchText.length > 0 && searchTextTyped.length > 0) {
            setSearchTextTyped(searchText);
            localStorage.setItem(USER_REVIEW?.USERSEARCHTEXT, searchText);
            const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
            if (tempSelectedFields?.length > 0) {
                tempSelectedFields.push("Id");
            }
            userReviewList({
                ...checkBoxFilter,
                searchText: searchTextTyped,
                fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                currentPage: count,
                facetFilters: checkedValues,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            });
        } else if (searchText?.length === 0) {
            showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
            setSearchText("");
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (e.target.value.length > 0) {
                setSearchTextTyped(searchText);
                localStorage.setItem(USER_REVIEW?.USERSEARCHTEXT, searchText);
                const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
                if (tempSelectedFields?.length > 0) {
                    tempSelectedFields.push("Id");
                }
                userReviewList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped,
                    fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                    currentPage: count,
                    facetFilters: checkedValues,
                    pageSize: pageNumber,
                    sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                });
            } else if (e.target.value.length === 0) {
                showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
                setSearchText("");
            }
        }
    };
    const handleCheckedBadgeRemove = (index, value) => {
        setCheckedState(checkedState + 1);
        setFinalCheckedBadgeValues(checkedValueBadges);
        setShowState(false);
        setFinalCheckedBadgeValues((prevBadges) => {
            return prevBadges.filter((badge) => badge !== value);
        });
        setCheckedValueBadges((prevBadges) => {
            return prevBadges.filter((badge) => badge !== value);
        });

        setCheckedValues((prevValues) =>
            prevValues
                .map((facet) => {
                    const values = facet.values.filter((v) => v !== value);
                    return { ...facet, values };
                })
                .filter((facet) => facet.values.length > 0)
        );
        const updatedProductBadgeValues = finalCheckedBadgeValues.filter((badge) => badge !== value);
        const updatedProductFilter = checkedValues
            .map((facet) => {
                const values = facet.values.filter((v) => v !== value);
                return { ...facet, values };
            })
            .filter((facet) => facet.values.length > 0);

        // Update local storage with the updated values
        localStorage.setItem(USER_REVIEW.USERBADGEVALUES, JSON.stringify(updatedProductBadgeValues));
        localStorage.setItem(USER_REVIEW.USERFILTER, JSON.stringify(updatedProductFilter));
    };
    const handleBadgeRemove = (values) => {
        setSearchTextTyped("");
        setSearchText("");

        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
        if (tempSelectedFields?.length > 0) {
            tempSelectedFields.push("Id");
        }
        userReviewList({
            ...checkBoxFilter,
            searchText: searchText,
            fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            currentPage: count,
            facetFilters: checkedValues,
            pageSize: pageNumber,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
        setCheckedValues((prevValues) =>
            prevValues.map((facet) =>
                facet.values.includes(values)
                    ? {
                          ...facet,
                          values: facet.values.filter((v) => v !== values),
                      }
                    : facet
            )
        );
    };
    const defaultReset = () => {
        localStorage.removeItem(USER_REVIEW.USERREVIEWEDITVIEWCHECKED);
        localStorage.removeItem(USER_REVIEW.USERREVIEWCOLUMNFILTER);
        localStorage.removeItem(USER_REVIEW.USERREVIEWEDITVIEWID);
        localStorage.removeItem(USER_REVIEW.USERREVIEWEXISTINGVIEW);
        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
        setExisitingViewColumn([]);
        userReviewList({
            ...checkBoxFilter,
            searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
            fieldNamesToReturn: [],
            currentPage: count,
            facetFilters: checkedValues,
            pageSize: pageNumber,
            sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
        });
    };
    const EditViewDefaultCheckAll = () => {
        const tempIssuerReturnName = staticViewFields?.reduce((obj, field) => {
            obj[field] = true;
            return obj;
        }, {});

        setCheckedItems(tempIssuerReturnName);
        setCheckedFields(staticViewFields);
    };
    useEffect(() => {
        setSelectedFields(selectedFields);
        let retString = localStorage.getItem(PRODUCTLIST.PRODUCTCOLUMNFILTER);
        let retArray = JSON.parse(retString);
        const fields =
            has(userReviewList, ["data", "results"]) && isArray(userReviewList?.data?.results) && !isEmpty(userReviewList?.data?.results)
                ? Object.keys(userReviewList?.data?.results[0]?.document)
                : [];
        if (userReviewList && userReviewList.isSuccess && userReviewList.data && userReviewList.data.count === 0) {
            setTempSelectedFields(
                retArray === null
                    ? has(userReviewList, ["data", "results"]) && isArray(userReviewList?.data?.results) && !isEmpty(userReviewList?.data?.results)
                        ? Object.keys(userReviewList?.data?.results[0]?.document)
                        : IssuerReturnName?.data
                    : retArray
            );
        } else if (userReviewList?.isSuccess && userReviewList?.data?.count === 0) {
            setTempSelectedFields(userReviewList?.originalArgs?.fieldNamesToReturn);
        } else {
            setTempSelectedFields(retArray === null ? ViewFields : retArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFields, ViewFields]);
    useEffect(() => {
        if (tempSelectedFields.length === staticViewFields.length) {
            if (editViewMenu === "") {
                setEditViewMenu("Default");
            }
        } else if (editViewMenu === "Default" && tempSelectedFields.length != staticViewFields.length) {
            setEditViewMenu("");
        }
    }, [tempSelectedFields]);
    React.useMemo(() => {
        setCount(userReviewListData?.originalArgs?.currentPage);
        if (userReviewListData?.isSuccess) {
            setData(userReviewListData?.data?.results);

            const receivedFields = userReviewListData?.data?.results[0]?.document ? Object.keys(userReviewListData.data.results[0].document) : [];
            const desiredOrder = [
                "AttachmentCount",
                "RequestDate",
                "RequestedBy",
                "Ticker",
                "Name",
                "ISIN",
                "OriginalStatus",
                "RequestStatus",
                "IssueName",
                "SubIssueName",
                "Note",
                "AttachmentNames",
                "AttachmentTypes",
                "ReviewedBy",
                "ReviewStatus",
                "ReviewDate",
                "ApprovedBy",
                "ApprovedDate",
                "AttachmentNotes",
                "LastUpdated",
            ];

            const reorderedFields = [];
            for (const field of desiredOrder) {
                if (receivedFields.includes(field)) {
                    reorderedFields.push(field);
                }
            }

            setViewFields(reorderedFields);

            const checkedItems = {};
            for (const field of staticViewFields) {
                checkedItems[field] = reorderedFields.includes(field);
            }

            setCheckedItems(checkedItems);
            setCheckedFields(reorderedFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userReviewListData]);
    useEffect(() => {
        if (has(IssuerReturnName, ["data"]) && isArray(IssuerReturnName?.data) && !isEmpty(IssuerReturnName?.data)) {
            const data = [...IssuerReturnName.data];
            const newArray = data.filter((item) => {
                return item !== "Id" && item !== "CanEdit" && item !== "CanApprove";
            });
            setStaticViewFields(newArray);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userReviewListData, IssuerReturnName]);
    useEffect(() => {
        if (
            EditViewData &&
            EditViewData?.isSuccess &&
            has(EditViewData, ["data", "fieldNameJson"]) &&
            Object.keys(EditViewData?.data?.fieldNameJson).length > 0
        ) {
            const checkboxQueryJson = JSON.parse(EditViewData?.data?.fieldNameJson);
            setExisitingViewColumn(checkboxQueryJson?.fieldNamesToReturn);
            // setSavedSearchData(checkboxQueryJson);
            const columnFilterValue = JSON.stringify(checkboxQueryJson?.fieldNamesToReturn);
            localStorage.setItem(USER_REVIEW.USERREVIEWCOLUMNFILTER, columnFilterValue);
            const checkedItems = {};
            for (const field of staticViewFields) {
                checkedItems[field] = checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
            }
            // setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
            setCheckedItems(checkedItems);
            setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);

            const getKeyValuePair = (_key, _value) => {
                return { [_key]: _value };
            };
            const sortFilter = getKeyValuePair(sortOrder?.sortColumnName, sortOrder?.sortOrder);
            if (isNull(retArray)) {
                userReviewList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped,
                    fieldNamesToReturn: isNull(retArray) ? checkboxQueryJson?.fieldNamesToReturn : retArray,
                    currentPage: count,
                    facetFilters: isNull(localStorage.getItem(USER_REVIEW.USERFILTER)) ? [] : checkedValues,
                    pageSize: pageNumber,
                    sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                });
            }
            setEditViewMenu(EditViewData?.data?.name);
            localStorage.setItem(USER_REVIEW.USERREVIEWEXISTINGVIEW, EditViewData?.data?.name);
            if (isNull(localStorage.getItem(USER_REVIEW.USERFILTER))) {
                setCheckedValues([]);
            }
        }
    }, [EditViewData]);
    useEffect(() => {
        setFinalCheckedBadgeValues(JSON.parse(getCheckedBadges));
        setSearchTextTyped(getSearchValue);
        userReviewList(checkBoxFilter);
        IssuerReturnName.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (getEditViewSave && getEditViewSave?.isSuccess && has(getEditViewSave, ["data"])) {
            const ID = getEditViewSave?.data.find((obj) => obj.name === editViewMenu);
            localStorage.setItem(USER_REVIEW.USERREVIEWEDITVIEWID, ID?.id);
            const editView = getEditViewSave.data.find((item) => item.name === editViewMenu);
            if (editView) {
                const checkboxQueryJson = JSON.parse(editView.fieldNameJson);
                // setSavedSearchData(checkboxQueryJson);

                const checkedItems = {};
                for (const field of staticViewFields) {
                    checkedItems[field] = checkboxQueryJson?.fieldNamesToReturn?.includes(field) ?? false;
                }
                setCheckedFields(checkboxQueryJson?.fieldNamesToReturn);
                setCheckedItems(checkedItems);
            }
        }
    }, [getEditViewSave]);
    useEffect(() => {
        //on page load ISIN
        const sortFilter = getKeyValuePair(sortColumnName, sortOrder);

        if (userReviewListData?.isSuccess) {
            if (checkedValues.length === 0) {
                tempSelectedFields.push("Id");
                setFinalCheckedBadgeValues(checkedValueBadges);
                const myArrayString = JSON.stringify(checkedValues);
                localStorage.setItem(USER_REVIEW.USERFILTER, myArrayString);
                localStorage.setItem(USER_REVIEW.USERBADGEVALUES, JSON.stringify(checkedValueBadges));
                localStorage.setItem(USER_REVIEW.USERSEARCHTEXT, searchText);
                userReviewList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
                    currentPage: count,
                    pageSize: pageNumber,
                    fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                    sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                    facetFilters: checkedValues,
                });
            } else {
                tempSelectedFields.push("Id");
                setFinalCheckedBadgeValues(checkedValueBadges);
                const myArrayString = JSON.stringify(checkedValues);
                localStorage.setItem(USER_REVIEW.USERFILTER, myArrayString);
                localStorage.setItem(USER_REVIEW.USERBADGEVALUES, JSON.stringify(checkedValueBadges));
                localStorage.setItem(USER_REVIEW.USERSEARCHTEXT, searchText);
                userReviewList({
                    ...checkBoxFilter,
                    searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
                    currentPage: count,
                    pageSize: pageNumber,
                    fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                    sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                    facetFilters: checkedValues,
                });
            }
        }
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, pageNumber]);
    useEffect(() => {
        if (!isNull(localStorage.getItem(USER_REVIEW.USERSORTINGHEADER))) {
            sortAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder]);
    useEffect(() => {
        if (searchText.length === 0 && searchInput) {
            if (tempSelectedFields.length > 0) {
                tempSelectedFields.push("Id");
            }
            setFinalCheckedBadgeValues(checkedValueBadges);
            const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
            userReviewList({
                ...checkBoxFilter,
                searchText: "*",
                currentPage: 1,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                facetFilters: checkedValues,
                fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);
    useEffect(() => {
        if (triggerUserReviewUpdate) {
            const body = {
                id: userId,
                reviewStatus: currentStatus,
                note: notes,
            };
            updateUserReview(body);
            // localStorage.removeItem("editUserReviewId");
            setTriggerUserReviewUpdate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerUserReviewUpdate]);
    useEffect(() => {
        getUserReviewById.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, userEditView]);
    useEffect(() => {
        if (updatedUserReviewData?.isSuccess) {
            showToastify("success", "User Review Updated Successfully");
            userReviewList({
                ...checkBoxFilter,
                fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                facetFilters: checkedValues,
                searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
                currentPage: count,
                pageSize: pageNumber,
            });
            setFinalCheckedBadgeValues(checkedValueBadges);
        } else if (updatedUserReviewData?.isError) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedUserReviewData?.isSuccess]);
    useEffect(() => {
        const { data } = getUserReviewById;
        setSelectedIssue(data?.issueName ? data?.issueName : "-");
        setSelectedSubIssue(data?.subIssueName ? data?.subIssueName : "-");
        setCurrentStatus(data?.reviewStatus);
        setNotes(data?.note);
    }, [getUserReviewById]);
    useEffect(() => {
        if (
            userReviewListData?.isSuccess &&
            has(userReviewListData, ["data", "facets"]) &&
            isArray(userReviewListData?.data?.facets) &&
            !isEmpty(userReviewListData?.data?.facets)
        ) {
            const tempArr = sortBy(userReviewListData?.data?.facets, ["displayName"]);
            setUpdatedFacets(tempArr);
        }

        if (
            userReviewList?.isSuccess &&
            has(userReviewList, ["data", "facets"]) &&
            isArray(userReviewList?.data?.facets) &&
            !isEmpty(userReviewList?.data?.facets) &&
            searchTextTyped?.length === 0 &&
            checkedValueBadges?.length > 0
        ) {
            setFinalCheckedBadgeValues(checkedValueBadges);
        }
    }, [userReviewListData]);
    useEffect(() => {
        if (checkedState) {
            if (tempSelectedFields?.length > 0) {
                tempSelectedFields.push("Id");
            }
            const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
            userReviewList({
                useDefaultFacets: true,
                fieldNamesToSearch: [],
                facetOptions: null,
                searchText: searchTextTyped?.length > 0 ? searchTextTyped : "",
                fieldNamesToReturn: isNull(retArray) ? tempSelectedFields : retArray,
                currentPage: 1,
                facetFilters: checkedValues,
                pageSize: pageNumber,
                sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedState]);
    useEffect(() => {
        if (IssuerReturnName?.isSuccess) {
            EditViewDefaultCheckAll();
        } else if (IssuerReturnName?.isLoading) {
            setCheckedItems([]);
            setCheckedFields([]);
        }
    }, [IssuerReturnName]);
    useEffect(() => {
        setEditViewInput("");
    }, [editView]);
    const sortedExistingViewColumn = sortBy(exisitngViewColumn);
    const sortedCheckedFields = sortBy(checkedFields);
    const arraysMatch = isEqual(sortedExistingViewColumn, sortedCheckedFields);
    return (
        <React.Fragment>
            <div className="flex-grow w-full overflow-hidden flex h-full">
                <div
                    className={`transition flex flex-col bg-gray-50 w-10/12 md:min-w-[322px] md:max-w-[322px] fixed sm:relative z-10 top-0 h-full ${
                        filterOpen ? "left-0" : "-left-96 sm:left-0"
                    }`}
                >
                    <div className="flex justify-between p-4">
                        <p className="font-bold">{PAGECONSTANTS?.FILTER}</p>
                        <button
                            className="block sm:hidden"
                            onClick={() => {
                                setFilterOpen(false);
                            }}
                        >
                            Close
                        </button>
                        {/* <p className="font-medium text-primary cursor-pointer">{PAGECONSTANTS?.FAVORITE}</p> */}
                    </div>
                    <div className="flex px-4 pb-4 border-b">
                        <div className="w-full relative rounded-md flex shadow-sm" style={{ backgroundColor: "rgb(243,244,246)" }}>
                            <input
                                class="border-0 focus:ring-2 rounded-md px-2 focus:ring-transparent focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 py-1 pl-3 text-dark"
                                type="text"
                                aria-label="Filter projects"
                                placeholder={searchTextTyped?.length > 0 ? "" : "Keyword Search..."}
                                maxLength={20}
                                onKeyDown={handleKeyPress}
                                onChange={OnChangeHandler}
                                onClick={() => setSearchInput(true)}
                                value={searchTextTyped === "*" ? "" : searchTextTyped}
                                style={{ backgroundColor: "rgb(243,244,246)" }}
                            />
                            {searchTextTyped?.length > 0 && searchTextTyped !== "*" && (
                                <XCircleIcon
                                    className="w-[18px]  ml-1 text-red-500 cursor-pointer absolute right-7 top-2"
                                    onClick={() => {
                                        handleBadgeRemove();
                                    }}
                                />
                            )}

                            <MagnifyingGlassIcon
                                className="w-4 text-dark absolute right-2 top-2 cursor-pointer"
                                value={"Click"}
                                onClick={handleSearchIconClick}
                            />
                        </div>
                    </div>
                    <div className="flex-1 overflow-auto px-4">
                        <div className="flex flex-wrap">
                            {finalCheckedBadgeValues?.map((item, index) => {
                                return (
                                    <div className="flex rounded w-fit p-2">
                                        <Badge title={item} value={item?.length > 31 ? item.slice(0, 30) + "..." : item} variant={"GrayBlue"} />
                                        <XCircleIcon
                                            className="w-[18px] ml-1 text-red-500 cursor-pointer"
                                            onClick={() => handleCheckedBadgeRemove(index, item)}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        {userReviewListData?.isSuccess &&
                        has(userReviewListData, ["data", "facets"]) &&
                        isArray(userReviewListData?.data?.facets) &&
                        !isEmpty(userReviewListData?.data?.facets) ? (
                            updatedFacets.map((facet) => {
                                const sortedFacets = (facet?.facetItems || []).map((item) => ({ value: item.value, count: item.count }));
                                const facetDisplayName = facet.displayName;
                                const isShown = showState[facetDisplayName] ?? false;
                                return (
                                    <>
                                        <div>
                                            <div
                                                className="flex items-center gap-1 py-2 whitespace-nowrap font-bold cursor-pointer"
                                                onClick={() => toggleShowState(facetDisplayName)}
                                            >   
                                                <ChevronRightIcon className={"w-4"} /> {facetDisplayName==="IssueName"?"Eliminate Issue":facetDisplayName==="SubIssueName"?"Eliminate Sub - Issue":facetDisplayName.replace(/(?=[A-Z][a-z])/g, " ")}
                                            </div>
                                            {isShown && (
                                                <div className="pl-5 flex flex-col gap-2">
                                                    {sortedFacets.map((item) => {
                                                        const value = item.value;
                                                        const checked = checkedValues?.some((facet) => facet?.values?.includes(value));
                                                        return (
                                                            <>
                                                                <div className="flex gap-2 items-center" key={data.id}>
                                                                    <Checkbox
                                                                        label={value + " (" + item.count + ")"}
                                                                        className={"rounded"}
                                                                        id={value}
                                                                        name={value}
                                                                        Checked={checked}
                                                                        handleChange={(e) => handleCheckChange(e, facetDisplayName)}
                                                                    />
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            })
                        ) : (
                            <></>
                        )}
                        {userReviewListData?.isLoading && (
                            <div class="flex flex-col space-y-4">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="flex-1 space-y-4 py-1">
                                        {range(7).map((range, i) => (
                                            <div class="h-4 bg-gray-300 rounded w-5/6"></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex gap-4 p-4 mb-4 h-15 pb-0">
                        <Button className="w-full" variant="primary-outline" value="Reset" onClick={() => handleResetClick()} />
                        <Button className="w-full" variant="primary" value="Apply" onClick={() => handleApplyClick()} />
                    </div>
                </div>
                <div className="h-full w-full overflow-auto flex flex-col">
                    <div className="flex justify-between border-b items-center py-4 md:p-4">
                        <h1 className="hidden lg:block font-bold whitespace-nowrap">User Review ({userReviewListData?.data?.count})</h1>
                        {userReviewListData?.isLoading || userReviewListData?.data?.results?.length === 0 || userReviewListData?.isError ? (
                            ""
                        ) : (
                            <div className="text-white gap-4 flex flex-col md:flex-row w-full px-4 justify-end">
                                <div className="flex justify-between">
                                    <Menu as="div" className="relative text-left">
                                        <div className="flex">
                                            <p className="text-gray-900 whitespace-nowrap block sm:hidden lg:block">{ISSUERLIST?.EXISTINGVIEW}</p>
                                            <Menu.Button className="flex justify-center rounded-md bg-white px-3 font-semibold text-gray-900 hover:bg-gray-50 items-center">
                                                {exisitngViewColumn.length === 0 ? editViewMenu : arraysMatch ? editViewMenu : ""}
                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute border shadow  z-10 mt-2 w-56 origin-top-right rounded-md bg-white 	 focus:outline-none  static-scroll">
                                                <div className="py-1 max-h-[10rem] overflow-auto">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <span
                                                                className={(active ? "bg-gray-100 text-gray-700" : "  text-black", "block px-4 py-2 text-sm")}
                                                                onClick={() => {
                                                                    defaultReset();
                                                                    setEditViewMenu(ISSUERLIST?.DEFAULT);
                                                                    setEditViewMenuId("");
                                                                }}
                                                                style={{ color: "black", cursor: "pointer" }}
                                                            >
                                                                {ISSUERLIST?.DEFAULT}
                                                            </span>
                                                        )}
                                                    </Menu.Item>
                                                    {getEditViewSave?.isSuccess &&
                                                        has(getEditViewSave, ["data"]) &&
                                                        isArray(getEditViewSave?.data) &&
                                                        !isEmpty(getEditViewSave?.data) &&
                                                        getEditViewSave?.data?.map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <span
                                                                            className={
                                                                                (active ? "bg-gray-100 text-gray-700" : "  text-black",
                                                                                "block px-4 py-2 text-sm hover:bg-gray-100 rounded")
                                                                            }
                                                                            onClick={() => {
                                                                                setEditViewMenu(item.name);
                                                                                setEditViewMenuId(item.id);
                                                                                localStorage.setItem(USER_REVIEW.USERREVIEWEDITVIEWID, item?.id);
                                                                                localStorage.removeItem(USER_REVIEW.USERREVIEWEDITVIEWCHECKED);
                                                                                localStorage.removeItem(USER_REVIEW.USERREVIEWCOLUMNFILTER);
                                                                                // localStorage.removeItem(USER_REVIEW.USERREVIEWCOLUMNFILTER);
                                                                                // handleResetClick();
                                                                                // defaultReset();
                                                                            }}
                                                                            style={{ color: "black", cursor: "pointer" }}
                                                                        >
                                                                            {item.name}
                                                                        </span>
                                                                    )}
                                                                </Menu.Item>
                                                            );
                                                        })}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <button
                                        className="text-dark flex gap-2 items-center sm:hidden"
                                        onClick={() => {
                                            setFilterOpen(true);
                                        }}
                                    >
                                        <i className="far fa-filter"></i>
                                        {PAGECONSTANTS?.FILTER}
                                    </button>
                                </div>
                                <div className="flex gap-3">
                                    <Link className="items-center text-primary whitespace-nowrap" onClick={handleEditView}>
                                        {ISSUERLIST?.EDITVIEW}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex-grow overflow-hidden md:pr-4 static-scroll">
                        {userReviewListData?.isSuccess && !isEmpty(userReviewListData?.data?.results) && (
                            <div className="h-full flex-col flex">
                                <TailwindTable
                                    columns={columns}
                                    data={data}
                                    setCount={setCount}
                                    count={count || 1}
                                    totalLength={userReviewListData?.data?.count}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    pageArray={pageArray}
                                    sortColumnName={sortColumnName}
                                    setSortColumnName={setSortColumnName}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    getRowCanExpand={() => true}
                                    setDocumentModalView={setDocumentModalView}
                                    setUserEditView={setUserEditView}
                                    setCanEditState={setCanEditState}
                                    setCanApproveState={setCanApproveState}
                                    path="/issuerdetails"
                                    returnPath="/issuer/user-review"
                                    sortingName={USER_REVIEW.USERSORTING}
                                    sortingHeadername={USER_REVIEW.USERSORTINGHEADER}
                                />
                            </div>
                        )}
                        {userReviewListData?.isLoading && (
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                    <tr>
                                                        {loadingTableheader?.UserReviewList?.map((item) => {
                                                            return (
                                                                <th
                                                                    scope="col"
                                                                    className="th group px-4 py-2 text-left text-xs font-bold text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                >
                                                                    {item}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {range(10).map((range, i) => (
                                                        <tr className="animate-pulse" key={i}>
                                                            {loadingTableheader?.UserReviewList?.map((item) => {
                                                                return (
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {userReviewListData?.isError ||
                            (isEmpty(userReviewListData?.data?.results) && !userReviewListData?.isLoading && (
                                <div className="flex flex-col">
                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <p className="text-center">{PAGECONSTANTS?.NODATAFOUND}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <UserContext.Provider
                value={{
                    pageNumber,
                    checked,
                    setChecked,
                    setFinalCheckedBadgeValues,
                    userEditView,
                    sortOrder,
                    setUserEditView,
                    checkedValues,
                    sortColumnName,
                    count,
                    searchTextTyped,
                    checkedValueBadges,
                    setDocumentModalView,
                    selectedIssue,
                    setSelectedIssue,
                    selectedSubIssue,
                    setSelectedSubIssue,
                    notes,
                    setNotes,
                    currentStatus,
                    setCurrentStatus,
                    getUserReviewById,
                    setTriggerUserReviewUpdate,
                    triggerUserReviewUpdate,
                    canEditState,
                    canApproveState,
                    checkedFields,
                    setCheckedFields,
                    setCommentapicall,
                    staticViewFields,
                    editViewInput,
                    setEditViewInput,
                    checkedItems,
                    setCheckedItems,
                    selectedFields,
                    setSelectedFields,
                    setEditView,
                    checkBoxFilter,
                    editViewReturnValue,
                    setEditViewReturnValue,
                    setEditViewMenu,
                    ViewFields,
                    setViewFields,
                    tempSelectedFields,
                }}
            >
                <Modal
                    setshowmodal={setUserEditView}
                    showmodal={userEditView}
                    title="Review User Request"
                    size={"sm:min-w-[576px]"}
                    body={<EditUserReviewModal />}
                    footer={<EditUserReviewModalFooter />}
                    isFooterEnable={true}
                />
                <Modal
                    setshowmodal={setDocumentModalView}
                    showmodal={documentModalView}
                    title="Document"
                    size={"sm:min-w-[576px]"}
                    body={<UserReviewDocumentModal />}
                    footer={<UserReviewDocumentModalFooter />}
                    isFooterEnable={true}
                />
                <Modal
                    setshowmodal={setEditView}
                    showmodal={editView}
                    title="Edit View"
                    size={"w-[485px]"}
                    body={
                        <EditView
                            postApiCall={userReviewList}
                            issuerColumnFilter={USER_REVIEW.USERREVIEWCOLUMNFILTER}
                            issuerEditViewCheck={USER_REVIEW.USERREVIEWEDITVIEWCHECKED}
                            AddID="Id"
                        />
                    }
                    footer={<EditViewFooter postApiCall={userReviewList} moduleName="UserReview" AddID="Id" />}
                    isFooterEnable={true}
                />
            </UserContext.Provider>
        </React.Fragment>
    );
};
export default UserReview;
