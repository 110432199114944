import { interceptorsApiCall } from "../../../globals/interceptors";
export const ViewUserRole = interceptorsApiCall.injectEndpoints({
    endpoints: (builder) => ({
        getViewUserRole: builder.query({
            query: () => "api/Admin/AccessControl/GetAccessControlUserRoleConfiguration",
        }),
    }),
});

export const { useGetViewUserRoleQuery } = ViewUserRole;
