import { interceptorsApiCall } from "../../globals/interceptors";

export const PermissionApi = interceptorsApiCall.injectEndpoints({
	endpoints: (builder) => ({
		GetPermission: builder.mutation({
			query: () => ({
                url:"/api/Authorization/AuthorizeUser",
                method:"POST"
            }),
		}),
	}),
});

export const { useGetPermissionMutation } = PermissionApi;