import React from "react";
const Checkbox = ({ label, Checked, handleChange, className, classNameBox, id, disabled, title, name ,classNameCheckbox}) => {
    return (
        <div className={`flex w-full ${classNameBox}`}>
            {" "}
            <input
                type="checkbox"
                disabled={disabled}
                id={id}
                name={name}
                value={id}
                className={`form-checkbox focus:ring-transparent text-primary-600 rounded ${classNameCheckbox}`}
                checked={Checked}
                onChange={handleChange}
            />{" "}
            <label htmlFor={id} title={title} className={`ml-2 text-sm ${className}`}>
                {" "}
                {label}{" "}
            </label>{" "}
        </div>
    );
};
export default Checkbox;
