import React, { useContext, useEffect, useState } from "react";
import { has, isArray } from "lodash";
import {
    useDeleteUserReviewDocumentMutation,
    useGetUserReviewDocumentDownloadLinkQuery,
    useGetUserReviewDocumentsQuery,
    useUpdateUserReviewDocumentCommentMutation,
} from "../../../Services/Issuer/UserReview/UserReviewApi";
import { queryString } from "../../../helpers";
import Table from "../../../components/Table/Table";
import { showToastify } from "../../../constants/Common";
import UserContext from "../../../context/modal/Contextmodel";

const UserReviewDocumentModal = () => {
    const userReviewId = sessionStorage.getItem("userReviewId");
    const { setDocumentModalView ,setCommentapicall} = useContext(UserContext);
    const [documentData, setDocumentData] = useState([]);
    const [documentcomment, setDocumentcomment] = useState("");
    const [triggerDownload, setTriggerDownload] = useState(false);
    const [downloadLink, setDownloadLink] = useState("");
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const [UpdateUserReviewDocumentComment, userReviewDocumentComment] = useUpdateUserReviewDocumentCommentMutation();
    const getUserReviewDocuments = useGetUserReviewDocumentsQuery(queryString({ params: { userReviewId: userReviewId } }), { skip: !userReviewId || userReviewId === undefined });
    const getUserReviewDocumentDownloadLink = useGetUserReviewDocumentDownloadLinkQuery(queryString({ params: { documentId: selectedDocumentId } }), { skip: !selectedDocumentId || selectedDocumentId === undefined });
    const [deleteUserReviewDocument, deleteUserReviewResponse] = useDeleteUserReviewDocumentMutation();
    const handleDeleteUserReviewDocument = () => {
        deleteUserReviewDocument(queryString({ params: { documentId: selectedDocumentId } }));
    };
    useEffect(()=>{
        getUserReviewDocuments.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleUpdateCommentsDocument = () => {
        const body = {
            userReviewId: userReviewId,
            documentId: selectedDocumentId,
            comment: documentcomment,
        };
        UpdateUserReviewDocumentComment(body);
    };
    useEffect(()=>{
        if(userReviewDocumentComment.isSuccess)
        {
            setCommentapicall(true)
            setDocumentModalView(false);
        }
    },[userReviewDocumentComment])
    useEffect(() => {
        if (getUserReviewDocuments?.isSuccess) {
            const comment = getUserReviewDocuments?.data[0]?.comment || "";
            setDocumentcomment(comment);
        }
    }, [getUserReviewDocuments]);
    useEffect(() => {
        if (
            getUserReviewDocuments &&
            getUserReviewDocuments?.isSuccess &&
            has(getUserReviewDocuments, ["data"]) &&
            isArray(getUserReviewDocuments?.data) &&
            getUserReviewDocuments?.data?.length > 0
        ) {
            const tempArr = [...getUserReviewDocuments?.data];
            const newArr = tempArr?.map((values) => {
                const myNewObject = {
                    Actions: true,
                    fileName: values.fileName,
                    documentId: values.documentId,
                    addedBy: values.addedBy,
                    contentType: values.contentType,
                    comment: values.comment,
                    isPrivate: values.isPrivate,
                };
                return myNewObject;
            });
            setDocumentData(newArr);
            setSelectedDocumentId(newArr[0]?.documentId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserReviewDocuments]);
    useEffect(() => {
        if (deleteUserReviewResponse?.isSuccess) {
            showToastify("success", "Document Deleted Successfully");
            setDocumentModalView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteUserReviewResponse?.isSuccess]);
    useEffect(() => {
        if (selectedDocumentId) {
            getUserReviewDocumentDownloadLink?.refetch(queryString({ params: { documentId: selectedDocumentId } }));
        }
    }, [triggerDownload]);
    useEffect(() => {
        if (getUserReviewDocumentDownloadLink?.isSuccess) {
            setDownloadLink(getUserReviewDocumentDownloadLink?.data?.data);
        }
        setTriggerDownload(false);
    }, [getUserReviewDocumentDownloadLink]);

    return (
        <div class="grid grid-cols-1 gap-4 h-[280px] overflow-auto">
            <div className="flex">
                <Table
                    data={documentData}
                    Actions={true}
                    onclick={handleDeleteUserReviewDocument}
                    setTriggerDownload={setTriggerDownload}
                    downloadLink={downloadLink}
                    triggerDownload={triggerDownload}
                    expandableKey={true}
                    documentcomment={documentcomment}
                    setDocumentcomment={setDocumentcomment}
                    handleUpdateCommentsDocument={handleUpdateCommentsDocument}

                />
            </div>
        </div>
    );
};

export default UserReviewDocumentModal;
