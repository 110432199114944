import React, { useEffect, useState } from "react";
import { isEmpty, pull } from "lodash";
import { Tab } from "@headlessui/react";
import { useGetManageAcessControlerApiQuery, useGrantPermissionToRoleApiMutation,useRevokePermissionFromRoleApiMutation} from "../../../Services/Admin/ManageAccessControl";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { ADMIN } from "../../../constants/appConstants";
import { disPascalize } from "../../../constants/Common";

const ManageAccessControl = ({ canEdit, tab }) => {
    const storedActiveTab = localStorage.getItem("activeTab");
    const [activeTab, setActiveTab] = useState(storedActiveTab || "Admin");
    const [permissions, setPermissions] = useState([]);
    const [roles, setRoles] = useState([]);
    const manageAccessControlerApi = useGetManageAcessControlerApiQuery();
    const [GrantPermission, GrantPermissiondata] = useGrantPermissionToRoleApiMutation();
    const [RevokePermission,Revokedata]=useRevokePermissionFromRoleApiMutation()

    // useEffect(() => {
    //     if (!tab === "manage-access-control") {
    //         setRoles([]);
    //     }
    // }, [tab]);


    const handleTabClick = (key) => {
        setActiveTab(key);
        setPermissions(manageAccessControlerApi?.data?.availablePermissions[key]);
        localStorage.setItem("activeTab", key);
    };

    useEffect(() => {
        if (manageAccessControlerApi.isSuccess) {
            if (
                manageAccessControlerApi &&
                manageAccessControlerApi?.data &&
                manageAccessControlerApi.data?.roles &&
                manageAccessControlerApi.data?.roles.length > 0
            ) {
                const sorted = [...manageAccessControlerApi.data?.roles].sort((a, b) => {
                    if (a?.name === "System Administrator") return -1;
                    if (b?.name === "System Administrator") return 1;
                    return 0;
                });
                setRoles(sorted);
            } else {
                setRoles([]);
            }
        } else {
            setRoles([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manageAccessControlerApi]);

    useEffect(() => {
        localStorage.setItem("activeTab", "Admin");
    }, []);
    useEffect(() => {
        manageAccessControlerApi.refetch();
    }, []);

    useEffect(() => {
        setPermissions(manageAccessControlerApi?.data?.availablePermissions[activeTab]);
    }, [manageAccessControlerApi?.data?.availablePermissions, activeTab]);

    useEffect(() => {
        if (manageAccessControlerApi.isSuccess && !storedActiveTab) {
            const availableTabs = Object.keys(manageAccessControlerApi?.data?.availablePermissions);
            if (availableTabs.length > 0) {
                setActiveTab(availableTabs[0]);
            }
        }
    }, [manageAccessControlerApi.isSuccess, manageAccessControlerApi?.data?.availablePermissions, storedActiveTab]);

    const getExpectedChecked = (arr, Checkedvalue) => {
        const tempArr = arr.toString().split(",");
        const check = tempArr.find((value) => value.trim() === Checkedvalue.toString().trim());
        if (check !== undefined) {
            return true;
        } else {
            return false;
        }
    };
    const handleCheckboxChange = async (e, permissionValue, role) => {
        if (e.target.checked) {
          
          setRoles((prevRoles) =>
            prevRoles.map((prevRole) => {
              if (prevRole === role) {
                return {
                  ...prevRole,
                  permissions:
                    prevRole.permissions === "None"
                      ? permissionValue
                      : prevRole.permissions + ", " + permissionValue,
                };
              }
              return prevRole;
            })
          );
          await GrantPermission({
            roleId: role.roleId,
            permissions: permissionValue,
          });
        } else {
        
          setRoles((prevRoles) =>
            prevRoles.map((prevRole) => {
              if (prevRole === role) {
                const updatedPermissions = prevRole.permissions
                  .split(",")
                  .filter((permission) => permission.trim() !== permissionValue.trim())
                  .join(", ");
                return {
                  ...prevRole,
                  permissions: updatedPermissions || "None",
                };
              }
              return prevRole;
            })
          );
          await RevokePermission({
            roleId: role.roleId,
            permissions: permissionValue,
          });
        }
      };
      
    return (
        <>
            {!isEmpty(manageAccessControlerApi?.data?.availablePermissions) && (
                <Tab.Group>
                    <div className="border-b">
                        <Tab.List className="flex">
                            {Object.keys(manageAccessControlerApi?.data?.availablePermissions).map((key) => {
                                if (key === "All") {
                                    return null;
                                }
                                return (
                                    <Tab
                                        key={key}
                                        className={`${
                                            activeTab === key ? "border-b-2  border-primary" : "border-b border-gray-200 hover:border-gray-300 text-gray-500"
                                        } bg-white inline-block py-2 px-4 font-semibold transition-colors duration-300`}
                                        onClick={() => handleTabClick(key)}
                                    >
                                        {key}
                                    </Tab>
                                );
                            })}
                        </Tab.List>
                    </div>
                    <Tab.Panels>
                        {Object.keys(manageAccessControlerApi?.data?.availablePermissions)?.map((key) => {
                            if (key === "All") {
                                return null;
                            }
                            return (
                                <Tab.Panel key={key}>
                                    <div className="flex-grow overflow-hidden static-scroll">
                                        <div className="h-full flex-col flex">
                                            <div className="overflow-auto w-full h-full">
                                                <table className="table min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                        <tr className="tr">
                                                            <th className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer">
                                                                <div className="flex w-full h-full gap-3 items-center">
                                                                    <div className="flex flex-grow items-center gap-2 font-bold" style={{ fontSize: "13px" }}>
                                                                        {ADMIN.PERMISSIONS}
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            {roles.map((value) => (
                                                                <th
                                                                    className="th group px-4 py-2 text-left text-xs font-medium text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                    key={value?.name}
                                                                >
                                                                    <div className="flex w-full h-full gap-3 items-center">
                                                                        <div
                                                                            className="flex flex-grow items-center gap-2 font-bold"
                                                                            style={{ fontSize: "13px", justifyContent: "center" }}
                                                                        >
                                                                            {disPascalize(value?.name)}
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {permissions?.map((permission, index) => (
                                                            <tr key={permission} className="tr even:bg-gray-100 whitespace-nowrap">
                                                                <td className="td px-4 py-2 hover:max-w-none overflow-hidden">{disPascalize(permission)}</td>
                                                                {roles.map((role, i) => (
                                                                    <td
                                                                        key={`${permission}-${role?.name}`}
                                                                        className="td px-4 py-2 hover:max-w-none overflow-hidden text-center"
                                                                    >
                                                                        <div className="flex justify-center">
                                                                            <Checkbox
                                                                                id={permission}
                                                                                classNameBox={
                                                                                    role?.permissions.toString() === "All"
                                                                                        ? "justify-center opacity-50"
                                                                                        : "justify-center "
                                                                                }
                                                                                classNameCheckbox={
                                                                                    role?.permissions.toString() === "All" || !canEdit
                                                                                        ? "cursor-not-allowed"
                                                                                        : "cursor-pointer"
                                                                                }
                                                                                Checked={
                                                                                    role?.permissions.toString() === "All" ||
                                                                                    getExpectedChecked(
                                                                                        role?.permissions.toString(),
                                                                                        storedActiveTab + "_" + permission
                                                                                    )
                                                                                }
                                                                                disabled={role?.name === "System Administrator" || !canEdit}
                                                                                handleChange={(e) => handleCheckboxChange(e, storedActiveTab + "_" + permission, role)}
                                                                                
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                            );
                        })}
                    </Tab.Panels>
                </Tab.Group>
            )}
        </>
    );
};
export default ManageAccessControl;
