import React, { useContext, useState } from "react";
import Button from "../components/Button/Button";
import Checkbox from "../components/Checkbox/Checkbox";
import UserContext from "../context/modal/Contextmodel";
import { difference, isNull, remove } from "lodash";
const EditView = ({
  postApiCall,
  issuerColumnFilter,
  issuerEditViewCheck,
  AddID,
}) => {
  const {
    staticViewFields,
    setSelectedFields,
    searchTextTyped,
    setFinalCheckedBadgeValues,
    checkedValueBadges,
    setEditView,
    checkedItems,
    setCheckedItems,
    checkedFields,
    setCheckedFields,
    count,
    pageNumber,
    checkBoxFilter,
    checkedValues,
    sortOrder,
    sortColumnName,
    setEditViewReturnValue,
  } = useContext(UserContext);
  let EditCheckbox = localStorage.getItem(issuerEditViewCheck);
  let EditCheckboxValue = JSON.parse(EditCheckbox);
  let columnFilter = localStorage.getItem(issuerColumnFilter);
  let ColumnValue = JSON.parse(columnFilter);
  const getKeyValuePair = (_key, _value) => {
    return { [_key]: _value };
  };
  const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
  const [tempCheckedValues, setTempCheckedValues] = useState(
    isNull(EditCheckboxValue) ? { ...checkedItems } : EditCheckboxValue
  );
  const [tempSelectedValues, setTempSelectedValues] = useState(
    isNull(ColumnValue) ? [...checkedFields] : ColumnValue
  );
  const handleChange = (event, name) => {
    const label = event.target.name;
    const isChecked = event.target.checked;
    setTempCheckedValues({ ...tempCheckedValues, [name]: isChecked });
    if (isChecked && !tempSelectedValues.includes(name)) {
      const UnselectedValues = difference(
        [...staticViewFields],
        [...tempSelectedValues, label.replace(/\s+/g, "")]
      );
      if (UnselectedValues && UnselectedValues.length) {
        const tempArr = [...staticViewFields];
        const filteredData = remove(tempArr, function (n) {
          return !UnselectedValues.includes(n);
        });
        setTempSelectedValues([...filteredData]);
        setEditViewReturnValue([...filteredData]);
      } else {
        setTempSelectedValues([...staticViewFields]);
      }
    } else if (!isChecked && tempSelectedValues.includes(name)) {
      setTempSelectedValues(
        tempSelectedValues.filter((field) => field !== name)
      );
      setEditViewReturnValue(
        tempSelectedValues.filter((field) => field !== name)
      );
    }
  };
  const ApplyBtnClick = () => {
    setFinalCheckedBadgeValues(checkedValueBadges);
    setSelectedFields([...tempSelectedValues]);
    setCheckedFields([...tempSelectedValues]);
    // tempSelectedValues.push(AddID);
    if (!isNull(AddID) && AddID !== undefined) {
      tempSelectedValues.push(AddID);
    }
    const storevalue = JSON.stringify(tempSelectedValues);
    localStorage.setItem(issuerColumnFilter, storevalue);
    const checkedName = JSON.stringify(tempCheckedValues);
    localStorage.setItem(issuerEditViewCheck, checkedName);
    let EditCheckbox = localStorage.getItem(issuerEditViewCheck);
    let EditCheckboxValue = JSON.parse(EditCheckbox);
    setCheckedItems(EditCheckboxValue);
    setEditView(false);

    postApiCall({
      ...checkBoxFilter,
      searchText: searchTextTyped,
      fieldNamesToReturn: tempSelectedValues,
      currentPage: count,
      facetFilters: checkedValues,
      pageSize: pageNumber,
      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
    });
  };

  return (
    <div className="flex flex-wrap">
      {" "}
      <div className="sm:grid sm:grid-rows-6 grid-flow-col sm:w-[480px] overflow-auto whitespace-nowrap p-2 gap-x-4">
        {" "}
        {staticViewFields.map((field, index) => {
          const label =
            field === "EliminateStatus"
              ? "Status"
              : field === "WeightedEliminatedPercent"
              ? "Weighted % Eliminated"
              : field === "EliminatedIssuersCount"
              ? "Total # Eliminated Stocks"
              : field === "IdentifiedProductsPercent"
              ? "% of Product Identified"
              : field === "ScreenedProductsPercent"
              ? "% of Product Screened"
              : field === "HoldingsCount"
              ? "# Holdings"
              : field === "IssuersCount"
              ? "# Issuers"
              : field === "IdentifiedIssuersCount"
              ? "# Issuers Identified"
              : field === "ScreenedIssuersCount"
              ? "# Issuers Screened"
              : field === "ProductsCount"
              ? "# Underlying Products"
              : field === "IdentifiedProductsCount"
              ? "# Underlying Products Identified"
              : field === "ScreenedProductsCount"
              ? "# Underlying Products Screened"
              : field === "IdenifiedBondsCount"
              ? "# Bonds Identified"
              : field === "IdenifiedOthersCount"
              ? "# Other Identified"
              : field === "UnclassifedCount"
              ? "# Unclassified"
              : field === "CashAllocationPercent"
              ? "Cash Allocation"
              : field === "OAISMSCApprovalStatus"
              ? "Is MSC Product"
              : field.replace(/(?=[A-Z][a-z])/g, " ");

          return (
            <>
              {" "}
              <Checkbox
                className={
                  field === "Name" ||
                  field === "Ticker" ||
                  field === "ISIN" ||
                  field === "OAISMSCApprovalStatus" ||
                  field === "ProductId"
                    ? "text-gray-400 "
                    : "text-gray-700"
                }
                disabled={
                  field === "Name" ||
                  field === "Ticker" ||
                  field === "ISIN" ||
                  field === "OAISMSCApprovalStatus" ||
                  field === "ProductId"
                }
                classNameBox={
                  field === "Name" ||
                  field === "Ticker" ||
                  field === "ISIN" ||
                  field === "OAISMSCApprovalStatus" ||
                  field === "ProductId"
                    ? "opacity-50"
                    : ""
                }
                key={field}
                name={field}
                label={label}
                handleChange={(e) => handleChange(e, field)}
                Checked={tempCheckedValues[field]}
              />{" "}
            </>
          );
        })}{" "}
      </div>{" "}
      <Button
        className="w-full"
        key="Apply"
        value="Apply"
        variant="primary"
        onClick={ApplyBtnClick}
      />{" "}
    </div>
  );
};
export default EditView;
