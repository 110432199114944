import React, { useState } from "react";

const PrintModalBody = ({ setPrintNote }) => {
  const [selectedOption, setSelectedOption] = useState("Yes");

  const handleCheckbox = (value) => {
    setSelectedOption(value);
    setPrintNote(value === "Yes");
  };

  const options = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <>
      <div className="text-center">Would you like to include notes? </div>
      <div className="flex justify-center m-4">
        {options.map((option) => (
          <div key={option.value}>
            <input
              type="checkbox"
              className="form-checkbox focus:ring-transparent text-primary-600 rounded"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleCheckbox(option.value)}
            />
            <label className="mx-2 text-sm text-gray-700">{option.label}</label>
          </div>
        ))}
      </div>
    </>
  );
};

export default PrintModalBody;
