import React, { useState } from "react";
import Badge from "../Badge/Badge";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { PAGECONSTANTS, formatCamelCase } from "../../constants/Common";
import "./index.css";
const IssuerDetailsTable = ({
    data,
    onclick,
    loading,
    setEdit,
    settdata,
    sortOrder,
    setSortorder,
    setshare,
    path,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    setHeadername,
    showTrash,
    print,
    keyValue,
}) => {
    const [columns, setColumns] = useState([]);
    // get list of columns from header object
    const listKeys = (headerObj, removeKeys) => {
        const headerArr = Object.keys(headerObj);
        const filteredHeaderValues = headerArr.filter((headerValue) => !removeKeys.includes(headerValue));
        setColumns(filteredHeaderValues);
    };
    const handleSorting = (data) => {
        setHeadername(data);
        setSortorder(sortOrder === "1" ? "0" : "1");
    };
    React.useEffect(() => {
        if (data && data?.length >= 1 && print) {
            listKeys(data[0], ["createdBy", "isActive", "id", "userId", "isin", "issuerId", "isSelf", "showEdit"]);
        } else if (data && data?.length >= 1 && !print) {
            listKeys(data[0], ["createdBy", "isActive", "id", "userId", "isin", "issuerId", "isSelf"]);
        }
    }, [data, print]);

    // handle page changes
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    // get items for current page

    // generate table header data
    const ThData = () => {
        return loading ? (
            <>
                <th>
                    <Skeleton width={"60%"} />
                </th>
                <th>
                    <Skeleton width={"60%"} />
                </th>{" "}
                <th>
                    <Skeleton width={"60%"} />
                </th>
            </>
        ) : columns && columns.length > 0 ? (
            columns.map((data) => (
                <th
                    style={{
                        padding: "20px",
                        fontWeight: "bold",
                        color: "rgb(107 114 128)",
                        fontSize: "14px",
                    }}
                    key={data}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatCamelCase(data)}</span>
                        {data === "Action" || print || data==="userName" ? (
                            ""
                        ) : (
                            <div className="hover-icon" style={{cursor:"pointer"}}>
                                <div
                                    onClick={() => {
                                        handleSorting(data);
                                    }}
                                >
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 320 512"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                </th>
            ))
        ) : null;
    };

    // generate table row data
    const tdData = () => {
        return data?.length ? (
            data?.map((data, index) => {
                return (
                    <tr
                        style={{
                            backgroundColor: index % 2 === 0 ? "rgb(243, 244, 246)" : "white",
                            padding: "5% 2%",
                            "--tw-bg-opacity": 1,
                        }}
                    >
                        {columns &&
                            columns?.length &&
                            columns.map((v) => {
                                return v === "Action" && data[v] === true ? (
                                    <td value={data.id}>
                                        <div style={{ display: "inline-flex",alignItems: "center" }}>
                                            {" "}
                                            {data?.isSelf ?<div
                                                style={{ marginLeft: "19px", maxWidth: "fit-content"}}
                                                onClick={() => {
                                                    onclick();
                                                    setEdit(data.description);
                                                    settdata(data.id);
                                                }}
                                            >
                                                {" "}
                                                 <Badge variant="PencilIcon" />
                                            </div> : ""}
                                            {/* onClick={()=>{setshare(true)} for future purpose  */}
                                            
                                                <div
                                                    style={{ marginLeft: "19px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setshare(true);
                                                        settdata(data.id);
                                                    }}
                                                >
                                                  {keyValue === "ProductDetails" ? (  <Badge variant="ShareIcon" />):""}
                                                </div>
                                            
                                        </div>
                                    </td>
                                ) : v === "showDelete" && data[v] === true ? (
                                    <td>{<Badge variant="TrashRed" onClick={() => onclick(data.id)} />}</td>
                                ) : v === "" && data[v] === true ? (
                                    <td>
                                        {
                                            <div style={{ display: "inline-flex" }}>
                                                {v === "Action" && data[v] === true && (
                                                    <div style={{ marginTop: "3px", marginLeft: "3px" }}>
                                                        <Badge
                                                            variant="ShareIcon"
                                                            onClick={() => {
                                                                setshare(true);
                                                                settdata(data.id);
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                {showTrash && (
                                                    <td style={{ padding: "2px" }}>
                                                        <Badge
                                                            variant="TrashRed"
                                                            onClick={() => {
                                                                onclick(data.id, data.name);
                                                            }}
                                                        />
                                                    </td>
                                                )}

                                                {/* Display the share icon if showShare is true */}
                                            </div>
                                        }
                                    </td>
                                ) : v === "showStatus" && data[v] === true ? (
                                    <td style={{ padding: "2px" }}> {<Badge variant="CircleSuccess" />}</td>
                                ) : (
                                    <td
                                        key={v}
                                        style={{
                                            padding: "2px",
                                            fontSize: "0.875rem",
                                            maxWidth: "500px",
                                            wordWrap: "break-word",
                                            width: v === "description" ? "60%" : "15px%",
                                        }}
                                    >
                                        {v === "name" && data[v].length >= 13 ? (
                                            <Link
                                                style={{
                                                    cursor: "pointer",
                                                    maxWidth: "fit-content",
                                                    color: "#1976d2",
                                                    marginLeft: "6%",
                                                    fontSize: "0.875rem",
                                                }}
                                                to={path}
                                                state={{
                                                    data: data?.id,
                                                }}
                                                title={data[v]}
                                            >
                                                {data[v]}
                                            </Link>
                                        ) : (
                                            <p
                                                style={{
                                                    marginLeft: "5px",
                                                    wordbreak: "break-all",
                                                    width: v === "userName" ? "140px" : "100%",
                                                    overflow: "hidden",
                                                    whiteSpace: "normal",
                                                    padding: "12px",
                                                    fontSize: "0.875rem",
                                                }}
                                            >
                                                {data[v]}
                                            </p>
                                        )}
                                    </td>
                                );
                            })}
                    </tr>
                );
            })
        ) : loading ? (
            <>
                <td>
                    <Skeleton width={"50%"} className="mt-2" count={5} />
                </td>
                <td>
                    <Skeleton width={"50%"} className="mt-3" count={5} />
                </td>
                <td>
                    <Skeleton width={"50%"} className="mt-3" count={5} />
                </td>
            </>
        ) : (
            <p style={{ textAlign: "center" }}>{PAGECONSTANTS?.NODATAFOUND}</p>
        );
    };

    const Pagination = () => {
        const pageNumbers = [];

        const maxVisiblePages = 5; // Maximum number of visible pages

        // Add page numbers to array

        for (let i = 1; i <= Math.ceil(count / itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        // If there are less pages than the maximum visible pages, show all pages

        if (pageNumbers.length <= maxVisiblePages) {
            return (
                <div className="flex justify-end items-center gap-4 p-3">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        {PAGECONSTANTS.PREVIOUS}
                    </button>

                    {pageNumbers.map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={`w-8 h-8 rounded ${currentPage === number ? "border border-gray-900" : ""}`}
                        >
                            {number}
                        </button>
                    ))}

                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(count / itemsPerPage)}>
                        {PAGECONSTANTS.NEXT}
                    </button>
                </div>
            );
        }

        // Show only the first and last maxVisiblePages/2 pages with dots in between

        const totalPages = pageNumbers.length;

        let firstPage = 1;

        let lastPage = maxVisiblePages;

        // If the current page is within the first maxVisiblePages/2 pages, show only the first maxVisiblePages

        if (currentPage <= maxVisiblePages / 2) {
            lastPage = maxVisiblePages;
        }

        // If the current page is within the last maxVisiblePages/2 pages, show only the last maxVisiblePages
        else if (currentPage >= totalPages - maxVisiblePages / 2) {
            firstPage = totalPages - maxVisiblePages + 1;

            lastPage = totalPages;
        }

        // Otherwise, show the current page and its neighbors, with dots on either side
        else {
            firstPage = currentPage - Math.floor(maxVisiblePages / 2);

            lastPage = currentPage + Math.floor(maxVisiblePages / 2);

            if (maxVisiblePages % 2 === 0) {
                firstPage += 1;
            }
        }

        const visiblePages = pageNumbers.slice(firstPage - 1, lastPage);

        return (
            <div className="flex justify-end items-center gap-4 p-3">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {PAGECONSTANTS.PREVIOUS}
                </button>

                {firstPage > 1 && <span>...</span>}

                {visiblePages.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`w-8 h-8 rounded ${currentPage === number ? "border border-gray-900" : ""}`}
                    >
                        {number}
                    </button>
                ))}

                {lastPage < totalPages && <span>...</span>}

                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(count / itemsPerPage)}>
                    {PAGECONSTANTS.NEXT}
                </button>
            </div>
        );
    };

    return (
        <div style={{ overflow: "auto", width: "100%" }}>
            {Array.isArray(data) && data.length > 0 ? (
                <>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr
                                style={{
                                    borderBottom: "2px solid #e5e7eb",
                                    fontSize: "0.875rem",
                                    textAlign: "left",
                                }}
                            >
                                {ThData()}
                            </tr>
                        </thead>
                        <tbody>{tdData()}</tbody>
                    </table>
                    {count > 4 && !print ? Pagination() : ""}
                </>
            ) : (
                <p style={{ textAlign: "center" }}>{PAGECONSTANTS?.NODATAFOUND}</p>
            )}
        </div>
    );
};
export default IssuerDetailsTable;
