import { interceptorsApiCall } from "../../../globals/interceptors";

export const GetAllRolesApi = interceptorsApiCall.injectEndpoints({
    endpoints: (builder) => ({
        GetAllRoles: builder.query({
            query: () => "/api/Admin/Roles/GetAllRoles",
        }),
    }),
});

export const { useGetAllRolesQuery } = GetAllRolesApi;
