import React, { useContext, useRef } from "react";
import { has, isArray } from "lodash";
import UserContext from "../context/modal/Contextmodel";
import { useGetWatchListQuery } from "../Services/Issuer/Home/IssuerHomeApi";
import { usePostWatchListMutation } from "../Services/Issuer/Search/IssuerSearchApi";
import { showToastify } from "../constants/Common";
import { queryString } from "../helpers";

const AddToWatchListView = ({ ModuleName }) => {
    const {
        setWLName,
        WLName,
        setChecked,
        setWLId,
        WLId,
        Isin,
        addToWatchList,
        shareDropDownValue,
        setShareDropDownValue,
        isDropdownVisible,
        setIsDropdownVisible,
        setAddToWatchList,
        Productid
    } = useContext(UserContext);
    const divRef = useRef();
    const body = {
        name: WLName,
        watchListId: WLId ? WLId : null,
        isin: Isin,
        moduleName: ModuleName,
        bloombergId: Productid ? Productid : null,
    };
    const getWatchListData = useGetWatchListQuery(queryString({ params: { ModuleName: ModuleName } }));
    const [postWatchList, postWatchListData] = usePostWatchListMutation();

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    const handleShareClick = (id, name) => {
        setShareDropDownValue(name);
        setIsDropdownVisible(false);
        setWLId(id);
    };

    const handleDropChange = () => {
        if (shareDropDownValue?.length === 0) {
            setIsDropdownVisible(false);
        }
    };

    React.useEffect(() => {
        if (addToWatchList === false) {
            setChecked([]);
            setWLName("");
            setWLId("");
            setShareDropDownValue("");
        }
    }, [addToWatchList]);

    React.useEffect(() => {
        if (postWatchListData?.isSuccess) {
            showToastify("success", "WatchList added successfully");
            setAddToWatchList(false);
        } else if (postWatchListData?.isError) {
            showToastify("warning", postWatchListData?.error?.data?.errors?.Name[0]);
            setWLName("");
            setAddToWatchList(false);
        }
    }, [postWatchListData]);

    React.useEffect(() => {
        getWatchListData.refetch();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <>
            <div className="flex flex-wrap">
                <div className=" mb-2 mt-2 w-full">
                    <h1 className="">Select an existing watchlist</h1>
                    <div>
                        <input
                            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            id="username"
                            type="text"
                            value={shareDropDownValue}
                            onClick={() => {
                                handleDropChange();
                                setIsDropdownVisible(!isDropdownVisible);
                            }}
                            disabled={WLName?.length > 0}
                            style={{
                                backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                                backgroundPosition: "340px 50%",
                                backgroundRepeat: "no-repeat",
                            }}
                        />

                        {isDropdownVisible && (
                            <div className="absolute left-0 w-full flex flex-col rounded mt-1  max-h-80 md:max-h-none h-40 px-3">
                                <div className="py-4 pl-1.5 bg-white border shadow rounded z-10 justify-between border-b last:border-b-0 flex overflow-auto ">
                                    <div className="flex text-dark flex-col gap-1 w-full " ref={divRef}>
                                        {has(getWatchListData, ["data"]) && isArray(getWatchListData?.data) && getWatchListData?.data?.length > 0 ? (
                                            getWatchListData?.data.map((data, index) => {
                                                return (
                                                    <h1
                                                        key={index}
                                                        onClick={() => handleShareClick(data?.id, data?.name)}
                                                        className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                                    >
                                                        {data?.name}
                                                    </h1>
                                                );
                                            })
                                        ) : (
                                            <h1
                                                ref={divRef}
                                                onClick={() => setIsDropdownVisible(false)}
                                                className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                            >
                                                <b>No Data Found</b>
                                            </h1>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <h1 className=" mb-2 mt-4">Save watchlist as:</h1>
            <input
                type="text"
                Value={WLName}
                onChange={(e) => {
                    setWLName(e.target.value);
                    setShareDropDownValue("");
                    setWLId("");
                }}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 && WLName.length > 0) {
                        postWatchList(body);
                        setWLName("");
                        setWLId("");
                    }
                }}
                id="small-input"
                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
        </>
    );
};

export default AddToWatchListView;
