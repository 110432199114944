export const IMG = {
  logo: "../../assets/images/logo.png",
  banner: "../../assets/images/banner.png",
  elevate: "../../assets/images/elevate.png",
};
export const WATCHLIST={
  WATCHLISTSHAREDMESSAGE:"WatchList Shared Successfully",
  DELETEWATCH:"Delete Watch List",
}
export const ISSUER = {
  ELIMINATE: "Eliminate Changes",
  SAVED: "Saved Search",
  ISSUERSORTINGHEADER: "ISSUER_SORTING_HEADER",
  ISSUERFILTER: "ISSUER_FILTER",
  ISSUERSORTING: "ISSUER_SORTING",
  SHARE: "Share your result",
  WATCH: "Watch List",
  ISSUERCOLUMNFILTER: "ISSUER_COLUMN_FILTER",
  EDITVIEWCHECKED: "EDIT_VIEW_CHECKED",
  ISSUERSEARCHTEXT: "ISSUER_SEARCH_TEXT",
  ISSUERBADGEVALUES: "ISSUER_BADGE_VALUES",
  SAVEDID: "SAVED_ID",
  PRODUCSAVEDID:'PRODUCTSAVED_ID',
  EXISTINGVIEW: "EXISTING_VIEW",
  SAVEDRETURNCOLUMN:"SAVED_RETURN_COLUMN",
  EDITVIEWID:"EDIT_VIEW_ID",
  SEARCH:
    "Search for Issuers by creating filters, saving custom views, and sharing your results with your teammates.",
};
export const USER_REVIEW={
  USERSEARCHTEXT: "USER_SEARCH_TEXT",
  USERFILTER: "USER_FILTER",
  USERBADGEVALUES: "USER_BADGE_VALUES",
  USERSORTINGHEADER: "USER_SORTING_HEADER",
  ACTIVETAB:"activeTab",
  USERFILTER: "USER_FILTER",
  USERSORTING: "USER_SORTING",
  USERREVIEWCOLUMNFILTER: "USER_REVIEW_COLUMN_FILTER",
  USERREVIEWEDITVIEWCHECKED: "USER_REVIEW_EDIT_VIEW_CHECKED",
  USERREVIEWEXISTINGVIEW: "USER_REVIEW_EXISTING_VIEW",
  USERREVIEWEDITVIEWID:"USER_REVIEW_EDIT_VIEW_ID",
  USERREVIEWSAVEDRETURNCOLUMN:"USER_REVIEW_SAVED_RETURN_COLUMN",
}
export const ADMIN = {
  PERMISSIONS: "Permissions",
  ADMINISTRATORS: "Administrators",
  USERS:"Manage Users",
  VIEWUSERS:"View Users",
  ROLES:"Manage Roles",
  VIEWROLES:"View Manage Roles",
  MANAGEACCESS:"Manage Access Control",
  VIEWMANAGEACCESS:"View Access Control",
  VIEWACCESS:"View Access Control Report",
  VIEWUSER:"View User Role Matrix"
};
export const Accesscontrolconst={
  PERMISSIONS:"Permissions",
  SYSTEMADMINISTRATOR:"System Administrator"
}
export const SAVEDSEARCH={
 DELETEMESSAGE:"Are you sure you want to delete",
 DELETETITLE:"Delete Saved Search",
 SAVEDSEARCHSHAREDMESSAGE:"Saved Search shared sucessfully",
}
export const Manageuserroles={
  USERS:"Users",
  SELECTEDROLES:"Selected User Roles"
}
export const LOGIN = {
  WELCOME: "Welcome back!!!",
  MESSAGE:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et..",
  LOGINMSG:
    "OneAscent Elevate Uses your office 365 active directory credentials. Please click the login button.",
  SIGNIN: "SignIn",
  LOGIN: "Login",
  LOGOUT: "Logout",
};
export const  EDITVIEWFOOTER={
 ENTERNAME:"Enter custom view name to save"
}

export const ISSUERLIST={
  SUCCESS:"success",
  STOCKEXCHANGE:"Stock Exchange",
  TICKER:"Ticker",
  SEARCHSUCESSFULLY:"Shared search sucessfully",
  WARNING:"warning",
  SHAREVALIDATION:"Please select email to share",
  ERROR:"error",
  SEARCHVALIDATION:"Please enter a value to search",
  EXISTINGVIEW:"Existing View:",
  DEFAULT:"Default",
  EDITVIEW:"Edit View",
  EXPORT:"Export",
}

export const PRODUCTLIST = {
  TICKER:"Ticker",
  NAME:"Name",
  PRODUCT_ID: "Product Id",
  PRODUCT_TYPE: "Product Type",
  PRODUCTSORTING: "PRODUCT_SORTING",
  PRODUCT_SHORTNOTES: "Enter short notes",
  PRODUCTCOLUMNFILTER: "PRODUCT_COLUMN_FILTER",
  PRODUCTSAVEDRETURNCOLUMN :"PRODUCT_SAVED_RETURNCOLUMN",
  PRODUCTSEARCHTEXT :"PRODUCT_SEARCH_TEXT",
  PRODUCTEDITVIEWCHECKED: "PRODUCT_EDIT_VIEW_CHECKED",
  PRODUCTEXISTINGVIEW: "PRODUCT_EXISTING_VIEW",
  PRODUCTEDITVIEWID:"PRODUCT_EDIT_VIEW_ID",
  PRODUCTFILTER :"PRODUCT_FILTER",
  PRODUCTBADGEVALUES :"PRODUCT_BADGE_VALUES",
  PRODUCTSORTINGNAME:"PRODUCTSORTING_NAME",
  PRODUCTHOLDINGSSORTING: "PRODUCTHOLDINGS_SORTING",
  PRODUCTHOLDINGSSORTINGNAME: "PRODUCTHOLDINGS_SORTING_NAME",
}
export const TAILWINDTABLE = {
  PREVIOUS:"Previous",
  NEXT:"Next"
};
export const RATINGLIST = {
  ELEVATE: "Elevate",
  ELIMINATE: " Eliminate",
};
export const DASHBOARD = {
  MESSAGE: "Message",
  SYSTEMHEALTH: "System Health",
  DATA: "Data import status",
  DASHBOARD: "Dashboard Data Refresh",
};
export const PRODUCT={
  PORTFOLIOPRODUCT:"Manager Selection Committee - Approved Products",
  PRODUCT:"Product",
  MORNINGSTAR:"Morningstar",
  SHARE_PRODUCTWATCHLIST: "Share Product WatchList",
  PERFORMANCE:"Performance",
  NOTES:"Notes",
  RISK:"Risk",
  SHARE_PRODUCTNOTES:"Share Product Notes",
  
}

export const HEADER = {
  CLOSEPANEL: "Close panel",
  HOME: "Home",
  ISSUER: "Issuer",
  PRODUCT: "Product",
  PORTFOLIO: "Portfolio",
  ADMIN: "Admin",
  MYPROFILE: " My Profile",
};
export const ISSUERDETAILS = {
  INDICES:"Indices", 
  COMPANY: "Company Profile",
  GICSSECTOR: "GICS Sector",
  GICSINDUSTRY: "GICS Industry",
  GICSINDUSTRYGROUP: "GICS Industry Group",
  MARKETCAP: "Market Cap  ",
  COUNTRY: "Country of Incorp",
  DESCRIPTION: "Description",
  NOTES: "Notes",
  SUBJECT: "Subject",
  USER: "User",
  DATE: "Date last Modified",
  ACTION: "Action",
  SHORTNOTES: "Enter short notes",
  INPUTVALUE:
    "laculis euismod netus enim magna at sit non turpis turpis Ut accumsan suspendisse elementum sollicitudin",
  VIEW: "View Notes",
  EDIT: "Edit Notes",
  SHARE_ISSUERNOTES: "Share Issuer Notes",
  SHARE_ISSUERWATCHLIST: "Share Issuer WatchList",
  SHARE_SAVEDSEARCH: "Share Saved Search",
  ENTERNAME: "Enter name",
  PRINT: "Print",
  SHAREVIEW: "Enter name",
  SHARE_WATCHLIST: "Share",
  ISIN: "ISIN",
  TICKER: "Ticker",
};

export const APP_VARIABLES = {
  BASE_URL: "https://api-vbi-dev.azurewebsites.net",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  NAVIGATE_PATH: "NAVIGATE_PATH",
};
