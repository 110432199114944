import React, { useEffect, useState } from "react";
import {isEmpty} from "lodash";
import Table from "../../../components/Table/Table";
import { ISSUER, ISSUERDETAILS, PRODUCT, WATCHLIST } from "../../../constants/appConstants";
import {useDeleteWatchListMutation,useGetPaginatedWatchListsQuery} from "../../../Services/Issuer/Home/IssuerHomeApi";
import { PAGECONSTANTS, getNormalDate, showToastify } from "../../../constants/Common";
import Modal from "../../../components/Modals/Modals";
import DeleteFooter from "../../DeleteFooter";
import { queryString } from "../../../helpers";
import ShareModal from "../../ShareModal/ShareModal";
import {useGetAllUsersQuery} from "../../../Services/ShareModal/ShareModalApi";
import UserContext from "../../../context/modal/Contextmodel";
import { usePostProductWatchListShareMutation } from "../../../Services/Product/ProductApi";

export const Productwatchlist = () => {
  const [deleteWatchList] = useDeleteWatchListMutation();
  const [deleteId, setDeleteId] = useState("");
  const [Watchlistdata, setWatchlistdata] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [isloading] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [shareInputValue, setShareInputValue] = useState("");
  const [tdata, settdata] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [shareDropDownValue, setShareDropDownValue] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [deletewatchlistname, setWatchlistname] = useState("");
  const [userId, setUserId] = useState("");
  const PaginatedWatchLists = useGetPaginatedWatchListsQuery(
    queryString({ params: { PageNumber: currentPage, PageSize: "5",ModuleName:"Products"} })
  );
  const GetUserDetailsData = useGetAllUsersQuery(
    queryString({ params: { searchText: shareInputValue } }),
    {
      skip: shareInputValue.length < 1,
    }
  );
  const [PostWatchListShare] = usePostProductWatchListShareMutation();
  const handleApiCall = () => {  
    showToastify(PAGECONSTANTS?.SUCCESS, PAGECONSTANTS?.DELETEDSUCCESSFULLY);
    deleteWatchList({ id: deleteId });
    if (Watchlistdata?.length === 1 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (Watchlistdata?.length <= 1 && currentPage === 1) {
      setCurrentPage(-1);
    }
    setSaveModal(false);
  };
  useEffect(() => {
    if (PaginatedWatchLists.isSuccess) PaginatedWatchLists.refetch();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const iconClick = (id, name) => {
    setSaveModal(true);
    setDeleteId(id);
    setWatchlistname(name);
  };
  const handleShareClick = (email, id) => {
    setIsDropdownVisible(false);
    setShareDropDownValue(email);
    setUserId(id);
  };
  const handlePostApi = () => {
    if (userId && tdata) {
      const body = {
        watchListId: tdata,
        sharedUserId: userId,
      };
      PostWatchListShare(body);
      showToastify(PAGECONSTANTS?.SUCCESS,WATCHLIST?.WATCHLISTSHAREDMESSAGE);
    } else {
      showToastify(PAGECONSTANTS?.WARNING, PAGECONSTANTS?.PLEASESELECTEMAIL);
    }
    setShareModal(false);
    setUserId("");
  };
  useEffect(() => {
    if (
      PaginatedWatchLists?.isSuccess &&
      !isEmpty(PaginatedWatchLists?.data.items)
    ) {
      const tempArr = [...PaginatedWatchLists.data.items];
      const newArr = tempArr.map((values) => {
        const myNewObject = {
          Action: true,
          ...values,
          lastModified: getNormalDate(values?.lastModified),
        };
        return myNewObject;
      });
      setWatchlistdata(newArr);
    }
  }, [PaginatedWatchLists]);

  useEffect(() => {
    if (
      PaginatedWatchLists?.isSuccess &&
      isEmpty(PaginatedWatchLists?.data.items) &&
      currentPage > 1
    ) {
      setCurrentPage(currentPage - 1);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PaginatedWatchLists]);

  return (
    <>
      <div className="flex justify-between align-middle mb-4">
        <h3 className="text-xl">{ISSUER.WATCH}</h3>
      </div>
      <div className="border rounded flex-grow">
        <Table
          data={PaginatedWatchLists?.isSuccess ? Watchlistdata : []}
          path="/productwatchlist"
          onclick={iconClick}
          loading={isloading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage="5"
          count={PaginatedWatchLists?.data?.totalCount}
          setshare={setShareModal}
          settdata={settdata}
          showTrash={true}
        />
      </div>
      <UserContext.Provider
        value={{
          shareInputValue,
          setShareInputValue,
          shareDropDownValue,
          setShareDropDownValue,
          Watchlistdata,
          isDropdownVisible,
          setIsDropdownVisible,
        }}
      >
        <Modal
          showmodal={saveModal}
          setshowmodal={setSaveModal}
          title={WATCHLIST?.DELETEWATCH}
          body={
            <div>
              Are you sure you want to delete "<b className="break-all">{deletewatchlistname}</b>"?
            </div>
          }
          footer={
            <DeleteFooter onClick={handleApiCall} setSaveModal={setSaveModal} />
          }
          isFooterEnable={true}
          size={"w-96"}
        />
        <ShareModal
          title={PRODUCT?.SHARE_PRODUCTWATCHLIST}
          size={"w-full sm:w-96"}
          showmodal={shareModal}
          setshowmodal={setShareModal}
          ApiData={GetUserDetailsData?.isSuccess && GetUserDetailsData}
          handleShareClick={handleShareClick}
          setShareModal={setShareModal}
          onClick={handlePostApi}
          // id={settdata}
          setUserId={setUserId}
        />
      </UserContext.Provider>
    </>
  );
};
