import React, { useEffect, useState } from "react";
import { has, isArray, isEmpty, isNull } from "lodash";
import { PRODUCT } from "../../../constants/appConstants";
import Table from "../../../components/Table/Table";
import { useGetOaisProductDataMutation } from "../../../Services/Product/ProductApi";
import { getKeyValuePair } from "../../../constants/Common";

export const Productportfolio = () => {
    const pageSize = 25;
    const [data, setData] = useState([]);
    const [sortheader,setSortheader]=useState('')
    const[sortoder,serSortoder]=useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [getProductPortfolio, getProductPortfolioData] = useGetOaisProductDataMutation();
    const sortFilter = sortheader ? { [sortheader]: sortoder } : null;
    const intialSort={WeightedEliminatedPercent:1}
    useEffect(() => {
        getProductPortfolio({
            searchText: "*",
            currentPage: currentPage,
            pageSize: pageSize,
            useDefaultFacets: false,
            sortOrderfilters: isNull(sortFilter)?intialSort:sortFilter,
            fieldNamesToReturn: [],
            fieldNamesToSearch: [],
            facetOptions: null,
        });
    }, [currentPage, pageSize, getProductPortfolio,sortoder]);
    
    useEffect(() => {
        if (
            getProductPortfolioData?.isSuccess &&
            has(getProductPortfolioData, ["data", "results"]) &&
            isArray(getProductPortfolioData?.data?.results) &&
            !isEmpty(getProductPortfolioData?.data?.results)
        ) {
            const tempArr = getProductPortfolioData?.data?.results?.map((data) => {
            return data?.document;
            });
            const newArr = tempArr.map((values) => ({
                Name: values.Name,
                Ticker: values.Ticker,
                "%Eliminated": values.WeightedEliminatedPercent,
                ProductId: values.ProductId,
            }));
            setData(newArr);
        }
    }, [getProductPortfolioData]);
    return (
        <div className="flex flex-col w-1/2 h-full overflow-auto">
            <div className="flex justify-between align-middle mb-4">
                <h3 className="text-xl">{PRODUCT.PORTFOLIOPRODUCT}</h3>
            </div>
            <div className="border rounded flex-grow overflow-hidden h-full static-scroll">
                <Table
                    data={data}
                    path={"/productDetails"}
                    serSortoder={serSortoder}
                    itemsPerPage={pageSize.toString()}
                    sorticon={true}
                    sortoder={sortoder}
                    setSortheader={setSortheader}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    visible={getProductPortfolioData?.data?.count <= 10 ? true : false}
                    setCurrentPage={setCurrentPage}
                    count={getProductPortfolioData?.data?.count}
                    keyValue="productPortfolio"
                />
            </div>
        </div>
    );
};
