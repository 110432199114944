import { interceptorsApiCall } from "../../globals/interceptors";

export const GetMasterSearchApi = interceptorsApiCall.injectEndpoints({
	endpoints: (builder) => ({
		getMasterSearch: builder.query({
			query: (params) => `api/Issuer/LookupIssuerandProduct?${params}`,
		}),
	}),
});

export const { useGetMasterSearchQuery} = GetMasterSearchApi;
