import { interceptorsApiCall } from "../../../globals/interceptors";
const apiWithTag = interceptorsApiCall.enhanceEndpoints({ addTagTypes: ["Post"] });
export const IssuerHomeApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getWatchList: builder.query({
            query: (params) => `api/Issuer/GetWatchLists?${params}`,
            providesTags: ["Post"],
        }),
        getSavedSearch: builder.query({
            query: () => "api/Issuer/GetSavedSearchList",
        }),
        getSavedSearchPagination: builder.query({
            query: (params) => `/api/Issuer/GetPaginatedSavedSearch?${params}`,
            providesTags: ["DeleteSaved"],
        }),
        getIssuerReturnField: builder.query({
            query: (params) => `api/Issuer/GetDefaultReturnFields?${params}`,
        }),
        getPaginatedWatchLists: builder.query({
            query: (params) => `/api/Issuer/GetPaginatedWatchLists?${params}`,
            providesTags: ["Post"],
        }),
        deleteWatchList: builder.mutation({
            query: (payload) => ({
                url: "api/Issuer/DeleteWatchList",
                method: "DELETE",
                body: payload,
                //   credentials: 'include',
            }),
            invalidatesTags: ["Post"],
        }),
        deleteSavedSearch: builder.mutation({
            query: (payload) => ({
                url: "api/Issuer/DeleteSavedSearch",
                method: "DELETE",
                body: payload,
                //   credentials: 'include',
            }),
            invalidatesTags: ["DeleteSaved"],
        }),
        getProductSavedSearchList: builder.query({
            query: (params) => `/api/Issuer/GetSavedSearchList?${params}`,
        }),
    }),
});

export const {
    useGetWatchListQuery,
    useGetSavedSearchQuery,
    useDeleteWatchListMutation,
    useDeleteSavedSearchMutation,
    useGetSavedSearchPaginationQuery,
    useGetIssuerReturnFieldQuery,
    useGetPaginatedWatchListsQuery,
	useGetProductSavedSearchListQuery,
} = IssuerHomeApi;
