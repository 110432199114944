import React, { useContext } from "react";
import Button from "../../components/Button/Button";
import UserContext from "../../context/modal/Contextmodel";

const EditUserReviewModalFooter = () => {
    const { setUserEditView, setTriggerUserReviewUpdate, canEditState, canApproveState } = useContext(UserContext);
    const closeModal = () => {
        setUserEditView(false);
    };
    return (
        <div>
            <div className="grid grid-rows-1 grid-flow-col mt-4 gap-4">
                <Button value="Cancel" variant="secondary" onClick={closeModal} />

                <Button
                    // disabled={canApproveState === false}
                    className="cursor-pointer"
                    value={"Submit"}
                    variant="primary"
                    onClick={() => {
                        setTriggerUserReviewUpdate(true);
                        closeModal();
                    }}
                />
            </div>
        </div>
    );
};

export default EditUserReviewModalFooter;
