import React, { useEffect, useRef, useState, useContext } from "react";
import { Tab } from "@headlessui/react";
import UserReviewFileUpload from "./UserReviewFileUpload";
import UserContext from "../../context/modal/Contextmodel";
import { queryString } from "../../helpers";
import { useGetIssueSubIssueDataGroupingQuery, usePostCreateUserReviewMutation } from "../../Services/Issuer/UserReview/UserReviewApi";
const AddUserReviewModal = (props) => {
    const { setTabchange, setCheckValue, setapibody } = useContext(UserContext);
    const divRef = useRef();
    const inputClassName = `appearance-none border border-gray-300 rounded-md  py-2 px-2 text-gray-700 leading-tight focus:outline-none h-[110px]`;
    const tabcss =
        "whitespace-nowrap px-0 py-4 mx-6 sm:py-0 ui-selected:border-b-primary ui-selected:border-b-2 ui-selected:text-primary ui-not-selected:border-b-2 ui-not-selected:border-b-transparent ui-not-selected:text-black";
    const currentDatetime = new Date();
    const statusRequestArray = ["Pass", "Fail"];
    const [selectedIssue, setSelectedIssue] = useState("");
    const [selectedSubIssue, setSelectedSubIssue] = useState("");
    const [userSubIssueDropdown, setUserSubIssueDropdown] = useState();
    const [issueDropdownVisible, setIssueDropdownVisible] = useState(false);
    const [subIssueDropdownVisible, setSubIssueDropdownVisible] = useState(false);
    const [statusRequestValue, setStatusRequestValue] = useState("");
    const [statusRequestDropdownVisible, setStatusRequestDropdownVisible] = useState(false);
    const [tab, setTab] = useState("details");
    const [rationaleValue, setRationaleValue] = useState("");
    const [PostCreateUserReview, PostCreated] = usePostCreateUserReviewMutation();
    const GetIssueSubIssueDataGroupingQuery = useGetIssueSubIssueDataGroupingQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));

    const handleIssueDropdown = (issueName) => {
        setSelectedIssue(issueName);
        setIssueDropdownVisible(false);
    };

    const handleStatusRequestDropdown = (request) => {
        setStatusRequestValue(request);
    };

    const handleSubIssueDropdown = (subIssueName) => {
        setSelectedSubIssue(subIssueName);
    };

    const subIssueNameDropdown = (issueName) => {
        const subIssueData = GetIssueSubIssueDataGroupingQuery?.data[issueName];
        if (subIssueData) {
            const subIssueValues = subIssueData.flatMap((value) => value);
            setUserSubIssueDropdown(subIssueValues);
            return subIssueValues;
        }
    };

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIssueDropdownVisible(false);
                setSubIssueDropdownVisible(false);
                setStatusRequestDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [divRef]);
    useEffect(() => {
        setapibody({
            dateSubmitted: currentDatetime.toISOString(),
            issuerName: props?.EliminateIssuerData?.data?.name,
            ticker: props?.EliminateIssuerData?.data?.ticker,
            isin: props?.EliminateIssuerData?.data?.isin,
            issueName: selectedIssue,
            subIssueName: selectedSubIssue,
            approvalStatus: null,
            requestStatus: statusRequestValue,
            note: rationaleValue,
        });
        if (selectedIssue?.length > 0 && selectedSubIssue?.length > 0 && rationaleValue?.length > 0 && statusRequestValue?.length > 0) {
            setCheckValue(true);
        } else {
            setCheckValue(false);
        }
    }, [selectedIssue, selectedSubIssue, rationaleValue, statusRequestValue]);

    useEffect(() => {
        if (PostCreated.isSuccess) {
            sessionStorage.setItem("userid", PostCreated?.data?.id);
        }
    }, [PostCreated]);
    useEffect(() => {
        if (GetIssueSubIssueDataGroupingQuery.isSuccess) {
            GetIssueSubIssueDataGroupingQuery.refetch();
        }
    }, []);
    return (
        <>
            <Tab.Group>
                <div className="w-100 flex justify-between w-fit gap-4 mb-4 static-scroll">
                    <div className="flex overflow-auto">
                        <Tab.List className="flex h-full align-middle overflow-auto">
                            <Tab
                                id="details"
                                className={tabcss}
                                onClick={() => {
                                    setTab("details");
                                    setTabchange("details");
                                }}
                            >
                                Details
                            </Tab>
                            <Tab
                                id="attachments"
                                className={tabcss}
                                onClick={() => {
                                    setTab("attachments");
                                    setTabchange("attachments");
                                }}
                            >
                                Attach Files
                            </Tab>
                        </Tab.List>
                    </div>
                </div>

                <Tab.Panels className="flex-grow">
                    {tab === "details" && (
                        <>
                            <div className="sm:grid sm:grid-cols-2 gap-4">
                                <div>
                                    <p>
                                        <strong>Issuer Name:</strong>
                                        <span
                                            className="ml-2"
                                            title={props.EliminateIssuerData?.data?.name?.length > 18 ? props.EliminateIssuerData?.data?.name : ""}
                                        >
                                            {props.EliminateIssuerData?.data?.name?.length > 18
                                                ? props.EliminateIssuerData?.data?.name?.slice(0, 15) + "..."
                                                : props.EliminateIssuerData?.data?.name}
                                        </span>
                                    </p>
                                    <p className="mt-3">
                                        <strong>Issue</strong>
                                    </p>
                                    <div className="relative">
                                        <input
                                            className="block cursor-pointer w-full p-2 mt-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            id="username"
                                            type="text"
                                            value={selectedIssue}
                                            ref={divRef}
                                            onClick={() => {
                                                setIssueDropdownVisible(!issueDropdownVisible);
                                            }}
                                            style={{
                                                backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                                                backgroundPosition: "right 50%",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        />
                                        {issueDropdownVisible && (
                                            <div ref={divRef} className="absolute left-0 flex flex-col rounded mt-1 max-h-80 md:max-h-none h-40">
                                                <div className="py-4 pl-1.5 bg-white border shadow rounded z-10 justify-between border-b last:border-b-0 flex overflow-auto">
                                                    <div className="flex text-dark flex-col gap-1 w-[98%]">
                                                        {GetIssueSubIssueDataGroupingQuery?.data ? (
                                                            Object.keys(GetIssueSubIssueDataGroupingQuery?.data).map((issueName) => (
                                                                <h1
                                                                    key={issueName}
                                                                    onClick={() => {
                                                                        handleIssueDropdown(issueName);
                                                                        subIssueNameDropdown(issueName);
                                                                    }}
                                                                    className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                                >
                                                                    {issueName}
                                                                </h1>
                                                            ))
                                                        ) : (
                                                            <h1
                                                                ref={divRef}
                                                                onClick={() => setIssueDropdownVisible(false)}
                                                                className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                            >
                                                                <b>No Data Found</b>
                                                            </h1>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <p>
                                        <strong>Issuer Ticker:</strong>
                                        <span className="pl-2">{props.EliminateIssuerData?.data?.ticker}</span>
                                    </p>
                                    <p className="mt-3">
                                        <strong>Sub-Issue</strong>
                                    </p>
                                    <div className="relative">
                                        <input
                                            className="block cursor-pointer w-full mt-2 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            id="username"
                                            type="text"
                                            value={selectedSubIssue}
                                            ref={divRef}
                                            onClick={() => {
                                                setSubIssueDropdownVisible(!subIssueDropdownVisible);
                                            }}
                                            style={{
                                                backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                                                backgroundPosition: "right 50%",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        />
                                        {subIssueDropdownVisible && (
                                            <div ref={divRef} className="absolute flex flex-col rounded mt-1 max-h-80 md:max-h-none h-40 w-full">
                                                <div className="py-4 pl-1.5 bg-white border shadow rounded z-10 justify-between border-b last:border-b-0 flex overflow-auto">
                                                    <div className="flex text-dark flex-col gap-1 w-[98%]">
                                                        {Array.isArray(userSubIssueDropdown) && userSubIssueDropdown.length > 0 ? (
                                                            userSubIssueDropdown.map((item) => (
                                                                <h1
                                                                    key={item}
                                                                    onClick={() => {
                                                                        handleSubIssueDropdown(item);
                                                                        setSubIssueDropdownVisible(false);
                                                                    }}
                                                                    className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                                >
                                                                    {item}
                                                                </h1>
                                                            ))
                                                        ) : (
                                                            <h1
                                                                onClick={() => setSubIssueDropdownVisible(false)}
                                                                className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                            >
                                                                <b>No Data Found</b>
                                                            </h1>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="mt-3">
                                    <strong>Request Status</strong>
                                </p>
                                <div className="relative">
                                    <input
                                        className="block cursor-pointer w-full mt-2 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        id="username"
                                        type="text"
                                        value={statusRequestValue}
                                        ref={divRef}
                                        onClick={() => {
                                            setStatusRequestDropdownVisible(!statusRequestDropdownVisible);
                                        }}
                                        style={{
                                            backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                                            backgroundPosition: "515px 50%",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    />
                                    {statusRequestDropdownVisible && (
                                        <div ref={divRef} className="absolute flex flex-col rounded mt-1 max-h-80 md:max-h-none h-40 w-full">
                                            <div className="py-4 pl-1.5 bg-white border shadow rounded z-10 justify-between border-b last:border-b-0 flex overflow-auto">
                                                <div className="flex text-dark flex-col gap-1 w-[98%]">
                                                    {statusRequestArray.length > 0 ? (
                                                        statusRequestArray.map((item) => (
                                                            <h1
                                                                key={item}
                                                                onClick={() => {
                                                                    handleStatusRequestDropdown(item);
                                                                    setStatusRequestDropdownVisible(false);
                                                                }}
                                                                className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                            >
                                                                {item}
                                                            </h1>
                                                        ))
                                                    ) : (
                                                        <h1
                                                            onClick={() => setStatusRequestDropdownVisible(false)}
                                                            className="cursor-pointer bg-white hover:bg-gray-200 rounded pl-2 py-1"
                                                        >
                                                            <b>No Data Found</b>
                                                        </h1>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <h3 className="mt-3">
                                <strong>Notes</strong>
                            </h3>
                            <textarea
                                className={`${inputClassName} w-full mt-2 resize-none`}
                                type="text"
                                value={rationaleValue}
                                onChange={(e) => setRationaleValue(e.target.value)}
                            />
                        </>
                    )}
                    {tab === "attachments" && (
                        <div class="grid grid-cols-1 gap-4">
                            <div className="border-2 border-dashed mt-3 mb-3 p-0">
                                <UserReviewFileUpload setAttachfile={props.setAttachfile} />
                            </div>
                        </div>
                    )}
                </Tab.Panels>
            </Tab.Group>
        </>
    );
};

export default AddUserReviewModal;
