import { React, Fragment, useRef, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import UserContext from "../../context/modal/Contextmodel";
import { has, isArray, isEmpty } from "lodash";
import Button from "../../components/Button/Button";

export default function ShareModal({ ApiData, handleShareClick, footer, title, showmodal, setshowmodal, size, setShareModal, setUserId, id, onClick }) {
    const cancelButtonRef = useRef(null);
    const { shareInputValue, setShareInputValue, shareDropDownValue, setShareDropDownValue, isDropdownVisible, setIsDropdownVisible } = useContext(UserContext);

    useEffect(() => {
        if (showmodal === false) {
            // setSelectedCheckbox("")
            setShareInputValue("");
            setShareDropDownValue("");
        }
    }, [showmodal]);
    return (
        <Transition.Root show={showmodal} as={Fragment}>
            <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={setshowmodal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 overflow-y-auto ">
                    <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={`relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${size}`}>
                                <div className="bg-white pb-0 p-3 sm:pb-0 rounded-xl">
                                    <div className="w-full">
                                        <div className="mt-3 sm:mt-0 sm:text-left">
                                            <div className="flex border-gray-200 border-b pb-2 justify-between">
                                                <Dialog.Title as="h1" className="text-xl font-semibold leading-6 text-gray-900">
                                                    {title}
                                                </Dialog.Title>
                                                <button>
                                                    <i
                                                        className="far fa-times"
                                                        onClick={() => {
                                                            setshowmodal(false);
                                                        }}
                                                    ></i>
                                                </button>
                                            </div>
                                            <div className="pt-4 w-full">
                                                {/* {title === "Share Saved Search" ? (
                          <Sharesearchcheckbox />
                        ) : (
                          ""
                        )} */}
                                                <div>
                                                    <div className="relative">
                                                        {title === "Share Saved Search" ? (
                                                            <h1 className="mb-2">Enter a username to share the saved search</h1>
                                                        ) : (
                                                            <h1 className="  "> Enter name</h1>
                                                        )}
                                                        <input
                                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            id="username"
                                                            type="text"
                                                            placeholder="Enter a name"
                                                            value={shareDropDownValue || shareInputValue}
                                                            onChange={(e) => {
                                                                setShareDropDownValue("");
                                                                setShareInputValue(e.target.value);
                                                                setIsDropdownVisible(e.target.value?.length > 0);
                                                            }}
                                                        />
                                                        {shareInputValue?.length > 0 && isDropdownVisible ? (
                                                            <div className="absolute left-0 w-full flex flex-col rounded  max-h-80 md:max-h-none mt-4 h-40">
                                                                <div className="py-4 pl-1.5 bg-white border shadow rounded items-center justify-between border-b last:border-b-0 flex overflow-auto ">
                                                                    <div className="flex text-dark flex-col gap-1 w-full ">
                                                                        {has(ApiData, ["data"]) &&
                                                                            isArray(ApiData?.data) &&
                                                                            !isEmpty(ApiData?.data) &&
                                                                            ApiData?.data?.map((data) => {
                                                                                return (
                                                                                    <h1
                                                                                        key={data.email}
                                                                                        onClick={() => handleShareClick(data.email, data.userId)}
                                                                                        className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                                                                    >
                                                                                        {data.email}
                                                                                    </h1>
                                                                                );
                                                                            })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-4 md:flex sm:flex-row-reverse sm:px-3">
                                    <div className="grid grid-rows-1 gap-5 grid-flow-col">
                                        <Button
                                            value="Cancel"
                                            variant="secondary"
                                            onClick={() => {
                                                setShareModal(false);
                                                setShareInputValue("");
                                                setShareDropDownValue("");
                                                id("");
                                                setUserId("");
                                            }}
                                        />
                                        {(title === "Share Saved Search" || title === "Share Issuer WatchList") && shareInputValue?.length >= 1 ? (
                                            <Button value="Share" variant="primary" onClick={onClick} />
                                        ) : title === "Share Product Notes" && shareInputValue?.length >= 1 ? (
                                            <Button value="Share" variant="primary" onClick={onClick} />
                                        ) : title === "Share Saved Search" || title === "Share Issuer WatchList" ? (
                                            <Button value="Share" className="opacity-50 cursor-not-allowed" variant="primary" />
                                        ) : shareInputValue?.length >= 1 ? (
                                            <Button value="Share" variant="primary" onClick={onClick} />
                                        ) : (
                                            <Button value="Share" variant="primary" className="opacity-50 cursor-not-allowed" />
                                        )}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
