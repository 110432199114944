import { interceptorsApiCall } from "../../../globals/interceptors";
const apiWithTag = interceptorsApiCall.enhanceEndpoints({ addTagTypes: ["Roles"] });
export const RolesGetApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        GetRolesApi: builder.query({
            query: () => "api/Admin/Roles/GetAllRoles",
            providesTags: ["Roles"],
        }),
        deleteRolesApi: builder.mutation({
            query: (params) => ({
                url: `api/Admin/Roles/DeleteRole?${params}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Roles"],
        }),
        createRoles: builder.mutation({
            query: (payload) => {
                return {
                    url: "api/Admin/Roles/CreateRole",
                    method: "POST",
                    body: payload,
                };
            },
            invalidatesTags: ["Roles"],
        }),
        updateRoles: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/Admin/Roles/UpdateRole`,
                    method: "PUT",
                    body: payload,
                };
            },
            invalidatesTags: ["Roles"],
        }),
    }),
});

export const { useGetRolesApiQuery, useDeleteRolesApiMutation, useCreateRolesMutation, useUpdateRolesMutation } = RolesGetApi;
