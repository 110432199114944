import React, { useEffect, useState } from "react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import Checkbox from "../../../components/Checkbox/Checkbox";
import {
    useAddUserRoleMutation,
    useDeleteUserRoleMutation,
    useGetAllManageUsersQuery,
    useGetUserByIdQuery,
} from "../../../Services/Admin/ManageUsers/GetUserById";
import { queryString } from "../../../helpers";
import { ISSUERLIST, Manageuserroles } from "../../../constants/appConstants";
import Modal from "../../../components/Modals/Modals";
import EditManageUsersModal from "./EditManageUsersModal";
import EditManageUsersModalFooter from "./EditManageUsersModalFooter";
import { disPascalize, showToastify } from "../../../constants/Common";
import { useGetAllRolesQuery } from "../../../Services/Admin/ManageUsers/GetAllRoles";
import ViewUserRoleReport from "../ViewUserRoleReport/ViewUserRoleReport";

const ManageUserRoles = ({ canEdit }) => {
    const [userId, setUserId] = useState("");
    const [roleId, setRoleId] = useState([]);
    const [editUsersModal, setEditUsersModal] = useState(false);
    const [matrixReportModal, setMatrixReportModal] = useState(false);
    const [activeTab, setActiveTab] = useState();
    const [searchUser, setSearchUser] = useState("");
    const getAllManageUsersList = useGetAllManageUsersQuery(queryString({ params: { searchtext: searchUser } }));
    const getManageUserById = useGetUserByIdQuery(queryString({ params: { userId: userId } }), { skip: userId.length === 0 });
    const [postAddUserapi] = useAddUserRoleMutation();
    const [DeleteUserapi] = useDeleteUserRoleMutation();
    const getAllRolesList = useGetAllRolesQuery();

    const OnChangeHandler = (e) => {
        setSearchUser(e.target.value);
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (e.target.value.length > 0) {
                setSearchUser(e.target.value);
                getAllManageUsersList({
                    searchText: searchUser,
                });
            } else if (e.target.value.length === 0) {
                showToastify(ISSUERLIST?.ERROR, ISSUERLIST?.SEARCHVALIDATION);
                setSearchUser("");
            }
        }
        if (e.target.value.length === 0) {
            setSearchUser("");
            setUserId("");
        }
    };
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    useEffect(() => {
        getAllRolesList.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="grid grid-cols-12 gap-4 md:gap-8">
                <div class="col-span-12 md:col-span-6">
                    <div class="flex justify-between">
                        <span className="font-bold self-center">{Manageuserroles?.USERS}</span>
                        <div className="md:w-3/5 md:relative drop-shadow-sm">
                            <input
                                class="bg-primary_opacity-50 border-0 px-2 focus:ring-transparent focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-1 pl-3 text-dark"
                                type="text"
                                aria-label="Filter projects"
                                placeholder={searchUser?.length > 0 ? "" : "Search Users..."}
                                onKeyDown={handleKeyPress}
                                onChange={OnChangeHandler}
                                value={searchUser === "*" ? "" : searchUser}
                                style={{ backgroundColor: "rgb(243,244,246)" }}
                            />
                            {searchUser?.length > 0 && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                    class="w-5 text-dark absolute right-2 top-2 cursor-pointer"
                                    onClick={() => {
                                        setSearchUser("");
                                        setUserId("");
                                    }}
                                >
                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                </svg>
                            )}
                        </div>

                        <div
                            className="p-1 rounded cursor-pointer"
                            style={{ backgroundColor: "rgb(243,244,246)" }}
                            onClick={() => {
                                setEditUsersModal(true);
                                !userId && setEditUsersModal(false);
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="border-2 min-h-[404px] max-h-[404px] mt-3 mb-3 overflow-auto static-scroll p-0">
                        {getAllManageUsersList &&
                            getAllManageUsersList?.isSuccess &&
                            getAllManageUsersList?.data?.map((value, index) => {
                                return (
                                    <div
                                        className={`p-1 flex ${activeTab === index && userId.length ? "bg-primary text-white" : ""
                                            } hover:bg-primary hover:text-white cursor-pointer`}
                                        onClick={() => {
                                            setUserId(value?.userId);
                                            handleTabClick(index);
                                        }}
                                    >
                                        <UserCircleIcon className={`w-6 rounded-full ${value?.isActive === false ? "text-red-500" : "text-black-500"}`} />
                                        <span className={`self-center mx-3  ${value?.isActive === false ? "text-red-500" : "text-black-500"}`}>
                                            {value?.fullName}
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6">
                    <div className="flex justify-between" style={{ height: "32px" }}>
                        <span className="font-bold self-center">{Manageuserroles?.SELECTEDROLES}</span>
                    </div>
                    <div className="border-2 mt-3 min-h-[404px] max-h-[404px] overflow-auto static-scroll">
                        <>
                            {getAllRolesList && getAllRolesList?.isSuccess && userId ? (
                                getAllRolesList?.data?.map((value) => {
                                    return getAllRolesList?.data?.length > 0 ? (
                                        <ul>
                                            <li className="px-3 pt-3">
                                                <Checkbox
                                                    label={disPascalize(value?.name)}
                                                    disabled={getManageUserById?.data?.isActive === false || !canEdit}
                                                    Checked={userId?.length ? getManageUserById?.data?.roles?.includes(value?.name) : false}
                                                    className={"rounded"}
                                                    id={value?.name}
                                                    handleChange={(e) => {
                                                        if (e.target.checked) {
                                                            setRoleId([...roleId, value?.roleId]);
                                                            postAddUserapi({ userId: getManageUserById?.data?.userId, roleId: value?.roleId });
                                                        } else {
                                                            DeleteUserapi({ userId: getManageUserById?.data?.userId, roleId: value?.roleId });
                                                        }
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    ) : (
                                        <></>
                                    );
                                })
                            ) : (
                                <p className="text-center" style={{ marginTop: "180px" }}>
                                    Please select any user to view the roles!
                                </p>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <Modal
                setshowmodal={setEditUsersModal}
                showmodal={editUsersModal}
                title="Edit User"
                size={"sm:min-w-[576px] min-w-full"}
                body={
                    <EditManageUsersModal
                        userDetail={getManageUserById}
                        userId={userId}
                        roleId={roleId}
                        getAllManageUsersList={getAllManageUsersList}
                        canEdit={canEdit}
                    />
                }
                footer={<EditManageUsersModalFooter setEditUsersModal={setEditUsersModal} editUsersModal={editUsersModal} />}
                isFooterEnable={true}
            />
            <Modal
                setshowmodal={setMatrixReportModal}
                showmodal={matrixReportModal}
                title="Role Matrix Report"
                size={"max-w-[576px]"}
                body={
                    <div className="min-h-[360px] max-h-[360px]">
                        <ViewUserRoleReport />
                    </div>
                }
                footer={<EditManageUsersModalFooter setMatrixReportModal={setMatrixReportModal} />}
                isFooterEnable={true}
            />
        </>
    );
};

export default ManageUserRoles;
