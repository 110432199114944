import React, { useEffect, useState } from "react";
import { has, isArray, isEmpty } from "lodash";
import Table from "../../../components/Table/Table";
import { useDeleteSavedSearchMutation,useGetSavedSearchPaginationQuery} from "../../../Services/Issuer/Home/IssuerHomeApi";
import {useGetAllUsersQuery,usePostSavedSearchShareMutation} from "../../../Services/ShareModal/ShareModalApi";
import { ISSUER, ISSUERDETAILS, ISSUERLIST, SAVEDSEARCH } from "../../../constants/appConstants";
import { getNormalDate } from "../../../components/Table/TailwindTable";
import { queryString } from "../../../helpers";
import ShareModal from "../../ShareModal/ShareModal";
import UserContext from "../../../context/modal/Contextmodel";
import Modal from "../../../components/Modals/Modals";
import DeleteFooter from "../../DeleteFooter";
import { PAGECONSTANTS, showToastify } from "../../../constants/Common";
export const Savedsearch = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [shareInputValue, setShareInputValue] = useState("");
  const [userId, setUserId] = useState("");
  const [tdata, settdata] = useState({});
  const [shareDropDownValue, setShareDropDownValue] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);
  const [saveModal, setSaveModal] = useState(false);
  const [deleteiteamname, setDeleteiteamname] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const GetSavedSearchPagination = useGetSavedSearchPaginationQuery(
    queryString({ params: { PageNumber: currentPage, PageSize: "5" } })
  );
  const GetUserDetailsData = useGetAllUsersQuery(
    queryString({ params: { searchText: shareInputValue } }),
    {
      skip: shareInputValue.length < 1,
    }
  );
  const [PostSavedSearchShare, PostSavedSearchShareData] =
    usePostSavedSearchShareMutation();
  const [deleteSavedSearch] = useDeleteSavedSearchMutation();
  const handlePostApi = () => {
    if (userId && tdata) {
      const body = {
        savedSearchId: tdata,
        sharedUserId: userId,
      };
      PostSavedSearchShare(body);
    } else {
      showToastify(ISSUERLIST?.WARNING,ISSUERLIST?.SHAREVALIDATION);
    }
    setShareModal(false);
    setUserId("");
  };
 
  useEffect(() => {
    if (PostSavedSearchShareData.isSuccess === true) {
      showToastify(ISSUERLIST?.SUCCESS, SAVEDSEARCH?.SAVEDSEARCHSHAREDMESSAGE);
    }
  }, [PostSavedSearchShareData]);
  const handleShareClick = (email, id) => {
    setIsDropdownVisible(false);
    setShareDropDownValue(email);
    setUserId(id);
  };
  const handleApiCall = () => {
    showToastify(ISSUERLIST?.SUCCESS,PAGECONSTANTS?.DELETEDSUCCESSFULLY);
    deleteSavedSearch({ id: deleteId });
    if (data?.length === 1 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (data?.length <= 1 && currentPage === 1) {
      setCurrentPage(-1);
    }
    setSaveModal(false);
  };
  const iconClick = (id, name) => {
    setSaveModal(true);
    setDeleteId(id);
    setDeleteiteamname(name);
  };

  useEffect(() => {
    if(GetSavedSearchPagination.isSuccess)
    {
      GetSavedSearchPagination.refetch();
      if (GetSavedSearchPagination?.length === 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      } else if (GetSavedSearchPagination?.length <= 1 && currentPage === 1) {
        setCurrentPage(-1);
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      GetSavedSearchPagination?.isSuccess &&
      has(GetSavedSearchPagination, ["data", "items"]) &&
      isArray(GetSavedSearchPagination?.data?.items) &&
      !isEmpty(GetSavedSearchPagination?.data?.items)
    ) {
      const tempArr = [...GetSavedSearchPagination?.data?.items];
      const newArr = tempArr.map((values) => {
        const myNewObject = {
          Action: true,
          ...values,
          lastModified: getNormalDate(values?.lastModified),
        };
        return myNewObject;
      });
      setData(newArr);
    }
  }, [GetSavedSearchPagination]);
  return (
    <div className="flex flex-col gap-8 grow">
      <div className="flex flex-col grow">
        <div className="flex justify-between align-middle mb-4">
          <h3 className="text-xl">{ISSUER.SAVED}</h3>
        </div>
        <div className="border rounded flex-grow">
          <Table
            data={GetSavedSearchPagination?.isSuccess ? data : []}
            path={"/issuer/search"}
            onclick={iconClick}
            itemsPerPage="5"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            count={GetSavedSearchPagination?.data?.totalCount}
            setshare={setShareModal}
            settdata={settdata}
            showTrash={true}
          />
        </div>
      </div>
      <UserContext.Provider
        value={{
          shareInputValue,
          setShareInputValue,
          shareDropDownValue,
          setShareDropDownValue,
          isDropdownVisible,
          setIsDropdownVisible,
        }}
      >
        <Modal
          showmodal={saveModal}
          setshowmodal={setSaveModal}
          title={SAVEDSEARCH?.DELETETITLE}
          body={
            <div>
              {SAVEDSEARCH?.DELETEMESSAGE} "<b className="break-all" >{deleteiteamname}</b>"?
            </div>
          }
          footer={
            <DeleteFooter onClick={handleApiCall} setSaveModal={setSaveModal} />
          }
          isFooterEnable={true}
          size={"w-96"}
        />
        <ShareModal
          title={ISSUERDETAILS.SHARE_SAVEDSEARCH}
          size={"w-full sm:w-96"}
          showmodal={shareModal}
          setshowmodal={setShareModal}
          ApiData={GetUserDetailsData?.isSuccess && GetUserDetailsData}
          handleShareClick={handleShareClick}
          setShareModal={setShareModal}
          onClick={handlePostApi}
          setUserId={setUserId}
        />
      </UserContext.Provider>
    </div>
  );
};
