import { configureStore } from "@reduxjs/toolkit";
import { IssuerSearchApi } from "../Services/Issuer/Search/IssuerSearchApi";
import { IssuerHomeApi } from "../Services/Issuer/Home/IssuerHomeApi";
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [IssuerSearchApi.reducerPath]: IssuerSearchApi.reducer,
    [IssuerHomeApi.reducerPath]: IssuerHomeApi.reducer,
  }, // Adding the api middleware enables caching, invalidation, polling, // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(IssuerSearchApi.middleware),
});
