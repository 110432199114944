import React, { useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import Badge from "../Badge/Badge";
import Button from "../Button/Button";
import { PAGECONSTANTS, formatCamelCase } from "../../constants/Common";
const Table = ({
    data,
    onclick,
    loading,
    setEdit,
    visible,
    settdata,
    setProductUserId,
    sorticon,
    sortoder,
    serSortoder,
    setSortheader,
    setshare,
    path,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    showTrash,
    setTriggerDownload,
    downloadLink,
    triggerDownload,
    expandableKey,
    documentcomment,
    setDocumentcomment,
    handleUpdateCommentsDocument,
    keyValue,
}) => {
    const [columns, setColumns] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const handleExpandRow = (documentId, comment) => {
        setExpandedRows(documentId);
        setDocumentcomment(comment);
    };
    const handleCollapseRow = () => {
        setExpandedRows(null);
    };
    const listKeys = (headerObj, removeKeys) => {
        const headerArr = Object.keys(headerObj);
        const filteredHeaderValues = headerArr.filter((headerValue) => !removeKeys.includes(headerValue));
        setColumns(filteredHeaderValues);
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    React.useEffect(() => {
        if (data && data?.length >= 1) {
            listKeys(data[0], [
                "createdBy",
                "isActive",
                "id",
                "userId",
                keyValue === "productHoldings" ? "" : "isin",
                "issuerId",
                "isSelf",
                "documentId",
                "contentType",
                "comment",
                "ProductType",
                "EliminateStatus",
                "EliminatedIssuersCount",
                "IdentifiedProductsPercent",
                "ScreenedProductsPercent",
                "HoldingsCount",
                "IdentifiedIssuersCount",
                "ScreenedIssuersCount",
                "IssuersCount",
                "ProductsCount",
                "IdentifiedProductsCount",
                "ScreenedProductsCount",
                "IdenifiedBondsCount",
                "IdenifiedOthersCount",
                "UnclassifedCount",
                "ProductId",
                "CashAllocationPercent",
                "OAISMSCApprovalStatus",
                "RefreshInterval",
                "LastUpdated",
            ]);
        }
    }, [data]);

    const ThData = () => {
        return loading ? (
            <>
                <th>
                    <Skeleton width={"60%"} />
                </th>
                <th>
                    <Skeleton width={"60%"} />
                </th>{" "}
                <th>
                    <Skeleton width={"60%"} />
                </th>
            </>
        ) : (
            <>
                {expandableKey && <th className="py-5 font-bold text-gray-500 text-xs" style={{ fontSize: "14px" }}></th>}
                {columns && columns.length > 0
                    ? columns.map((data) => (
                          <th className="py-5 font-bold text-gray-500 text-xs px-4" style={{ fontSize: "14px" }} key={data}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                             
                              {formatCamelCase(data)}
                                  <div className="hover-icon cursor-pointer" onClick={()=>{setSortheader(data==="%Eliminated"?"WeightedEliminatedPercent":data); serSortoder(sortoder === 1 ? 0 : 1);}}>
                                  {data !== "ProductId" && sorticon && (
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        strokeWidth="0"
                                        viewBox="0 0 320 512"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path>
                                    </svg>
                                    )}
                                  </div>
                              </div>
                          </th>
                      ))
                    : null}
            </>
        );
    };
    const tdData = () => {
        return data?.length ? (
            data?.map((data) => {
                const isRowExpanded = data.documentId === expandedRows;
                return (
                    <>
                        <tr className="odd:bg-gray-100 text-left">
                            {expandableKey && (
                                <td>
                                    {isRowExpanded ? (
                                        <button className="text-primary underline  max-w-fit" onClick={() => handleCollapseRow(data.documentId)}>
                                            <ChevronDownIcon className={"w-4"} />
                                        </button>
                                    ) : (
                                        <button className=" max-w-fit" onClick={() => handleExpandRow(data.documentId, data?.comment)}>
                                            <ChevronRightIcon className={"w-4"} />
                                        </button>
                                    )}
                                </td>
                            )}
                            {columns &&
                                columns?.length &&
                                columns.map((v) => {
                                    return v === "showEdit" && data[v] === true ? (
                                        <td value={data.id}>
                                            <div className="inline-flex">
                                                {" "}
                                                <div
                                                    className=" py-2 max-w-fit"
                                                    onClick={() => {
                                                        onclick();
                                                        setEdit(data.description);
                                                        settdata(data.id);
                                                    }}
                                                >
                                                    {<Badge variant="PencilIcon" />}
                                                </div>
                                                <div
                                                    className="mt-3 cursor-pointer"
                                                    onClick={() => {
                                                        setshare(true);
                                                        settdata(data.id);
                                                    }}
                                                >
                                                    {" "}
                                                    <Badge variant="ShareIcon" />
                                                </div>
                                            </div>
                                        </td>
                                    ) : v === "Actions" && data[v] === true ? (
                                        <td value={data.id}>
                                            <div className="inline-flex left-0 ml-7">
                                                <div>
                                                    {
                                                        <Link to={downloadLink} target="_blank" download>
                                                            <Badge
                                                                variant="DocumentDownload"
                                                                onClick={() => {
                                                                    setTriggerDownload(!triggerDownload);
                                                                }}
                                                            />
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    ) : v === "isPrivate" && data[v] === true ? (
                                        <td>
                                            {" "}
                                            {
                                                <div className="ml-8">
                                                    <Badge variant="CircleSuccess" />
                                                </div>
                                            }
                                        </td>
                                    ) : v === "isPrivate" && data[v] === false ? (
                                        <td className="py-2">
                                            {" "}
                                            {
                                                <div className="ml-8">
                                                    <Badge variant="CircleFail" />
                                                </div>
                                            }
                                        </td>
                                    ) : v === "showDelete" && data[v] === true ? (
                                        <td>{<Badge variant="TrashRed" onClick={() => onclick(data.id)} />}</td>
                                    ) : v === "Action" && data[v] === true ? (
                                        <td>
                                            {
                                                <div className="inline-flex">
                                                    {v === "Action" && data[v] === true && (
                                                        <div className="mt-3 mx-3">
                                                            <Badge
                                                                variant="ShareIcon"
                                                                onClick={() => {
                                                                    setshare(true);
                                                                    settdata(data.id);
                                                                    setProductUserId(data.userId);
                                                                }}
                                                            />
                                                        </div>
                                                    )}

                                                    {showTrash && (
                                                        <td className="py-2">
                                                            <Badge
                                                                variant="TrashRed"
                                                                onClick={() => {
                                                                    onclick(data.id, data.name);
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                                </div>
                                            }
                                        </td>
                                    ) : v === "showStatus" && data[v] === true ? (
                                        <td className="p-2"> {<Badge variant="CircleSuccess" />}</td>
                                    ) : (
                                        <td className="p-2 py-2 text-sm">
                                            {v === "name" && data[v]?.length >= 13 && !(keyValue === "productHoldings") ? (
                                                <Link
                                                    className={` ${v === "name" ? "cursor-pointer max-w-fit text-primary" : ""}`}
                                                    to={path}
                                                    state={{
                                                        data: data?.id,
                                                    }}
                                                    title={data[v]?.length >= 13 ? data[v] : ""}
                                                    onClick={() => sessionStorage.setItem("Id", data?.id)}
                                                >
                                                    {data[v]}
                                                </Link>
                                            ) : v === "name" && data[v]?.length <= 13 && !(keyValue === "productHoldings") ? (
                                                <Link
                                                    className={` ${v === "name" ? "cursor-pointer max-w-fit text-primary" : ""}`}
                                                    to={path}
                                                    state={{
                                                        data: data?.id,
                                                    }}
                                                    onClick={() => sessionStorage.setItem("Id", data?.id)}
                                                >
                                                    {data[v]}
                                                </Link>
                                            ) : v === "ProductId" && data[v]?.length <= 13 && !(keyValue === "productHoldings") ? (
                                                <Link
                                                    className={` ${v === "ProductId" ? "cursor-pointer max-w-fit text-primary mr-4" : ""}`}
                                                    to={path}
                                                    state={{
                                                        data: data?.ProductId,
                                                        path: "/product/home",
                                                    }}
                                                    onClick={() => {
                                                        sessionStorage.setItem("currentPath", path);
                                                        sessionStorage.setItem("productId", data?.ProductId);
                                                    }}
                                                >
                                                    {data[v]}
                                                </Link>
                                            ) : v === "Name" && keyValue === "productPortfolio" ? (
                                                <Link
                                                to={path}
                                                className="text-primary"
                                                state={{
                                                    data: data?.ProductId,
                                                    path: "/product/home",
                                                }}
                                                onClick={() => {
                                                    sessionStorage.setItem("currentPath", path);
                                                    sessionStorage.setItem("productId", data?.ProductId);
                                                }}
                                                    title={data[v]}
                                                    style={{
                                                        wordbreak: "break-all",
                                                        width: v === "Name" ? "140px" : "100%",
                                                        overflow: "hidden",
                                                        fontSize: "0.875rem",
                                                    }}
                                                >
                                                    {data[v]}
                                                </Link>
                                            ) : v === "% Eliminated" && keyValue === "productPortfolio" ? (
                                                <span className="ml-12">{data[v]}</span>
                                            ) : v === "Ticker" && keyValue === "productPortfolio" ? (
                                                <Link className="ml-4 cursor-pointer text-primary" to={path}
                                                state={{
                                                    data: data?.ProductId,
                                                    path: "/product/home",
                                                }}
                                                onClick={() => {
                                                    sessionStorage.setItem("currentPath", path);
                                                    sessionStorage.setItem("productId", data?.ProductId);
                                                }}>{data[v]}</Link>
                                            ) : v === "weight" && keyValue === "productHoldings" ? (
                                                <span>{data[v] + " %"}</span>
                                            ) : v === "name" && keyValue === "productHoldings" ? (
                                                <span>{data[v]}</span>
                                            ) : data[v]?.length > 18 ? (
                                                <p title={data[v].length >= 13 ? data[v] : ""}>{data[v]}</p>
                                            ) : (
                                                <p
                                                    style={{
                                                        marginLeft: "5px",
                                                        wordbreak: "break-all",
                                                        width: v === "userName" ? "140px" : "100%",
                                                        overflow: "hidden",
                                                        whiteSpace: "normal",
                                                        padding: "12px",
                                                        fontSize: "0.875rem",
                                                    }}
                                                >
                                                    {data[v]}
                                                </p>
                                            )}
                                        </td>
                                    );
                                })}
                        </tr>
                        {isRowExpanded && (
                            <tr className="">
                                <td colSpan={5}>
                                    <div className="flex gap-4 p-1">
                                        <input
                                            value={documentcomment}
                                            placeholder={data.length === 0 ? "No comments" : ""}
                                            className="border border-gray-300 rounded-md flex-grow focus:outline-none px-6 py-2"
                                            onChange={(e) => {
                                                setDocumentcomment(e.target.value);
                                            }}
                                        />
                                        <div>
                                            <Button
                                                value="Update"
                                                variant="primary"
                                                size="sm"
                                                className={data.length === 0 || documentcomment?.length === 0 ? "opacity-50 cursor-not-allowed" : ""}
                                                onClick={handleUpdateCommentsDocument}
                                                disabled={data.length === 0 || documentcomment?.length === 0}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </>
                );
            })
        ) : loading ? (
            <>
                <td>
                    <Skeleton width={"50%"} className="mt-2" count={5} />
                </td>
                <td>
                    <Skeleton width={"50%"} className="mt-3" count={5} />
                </td>
                <td>
                    <Skeleton width={"50%"} className="mt-3" count={5} />
                </td>
            </>
        ) : (
            <p className="text-center">{PAGECONSTANTS?.NODATAFOUND}</p>
        );
    };

    const Pagination = () => {
        const pageNumbers = [];

        const maxVisiblePages = 5;
        for (let i = 1; i <= Math.ceil(count / itemsPerPage); i++) {
            pageNumbers.push(i);
        }
        if (pageNumbers.length <= maxVisiblePages) {
            return (
                <div className="flex justify-end items-center gap-4 p-3 mt-auto">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        {PAGECONSTANTS.PREVIOUS}
                    </button>

                    {pageNumbers.map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={`w-8 h-8 rounded ${currentPage === number ? "border border-gray-900" : ""}`}
                        >
                            {number}
                        </button>
                    ))}

                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(count / itemsPerPage)}>
                        {PAGECONSTANTS.NEXT}
                    </button>
                </div>
            );
        }
        const totalPages = pageNumbers.length;

        let firstPage = 1;

        let lastPage = maxVisiblePages;
        if (currentPage <= maxVisiblePages / 2) {
            lastPage = maxVisiblePages;
        } else if (currentPage >= totalPages - maxVisiblePages / 2) {
            firstPage = totalPages - maxVisiblePages + 1;

            lastPage = totalPages;
        } else {
            firstPage = currentPage - Math.floor(maxVisiblePages / 2);

            lastPage = currentPage + Math.floor(maxVisiblePages / 2);

            if (maxVisiblePages % 2 === 0) {
                firstPage += 1;
            }
        }

        const visiblePages = pageNumbers.slice(firstPage - 1, lastPage);

        return (
            <div className="flex justify-end items-center gap-4 p-3">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {PAGECONSTANTS.PREVIOUS}
                </button>

                {firstPage > 1 && <span>...</span>}

                {visiblePages.map((number) => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`w-8 h-8 rounded ${currentPage === number ? "border border-gray-900" : ""}`}
                    >
                        {number}
                    </button>
                ))}

                {lastPage < totalPages && <span>...</span>}

                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(count / itemsPerPage)}>
                    {PAGECONSTANTS.NEXT}
                </button>
            </div>
        );
    };

    return (
        <div className="flex flex-col h-full">
            {Array.isArray(data) && data.length > 0 ? (
                <>
                    <div className="overflow-auto w-full">
                        <table className="w-full">
                            <thead className="bg-gray-50 " style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                <tr className="border-b-2 text-left">{ThData()}</tr>
                            </thead>
                            <tbody>{tdData()}</tbody>
                        </table>
                    </div>
                    {visible ? "" : count > 4 ? Pagination() : ""}
                </>
            ) : (
                <p className="text-center">{PAGECONSTANTS?.NODATAFOUND}</p>
            )}
        </div>
    );
};
export default Table;
