import React, { useEffect, useState } from "react";
import RatingList from "../../components/Rating/RatingList";
import { useNavigate } from "react-router-dom";
import { useGetIssuerDetailsQuery } from "../../Services/IssuerDetails/CreateIssuerNotesApi";
import { queryString } from "../../helpers";
import { has, isEmpty } from "lodash";
import { useGetIssuerStackholderGraphDetailsQuery } from "../../Services/IssuerDetails/GetIssuerStackholderGraphDataApi";
import { Tab } from "@headlessui/react";
import { useEliminateIssuerDetailsApiQuery } from "../../Services/EliminateIssuer/EliminateIssuerApi";
import EliminateTabs from "./EliminateTabs";
import { PAGECONSTANTS, showToastify } from "../../constants/Common";
import Modal from "../../components/Modals/Modals";
import AddUserReviewModal from "./AddUserReviewModal";
import AddUserReviewModalFooter from "./AddUserReviewModalFooter";
import UserContext from "../../context/modal/Contextmodel";
import { ToastContainer } from "react-toastify";

const EliminateMain = () => {
    const path = sessionStorage.getItem("currentPath");
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const [issuerItem, setIssuerItem] = useState({});
    const [userid, setUserid] = useState();
    const [tabchange, setTabchange] = useState("details");
    const [stackHolderData, setStackHolderData] = useState({});
    const [toaster, setToast] = useState(false);
    const [userreviewtoaster, setuserreviewtoaster] = useState(false);
    const [stackBarCount, setStackBarCount] = useState([]);
    const [stackName, setStackName] = useState([]);
    const [checkvalue, setCheckValue] = useState(false);
    const [tabColour, setTabColour] = useState(false);
    const [attachfile, setAttachfile] = useState({});
    const [issueruseridstate, setiissueruseridstate] = useState();
    const [eliminateTabs, setEliminateTabs] = useState();
    const [fileuploaded, setfileuploaded] = useState(false);
    const [addUserReviewModal, setAddUserReviewModal] = useState(false);
    const [apibody, setapibody] = useState({});
    const IssuerDetailsData = useGetIssuerDetailsQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));
    const IssuerStackholderData = useGetIssuerStackholderGraphDetailsQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));
    const EliminateIssuerData = useEliminateIssuerDetailsApiQuery(queryString({ params: { ISIN: String(sessionStorage.getItem("ISIN")) } }));

    const handleIssuerStackholderData = () => {
        if (IssuerStackholderData?.isSuccess && has(IssuerStackholderData, ["data"]) && !isEmpty(IssuerStackholderData?.data)) {
            setStackHolderData(IssuerStackholderData.data);
        }
        return "";
    };

    const handleSelectedTabColour = () => {
        EliminateIssuerData?.data?.issues?.map((data) => {
            return data?.subIssueDetails?.map((value) => {
                return (value.msciValue === "True" || value.issValue === "True" || value.oaisValue === "True") && setTabColour(true);
            });
        });
    };
    const handleBarcount = () => {
        const dataArray = IssuerStackholderData?.data ?? [];
        if (!Array.isArray(dataArray)) {
            return "";
        }
        for (const data of dataArray) {
            setStackBarCount(data?.barCount ?? 0);
            setStackName(data?.name ?? "");
        }
        return "";
    };
    const RatingData = [
        {
            id: "3",
            icon: "employees",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Employees")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Employees")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "2",
            icon: "customers",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Customers")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Customers")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "6",
            icon: "suppliers",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Suppliers")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Suppliers")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "4",
            icon: "environment",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Environment")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Environment")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
        {
            id: "1",
            icon: "communities",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Communities")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Communities")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },

        {
            id: "5",
            icon: "society",
            iconBg: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Society")?.barCount === 3 ? "#ef4444" : "",
            cell: 3,
            negative: {
                visible: true,
                rating: stackHolderData?.issueStackHolders?.find((stack) => stack.name === "Society")?.barCount === 3 ? 3 : "",
            },
            passitive: { visible: true, rating: 0 },
        },
    ];
    useEffect(() => {
        handleIssuerStackholderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IssuerStackholderData]);

    useEffect(() => {
        handleBarcount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stackHolderData]);

    useEffect(() => {
        if (has(IssuerDetailsData, ["data"]) && IssuerDetailsData?.isSuccess) {
            setIssuerItem(IssuerDetailsData?.data);
        }
    }, [IssuerDetailsData]);
    useEffect(() => {
        if (EliminateIssuerData.isSuccess) {
            EliminateIssuerData.refetch();
            setEliminateTabs(
                EliminateIssuerData?.data?.issues.map((value) => {
                    return value.issueName;
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (toaster === true) {
            showToastify("success", "User review document posted successfully");
        }
        setToast(false);

        if (userreviewtoaster === true) {
            showToastify("success", "User review added successfully");
        }
        setuserreviewtoaster(false);
    }, [toaster, userreviewtoaster]);

    useEffect(() => {
        if (addUserReviewModal) {
            setCheckValue(false);
        }
    }, [addUserReviewModal]);
    useEffect(() => {
        handleSelectedTabColour();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EliminateIssuerData]);

    return (
        <div className="w-full h-full">
            <>
                <ToastContainer hideProgressBar />
                <div className="flex justify-between px-4 pt-4 border-b">
                    <div className="pb-4 flex gap-3">
                        <h1 className="text-lg mb-1">
                            <i
                                className="far fa-arrow-left cursor-pointer"
                                onClick={() => {
                                    navigate("/issuerdetails");
                                    sessionStorage.removeItem("EliminatePath");
                                    sessionStorage.setItem("currentPath", "/issuerdetails");
                                }}
                            ></i>
                        </h1>
                        <div>
                            <h1 className="text-2xl mb-1 text-black">{issuerItem?.Name}</h1>
                        </div>
                    </div>
                    <button
                        // disabled={true}
                        className="bg-primary px-2 py-0.5 m-3 rounded-md text-white"
                        onClick={() => {
                            setAddUserReviewModal(true);
                        }}
                    >
                        {PAGECONSTANTS?.ADD_REVIEW}
                    </button>
                </div>

                <div className="sm:flex">
                    <div className="p-4">
                        <RatingList RatingData={RatingData} eliminateTabs={eliminateTabs} EliminateIssuerData={EliminateIssuerData} />
                    </div>
                    <div className="p-4 flex-grow">
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <div className="sm:flex w-full gap-4">
                                <Tab.List className={"flex-col sm:w-1/4 flex p-4 bg-gray-100 rounded-md"}>
                                    {EliminateIssuerData?.data?.issues?.map(({ issueName, currentStatus }, index) => {
                                        const className = `text-left p-4 ${currentStatus ? "text-red-500" : selectedIndex === index ? "bg-gray-200" : ""}${currentStatus && selectedIndex === index ? " bg-red-200" : ""
                                            } rounded-lg`;
                                        const onClick = () => {
                                            setSelectedIndex(index);
                                            navigate(`/eliminate/${issueName}`);
                                        };
                                        return (
                                            <Tab
                                                id={issueName}
                                                className={className}
                                                selectedClassName="text-left p-4 bg-gray-200 rounded-lg"
                                                onClick={onClick}
                                            >
                                                {issueName}
                                            </Tab>
                                        );
                                    })}
                                </Tab.List>
                                <Tab.Panels className="w-3/4">
                                    {EliminateIssuerData?.data?.issues?.map((value) => (
                                        <Tab.Panel>
                                            <EliminateTabs
                                                key={value.issueName}
                                                eliminateIssuerDetails={EliminateIssuerData?.isSuccess && EliminateIssuerData}
                                                tableData={
                                                    value.subIssueDetails?.map((item) => {
                                                        return item;
                                                    }) || []
                                                }
                                                tabdetails={
                                                    value.subIssueNoteDetails?.map((item) => {
                                                        return item;
                                                    }) || []
                                                }
                                                isin={sessionStorage.getItem("ISIN")}
                                            />
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </div>
                        </Tab.Group>
                    </div>
                </div>
            </>
            <UserContext.Provider
                value={{
                    setUserid,
                    userid,
                    tabchange,
                    issueruseridstate,
                    fileuploaded,
                    setfileuploaded,
                    setiissueruseridstate,
                    setuserreviewtoaster,
                    attachfile,
                    setAttachfile,
                    apibody,
                    setapibody,
                    setToast,
                    addUserReviewModal,
                    setTabchange,
                    checkvalue,
                    setCheckValue,
                }}
            >
                <Modal
                    setshowmodal={setAddUserReviewModal}
                    showmodal={addUserReviewModal}
                    title="Create Issuer Review Request"
                    size={"w-[576px]"}
                    body={<AddUserReviewModal EliminateIssuerData={EliminateIssuerData} />}
                    footer={<AddUserReviewModalFooter setAddUserReviewModal={setAddUserReviewModal} apibody={apibody} attachfile={attachfile} />}
                    isFooterEnable={true}
                />
            </UserContext.Provider>
        </div>
    );
};

export default EliminateMain;
