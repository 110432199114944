import React from 'react'
import Button from '../components/Button/Button'

function DeleteFooter({onClick,setSaveModal}) {

  return (
    <div className='w-full'>
      <div className="grid grid-rows-1 grid-flow-col gap-3 mt-4">
          <Button
            value="Cancel"
            variant="secondary"
            onClick={()=>setSaveModal(false)}
            size='sm'
          />
          <Button
            value="Delete"
            variant="danger"
            onClick={onClick}
            size='sm'
          />
      </div>
    </div>
         
  )
}

export default DeleteFooter