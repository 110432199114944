import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import logo from "../assets/images/logo-white.svg";
import logoBlack from "../assets/images/logo.svg";
import { Menu, Dialog, Transition } from "@headlessui/react";
import MasterSearch from "./MasterSearch/MasterSearch";
import Button from "./Button/Button";
import { LogoutButton } from "./LogoutButton/LogoutButton";
import { LoginName } from "./LoginName/LoginName";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { HEADER, ISSUER, PRODUCTLIST, USER_REVIEW } from "../constants/appConstants";
import { has } from "lodash";
import UserContext from "../context/modal/Contextmodel";
const Header = () => {
    const { Permissionapi } = useContext(UserContext);
    const permissionArray = Permissionapi?.split(", ");
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (Permissionapi === "None") {
            navigate("/dashboard");
            sessionStorage.setItem("currentPath", "/dashboard");
            sessionStorage.setItem("issuerPath", "/dashboard");
        }
    }, [Permissionapi]);
    React.useEffect(() => {
        if (
            has(location, "pathname") &&
            location?.pathname !== "/issuerdetails" &&
            location?.pathname !== "/issuer/search" &&
            !location?.pathname.startsWith("/eliminate/")
        ) {
            localStorage.removeItem(ISSUER.ISSUERFILTER);
            localStorage.removeItem(ISSUER.ISSUERCOLUMNFILTER);
            localStorage.removeItem(ISSUER.EDITVIEWCHECKED);
            localStorage.removeItem(ISSUER.ISSUERSORTING);
            localStorage.removeItem(ISSUER.ISSUERSORTINGHEADER);
            localStorage.removeItem(ISSUER.ISSUERSEARCHTEXT);
            localStorage.removeItem(ISSUER.ISSUERBADGEVALUES);
            localStorage.removeItem(ISSUER.SAVEDID);
            localStorage.removeItem(ISSUER.EXISTINGVIEW);
            localStorage.removeItem(ISSUER.SAVEDRETURNCOLUMN);
            localStorage.removeItem(ISSUER.EDITVIEWID);
            localStorage.removeItem("ApplyButton");
        }
        if (has(location, "pathname") && location?.pathname !== "/issuerdetails" && location?.pathname !== "/issuer/user-review" && ! location?.pathname.startsWith("/eliminate/") ) {
            localStorage.removeItem(USER_REVIEW.USERFILTER);
            localStorage.removeItem(USER_REVIEW.USERSORTING);
            localStorage.removeItem(USER_REVIEW.USERSORTINGHEADER);
            localStorage.removeItem(USER_REVIEW.USERSEARCHTEXT);
            localStorage.removeItem(USER_REVIEW.USERBADGEVALUES);
            localStorage.removeItem(USER_REVIEW.USERREVIEWCOLUMNFILTER);
            localStorage.removeItem(USER_REVIEW.USERREVIEWEDITVIEWCHECKED);
            localStorage.removeItem(USER_REVIEW.USERREVIEWEXISTINGVIEW);
            localStorage.removeItem(USER_REVIEW.USERREVIEWSAVEDRETURNCOLUMN);
            localStorage.removeItem(USER_REVIEW.USERREVIEWEDITVIEWID);
            sessionStorage.removeItem("userReviewId");
            sessionStorage.removeItem("editUserReviewId");
        }
        if (has(location, "pathname") && location?.pathname !== "/Product/search") {
            localStorage.removeItem(ISSUER.PRODUCSAVEDID);
        }
        if (has(location, "pathname") && location?.pathname !== "/admin/manage-access-control") {
            localStorage.removeItem(USER_REVIEW?.ACTIVETAB);
        }
        if (has(location, "pathname") && location?.pathname !== "/admin/view-access-control-report") {
            localStorage.removeItem("activeTab1");
        }
        if (has(location, "pathname") && location?.pathname !== "/issuer/user-review") {
            sessionStorage.removeItem("userReviewId");
            sessionStorage.removeItem("editUserReviewId");
        }
        if (
            has(location, "pathname") &&
            location?.pathname !== "/Product/search" &&
            location?.pathname !== "/productDetails" &&
            location?.pathname !== "/issuerdetails" &&
            !location?.pathname.startsWith("/eliminate/")
        ) {
            localStorage.removeItem(PRODUCTLIST.PRODUCTFILTER);
            localStorage.removeItem(PRODUCTLIST.PRODUCTSORTING);
            localStorage.removeItem(PRODUCTLIST.PRODUCTSORTINGNAME);
            localStorage.removeItem("ApplyButton");
            localStorage.removeItem(PRODUCTLIST.PRODUCTSEARCHTEXT);
            localStorage.removeItem(PRODUCTLIST.PRODUCTBADGEVALUES);
            localStorage.removeItem(PRODUCTLIST.PRODUCTCOLUMNFILTER);
            localStorage.removeItem(PRODUCTLIST.PRODUCTEDITVIEWCHECKED);
            localStorage.removeItem(PRODUCTLIST.PRODUCTEXISTINGVIEW);
            localStorage.removeItem(PRODUCTLIST.PRODUCTSAVEDRETURNCOLUMN);
            localStorage.removeItem(PRODUCTLIST.PRODUCTEDITVIEWID);
        }
        if (
            has(location, "pathname") &&
            location?.pathname !== "/Product/search" &&
            location?.pathname !== "/productDetails" &&
            location?.pathname !== "/eliminate/Abortion" &&
            location?.pathname !== "/issuerdetails"
        ) {
            sessionStorage.removeItem("productId");
        }
        if (has(location, "pathname") && location?.pathname !== "/productDetails") {
            localStorage.removeItem(PRODUCTLIST.PRODUCTHOLDINGSSORTINGNAME);
            localStorage.removeItem(PRODUCTLIST.PRODUCTHOLDINGSSORTING);
        }
    }, [location]);
    if (
        (has(location, "pathname") && location?.pathname === "/Product/home") ||
        location?.pathname === "/issuer/home" ||
        location?.pathname === "/issuer/search"
    ) {
        sessionStorage.removeItem("BasePath");
    }
    // useEffect(() => {
    //     const isLocalStorageEmpty = Object.keys(localStorage).length === 0;
    //     if (isLocalStorageEmpty) {
    //         navigate('/')
    //     }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [localStorage]);
    if (Permissionapi && (Permissionapi === "None" || Permissionapi === "" || Permissionapi === "empty" || Permissionapi === null)) {
        return "";
    } else {
        if (Permissionapi && !(Permissionapi === "None" || Permissionapi === "" || Permissionapi === "empty" || Permissionapi === null))
            return (
                <header className="bg-dark p-4 flex gap-8 items-center text-white sticky top-0 sm:z-30">
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-40" onClose={setOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-500"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-500"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-hidden">
                                <div className="absolute inset-0 overflow-hidden">
                                    <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full ">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                                            enterFrom="-translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                                            leaveFrom="translate-x-0"
                                            leaveTo="-translate-x-full"
                                        >
                                            <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-in-out duration-500"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in-out duration-500"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <div className="absolute right-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                                        <button
                                                            type="button"
                                                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">{HEADER.CLOSEPANEL}</span>
                                                            <i className="far fa-times"></i>
                                                        </button>
                                                    </div>
                                                </Transition.Child>
                                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                                    <div className="px-4 sm:px-6">
                                                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                            <img src={logoBlack} className="w-2/3" alt="One Ascent" />
                                                        </Dialog.Title>
                                                    </div>
                                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                        <nav className="gap-4 flex flex-col ml-auto">
                                                            <>
                                                                {" "}
                                                                <span
                                                                    className="flex gap-1.5 items-center cursor-pointer"
                                                                    onClick={() => {
                                                                        navigate("/dashboard");
                                                                        sessionStorage.setItem("masterSearchPath", "/dashboard");
                                                                    }}
                                                                >
                                                                    <i className="far fa-home"></i> {HEADER.HOME}
                                                                </span>
                                                                {permissionArray?.includes("Issuer_Search") ||
                                                                permissionArray?.includes("Issuer_UserReview") ||
                                                                permissionArray?.includes("Issuer_SystemReview") ||
                                                                permissionArray?.includes("All") ? (
                                                                    <span
                                                                        className="flex gap-1.5 items-center cursor-pointer"
                                                                        onClick={() => {
                                                                            navigate("/issuer/home");
                                                                            sessionStorage.setItem("masterSearchPath", "/issuer/home");
                                                                        }}
                                                                    >
                                                                        <i className="far fa-dollar-sign"></i> {HEADER.ISSUER}
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                            {Permissionapi?.includes("Product_Search") || Permissionapi?.includes("All") ? (
                                                                <span
                                                                    className="flex gap-1.5 items-center cursor-pointer"
                                                                    onClick={() => {
                                                                        navigate("/Product/home");
                                                                        sessionStorage.setItem("masterSearchPath", "/Product/home");
                                                                    }}
                                                                >
                                                                    <i class="far fa-lightbulb-dollar"></i> {HEADER.PRODUCT}
                                                                </span>
                                                            ) : null}
                                                            {/* {Permissionapi?.includes("All") ? (
                                                                <span
                                                                    className="flex gap-1.5 items-center cursor-pointer"
                                                                    onClick={() => {
                                                                        navigate("/Portfolio");
                                                                        sessionStorage.setItem("masterSearchPath", "/Portfolio");
                                                                    }}
                                                                >
                                                                    <i class="far fa-suitcase"></i> {HEADER.PORTFOLIO}
                                                                </span>
                                                            ) : null} */}
                                                            {Permissionapi?.includes("Admin_ViewUsers") ||
                                                            Permissionapi?.includes("Admin_ManageUsers") ||
                                                            Permissionapi?.includes("Admin_ViewRoles") ||
                                                            Permissionapi?.includes("Admin_ManageRoles") ||
                                                            Permissionapi?.includes("Admin_ViewRoleAccessControl") ||
                                                            Permissionapi?.includes("Admin_ManageRoleAccessControl") ||
                                                            Permissionapi?.includes("Admin_ViewUserRoleMatrix") ||
                                                            Permissionapi?.includes("All") ? (
                                                                <span
                                                                    className="flex gap-1.5 items-center cursor-pointer"
                                                                    onClick={() => {
                                                                        navigate("/admin/users");
                                                                        sessionStorage.setItem("masterSearchPath", "/admin/users");
                                                                    }}
                                                                >
                                                                    <i class="fa fa-user-shield"></i> {HEADER.ADMIN}
                                                                </span>
                                                            ) : null}
                                                        </nav>
                                                    </div>
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                    <div className="w-3/2 flex items-center gap-4">
                        <button className="text-white block lg:hidden" onClick={() => setOpen(true)}>
                            <i className="far fa-align-left text-2xl"></i>
                        </button>
                        <Link to={"/dashboard"} className="flex gap-1.5 items-center">
                            <img src={logo} className="w-full hidden md:block" alt="One Ascent" />
                        </Link>
                    </div>
                    {permissionArray?.includes("Issuer_Search") || permissionArray?.includes("All") ? (
                        <form className="group w-52">
                            <MasterSearch />
                        </form>
                    ) : null}
                    <nav className="text-white gap-4 hidden lg:flex ml-auto text-sm">
                        <Link
                            to={"/dashboard"}
                            className="flex gap-1.5 items-center"
                            onClick={() => {
                                sessionStorage.setItem("currentPath", "/dashboard");
                                sessionStorage.setItem("masterSearchPath", "/dashboard");
                            }}
                        >
                            <i className="far fa-home"></i> {HEADER.HOME}
                        </Link>
                        {permissionArray?.includes("Issuer_Search") ||
                        permissionArray?.includes("Issuer_UserReview") ||
                        permissionArray?.includes("Issuer_SystemReview") ||
                        permissionArray?.includes("All") ? (
                            <>
                                <Link
                                    to={"issuer/home"}
                                    className="flex gap-1.5 items-center"
                                    onClick={() => {
                                        sessionStorage.setItem("currentPath", "issuer/home");
                                        sessionStorage.setItem("masterSearchPath", "/issuer/home");
                                    }}
                                >
                                    <i className="far fa-dollar-sign"></i> {HEADER.ISSUER}
                                </Link>
                            </>
                        ) : null}
                        {permissionArray?.includes("Product_Search") || permissionArray?.includes("All") ? (
                            <Link
                                to={"/Product/home"}
                                className="flex gap-1.5 items-center"
                                onClick={() => {
                                    sessionStorage.setItem("currentPath", "/Product/home");
                                    sessionStorage.setItem("masterSearchPath", "/Product/home");
                                }}
                            >
                                <i class="far fa-lightbulb-dollar"></i>
                                {HEADER.PRODUCT}
                            </Link>
                        ) : null}
                        {/* {Permissionapi?.includes("Portfolio_Search") || Permissionapi?.includes("All") ? (
                            <Link
                                to={"Portfolio"}
                                className="flex gap-1.5 items-center"
                                onClick={() => {
                                    sessionStorage.setItem("currentPath", "Portfolio");
                                    sessionStorage.setItem("masterSearchPath", "/Portfolio");
                                }}
                            >
                                <i class="far fa-suitcase"></i>
                                {HEADER.PORTFOLIO}
                            </Link>
                        ) : null} */}
                        {Permissionapi?.includes("Admin_ViewUsers") ||
                        Permissionapi?.includes("Admin_ManageUsers") ||
                        Permissionapi?.includes("Admin_ViewRoles") ||
                        Permissionapi?.includes("Admin_ManageRoles") ||
                        Permissionapi?.includes("Admin_ViewRoleAccessControl") ||
                        Permissionapi?.includes("Admin_ManageRoleAccessControl") ||
                        Permissionapi?.includes("Admin_ViewUserRoleMatrix") ||
                        Permissionapi?.includes("All") ? (
                            <Link
                                to={`admin/${sessionStorage.getItem("PATH")}`}
                                className="flex gap-1.5 items-center"
                                onClick={() => {
                                    sessionStorage.setItem("currentPath", `admin/${sessionStorage.getItem("PATH")}`);
                                    sessionStorage.setItem("masterSearchPath", "/admin/users");
                                }}
                            >
                                <i class="fa fa-user-shield"></i> {HEADER.ADMIN}
                            </Link>
                        ) : null}
                    </nav>
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button
                                disabled
                                className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                            >
                                <i className="far fa-bell"></i>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        <Button value="Edit" variant="transparent" />
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <Menu as="div" className="relative inline-block text-left">
                        <div className="flex items-center gap-2">
                            <Menu.Button className="flex items-center gap-3">
                                <div className="inline-flex w-8 h-8  overflow-hidden justify-center bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    {" "}
                                    <img
                                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                        className="h-full rounded-full "
                                        alt="Name"
                                    />
                                </div>
                                <div className="hidden md:block">
                                    <AuthenticatedTemplate>
                                        <LoginName />
                                    </AuthenticatedTemplate>
                                </div>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-50 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    <Menu.Item as="div">
                                        <button className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-not-allowed`}>
                                            {HEADER.MYPROFILE}
                                        </button>
                                        <LogoutButton />
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </header>
            );
    }
};

export default Header;
