import React, { useContext, useEffect, useRef } from "react";
import { has, isArray, isEmpty, isNull } from "lodash";
import UserContext from "../context/modal/Contextmodel";
import { useGetProductSavedSearchListQuery, useGetSavedSearchPaginationQuery, useGetSavedSearchQuery } from "../Services/Issuer/Home/IssuerHomeApi";
import { getNormalDate } from "../components/Table/TailwindTable";
import { useSavedSearchMutation } from "../Services/Issuer/Search/IssuerSearchApi";
import { getKeyValuePair, showToastify } from "../constants/Common";
import { queryString } from "../helpers";

function Saveresultbody({ ModuleName }) {
    const {
        savedResultValue,
        savedsearcheckbox,
        setsavedsearcheckbox,
        SetSavedResultValue,
        savedSearchdata,
        setSavedSearchdata,
        shareDropDownValue,
        setShareDropDownValue,
        isDropdownVisible,
        setIsDropdownVisible,
        saveModal,
        count,
        pageNumber,
        searchTextTyped,
        checkedValues,
        sortColumnName,
        sortOrder,
        tempSelectedFields,
        setSaveModal,
        editViewMenuId,
    } = useContext(UserContext);
    const divRef = useRef();
    let checkedid = savedsearcheckbox;
    const [saveSearch, saveSearchData] = useSavedSearchMutation();
    const sortFilter = getKeyValuePair(sortColumnName, sortOrder);
    const getProductSavedSearchListData = useGetProductSavedSearchListQuery(queryString({ params: { ModuleName: ModuleName } }));

    const initialValues =
        savedResultValue.length >= 1
            ? {
                  Name: savedResultValue,
                  moduleName: ModuleName,
                  savedSearchViewId: editViewMenuId,
                  searchQueryJson: {
                      searchText: searchTextTyped,
                      useDefaultFacets: true,
                      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                      fieldNamesToReturn: tempSelectedFields,
                      fieldNamesToSearch: [],
                      facetOptions: null,
                      facetFilters: checkedValues,
                      searchIndexName: "string",
                      currentPage: count,
                      pageSize: pageNumber,
                  },
              }
            : {
                  saveSearchId: checkedid,
                  moduleName: ModuleName,
                  savedSearchViewId: editViewMenuId,
                  searchQueryJson: {
                      searchText: "*",
                      useDefaultFacets: true,
                      sortOrderfilters: isNull(sortOrder) ? null : sortFilter,
                      fieldNamesToReturn: tempSelectedFields,
                      fieldNamesToSearch: [],
                      facetOptions: null,
                      facetFilters: checkedValues,
                      searchIndexName: "string",
                      currentPage: count,
                      pageSize: pageNumber,
                  },
              };
    // const getSavedSearchData = useGetSavedSearchPaginationQuery(queryString({ params: { PageNumber: 1, PageSize: "50", ModuleName: ModuleName } }));
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };
    useEffect(() => {
        getProductSavedSearchListData.refetch();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (savedResultValue.length >= 1) {
            setsavedsearcheckbox("");
        }
    }, [savedResultValue]);
    useEffect(() => {
        if (savedsearcheckbox.length) {
            SetSavedResultValue("");
        }
    }, [savedsearcheckbox]);

    useEffect(() => {
        if (saveModal === false) {
            setShareDropDownValue("");
        }
    }, [saveModal]);

    const handleShareClick = (id, name) => {
        setShareDropDownValue(name);
        setIsDropdownVisible(false);
        setsavedsearcheckbox(id);
    };
    const handleChange = (e) => {
        SetSavedResultValue(e.target.value);
        setShareDropDownValue("");
    };
    const handleDropChange = (e) => {
        if (shareDropDownValue?.length === 0) {
            setIsDropdownVisible(false);
        }
    };
    useEffect(() => {
        if (shareDropDownValue?.length === 0) {
            setIsDropdownVisible(false);
            setsavedsearcheckbox("");
        }
    }, [shareDropDownValue]);
    useEffect(() => {
        if (saveSearchData?.isSuccess) {
            showToastify("success", "SavedSearch added sucessfully");
            setSaveModal(false);
        } else if (saveSearchData?.isError) {
            showToastify("warning", saveSearchData?.error?.data?.errors?.Name[0]);
            SetSavedResultValue("");
            setSaveModal(false);
        }
    }, [saveSearchData]);
    return (
        <>
            <div className="flex flex-wrap">
                <div className=" mb-2 mt-2 w-full">
                    <h1 className="">Overwrite an existing saved search</h1>
                    <div>
                        <input
                            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            id="username"
                            type="text"
                            value={shareDropDownValue}
                            // onChange={(e) => handleDropChange(e)}
                            onClick={() => {
                                handleDropChange();
                                setIsDropdownVisible(!isDropdownVisible);
                            }}
                            disabled={savedResultValue.length > 0}
                            style={{
                                backgroundImage: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fill-opacity="0.4"%3E%3Cpath fill-rule="evenodd" d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" /%3E%3C/svg%3E')`,
                                backgroundPosition: "340px 50%",
                                backgroundRepeat: "no-repeat",
                            }}
                        />
                        {isDropdownVisible && (
                            <div className="absolute left-0 w-full flex flex-col rounded mt-1  max-h-80 md:max-h-none h-40 px-3">
                                <div className="py-4 pl-1.5 bg-white border shadow rounded z-10 justify-between border-b last:border-b-0 flex overflow-auto ">
                                    <div className="flex text-dark flex-col gap-1 w-full " ref={divRef}>
                                        {has(getProductSavedSearchListData, ["data"]) &&
                                        isArray(getProductSavedSearchListData?.data) &&
                                        getProductSavedSearchListData?.data?.length > 0 ? (
                                            getProductSavedSearchListData?.data?.map((data, index) => {
                                                return (
                                                    <h1
                                                        key={index}
                                                        onClick={() => {
                                                            handleShareClick(data.id, data.name);
                                                        }}
                                                        className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                                    >
                                                        {data.name}
                                                    </h1>
                                                );
                                            })
                                        ) : (
                                            <h1
                                                ref={divRef}
                                                onClick={() => setIsDropdownVisible(false)}
                                                className="cursor-pointer bg-white hover:bg-gray-50 rounded pl-2"
                                            >
                                                <b>No Data Found</b>
                                            </h1>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <h1 className=" mb-2 mt-4">Save search as:</h1>
            {savedsearcheckbox.length ? (
                <input
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={""}
                    disabled
                />
            ) : (
                <input
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    id="username"
                    type="text"
                    value={savedResultValue}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && savedResultValue.length > 0) {
                            saveSearch(initialValues);
                            SetSavedResultValue("");
                        }
                    }}
                />
            )}
        </>
    );
}

export default Saveresultbody;
