import { useEffect } from "react";
import Checkbox from "../../components/Checkbox/Checkbox";

const AddnoteBody = ({ title, print, setnote }) => {
    useEffect(() => {
        setnote("");
    }, []);
    const Checkboxlabel = ["Summary Page", "Eliminate Page", "Eliminate History", "Elevate Page", "Elevate History", "Analyst Notes"];
    return (
        <>
            {print ? (
                <div className="grid grid-cols-3 gap-1">
                    {Checkboxlabel.map((value) => {
                        return <Checkbox label={value} />;
                    })}
                </div>
            ) : (
                <div className="flex flex-col gap-1 h-[130px] mb-1">
                    <p className="text-sm">{title}</p>
                    <textarea
                        maxLength="499"
                        className={`appearance-none border border-gray-300 rounded-md w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none h-[90px]`}
                        id="enterName"
                        type="text"
                        onChange={(e) => {
                            setnote(e.target.value);
                        }}
                        style={{ resize: "none" }}
                    />
                </div>
            )}
        </>
    );
};
export default AddnoteBody;
