import React from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import { RATINGLIST } from "../../constants/appConstants";
import PrintRating from "./PrintRating";

const PrintRatingList = ({ RatingData = [], isin, EliminateIssuerData ,print}) => {
    const eliminateLocation = EliminateIssuerData?.data?.issues?.map((tab) => window.location.pathname.startsWith(`/eliminate/${tab.issueName}`));

    const disableStyle = {
        cursor: "pointer",
        pointerEvents: "none",
    };

    return (
        <div style={{ display: "flex" }}>
            {" "}
            <div style={{ marginBottom: "1rem",textAlign:"center"}}>
                {!print && (
                    <Link
                        style={{
                            fontSize: "1.5rem",
                            color: "var(--primary)",
                            textDecoration: "underline",
                            opacity: "0.5",
                            cursor: "not-allowed",
                        }}
                        disabled={true}
                    >
                        {RATINGLIST.ELEVATE}
                    </Link>
                ) }
                <div style={{padding:"10px"}}>
                    {RatingData.map((data) => (
                        <PrintRating key={data.id} RatingData={data} EliminateIssuerData={EliminateIssuerData} />
                    ))}
                </div>
                {!print && (
                    <Link
                        style={{
                            fontSize: "1.5rem",
                            color: "var(--primary)",
                            textDecoration: "underline",
                        }}
                        to={{
                            pathname: "/eliminate/Abortion",
                            state: {
                                data: isin,
                            },
                        }}
                    >
                        {RATINGLIST.ELIMINATE}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default PrintRatingList;
