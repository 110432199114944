import React from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import { RATINGLIST } from "../../constants/appConstants";

const RatingList = ({ RatingData = [], isin, EliminateIssuerData }) => {
    const eliminateLocation = EliminateIssuerData?.data?.issues?.map((tab) => {
        return window.location.pathname.startsWith(`/eliminate/${tab.issueName}`);
    });

    const disablestyle = {
        cursor: "pointer",
        pointerEvents: "none",
    };
    return (
        <div className={`mb-1 ${!eliminateLocation ? "flex" : ""} flex-col items-center gap-4`}>
            {!eliminateLocation && (
                <Link className="text-xl text-primary underline opacity-50 cursor-not-allowed" disabled={true} style={disablestyle}>
                    {RATINGLIST.ELEVATE}
                </Link>
            )}
            {eliminateLocation && (
                <Link className="text-xl text-black" to={"/"} disabled={true} style={disablestyle}>
                    {RATINGLIST.ELIMINATE}
                </Link>
            )}
            <div>
                {RatingData.map((data) => (
                    <Rating key={data.id} RatingData={data} EliminateIssuerData={EliminateIssuerData} />
                ))}
            </div>
            {!eliminateLocation && (
                <Link
                    className="text-xl text-primary underline"
                    to={"/eliminate/Abortion"}
                    state={{
                        data: isin,
                    }}
                    onClick={() => {
                        sessionStorage.setItem("EliminatePath", "/eliminate/Abortion");
                        sessionStorage.setItem("currentPath", "/eliminate/Abortion");
                    }}
                >
                    {RATINGLIST.ELIMINATE}
                </Link>
            )}
        </div>
    );
};

export default RatingList;
