import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { has, isArray, isEmpty, isNull, range } from "lodash";
import axios from "axios";
import fileDownload from "js-file-download";
import { ToastContainer } from "react-toastify";
import AddProductNotesFooter from "./AddProductNotesFooter";
import ShareModal from "../../ShareModal/ShareModal";
import {
    useCreateProductNotesApiMutation,
    useGetPaginatedPproductHoldingListMutation,
    useGetProductByIdQuery,
    usePostProductNotesListApiMutation,
    useShareProductNotesApiMutation,
    useUpdateProductNotesMutation,
} from "../../../Services/Product/ProductApi";
import { queryString } from "../../../helpers";
import TailwindTable, { getNormalDate } from "../../../components/Table/TailwindTable";
import { getKeyValuePair, loadingTableheader, removeSpecialCharacters, showToastify } from "../../../constants/Common";
import { ISSUERDETAILS, PRODUCT, PRODUCTLIST } from "../../../constants/appConstants";
import AddnoteBody from "../../IssuerDetails/AddActionBody";
import Modal from "../../../components/Modals/Modals";
import UserContext from "../../../context/modal/Contextmodel";
import { useGetAllUsersQuery } from "../../../Services/ShareModal/ShareModalApi";
import Table from "../../../components/Table/Table";
import Button from "../../../components/Button/Button";
import IssuerDetailsTable from "../../../components/Table/IssuerDetailsTable";
import Sharebody from "../../IssuerDetails/ShareAction";
import ShareFooter from "../../IssuerDetails/ShareFooter";

const ProductDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const pageArray = [25, 50, 75, 100, 125, 150];
    const locationStateBloombergId = location?.state?.data;
    const path = location?.state?.path || "";
    const localBloombergId = sessionStorage.getItem("productId");
    const checkSortHeader = localStorage.getItem(PRODUCTLIST.PRODUCTHOLDINGSSORTINGNAME);
    const checkSort = localStorage.getItem(PRODUCTLIST.PRODUCTHOLDINGSSORTING);
    const bloombergId = isNull(localBloombergId) ? locationStateBloombergId : localBloombergId;
    const tabcss =
        "whitespace-nowrap px-0 py-4 mx-6 sm:py-0 ui-selected:border-b-primary ui-selected:border-b-2 ui-selected:text-primary ui-not-selected:border-b-2 ui-not-selected:border-b-transparent ui-not-selected:text-black";
    const [sortOrder, setSortorder] = useState("0");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(25);
    const [notelist, setNotelist] = useState([]);
    const [note, setNote] = useState("");
    const [addmodal, setAddmodal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [shareInputValue, setShareInputValue] = useState("");
    const [shareDropDownValue, setShareDropDownValue] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(true);
    const [data, setData] = useState([]);
    const [ViewFields, setViewFields] = useState([]);
    const [count, setCount] = useState(1);
    const [tdata, settdata] = useState({});
    const [userId, setUserId] = useState("");
    const [productUserId, setProductUserId] = useState("");
    const [tab, setTab] = useState("current");
    const [sortColumnName, setSortColumnName] = useState(checkSortHeader);
    const [holdingSortOrder, setHoldingSortOrder] = useState(checkSort);
    const [modal, setshowmodal] = useState(false);
    const [edit, setEdit] = useState();
    const [headername, setHeadername] = useState("");
    const [apicall, setApicall] = useState(false);
    const [share, setshare] = useState(false);
    const body = {
        bloombergId: bloombergId,
        pageNumber: currentPage,
        pageSize: 25,
        sortOrder: sortOrder,
        orderBy: headername.charAt(0).toUpperCase() + headername.slice(1),
    };
    const [UpdateNotes] = useUpdateProductNotesMutation();
    const [ProductNotePagination, ProductNotePaginationdata] = usePostProductNotesListApiMutation();
    const [shareProductNotes] = useShareProductNotesApiMutation();
    const [paginatedProductDetailList, paginatedProductDetailListData] = useGetPaginatedPproductHoldingListMutation();
    const GetUserDetailsData = useGetAllUsersQuery(queryString({ params: { searchText: shareInputValue } }), {
        skip: shareInputValue.length < 1,
    });
    const getProductDetailsById = useGetProductByIdQuery(queryString({ params: { BloombergId: bloombergId } }), {
        skip: !bloombergId || bloombergId === undefined || bloombergId === null,
    });
    const [postProductNotes, postProductNotesData] = useCreateProductNotesApiMutation();
    const handlePostApi = () => {
        if (userId && tdata) {
            const body = {
                productNotesId: tdata,
                sharedUserId: userId,
            };
            shareProductNotes(body);
            showToastify("success", "Notes Shared Successfully");
        }
        setShareModal(false);
        setUserId("");
        settdata("");
        setProductUserId("");
    };
    const handleShareClick = (email, id) => {
        setIsDropdownVisible(false);
        setShareDropDownValue(email);
        setUserId(id);
    };
    // useEffect(() => {
    //     const holdingBody = {
    //         bloombergId: bloombergId,
    //         pageNumber: count,
    //         pageSize: pageNumber,
    //         orderBy: isNull(sortColumnName) ? "holdingWeight" : sortColumnName,
    //         sortOrder: isNull(holdingSortOrder) ? 1 : holdingSortOrder,
    //     };
    //     paginatedProductDetailList(holdingBody);
    //     setSortColumnName("holdingWeight")
    //     localStorage.setItem("PRODUCTHOLDINGS_SORTING" ,"holdingWeight");
    //     localStorage.setItem("PRODUCTHOLDINGS_SORTING_NAME",1);
    // }, []);
    const exportapicall = async () => {
        const body = {
            bloombergId: bloombergId,
            format: 0,
        };

        const response = await fetch(`${process.env.REACT_APP_DEV_APIBASEURI}api/Product/ExportProductHoldings`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
            },
            body: JSON.stringify(body),
        });

        if (response.ok) {
            const contentDisposition = response.headers.get("content-disposition");
            console.log("Content-Disposition:", contentDisposition);
            console.log(response, "response");
            const data = await response.arrayBuffer();
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const filename = `${getProductDetailsById?.data?.Name}-Holdings.xlsx`;
            if (typeof window.navigator.msSaveBlob !== "undefined") {
                // For IE and Edge browsers
                window.navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", removeSpecialCharacters(filename));
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    };

    // const exportapicall = async () => {
    //     const body = {
    //         bloombergId: bloombergId,
    //         format: 0,
    //     };
    //     await axios
    //         .post("https://api-vbi-dev.azurewebsites.net/api/Product/ExportProductHoldings", body, {
    //             responseType:"arraybuffer",
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    //             },
    //         })
    //         .then((response) => {
    //             // response.request.getResponseHeader('Content-Disposition');
    //             // console.log(response.request.getResponseHeader('Content-Disposition'));
    //             // const contentDisposition = response.headers["content-disposition"];
    //             // // const filename = contentDisposition.match(/filename="(.*?)"/)[1];
    //             // console.log("Filename:", response.headers);
    //             // console.log(response, "response");
    //             const blob = new Blob([response.data], {
    //                 type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //             });

    //             fileDownload(blob, `${getProductDetailsById?.data?.Name}-Holdings.xlsx`);
    //         });
    // };
    const sortAPI = () => {
        paginatedProductDetailList({
            bloombergId: bloombergId,
            pageNumber: count,
            pageSize: pageNumber,
            orderBy: sortColumnName,
            sortOrder: holdingSortOrder,
        });
    };
    const columns = ViewFields?.map((key) => {
        if (key === "holdingTicker") {
            return {
                Header: "Ticker",
                accessor: key,
            };
        }
        if (key === "holdingName") {
            return {
                Header: "Name",
                accessor: key,
            };
        }
        if (key === "holdingType") {
            return {
                Header: "Type",
                accessor: key,
            };
        }
        if (key === "holdingWeight") {
            return {
                Header: "Weight",
                accessor: key,
            };
        }
        if (key === "holdingCategory") {
            return {
                Header: "Category",
                accessor: key,
            };
        }
        if (key === "statusText") {
            return {
                Header: "Status",
                accessor: key,
            };
        }
        if (key !== "undefined") {
            return {
                Header: key,
                accessor: key,
            };
        }
    });
    useEffect(() => {
        getProductDetailsById.refetch();
    }, []);
    useEffect(() => {
        ProductNotePagination(body);
        if (apicall === true) {
            ProductNotePagination(body);
            setApicall(false);
            // setCurrentPage(1);
        }
        // ProductNotePaginationdata?.isSuccess && showToastify("success", "Product note successfully created.");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, sessionStorage.getItem("productId"), apicall, sortOrder]);

    useEffect(() => {
        const holdingBody = {
            bloombergId: bloombergId,
            pageNumber: count,
            pageSize: pageNumber,
            orderBy: isNull(sortColumnName) ? "holdingWeight" : sortColumnName,
            sortOrder: isNull(holdingSortOrder) ? 1 : holdingSortOrder,
        };
            paginatedProductDetailList(holdingBody);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, count]);
    React.useMemo(() => {
        setCount(paginatedProductDetailListData?.originalArgs?.pageNumber);
        if (paginatedProductDetailListData?.isSuccess) {
            setData(paginatedProductDetailListData?.data);
            const fields =
                has(paginatedProductDetailListData, ["data"]) && isArray(paginatedProductDetailListData?.data) && !isEmpty(paginatedProductDetailListData?.data)
                    ? Object.keys(paginatedProductDetailListData?.data[0]).filter((field) => field !== "statusText")
                    : [];
            const statusTextIndex = fields.indexOf("status");
            if (statusTextIndex > -1) {
                fields.splice(statusTextIndex, 1);
                fields.unshift("status");
            }
            setViewFields([...fields]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginatedProductDetailListData]);
    useEffect(() => {
        if (holdingSortOrder !== null && sortColumnName !== null) {
            sortAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortColumnName, holdingSortOrder]);
    useEffect(() => {
        setShareInputValue("");
        setShareDropDownValue("");
    }, [shareModal]);
    useEffect(() => {
        if (postProductNotesData?.isSuccess) {
            setAddmodal(false);
            showToastify("success", "Product note successfully created.");
            setApicall(true);
        }
    }, [postProductNotesData]);

    useEffect(() => {
        if (ProductNotePaginationdata?.isSuccess && has(ProductNotePaginationdata, ["data", "items"]) && !isEmpty(ProductNotePaginationdata?.data)) {
            const tempArr = [...ProductNotePaginationdata?.data?.items];
            const newArr = tempArr.map((values) => {
                const myNewObject = {
                    ...values,
                    Action: true,
                    lastModified: getNormalDate(values?.lastModified),
                };
                return myNewObject;
            });
            setNotelist(newArr);
        }
    }, [ProductNotePaginationdata]);
    return (
        <>
            <ToastContainer hideProgressBar={true} />
            <div className="h-full w-full overflow-auto flex flex-col">
                <div className="flex flex-grow justify-between overflow-auto border-b items-center py-4 md:p-4">
                    <div className="w-full h-full flex flex-col">
                        <div className="flex justify-between border-b px-4 sm:px-0">
                            <div className="pb-4 flex gap-3">
                                <h1 className="text-lg mb-1">
                                    <i
                                        className="far fa-arrow-left cursor-pointer"
                                        onClick={() => {
                                            sessionStorage.setItem("currentPath", path ? path : "/Product/search");
                                            path
                                                ? navigate(path)
                                                : navigate(
                                                      sessionStorage.getItem("BasePath")?sessionStorage.getItem("BasePath"):sessionStorage.getItem("masterSearchPath") === "/Product/home" ? "/Product/home" : "/Product/search"
                                                  );
                                        }}
                                    ></i>
                                </h1>
                                <div>
                                    <div className="flex items-center gap-2 text-sm">
                                        <h1 className="text-2xl mb-1">{getProductDetailsById?.data?.Name}</h1>
                                        <div className="w-2.5 h-0.5 bg-gray-400 "></div>
                                        <h1 className="text-2xl mb-1">{getProductDetailsById?.data?.Ticker}</h1>
                                    </div>

                                    <div className="flex items-center gap-2 text-sm">
                                        <p className="text-gray-400">Date Last Modified</p>
                                        <div className="w-1.5 h-1.5 bg-gray-400 rounded-lg"></div>
                                        <p className="text-gray-400">{getNormalDate(getProductDetailsById?.data?.LastUpdated)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-3 mt-4">
                            <h1 className="text-lg">Eliminate: {`${getProductDetailsById?.data?.WeightedEliminatedPercent.toFixed(4)} %`}</h1>
                        </div>
                        <div className="flex justify-between mt-4">
                            <div className="flex gap-3">
                                <h1 className={"text-lg cursor-pointer"}>Holdings ({getProductDetailsById?.data?.HoldingsCount})</h1>
                            </div>
                        </div>

                        <Tab.Group>
                            <div className="w-100 flex justify-between gap-4 mb-4 static-scroll border-b-2 mt-7">
                                <div className="flex overflow-auto">
                                    <Tab.List className="flex h-full align-middle overflow-auto">
                                        {" "}
                                        <Tab
                                            id="current"
                                            className={tabcss}
                                            onClick={() => {
                                                setTab("current");
                                            }}
                                        >
                                            Current
                                        </Tab>
                                        <Tab
                                            disabled
                                            id="historical"
                                            className={`${tabcss} cursor-not-allowed opacity-50`}
                                            // onClick={() => {
                                            //     setTab("historical");
                                            // }}
                                        >
                                            History
                                        </Tab>
                                        <Tab
                                            id="notes"
                                            className={tabcss}
                                            onClick={() => {
                                                setTab("notes");
                                            }}
                                        >
                                            Notes
                                        </Tab>
                                    </Tab.List>
                                </div>
                                {tab === "current" && (
                                    <p
                                        className="cursor-pointer mr-3"
                                        style={{ color: "rgb(14 165 233)" }}
                                        onClick={() => {
                                            exportapicall();
                                            showToastify("success", "Export initiated");
                                        }}
                                    >
                                        Export
                                    </p>
                                )}
                                {tab === "notes" && (
                                    <div style={{ display: "flex", marginBottom: "1rem", justifyContent: "end" }}>
                                        {/* <h3 className="text-xl mx-4">{""}</h3> */}
                                        <Button className="mr-4" variant="primary" value="Add notes" onClick={() => setAddmodal(true)} />
                                    </div>
                                )}
                            </div>
                            {tab === "current" && (
                                <div className="flex-col flex h-full overflow-auto">
                                    {paginatedProductDetailListData?.isSuccess && !isEmpty(paginatedProductDetailListData?.data) && (
                                        <TailwindTable
                                            columns={columns}
                                            data={data}
                                            setCount={setCount}
                                            count={count || 1}
                                            totalLength={getProductDetailsById?.data?.HoldingsCount}
                                            setPageNumber={setPageNumber}
                                            pageNumber={pageNumber}
                                            pageArray={pageArray}
                                            keyValue="productHoldings"
                                            sortColumnName={sortColumnName}
                                            setSortColumnName={setSortColumnName}
                                            sortOrder={holdingSortOrder}
                                            setSortOrder={setHoldingSortOrder}
                                            sortingName={PRODUCTLIST.PRODUCTHOLDINGSSORTING}
                                            sortingHeadername={PRODUCTLIST.PRODUCTHOLDINGSSORTINGNAME}
                                            returnPath={"/productDetails"}
                                        />
                                    )}

                                    {paginatedProductDetailListData?.isLoading && (
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                                <tr>
                                                                    {loadingTableheader?.ProductDetailsList?.map((item) => {
                                                                        return (
                                                                            <th
                                                                                scope="col"
                                                                                className="th group px-4 py-2 text-left text-xs font-bold text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                            >
                                                                                {item}
                                                                            </th>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {range(10).map((range, i) => (
                                                                    <tr className="animate-pulse" key={i}>
                                                                        {loadingTableheader?.ProductDetailsList?.map((item) => {
                                                                            return (
                                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                                    <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {tab === "notes" && (
                                <div className="flex flex-col grow">
                                    <div style={{ flexGrow: 1 }}>
                                        {/* <div style={{ display: "flex", marginBottom: "1rem", justifyContent: "end" }}>
                                            <h3 className="text-xl mx-4">{""}</h3>
                                            <Button className="mr-4" variant="primary" value="Add notes" onClick={() => setAddmodal(true)} />
                                        </div> */}
                                        {ProductNotePaginationdata?.isSuccess && !isEmpty(ProductNotePaginationdata?.data?.items) && (
                                            <div
                                                style={{
                                                    border: "1px solid #e5e7eb",
                                                    borderRadius: "4px",
                                                    // marginLeft: "16px",
                                                    // marginRight: "16px",
                                                    marginBottom: "10px",
                                                }}
                                                id="table"
                                            >
                                                <IssuerDetailsTable
                                                    data={ProductNotePaginationdata?.isSuccess ? notelist : []}
                                                    onclick={() => setshowmodal(true)}
                                                    sortOrder={sortOrder}
                                                    itemsPerPage="5"
                                                    currentPage={currentPage}
                                                    settdata={settdata}
                                                    setEdit={setEdit}
                                                    setCurrentPage={setCurrentPage}
                                                    count={ProductNotePaginationdata?.data?.totalCount}
                                                    setshare={setShareModal}
                                                    setSortorder={setSortorder}
                                                    setProductUserId={setProductUserId}
                                                    keyValue="ProductDetails"
                                                    setHeadername={setHeadername}
                                                />
                                            </div>
                                        )}
                                        {ProductNotePaginationdata?.isLoading && (
                                            <div className="flex flex-col">
                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead className="bg-gray-50" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                                                                    <tr>
                                                                        {loadingTableheader?.ProductDetailsnotes?.map((item) => {
                                                                            return (
                                                                                <th
                                                                                    scope="col"
                                                                                    className="th group px-4 py-2 text-left text-xs font-bold text-gray-500 tracking-wider z-0 overflow-hidden whitespace-nowrap cursor-pointer"
                                                                                >
                                                                                    {item}
                                                                                </th>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="bg-white divide-y divide-gray-200">
                                                                    {range(5).map((range, i) => (
                                                                        <tr className="animate-pulse" key={i}>
                                                                            {loadingTableheader?.ProductDetailsnotes?.map((item) => {
                                                                                return (
                                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                                        <div className="bg-gray-200 h-4 w-20 rounded"></div>
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Tab.Group>
                    </div>
                </div>
            </div>
            <UserContext.Provider
                value={{
                    setNote,
                    note,
                    setAddmodal,
                    setShareInputValue,
                    shareInputValue,
                    postProductNotes,
                    setShareDropDownValue,
                    postProductNotesData,
                    userId,
                    setUserId,
                    setProductUserId,
                    tdata,
                    shareDropDownValue,
                    settdata,
                    setIsDropdownVisible,
                    isDropdownVisible,
                    setShareModal,
                    edit,
                    setshowmodal,
                    setApicall,
                    setshare,
                }}
            >
                <Modal
                    body={<AddnoteBody title={PRODUCTLIST.PRODUCT_SHORTNOTES} setnote={setNote} Addnotes={true} />}
                    showmodal={addmodal}
                    setshowmodal={setAddmodal}
                    footer={<AddProductNotesFooter value1={"Cancel"} value2={"Add"} />}
                    title={"Add Notes"}
                    size={"w-[400px]"}
                />
                <Modal
                    body={<Sharebody setEdit={setEdit} edit={edit} Bodytitle={ISSUERDETAILS?.SHORTNOTES} />}
                    showmodal={modal}
                    setshowmodal={setshowmodal}
                    footer={<ShareFooter value1={"Cancel"} value2={"Save"} UpdateApiCall={UpdateNotes} />}
                    title={ISSUERDETAILS.EDIT}
                    size={"w-[400px]"}
                />
                <ShareModal
                    title={PRODUCT.SHARE_PRODUCTNOTES}
                    size={"w-full sm:w-96"}
                    showmodal={shareModal}
                    setshowmodal={setShareModal}
                    ApiData={GetUserDetailsData?.isSuccess && GetUserDetailsData}
                    handleShareClick={handleShareClick}
                    setShareModal={setShareModal}
                    onClick={handlePostApi}
                    id={settdata}
                    setUserId={setUserId}
                />
            </UserContext.Provider>
        </>
    );
};

export default ProductDetails;
