import React, { useEffect, useRef, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import Badge from "../Badge/Badge";
import Button from "../Button/Button";
import { queryString } from "../../helpers";
import { useGetMasterSearchQuery } from "../../Services/MasterSearch/GetMasterSearchApi";
import { ISSUERDETAILS } from "../../constants/appConstants";
import { PAGECONSTANTS } from "../../constants/Common";
const MasterSearch = () => {
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const inputTypeRef = useRef(null);
    const [showSug, setShowSug] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [Data, setData] = useState([]);
    const [filterButton, setFilterButton] = useState("All");
    const buttonValues = ["All", "Issuer", "Product"];
    const GetSearchData = useGetMasterSearchQuery(queryString({ params: { searchText: searchText } }), {
        skip: searchText.length < 2,
    });
    const handelsubmit = (e, name) => {
        sessionStorage.setItem(ISSUERDETAILS.ISIN, name.isin);
        const destination = name.isIssuer ? "/issuerdetails" : "/productDetails";
        navigate(destination);
        sessionStorage.setItem("currentPath", "/issuerdetails");
        sessionStorage.setItem("productId", name.productId);
        // sessionStorage.setItem("masterSearchPath", "/dashboard")
        sessionStorage.setItem("issuerPath", sessionStorage.getItem("masterSearchPath") ? sessionStorage.getItem("masterSearchPath") : "/dashboard");
        // window.location.pathname?.includes("/issuer/home") && sessionStorage.setItem("issuerPath", "/issuer/home");
        setShowSug(false);
        setSearchText("");
        inputTypeRef.current.value = "";
    };

    const OnChangeHandler = (e) => {
        let InputValue = e.target.value;
        if (InputValue.length > 0) {
            setShowSug(true);
        } else {
            setShowSug(false);
        }
        setSearchText(InputValue);
    };
    const handleFilterChange = (e) => {
        e.preventDefault();
        setFilterButton(e.target.value);
    };
    useEffect(() => {
        setFilterButton("All");
    }, [showSug]);
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowSug(false);
                setSearchText("");
                inputTypeRef.current.value = "";
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    useEffect(() => {
        if (GetSearchData?.isSuccess) {
            const { issuerDTO, productDTO } = GetSearchData.data;
            if (filterButton === "Issuer") {
                setData(issuerDTO);
            } else if (filterButton === "Product") {
                setData(productDTO);
            } else {
                setData([...issuerDTO, ...productDTO]);
            }
        }
    }, [GetSearchData, filterButton]);
    return (
        <div ref={wrapperRef} className="md:relative drop-shadow-sm">
            <input
                className="bg-primary_opacity-50 focus:ring-2  px-12 focus:ring-primary focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-1 pl-3 ring-1 ring-slate-200 shadow-sm text-dark"
                type="text"
                aria-label="Filter projects"
                placeholder="Search..."
                onChange={OnChangeHandler}
                ref={inputTypeRef}
                style={{ backgroundColor: "rgb(219,222,232)" }}
                value={inputTypeRef?.current?.value}
            />
            <MagnifyingGlassIcon className="w-4 text-dark absolute right-2 top-2" />
            {showSug ? (
                <div className="absolute bg-white flex flex-col rounded p-4 md:w-96 mt-1 border shadow -left-14 sm:left-0 w-100 h-80 md:max-h-none ">
                    <div className="flex text-sm justify-end text-dark gap-2 border-b pb-4 items-center">
                        <label className="font-semibold">Filter:</label>
                        {buttonValues.map((value) => {
                            return (
                                <Button
                                    // disabled={true}
                                    key={value}
                                    value={value}
                                    variant={filterButton === value ? "primary" : "transparent"}
                                    size="sm"
                                    onClick={handleFilterChange}
                                // style={value === "ETF" || value === "MF" ? { backgroundColor: "rgb(128, 128, 128)" } : {}}
                                />
                            );
                        })}
                    </div>
                    <div className="h-80 overflow-auto static-scroll">
                        {Data.length !== 0 && searchText.length > 1 ? (
                            Data?.map((item) => {
                                return (
                                    <>
                                        <div className="py-4 items-center justify-between border-b last:border-b-0 flex ">
                                            <div className="flex text-dark flex-col gap-1">
                                                <h1>{item.name}</h1>
                                                <div className="flex gap-3">
                                                    <p className="text-primary cursor-pointer" onClick={(e) => handelsubmit(e, item)}>
                                                        {item.ticker}
                                                    </p>
                                                    <p>{item.text2}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <Badge value={item.badge} variant={"GrayBlue"} />
                                            </div>
                                        </div>
                                    </>
                                );
                            })
                        ) : (
                            <div className="py-4 items-center justify-between border-b last:border-b-0 flex">
                                <div className="flex text-dark flex-col gap-1">
                                    <h1>{PAGECONSTANTS.NODATAFOUND}</h1>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};
export default MasterSearch;
