import React from "react";
import Button from "../../components/Button/Button";

const PrintModalFooter = ({buttonValue,onClick,setPrint}) => {
    return (
        <div className="flex justify-center w-full"> 
            <div className="grid grid-rows-1 grid-flow-col gap-4 ">
                <div>
                    <Button value="Cancel"variant="secondary" onClick={()=>{setPrint(false)}} />
                </div>
                <div>
                    <Button
                        value={buttonValue}
                        variant="primary"
                        onClick={()=>{onClick();setPrint(false);}}
                        className={"cursor-pointer"}
                        style={{width:"100px"}} 
                    />
                </div>
            </div>
        </div>
    );
};

export default PrintModalFooter;
