import { interceptorsApiCall } from "../../globals/interceptors";
const apiWithTag = interceptorsApiCall.enhanceEndpoints({ addTagTypes: ["IssuerNotes"] });

export const CreateissuerNotesApi = apiWithTag.injectEndpoints({
	endpoints: (builder) => ({
		getIssuerDetails: builder.query({
			query: (params) => `api/Issuer/GetIssuer?${params}`,
		}),
		// getIssuerNotes: builder.query({
		// 	query: (params) => `api/Issuer/GetIssuerNotesList?${params}`,
			
		// }),
		putIssuerNotes: builder.mutation({
			query: (payload) => {
				return {
					url: "api/Issuer/UpdateIssuerNotes",
					method: "PUT",
					body: payload,
				};
			},
			invalidatesTags: ["IssuerNotes"],
			
		}),
		postIssuerNotes: builder.mutation({
			query: (payload) => {
				return {
					url: "api/Issuer/CreateIssuerNotes",
					method: "POST",
					body: payload,
				};
			},
			invalidatesTags: ["IssuerNotes"],
		}),
		postPaginatedIssuerNotes:builder.mutation({
			query:(payload)=>{
				return{
					url:"/api/Issuer/GetPaginatedIssuerNotes",
					method:"POST",
					body:payload,
				}
			},
			providesTags: ["IssuerNotes"],
		}),
		
	}),
	
});

export const { useGetIssuerDetailsQuery,usePostPaginatedIssuerNotesMutation, useGetIssuerNotesQuery, usePutIssuerNotesMutation, usePostIssuerNotesMutation } = CreateissuerNotesApi;
