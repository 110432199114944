import React, { useContext } from "react";
import Button from "../../../components/Button/Button";
import UserContext from "../../../context/modal/Contextmodel";

const AddProductNotesFooter = ({ value1, value2 }) => {
    const { setAddmodal, setNote, note, postProductNotes} = useContext(UserContext);
    const bloombergId = sessionStorage.getItem("productId");
    const body = {
        description: note,
        bloombergId: bloombergId,
    };
    const closeModal = () => {
        setAddmodal(false);
        setNote("");
    };

    const SaveModal = async () => {
        if (note?.length >= 1) {
            postProductNotes(body);
            setNote("");
        }
    };
    return (
        <>
            <div className="grid grid-rows-1 grid-flow-col gap-4">
                <Button value={value1} variant="secondary" onClick={closeModal} />
                <Button
                    value={value2}
                    variant="primary"
                    onClick={SaveModal}
                    className={note?.length >= 1 ? "" : "opacity-50 cursor-not-allowed"}
                    disabled={note?.length < 1}
                />
            </div>
        </>
    );
};

export default AddProductNotesFooter;
