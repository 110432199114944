import { interceptorsApiCall } from "../../globals/interceptors";
const apiWithTag=interceptorsApiCall.enhanceEndpoints({addTagTypes:['Post']})
export const ManageAcessControlerApi = apiWithTag.injectEndpoints({
	endpoints: (builder) => ({
		getManageAcessControlerApi:builder.query({
			query: () => "/api/Admin/AccessControl/GetAccessControlConfiguration",
			// providesTags: ['Post'],
		}),
		GrantPermissionToRoleApi: builder.mutation({
			query: (payload) => ({
					url: "/api/Admin/AccessControl/GrantPermissionToRole",
					method: "POST",
					body: payload,
			}),
			// invalidatesTags: ['Post'],
		}),
		RevokePermissionFromRoleApi: builder.mutation({
			query: (payload) => ({
					url: "/api/Admin/AccessControl/RevokePermissionFromRole",
					method: "POST",
					body: payload,
			}),
			// invalidatesTags: ['Post'],
		}),
	}),
	
});

export const { useGetAccessControlConfigurationWithOutSAQuery ,useGetManageAcessControlerApiQuery,useRevokePermissionFromRoleApiMutation,useGrantPermissionToRoleApiMutation} = ManageAcessControlerApi;
